import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { logout } from "../services/auth";
// import { setUISelection } from '@testing-library/user-event/dist/types/document';
const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root1: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    tollbar: {
      justifyContent: "space-between",
      width: "100%",
      flexDirection: "row",
    },
    user: {
      // justifyContent: 'space-between',
      // width: '100%',
      display: "flex",
      flexDirection: "column",
    },
    button: {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: "13px",
    },
    appBar1: {
      zIndex: theme.zIndex.drawer + 1,
    },
    appBar: {
      backgroundColor: "#3D1F99",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      backgroundColor: "#3D1F99",
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

interface HeaderProps {
  title: string;
  logado: boolean;
  open: boolean;
  empresa?: string;
  handleDrawerOpenClose(event: object): void;
}

const AppBarTop: React.FC<HeaderProps> = (props) => {
  // let navigate   = useNavigate();
  const classes = useStyles();
  const [textLogin, setTextLogin] = useState("Login");
  const [empresa, setEmpresa] = useState("");
  const [userName, setUserName] = useState("");
  useEffect(() => {
    if (props.logado) {
      setTextLogin("Sair");
      if (localStorage.getItem("Empresa") != null) {
        if (localStorage.getItem("mobile") != "true") {
          setEmpresa(String(localStorage.getItem("Empresa")));
        }
        setUserName(String(localStorage.getItem("UserName")));
      }
    }
  }, []);

  useEffect(() => {
    if (props.logado) {
      setTextLogin("Sair");
      if (localStorage.getItem("Empresa") != null) {
        if (localStorage.getItem("mobile") != "true") {
          setEmpresa(String(localStorage.getItem("Empresa")));
        }
        setUserName(String(localStorage.getItem("UserName")));
      }
    }
  }, [props.logado]);

  function handleLogout(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    if (props.logado) {
      // logout();
      window.location.href = "/logout";
      // navigate(`/logout`);   //       className={classes.appBar}
    } else {
      window.location.href = "/login";
    }
  }
  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.open,
      })}
    >
      <Toolbar className={classes.tollbar}>
        <IconButton
          onClick={props.handleDrawerOpenClose}
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          {props.title}
        </Typography>
        <Typography variant="h5" className={classes.title}>
          {empresa}
        </Typography>
        <div className={classes.user}>
          {userName}
          <Button
            onClick={handleLogout}
            className={classes.button}
            type="button"
            color="inherit"
          >
            {textLogin}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarTop;
