import React, { useEffect, useState, useRef} from 'react';
import MUIDataTable from "mui-datatables";
import {MUIDataTableOptions, SelectableRows, } from "mui-datatables";
import {useNavigate} from "react-router-dom";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NewIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AccountTree from '@material-ui/icons/AccountTree';

import api from '../../services/api';
import labels from '../../component/TableLabels';

interface Item {
  codigo: number,
  // codigo: number,
  nome: string
}
interface HeaderProps {
  title?: string,
}

const Estabelecimento: React.FC<HeaderProps> = (props) => {
  let navigate   = useNavigate();
  const classes = useStyles();

  const buttonEditRef = useRef<HTMLButtonElement>(null);

  const [itens, setItens] = useState([]);
  const [visible, setVisible] = useState(Boolean);
  const [ID, setID] = useState(Number);
  
  useEffect(()=>{
    // alert(localStorage.getItem('UserTipo'));

    if (localStorage.getItem('UserTipo') != 'S') {
      alert('Opção Inválida!');
      navigate('/');
    } else {
      handleBuscaDados();
    }
  }, []);

  const handleBuscaDados = ( ) => {
    api.get('estabelecimento').then(response => {
      //console.log(response);
      setItens(response.data);
      setVisible(true);
    })
  }
  // codigo').notNullable();
  // table.string('nome', 120).notNullable();
  // table.string('token', 120).notNullable();
  // table.string('fone', 12).notNullable();
  // table.string('email', 120).notNullable();
  // table.string('st_ativo
  const columns = [ {
    name: "id_estabelecimento",
    label: "ID",
    options: {
     filter: false,
     sort: false,
     display: false,
    }
   },{
    name: "codigo",
    label: "Código",
    options: {
     filter: true,
     sort: false
    }
   },{
      name: "nome",
      label: "Nome",
      options: {
       filter: true,
       sort: false
      }
    },{
      name: "email",
      label: "E-mail",
      options: {
       filter: true,
       sort: false
      }
    },{
      name: "fone",
      label: "Fone",
      options: {
        filter: true,
        sort: false
      }
   },{
    name: "qtd_veiculos",
    label: "Qtd Máx. Veículos",
    options: {
      filter: true,
      sort: false
    }
  },{
    name: "usa_frete",
    label: "Usa Frete?",
    options: {
      filter: true,
      sort: false
    }
  },  
  ];//nome,,email
  
  const handleClickEdit = ( ) => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      
      navigate(`/estabelecimentonew/${selecionado.codigo}`);
    } else {
      alert('Nenhum Item Selecionado!');
    }
  };
  
  const handleClickLocalizacao = ( ) => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      
      navigate(`/localizacao/${selecionado.codigo}`);
    } else {
      alert('Nenhum Item Selecionado!');
    }
  };
  const handleClickNew = ( ) => {
    navigate("/estabelecimentonew");
  };

  var selectRow:number = -1;
  var SelectableRows: SelectableRows = 'single';
  var options :MUIDataTableOptions = {
    onRowSelectionChange:(rowsSelectedData, allRows, rowsSelected) => {
      let y1 = rowsSelected as number[];
      selectRow = -1;
      setID(-1);
      setVisible(true);
      // console.log('1');
      y1.map((numero:number) => {
        selectRow = numero;
        setVisible(false);
        setID(numero);
        // console.log('numero---',numero);
      }); 
    },
    textLabels: labels,
    selectableRows: SelectableRows,
    onRowsDelete:() => {
      let selecionado = itens[ID] as Item;
      api.delete(`/estabelecimento/${selecionado.codigo}`).
          then(function (response) {
            alert(response.data.result);
            handleBuscaDados();
        });
    }, 
    filter: false,
      print: false,
      download: false,
      viewColumns: false,
  }

  return (
    <div>
      <div className={classes.root}>
      <Button
        ref={buttonEditRef}
        disabled = {visible}
        size="small" 
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={(event) => handleClickEdit()}
        startIcon={<EditIcon />}
      >Editar
      </Button>
      <Button
        size="small" 
        variant="contained"
        color="primary"
        onClick={(event) => handleClickNew()}
        className={classes.button}
        startIcon={<NewIcon />}
      >
        Novo
      </Button>
      
    </div>
      <MUIDataTable 
      title={'Estabelecimentos'}
      data={itens}
      columns={columns}
      options={options}
    />
    </div>
   
  );
}
export default Estabelecimento;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonExtra: {
      margin: theme.spacing(1),
      backgroundColor:'green'
    },
    buttonHide: {
      
      display: 'none',
    },
  }),
);
/**
 * <Button
        ref={buttonEditRef}
        disabled = {visible}
        size="small" 
        variant="contained"
        color="secondary"
        className={classes.buttonExtra}
        onClick={(event) => handleClickLocalizacao()}
        startIcon={<AccountTree />}
      >Localizações
      </Button>
      <Button
        ref={buttonEditRef}
        disabled = {visible}
        size="small" 
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={(event) => handleClickEdit()}
        startIcon={<EditIcon />}
      >Editar
      </Button>
 */