import React,{useEffect,useRef} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import api from '../services/api';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root1: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    prods: {
      display: 'flex',
        flex: 1,
        width: '100%',
    },
    removeLinha: {
      width: '10px',
      fontSize: '28px',
      color: '#FF0000',
      marginLeft: '3px',
      
    },resumo: {
      width: '100%',
      //display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#Fff',
      alignItems: 'center',
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      borderRadius: '5px',
      marginLeft: '5px',
      marginRight: '5px',
    },resumoTitle: { 
      marginBottom: 10,color: '#049cd9',
      fontSize: '26px',
      fontWeight: 700,
      height: 'auto',
      lineHeight: '26px',
      padding: '20px 20px 16px 0',
      textAlign:'center'
    },resumoText: { 
      color: '#049cd9',
      fontSize: '20px',
      fontWeight: 700,
      height: 'auto',
      lineHeight: '26px',
      padding: '5px',
    }
  }),
);

interface Item {
    id_viagem:number,
    cd_viagem: number,
    comissao: number,
    comissao_viagem: number,
    desconta: number,
    descricao_viagem: string,
    destino_viagem: string,
    destino?: string,
    peso:number,
    id_estabelecimento: number,
    id_motorista: number,
    id_veiculo_1: number,
    id_veiculo_2: number,
    km_medio_viagem: number,
    mercadoria_viagem: string,
    nome_motorista: string,
    obs_viagem: string,
    origem_viagem: string,
    placa_veiculo: string,
    saldo: number,
    soma: number,
    st_ativo: string,
    total: number,
    valor: number,
  }

interface HeaderProps {
  title?: string,
  estab?: number,
  pos?:number,
  id_prod?:number,
  array:Array<Item>,
  refresh:number,
  onReturn:() => void
}

const GridViagemDespesas: React.FC<HeaderProps> = (props) => {
  const classes = useStyles();
  const qtdRef = useRef<HTMLInputElement>(null);

  const itensSelect   = useRef(Array<Item>());
  const totalFrete    = useRef(0);
  const totalDespesa  = useRef(0);
  const totalComissao = useRef(0);
  const resultado = useRef(0);
  const display = useRef('a');

  const nf = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }); 
  
  useEffect(() => {
    display.current       = 'none';
    itensSelect.current   = props.array;

    totalFrete.current    = 0;
    totalDespesa.current  = 0;
    totalComissao.current = 0;
    resultado.current     = 0;
    props.array.map((item:Item, numero:number) => {
      display.current       = 'block';
      totalFrete.current    = totalFrete.current    + Number(item.valor);
      totalDespesa.current  = totalDespesa.current  + Number(item.desconta);
      totalComissao.current = totalComissao.current + Number(item.comissao);

      // console.log('atual',totalDespesa.current);
    });
    resultado.current = Number(totalFrete.current) - Number(totalDespesa.current) - Number(totalComissao.current)
  }, [props.refresh]);

  async function handleSubmit() {
    if (window.confirm("Finalizar Viagem(s)?")) {
      // console.log('lista',itensSelect.current);
      await props.array.map((item:Item, numero:number) => {
          api.post(`finalizaViagem/${item.id_viagem}/C`).then(function (response) {
            //alert(response.data.result);
            // console.log('retorn',response.data);
            if (response.data.sucess) {
              // alert('Viagem(s) Finalizada(s)!');
              props.onReturn();

            }


          })
      });
      // this.removeToCollection(11);
    }
  };

  return (   
    <div className={classes.resumo} style={{ display: `${display.current}`}}>
      <div>
        <div className={classes.resumoTitle}>Resumo</div>
        <List style={{ padding: '0'}}>
          { 
            itensSelect.current.map((value:Item, index:number) => {
              return (
                <ListItem  style={{ padding: '5px', marginBottom: '8px'}} key={index} className={classes.prods} id={`linha_${index}`} >            
                  <TextField 
                      value={value.peso}
                      id={`${index}-qtd-posicao`} 
                      style={{ width: '33%' }}
                      label="Peso" 
                      variant="outlined" />              
                  <TextField
                      value={nf.format(value.valor)}
                      id={`${index}-qtd`} 
                      style={{ width: '33%', marginLeft: 2 }}
                      label="Frete" 
                      variant="outlined" />
                  <TextField
                      value={nf.format(value.comissao)}
                      id={`${index}-qtd`} 
                      style={{ width: '33%', marginLeft: 2 }}
                      label="Comissão" 
                      variant="outlined" />
                </ListItem>
              )
            })
          }
        </List>
        <div className={classes.resumoText}><span>Total Frete:</span>{nf.format(totalFrete.current)} </div>
        <div className={classes.resumoText}><span>Total Despesas:</span>{nf.format(totalDespesa.current)} </div>
        <div className={classes.resumoText}><span>Total Comissão:</span>{nf.format(totalComissao.current)} </div>
        <div className={classes.resumoText} style={{borderTop: 'solid'}}><span>Resultado:</span>{nf.format(resultado.current)} </div>
        <div style={{textAlign:'center', marginBottom:10}}>
          <Button
              type="submit"
              size="small" 
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{width: '35%', alignItems:'center'}}>
                  Finalizar
          </Button>
        </div> 
      </div>
    </div>
  );
}

export default GridViagemDespesas;
