import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import NewIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
import AccountTree from "@material-ui/icons/AccountTree";
import NoteAdd from "@material-ui/icons/NoteAdd";
import PhotoIcon from "@material-ui/icons/Photo";

import "bootstrap/dist/css/bootstrap.min.css";
// import NoteAdd from '@material-ui/icons/AddRoad';

// import { FiArrowLeft} from 'react-icons/fi';
// import GridViagemDespesas from '../../component/GridViagemDespesas';

import api, { URL } from "../../services/api";
import labels from "../../component/TableLabels";
import ModalConfirm from "../../component/ModalConfirm";
import { Button as Button1 } from "react-bootstrap";
//import Form from 'react-bootstrap/Form';
import { Form } from "@unform/web";
import TextField from "@material-ui/core/TextField";
import Input from "@mui/material/Input";
// import TextField from "@mui/material/TextField";

import Modal from "react-bootstrap/Modal";
import ImageEvento from "../../component/Img";

const baseURL = `${URL}/files/`;

interface Item {
  id_viagem: number;
  cd_viagem: number;
  comissao: number;
  comissao_viagem: number;
  desconta: number;
  descricao_viagem: string;
  destino_viagem: string;
  destino?: string;
  peso: number;
  id_estabelecimento: number;
  id_motorista: number;
  id_veiculo_1: number;
  id_veiculo_2: number;
  km_medio_viagem: number;
  mercadoria_viagem: string;
  nome_motorista: string;
  obs_viagem: string;
  origem_viagem: string;
  placa_veiculo: string;
  saldo: number;
  soma: number;
  st_ativo: string;
  total: number;
  valor: number;
}
interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
    };
  };
}
interface FimViagem {
  data_chegada: Date;
  km_final: number;
}
/**
"id_viagem": 3,
"descricao_viagem": "Carga Netto",
"valor": "18400.80",
"nome_motorista": "Samuel",
"placa_veiculo": "AAW-9D43",
"soma": "0",
"desconta": "2400.20",
 "saldo": 9188.5,
    "comissao": 275.655,
    "total": 8912.845
*/

const Viagem: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const buttonEditRef = useRef<HTMLButtonElement>(null);
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [responsive, setResponsive] = useState("simple");
  const [ID, setID] = useState(Number);
  // const data_chegada = useState(Date);
  // const km_final     = useState(Number);
  const [visible, setVisible] = useState(false);

  const [itens, setItens] = useState({});
  const marcado = useRef(0);
  var titleConfirm = "Viagem Finalizada com Sucesso!";
  var descricaoConfirm = "Fazer Acerto?";
  var titleConfirmEdit = "Editar Despesa";
  var descricaoConfirmEdit = "Editar Despesa?";
  const [descontos, setDescontos] = useState([]);
  const listaItens = useRef(Array<Item>());
  const [refresh, doRefresh] = useState(0);
  const [IDDespesa, setIDDespesa] = useState(Number);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const [formData, setFormData] = useState({
    id_estabelecimento: 0,
    id_viagem: "",
    codigo: "",
    mercadoria: "",
    descricao: "",
    origem: "",
    destino: "",
    valor: 0,
    comissao: 0,
    km_inicio: "",
    km_fim: "",
    km_total: "",
    lt_viagem: 0,
    obs_viagem: "",
    nome_motorista: "",
    placa: "",
    placa2: "",
    soma: 0,
    desconta: 0,
    saldo: 0,
    km_final: 0,
    comissao_vl: 0,
    total: 0,
    data_saida: new Date(),
    data_chegada: new Date(),
  });

  const navigateAcerto = () => {
    // let selecionado = itens[ID] as Item;
    // navigate(`/viagemacerto/${selecionado.id_viagem}`);
  };

  const handleClose = async () => {
    setShow(false);
    // let selecionado = itens[ID] as Item;
    await api
      .post(`finalizaViagem/${formData.id_viagem}/F`, formData)
      .then(function (response) {
        if (response.data.sucess) {
          setShowConfirm(true);
        } else {
          // console.log(response.data);
        }
      });
  };
  const handleCloseCancel = async () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    // console.log('descontos',descontos);
    handleBuscaDados();
    // handleBuscaDespesas();
  }, []);

  useEffect(() => {
    doRefresh((prev) => refresh + 2);
  }, [listaItens.current]);

  const handleBuscaDados = async () => {
    // console.log('busca viagensfinalizadas/F');
    // passando C de Concluido
    var id_viagem = 0;
    await api
      .get(`viagensandamento`)
      .then((response) => {
        console.log("retorno", response.data);
        id_viagem = response.data.id_viagem;
        setFormData(response.data);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
      });
    handleBuscaDespesas(id_viagem);
  };
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    // console.log(tableMeta);
    // console.log(updateValue);
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return nf.format(value);
  };
  const columnsDespesas = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        display: true,
        sort: false,
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        display: false,
        sort: false,
      },
    },
    {
      name: "litros",
      label: "Litros",
      options: {
        filter: true,
        display: false,
        sort: false,
      },
    },
    {
      name: "media",
      label: "Média",
      options: {
        filter: true,
        display: false,
        sort: false,
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "foto",
      label: "Foto",
      options: {
        filter: true,
        display: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShowImage(true)}
            >
              {" "}
              <PhotoIcon />
            </div>
          );
        },
      },
    },
  ];
  var selectRow: number = -1;
  var SelectableRows: SelectableRows = "single";
  var options: MUIDataTableOptions = {
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setDescontos([]);
      let y1 = rowsSelected as number[];
      selectRow = -1;
      marcado.current = 0;
      setID(-1);
      setVisible(true);
      y1.map((numero: number) => {
        // let item = itens[numero] as Item;
        selectRow = numero;
        setVisible(false);
        setID(numero);
      });
      doRefresh((prev) => refresh + 1);
    },
    // selectableRowsHeader: itens.length > 0,
    selectableRows: SelectableRows,
    textLabels: labels,
    selectableRowsOnClick: true,
    responsive: "standard",
    // responsiveScroll

    rowsPerPage: 15,
    rowsPerPageOptions: [15, 20, 50, 100],
  };
  var SelectableRowsDespesas: SelectableRows = "none";
  var optionsDespesas: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const id = Number(rowData[0]);
      setIDDespesa(id);
      if (rowData[1] != "") {
        setSourceImage(baseURL + rowData[1]);
      } else {
        setSourceImage("");
      }
      // setShowImage(true);
      // console.log('click numero---', rowData);
    },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (evt: any) => {
        // alert("Double click!");
        setShowConfirmEdit(true);
      },
    }),
    selectableRows: SelectableRowsDespesas,
    textLabels: labels,
    filter: false,
    print: false,
    responsive: "standard",
    download: false,
    viewColumns: false,
  };
  const handleClickNew = () => {
    navigate("/estabelecimentonew");
  };
  const handleClickEditDespesa = () => {
    if (IDDespesa >= 0) {
      // let selecionado = itens[ID] as Item;
      navigate(`/viagem/${formData.id_viagem}/despesaapp/${IDDespesa}`);
    } else {
      alert("Nenhuma Despesa Selecionada!");
    }
  };
  async function handleClickPosicao() {
    setShow(!show);
  }
  const handleClickNewDespesa = () => {
    // console.log(selecionado);

    if (ID >= 0) {
      // let selecionado = itens[ID] as Item;
      // console.log(selecionado.id_viagem);
      navigate(`/viagem/${formData.id_viagem}/despesaapp`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  function handleClickEditDespesa1(id_evento: number) {
    console.log(id_evento);
    setIDDespesa(id_evento);
    setShowConfirmEdit(true);
  }
  const handleClickNewViagemResumo = () => {
    navigate(`/viagemresumo`);
  };
  const handleOnReturn = async () => {
    marcado.current = 0;
    setID(-1);
    listaItens.current = Array<Item>();
    //await alert('Viagem(s) Finalizada(s)!');
    doRefresh(0);
    handleBuscaDados();
  };
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  }
  async function handleBuscaDespesas(id_viagem: Number) {
    await api.get(`eventoviagem/${id_viagem}/2`).then((response) => {
      //descontos
      console.log("descontos", response.data);
      setDescontos(response.data);
    });
  }
  const formatDate = (date: Date) => {
    var d = new Date(date);
    d.setHours(d.getHours() + 3); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas
    // console.log(d);
    try {
      let formattedDate = Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(d);

      // console.log(formattedDate);
      return formattedDate;
    } catch (error) {
      return date;
    }
  };
  return (
    <div style={{ paddingLeft: "0", marginRight: "0" }}>
      <div className={classes.root}>
        <div className={classes.buttons}>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            className={classes.buttonAcerto}
            onClick={(event) => handleClickPosicao()}
            startIcon={<AccountTree />}
          >
            Finalizar
          </Button>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            // className={classes.buttonExtra}
            onClick={(event) => handleClickNewDespesa()}
            startIcon={<NoteAdd />}
          >
            Nova Despesa
          </Button>
        </div>
      </div>
      <div className={classes.grid}>
        <div style={{ flexDirection: "column", width: "100%" }}>
          <h2 style={{ textAlign: "center", marginTop: "7px" }}>
            Viagem em Andamento
          </h2>
          <TextField
            disabled
            type="text"
            value={formData.destino}
            onChange={handleInputChange}
            style={{ width: "100%", fontSize: 12 }}
            // className={classes.field}
            id="destino"
            name="destino"
            label="Destino"
          />
          <div
            style={{
              flexDirection: "row",
              width: "100%",
              display: "flex",
              marginTop: "2px",
            }}
          >
            <TextField
              disabled
              type="text"
              value={formataValor(formData.comissao_vl, 0, 0)}
              onChange={handleInputChange}
              // className={classes.field}
              style={{ marginRight: "10px" }}
              id="comissao"
              name="comissao"
              label="Comissão em R$"
            />
            <TextField
              disabled
              type="text"
              value={formatDate(formData.data_saida)}
              onChange={handleInputChange}
              style={{ marginLeft: "10px" }}
              id="saida"
              name="saidad"
              label="Data de Saída"
            />
          </div>
        </div>
      </div>

      <div className={classes.tabelas}>
        <div
          className={classes.tabela}
          style={{
            width: "100%",
            borderRadius: 20,
          }}
        >
          {/* <MUIDataTable 
                title={`Despesas`}
                data={descontos}
                columns={columnsDespesas}
                options={optionsDespesas}
              /> */}
          {descontos.map((despesa: any, index: any) => (
            <div
              className={classes.post}
              style={
                despesa.aprovada == "A"
                  ? { borderLeft: "8px solid #fbe20b" } // amarelo
                  : despesa.aprovada == "N"
                  ? { borderLeft: "8px solid #fd0e0e" } // vermelho
                  : despesa.aprovada == "P"
                  ? { borderLeft: "8px solid #8e8cff" }
                  : { borderLeft: "8px solid #34cb01" } // verde
              }
              // style={{  }}
              onClick={(event) =>
                handleClickEditDespesa1(despesa.id_evento_viagem)
              }
            >
              <div>
                <div
                  className={classes.postHeader}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <span
                    style={{
                      backgroundColor:
                        despesa.aprovada == "A"
                          ? "#fbe20b" // amarelo
                          : despesa.aprovada == "N"
                          ? "#fd0e0e" // vermelho
                          : despesa.aprovada == "P"
                          ? "#8e8cff"
                          : "#34cb01", // verde,
                    }}
                    className={classes.postStatus}
                  >
                    {
                      despesa.aprovada == "A"
                        ? "Pendente" // amarelo
                        : despesa.aprovada == "N"
                        ? "Negada" // vermelho
                        : despesa.aprovada == "P"
                        ? "Parcial"
                        : "Aprovada" // verde
                    }
                  </span>
                  <div
                    style={{ justifyContent: "end" }}
                    className={classes.postPropertyHeader}
                  >
                    <span
                      style={{ marginRight: "5px" }}
                      className={classes.postHeaderValue}
                    >
                      Valor:
                    </span>
                    <TextField
                      style={{}}
                      inputProps={{ readOnly: true }}
                      className={classes.postHeaderValue}
                      value={formataValor(despesa.valor, null, null)}
                    />
                  </div>
                </div>
                <div className={classes.postDados}>
                  <div className={classes.postPropertyHeader}>
                    <span className={classes.postProperty}>Descrição:</span>
                    <TextField
                      inputProps={{ readOnly: true }}
                      style={{ textAlign: "center" }}
                      className={classes.postValue}
                      value={despesa.descricao}
                    />
                  </div>
                  <div className={classes.postPropertyHeader}>
                    <span className={classes.postProperty}>Local:</span>

                    <TextField
                      inputProps={{ readOnly: true }}
                      className={classes.postValue}
                      value={despesa.local}
                    />
                  </div>
                  <div className={classes.postPropertyHeader}>
                    <span className={classes.postProperty}>Data:</span>

                    <TextField
                      inputProps={{ readOnly: true }}
                      className={classes.postValue}
                      value={formatDate(despesa.created_at)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* } */}
      <Modal style={{ marginTop: 100 }} show={show} onHide={handleCloseCancel}>
        <Form //className={classes.dadosViavem} ref={formRef}
          onSubmit={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Finalizar Viagem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1"> */}
            <div className="mb-3">
              {/* <div style={{marginTop: 10, marginLeft: 20}}> */}
              <span
                style={{ marginTop: 10, marginLeft: 20 }}
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required"
              >
                Data da Chegada
              </span>
              {/* </div> */}
              <TextField
                required
                type="date"
                value={formData.data_chegada}
                onChange={handleInputChange}
                // className="mb-3"//
                className={classes.field}
                id="data_chegada"
                name="data_chegada"
                // label="Data da Chegada"
              />
            </div>
            <div className="mb-3">
              {/* <h2>Km Final</h2> */}
              <TextField
                required
                type="number"
                value={formData.km_final}
                onChange={handleInputChange}
                className={classes.field}
                id="km_final"
                name="km_final"
                label="KM Final"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button1 variant="secondary" onClick={handleCloseCancel}>
              Fechar
            </Button1>
            <Button1 variant="primary" type="submit" onClick={handleClose}>
              Finalizar
            </Button1>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalConfirm
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirm(false);
          }
        }}
      />
      <ModalConfirm
        title={titleConfirmEdit}
        description={descricaoConfirmEdit}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirmEdit}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            // navigateAcerto();
            handleClickEditDespesa();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirmEdit(false);
          }
        }}
      />
      <div>
        <ImageEvento
          show={showImage}
          source={sourceImage}
          onReturn={() => {
            setShowImage(false);
            setSourceImage("");
          }}
        />
      </div>
    </div>
  );
};
export default Viagem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: 'flex',
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      marginRight: "0px",

      // paddingLeft:'10px',
      // margin: theme.spacing(1),
    },
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    grid: {
      display: "flex",
      flexDirection: "row",
    },
    grid70: {
      display: "none",
      width: "65%",
    },
    grid100: {
      display: "none",
      width: "100%",
    },
    field: {
      textAlign: "center",
      width: "90%",
      fontSize: "22px",
      paddingTop: "0px",
      paddingBottom: "0px",
      marginTop: "0px",
      marginBottom: "0px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
    },
    buttonAcerto: {
      marginLeft: "2px",
      backgroundColor: "green",
    },
    buttons: {
      flexDirection: "row",
      justifyContent: "space-between",
      display: "flex",
    },
    button: {
      margin: theme.spacing(1),
    },
    postHeader: {
      flexDirection: "row",
      // alignItems: "center",
    },
    postHeaderValue: {
      color: "#1A0657",
      fontWeight: "bold",
      fontSize: 20,
      // backgroundColor: "#6df2a6",
    },
    postHeaderValuePendente: {
      color: "#1A0657",
      fontWeight: "bold",
      fontSize: 20,
      // backgroundColor: "#ffdcdc",
      paddingLeft: "10px",
      borderRadius: "5px",
    },
    postPropertyHeader: {
      alignItems: "inherit",
      display: "flex",
      width: "100%",
    },
    postProperty: {
      fontSize: 13,
      color: "#1A0657",
      paddingLeft: 10,
      // fontWeight:'bold'
    },
    postValue: {
      fontSize: 12,
      color: "#737380",
      textAlign: "center",
      paddingLeft: "5px",
      width: "100%",
      marginLeft: 8,
    },
    postStatus: {
      paddingTop: "7px",
      paddingLeft: "2px",
      paddingRight: "6px",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      fontWeight: "bold",
      fontSize: 12,
      marginLeft: "-1px",
    },
    postDados: {
      marginTop: 3,
      marginBottom: 3,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    post: {
      marginBottom: 8,
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      paddingRight: 10,
      backgroundColor: "#FFFFFF",
      borderRadius: 7,
      borderBottomColor: "#C5CADA",
      borderTopColor: "#C5CADA",
      borderRightColor: "#C5CADA",
      justifyContent: "center",
      borderLeft: "8px solid #1A0657",
      flexDirection: "column",
      display: "flex",
    },
  })
);
