import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import MUIDataTable from "mui-datatables";
import { useReactToPrint } from "react-to-print";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Typography } from "@mui/material";
import Print from "@material-ui/icons/Print";
import { BsArrowBarLeft } from "react-icons/bs";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Link, useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { darkBackground } from "../../assets/scss/themejs";
import ModalConfirm from "../../component/ModalConfirm";
import ModalOK from "../../component/ModalConfirm";

import api, { URL } from "../../services/api";
import labels from "../../component/TableLabels";
import ImageEvento from "../../component/Img";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUp from "@mui/icons-material/ArrowCircleUp";

const baseURL = `${URL}/files/`;

interface Item {
  id_viagem: number;
  descricao: string;
  origem: string;
  destino: string;
  valor: string;
  comissao: string;
  km_total: string;
  obs_viagem: string;
  nome_motorista: string;
  placa: string;
  placa2: string;
  data_saida: Date;
  data_chegada: Date;
  id_estabelecimento: number;
  codigo: string;
  mercadoria: string;
  km_inicio: string;
  km_fim: string;
  lt_viagem: number;
  soma: number;
  desconta: number;
  saldo: number;
  comissao_vl: number;
  total: number;
  open: number;
}
interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
      id: string;
    };
  };
}

const formatDate = (date: Date) => {
  var d = new Date(date);
  d.setHours(d.getHours() + 3); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas
  // console.log(d);
  try {
    let formattedDate = Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(d);

    // console.log(formattedDate);
    return formattedDate;
  } catch (error) {
    return date;
  }
};

const ViagemAcerto: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const [concluida, setConcluida] = useState(false);

  const [IDs, setIDs] = useState("");
  const [habitidadoEdicao, setHabitidadoEdicao] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [adicionais, setAdicionais] = useState([]);
  const [descontos, setDescontos] = useState([]);
  const [open, setOpen] = useState([0, 0, 0]);
  const [show, setShow] = useState<boolean>(false);
  const [titleOK, setTitleOK] = useState("");
  var titleConfirm = "Finalizar Viagens?";
  const [formData, setFormData] = useState([
    {
      id_estabelecimento: 0,
      id_viagem: "",
      codigo: "",
      mercadoria: "",
      descricao: "",
      origem: "",
      destino: "",
      valor: 0,
      comissao: 0,
      km_inicio: "",
      km_fim: "",
      km_total: "",
      lt_viagem: 0,
      obs_viagem: "",
      nome_motorista: "",
      placa: "",
      placa2: "",
      soma: 0,
      desconta: 0,
      saldo: 0,
      comissao_vl: 0,
      total: 0,
      open: 0,
      data_saida: new Date(),
      data_chegada: new Date(),
      adicionais: [],
      descontos: [],
    },
  ]);
  const [opens, setOpens] = useState([0, 0]);

  const [totais, setTotais] = useState({
    freteTotal: 0,
    adiantamentoTotal: 0,
    descontoTotal: 0,
    comissaoTotal: 0,
    saldoTotal: 0,
    totalGeral: 0,
    km_total: 0,
    diesel_LT: 0,
    km_medio: 0,
    data_saida: new Date(),
    data_chegada: new Date(),
    finaliza: new Array(),
  });

  const formRef = useRef<FormHandles>(null);
  const printRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  // const ReactPDFPrint = () => {
  const componentRef = useRef<any>();
  const pageStyle = `
  @media all {
    .page -
      break {
        display: none;
      }
  }
  
  @media print {
    html,
    body {
      height: initial!important;
      overflow: initial!important; -
      webkit - print - color - adjust: exact;
    }
  }
  
  @media print {
    .page -
      break {
        margin - top: 1 rem;
        display: block;
        page -
          break -before: auto;
      }
  }
  
  @page {
    size: auto;
    margin: 20 mm;
  }
`;
  const handlePrint = useReactToPrint({
    // onBeforePrint: () => {
    //   setConcluida(true);
    // },
    content: () => componentRef.current,
    documentTitle: "AcertoViagem",
    pageStyle: pageStyle,

    // onAfterPrint: () => alert('print')
  });

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      var dadosAtuais = formData;
      // dadosAtuais.slice(0, 2);
      setFormData(dadosAtuais);
      const parametros = params.id as String;

      handleBuscaDados(parametros);
      //@ts-ignore
      setIDs(parametros);
    }
  }, []);

  async function handleBuscaDados(idPar: String) {
    // console.log("id handleBuscaDados", idPar);
    var dadosViagens = new Array();
    var dadosTotais = {
      freteTotal: 0,
      adiantamentoTotal: 0,
      descontoTotal: 0,
      comissaoTotal: 0,
      saldoTotal: 0,
      totalGeral: 0,
      km_total: 0,
      diesel_LT: 0,
      km_medio: 0,
      data_saida: new Date(),
      data_chegada: new Date(),
      finaliza: [],
    };
    // if (idPar > 0 && idPar != undefined) {
    // console.log('id', idPar);
    await api.get(`viagenscustos/${idPar}`).then((response) => {
      console.log("viagem", response.data);
      // console.log("viagem0000", response.data[0]);
      // console.log('tamanho',response.data.length)
      if (response.data.length > 0) {
        //console.log('viagem0000', response.data[0].st_ativo)
        if (response.data[0].st_ativo == "C") {
          alert("Viagem já Concluída!");
          setConcluida(true);
          // navigate("/viagens");
        } else if (response.data[0].st_ativo != "F") {
          alert("Viagem ainda não Finalizada!");
          navigate("/viagens");
        }
        // var dadosAtuais = formData;
        // dadosAtuais.push(response.data[0]);

        dadosViagens = response.data;
        // dadosTotais.adiantamentoTotal =

        // setFormData(response.data);
      } else {
        // console.log('tamanho',response.data)
        // navigate("/viagens");
      }
    });

    await api
      .get(`eventoviagens/${idPar}/1`)
      .then((response) => {
        //adicionais
        //@ts-ignore
        response.data.map((data1, index1) => {
          dadosViagens.map((data, index) => {
            if (data.id_viagem == data1.id_viagem) {
              dadosViagens[index].adicionais.push(data1);
            }
          });
        });
        // console.log("tipo 1", response.data);
        // setAdicionais(response.data);
      })
      .catch((error) => {
        console.log("erro", error);
      })
      .finally(() => {
        //console.log('id333333', idPar);
      });
    await api.get(`eventoviagens/${idPar}/2`).then((response) => {
      //descontos
      // console.log('descontos', response.data)
      // dadosViagens[].descontos = new Array();
      //@ts-ignore
      response.data.map((data1, index1) => {
        dadosViagens.map((data, index) => {
          if (data.id_viagem == data1.id_viagem) {
            dadosViagens[index].descontos.push(data1);
          }
        });
      });
      // console.log("tipo 2", response.data);

      // setDescontos(response.data);
    });
    var data_saida = new Date();
    var data_chegada = new Date();
    var fechamento = new Array();
    dadosViagens.map((data1, index1) => {
      dadosTotais.adiantamentoTotal += data1.soma;
      dadosTotais.descontoTotal += data1.desconta;
      dadosTotais.freteTotal += Number(data1.valor);
      dadosTotais.comissaoTotal += Number(data1.comissao_vl);
      dadosTotais.saldoTotal += data1.saldo;
      dadosTotais.totalGeral += data1.total;
      dadosTotais.diesel_LT += data1.lt_viagem;
      dadosTotais.km_total += data1.km_total;
      if (index1 == 0) {
        dadosTotais.data_saida = data1.data_saida;
      }
      if (index1 == dadosViagens.length) {
        dadosTotais.data_chegada = data1.data_chegada;
      }
      fechamento.push({
        id_viagem: data1.id_viagem,
        km_total: Number(data1.km_total),
        km_medio: (Number(data1.km_total) / data1.lt_viagem).toFixed(2),
        diesel_LT: data1.lt_viagem,
      });
    });
    //@ts-ignore
    dadosTotais.finaliza = fechamento;

    //@ts-ignore
    dadosTotais.km_medio = (
      Number(dadosTotais.km_total) / dadosTotais.diesel_LT
    ).toFixed(2);
    //@ts-ignore
    setFormData(dadosViagens);
    // console.log("dadosViagens", dadosViagens);
    setTotais(dadosTotais);
    // }
    // }
    // );
  }
  async function handleBuscaDespesas(idPar: String) {
    const id1 = Number(idPar);

    if (id1 > 0 && id1 != undefined) {
      var dados = formData;

      dados.map((data, index) => {
        if (data.id_viagem == idPar) {
          dados[index].open = dados[index].open == 1 ? 0 : 1;
        } else {
          // opens1.push(data.open);
        }
      });

      setFormData(dados);
      newState(ignored + 1);
    }
  }
  const [ignored, newState] = useState(0);

  const TipoValor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    return TipoValor.format(value);
  };
  const columns = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  const columnsDespesas = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "litros",
      label: "Litros",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "media",
      label: "Média",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  var selectRow: number = -1;
  var SelectableRows: SelectableRows = "none";
  var options: MUIDataTableOptions = {
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      let y1 = rowsSelected as number[];
      selectRow = -1;
      // setID(-1);
      // setVisible(true);
      // console.log('1');
      y1.map((numero: number) => {
        selectRow = numero;
        // setVisible(false);
        // setID(numero);
        // console.log('numero---',numero);
      });
    },
    onRowClick: (rowData) => {
      setSourceImage(baseURL + rowData[1]);
      setShowImage(true);
    },
    selectableRows: SelectableRows,
    textLabels: labels,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
  };
  async function handleClickEditSave() {
    if (window.confirm("Salvar Edição da Viagem(s)?")) {
      setHabitidadoEdicao(true);
      // console.log(formData);
      await api
        .put(`viagem/${formData[0].codigo}`, formData)
        .then((response) => {
          if (response.data.sucess) {
            alert("Viagem Atualizada!");
          } else {
            alert(response.data.result);
          }
        });
    }
  }
  async function handleClickReabrir() {
    // if (window.confirm("Reabrir Viagem?")) {
    //   await api.post(`finalizaViagem/${ID}/R`).then(function (response) {
    //     //alert(response.data.result);
    //     // console.log('retorn',response.data);
    //     if (response.data.sucess) {
    //       alert("Viagem Reaberta com Sucesso!");
    //       navigate("/lancamentos");
    //     }
    //   });
    // }
  }
  const handleClickEdit = () => {
    // if (habitidadoEdicao) {
    //   // setColorEdit('inherit');
    //   // setTextEdit('EDITAR');
    //   setHabitidadoEdicao(false);
    // } else {
    //   // setColorEdit('secondary');
    //   // setTextEdit('SALVAR');
    //   setHabitidadoEdicao(true);
    // }
    // if (ID >= 0) {
    //   //let selecionado = itens[ID] as Item;
    //   //history(`/viagem/${selecionado.id_viagem}`);
    // } else {
    //   alert("Nenhum Item Selecionado!");
    // }
  };
  async function handleSubmit(event: FormEvent) {
    setShowConfirm(true);
  }
  async function handleSubmitOK() {
    if (IDs != "") {
      await api
        .post(`finalizaViagens`, { finaliza: totais.finaliza })
        .then(function (response) {
          if (response.data.sucess) {
            setTitleOK("Viagens Finalizadas com Sucesso!");
            setShow(true);
          } else {
            setTitleOK("Erro ao Finalizar as Viagens. Refaça o processo!");
            setShow(true);
          }
        });
      // }
    } else {
      //await api.put(`localizacoesestab/${IDEstab}/${IDLocalizacao}`, formData);
      // alert('Editado');
    }
  }
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    if (id == "comissao") {
      const comissao_vl = formData[0].valor * (Number(value) / 100);
      // console.log('id campo',id);
      // console.log('comissao',comissao_vl);
      // setFormData({...formData, [String(id)]:value, ['comissao_vl']:Number(comissao_vl)});
      // setFormData({...formData, [});
    } else {
      setFormData({ ...formData, [id]: value });
    }
  }
  function abreDespesas(id: string) {
    console.log(open[0]);
  }

  return (
    <div style={{ marginTop: "10px" }} ref={componentRef}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-center",
          paddingRight: "6px",
        }}
      >
        <Link
          ref={linkRef}
          to={`/viagens`}
          style={{ display: "flex", fontSize: "17px" }}
        >
          <BsArrowBarLeft style={{ height: "23px", width: "36px" }} />
          Voltar
        </Link>
        <Print onClick={(event) => handlePrint()} />
      </div>
      <div className={classes.root}>
        <Container className={classes.formdados}>
          <Form
            className={classes.dadosViavem}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <fieldset className={classes.titulo}>
              <h2 className={classes.tituloh2}>Acerto de Viagem</h2>
            </fieldset>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                required
                disabled
                // value={formData[0]?.nome_motorista}
                onChange={handleInputChange}
                className={classes.field}
                id="nome_motorista"
                name="nome_motorista"
                label="Motorista"
              />
              <TextField
                required
                disabled
                value={formData[0].placa}
                onChange={handleInputChange}
                className={classes.field}
                id="placa"
                name="placa"
                label="Placa"
              />
              <TextField
                disabled
                value={formData[0].placa2}
                onChange={handleInputChange}
                className={classes.field}
                id="placa2"
                name="placa2"
                label="Placa 2"
              />
            </div>
            <Typography className={classes.textos}>
              Viagens selecionadas
            </Typography>
            {formData.map((dados, index) => (
              <div key={index}>
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    padding: "10px",
                    border: "1px solid #e5e5e6",
                    borderRadius: "8px",
                    boxShadow:
                      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex",
                      alignItems: "flex-center",
                    }}
                  >
                    {/* <TextField
                      style={{ width: "15%", visibility: "hidden" }}
                      required
                      disabled
                      type="number"
                      value={dados.codigo}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="codigo"
                      name="codigo"
                      label="Código"
                    /> */}
                    <TextField
                      style={{ width: "25%" }}
                      disabled
                      value={dados.id_viagem}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="id_viagem"
                      name="id_viagem"
                      label="ID"
                    />
                    <TextField
                      required
                      disabled={habitidadoEdicao}
                      value={dados.descricao}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="descricao"
                      name="descricao"
                      label="Descrição"
                    />
                    <TextField
                      required
                      disabled
                      value={TipoValor.format(dados.valor)}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="valor"
                      name="valor"
                      label="Valor de Frete"
                    />
                    <TextField
                      required
                      disabled={habitidadoEdicao}
                      value={dados.comissao}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="comissao"
                      name="comissao"
                      label="Comissão em %"
                    />
                    <TextField
                      required
                      disabled
                      value={TipoValor.format(dados.soma)}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="soma"
                      name="soma"
                      label="Adi. (+)"
                    />
                    <TextField
                      required
                      disabled
                      value={TipoValor.format(dados.desconta)}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="desconta"
                      name="desconta"
                      label="Des. (-)"
                    />
                    <TextField
                      required
                      disabled
                      value={TipoValor.format(dados.saldo)}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="saldo"
                      name="saldo"
                      label="Saldo"
                    />
                    <TextField
                      required
                      disabled
                      value={TipoValor.format(dados.comissao_vl)}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="comissao_vl"
                      name="comissao_vl"
                      label="Comissão em R$"
                    />
                    <TextField
                      required
                      disabled
                      value={TipoValor.format(dados.total)}
                      onChange={handleInputChange}
                      className={classes.field}
                      id="total"
                      name="total"
                      label="Total"
                    />
                  </div>

                  {dados.open == 0 && (
                    <div style={{ textAlign: "center" }}>
                      <ArrowCircleDownIcon
                        className={classes.textos}
                        sx={{
                          cursor: "pointer",
                          width: "45px",
                          height: "45px",
                          color: "#04b01a",
                        }}
                        onClick={(event) =>
                          handleBuscaDespesas(dados.id_viagem)
                        }
                      />
                    </div>
                    // <Typography
                    //   // id={dados.open}
                    //   // id=""
                    //   key={ignored}
                    //   // key={index}
                    //   className={classes.textos}
                    //   sx={{ cursor: "pointer" }}
                    //   onClick={(event) => handleBuscaDespesas(dados.id_viagem)}
                    // >
                    //   Ver Detalhes...
                    //   {/* <ArrowDropDown /> */}
                    //   <ArrowCircleDownIcon />
                    // </Typography>
                  )}
                  {dados.open == 1 && (
                    <div style={{ textAlign: "center" }}>
                      <ArrowCircleUp
                        className={classes.textos}
                        sx={{
                          cursor: "pointer",
                          width: "45px",
                          height: "45px",
                          color: "#d91616",
                        }}
                        onClick={(event) =>
                          handleBuscaDespesas(dados.id_viagem)
                        }
                      />
                    </div>
                    // <Typography
                    //   // key={dados.open}
                    //   key={ignored}
                    //   className={
                    //     dados.open == 1 ? classes.textos : classes.textosHidden
                    //   }
                    //   sx={{ cursor: "pointer" }}
                    //   onClick={(event) => handleBuscaDespesas(dados.id_viagem)}
                    // >
                    //   Fechar Detalhes...
                    //   <ArrowCircleUp />
                    // </Typography>
                  )}
                  {/* Se era pra mostrar abre os detalhes */}
                  {dados.open == 1 && (
                    <div
                      className={classes.tabelas}
                      key={ignored}
                      style={{ flexDirection: "column" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex",
                          alignItems: "flex-center",
                        }}
                      >
                        <TextField
                          disabled={habitidadoEdicao}
                          value={formatDate(dados.data_saida)}
                          defaultValue={dados.data_saida}
                          onChange={handleInputChange}
                          className={classes.field}
                          id="data_saida"
                          name="data_saida"
                          label="Data de Saída"
                        />
                        <TextField
                          disabled={habitidadoEdicao}
                          value={formatDate(dados.data_chegada)}
                          onChange={handleInputChange}
                          className={classes.field}
                          id="data_chegada"
                          name="data_chegada"
                          label="Data de Chegada"
                        />
                        <TextField
                          required
                          disabled={habitidadoEdicao}
                          value={dados.mercadoria}
                          onChange={handleInputChange}
                          className={classes.field}
                          id="mercadoria"
                          name="mercadoria"
                          label="Mercadoria"
                        />
                        <TextField
                          required
                          disabled={habitidadoEdicao}
                          value={dados.origem}
                          onChange={handleInputChange}
                          className={classes.field}
                          id="origem"
                          name="origem"
                          label="Origem"
                        />
                        <TextField
                          required
                          disabled={habitidadoEdicao}
                          value={dados.destino}
                          onChange={handleInputChange}
                          className={classes.field}
                          id="destino"
                          name="destino"
                          label="Destino"
                        />
                        <TextField
                          required
                          disabled
                          value={dados.km_total}
                          onChange={handleInputChange}
                          className={classes.field}
                          id="km_total"
                          name="km_total"
                          label="Km Percorrido"
                        />
                        <TextField
                          required
                          disabled
                          value={dados.lt_viagem}
                          onChange={handleInputChange}
                          className={classes.field}
                          id="litros"
                          name="litros"
                          label="Diesel Litros"
                        />
                        <TextField
                          required
                          disabled
                          value={(
                            Number(dados.km_total) / dados.lt_viagem
                          ).toFixed(2)}
                          onChange={handleInputChange}
                          className={classes.field}
                          id="km_litro"
                          name="km_litro"
                          label="Km/L"
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex",
                          alignItems: "flex-center",
                        }}
                      >
                        <div
                          className={classes.tabela}
                          style={{ width: "45%", paddingRight: "5px" }}
                        >
                          <MUIDataTable
                            title={`Adicionais`}
                            data={dados.adicionais}
                            columns={columns}
                            options={options}
                          />
                        </div>
                        <div
                          className={classes.tabela}
                          style={{ width: "55%", paddingLeft: "5px" }}
                        >
                          <MUIDataTable
                            title={`Despesas`}
                            data={dados.descontos}
                            columns={columnsDespesas}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <Typography className={classes.textos}>Totais</Typography>
            <Divider
              textAlign="center"
              style={{ width: "100%", marginBottom: "20px" }}
            ></Divider>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                disabled={habitidadoEdicao}
                value={formatDate(totais.data_saida)}
                defaultValue={totais.data_saida}
                onChange={handleInputChange}
                className={classes.field}
                id="data_saida"
                name="data_saida"
                label="Data de Saída"
              />
              <TextField
                disabled={habitidadoEdicao}
                value={formatDate(totais.data_chegada)}
                onChange={handleInputChange}
                className={classes.field}
                id="data_chegada"
                name="data_chegada"
                label="Data de Chegada"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(totais.freteTotal)}
                onChange={handleInputChange}
                className={classes.field}
                id="valor"
                name="valor"
                label="Valor de Frete"
              />
              <TextField
                required
                disabled
                value={totais.diesel_LT}
                onChange={handleInputChange}
                className={classes.field}
                id="diesel_LT"
                name="diesel_LT"
                label="Diesel Total"
              />
              <TextField
                required
                disabled
                value={totais.km_total}
                onChange={handleInputChange}
                className={classes.field}
                id="km_total"
                name="km_total"
                label="KM Total"
              />
              <TextField
                required
                disabled
                value={totais.km_medio}
                onChange={handleInputChange}
                className={classes.field}
                id="km_medio"
                name="km_medio"
                label="KM/L"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                required
                disabled
                value={TipoValor.format(totais.adiantamentoTotal)}
                onChange={handleInputChange}
                className={classes.field}
                id="soma"
                name="soma"
                label="Adi. (+)"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(totais.descontoTotal)}
                onChange={handleInputChange}
                className={classes.field}
                id="desconta"
                name="desconta"
                label="Des. (-)"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(totais.saldoTotal)}
                onChange={handleInputChange}
                className={classes.field}
                id="saldo"
                name="saldo"
                label="Saldo"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(totais.comissaoTotal)}
                onChange={handleInputChange}
                className={classes.field}
                id="comissao_vl"
                name="comissao_vl"
                label="Comissão em R$"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(totais.totalGeral)}
                onChange={handleInputChange}
                className={classes.field}
                id="total"
                name="total"
                label="Total"
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              {!concluida && (
                <>
                  {!habitidadoEdicao && (
                    <Button
                      onClick={(event) => handleClickEditSave()}
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{ width: "35%" }}
                    >
                      SALVAR
                    </Button>
                  )}
                  {habitidadoEdicao && (
                    <Button
                      onClick={(event) => handleClickEdit()}
                      size="small"
                      variant="contained"
                      color="inherit"
                      style={{ width: "35%" }}
                    >
                      EDITAR
                    </Button>
                  )}
                  {habitidadoEdicao && (
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ width: "35%" }}
                    >
                      Finalizar Todas as Viagens?
                    </Button>
                  )}
                </>
              )}
              {concluida && (
                <Button
                  onClick={(event) => handleClickReabrir()}
                  size="small"
                  variant="contained"
                  color="default"
                  style={{ width: "35%" }}
                >
                  Reabrir
                </Button>
              )}
            </div>
          </Form>
        </Container>
      </div>
      <ModalConfirm
        title={titleConfirm}
        description={""}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // navigateAcerto();
            handleSubmitOK();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirm(false);
            // navigate(`/manutencoes`);
          }
        }}
      />
      <ModalOK
        title={titleOK}
        description={""}
        // noText="Não"
        // yesText="Sim"
        neutralText={"OK"}
        confirm={false}
        show={show}
        setShow={(show1: boolean) => {
          // alert(show1);
          navigate(`/viagens`);
          if (show1) {
            // navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            // setShowConfirm(false);
            // navigate(`/manutencoes`);
          }
        }}
      />
      <ImageEvento
        show={showImage}
        source={sourceImage}
        onReturn={() => {
          setShowImage(false);
          setSourceImage("");
        }}
      />
    </div>
  );
};
export default ViagemAcerto;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["height", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    tabelasShift: {
      marginTop: "10px",
      width: "100%",
      display: "block",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
      height: `0px`,
      transition: theme.transitions.create(["height", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["height", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      // marginLeft: drawerWidth,
      width: `calc(100% - 5px)`,
      transition: theme.transitions.create(["height", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    tabela: {
      width: "50%",
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "8px",
    },
    dadosViavem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonExtra: {
      margin: theme.spacing(1),
      backgroundColor: "green",
    },
    button: {
      margin: theme.spacing(1),
    },
    titulo: {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderBottom: "2px solid rgb(0 0 0 / 20%)",
      width: "100%",
      padding: "20px",
      marginBottom: "5px",
    },
    tituloh2: {
      textAlign: "center",
      color: "#444445",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    fieldID: {
      display: "none",
    },
    buttonHide: {
      display: "none",
    },
    formdados: {
      width: "100%",
      maxWidth: "10280px",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      paddingBottom: "8px",
      //display: 'flex',
      flexDirection: "row",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
    },
    textos: {
      fontSize: "0.95rem",
      fontWeight: 500,
      color: darkBackground,
      // backgroundColor:secondary800,
      paddingLeft: "5px",
      paddingTop: "2px",
      paddingBottom: "2px",
      marginBottom: "2px",
      borderRadius: "8px",
      textAlign: "center",
    },
    textosHidden: {
      display: "nome",
    },
  })
);
