import React, { useEffect, useRef, ChangeEvent, useState, FormEvent } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Link,useNavigate, useParams } from 'react-router-dom';
import { FiArrowLeft} from 'react-icons/fi';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import api from '../../services/api';

interface RouterProps {
  history?: {};
  location?: {};
  id?:string;
  path?: string;
  match?: {params: {
    id: string}};    
};
 

const VeiculoNovo: React.FC<RouterProps> = (props:RouterProps) => {
    let navigate   = useNavigate();
    const params = useParams();
    const classes = useStyles();
    var [IDProduto, setIDProduto] = useState(Number);
    const [formData, setFormData] = useState({
      id_veiculo:'',
      placa:'',
      tipo:'',
      ano:'',
      modelo:'',
      marca:'',
      cor:'',
      renavam:'',
      id_mercadoria:'',
    });

    const formRef = useRef<FormHandles>(null);
    const linkRef = useRef<HTMLAnchorElement>(null);
    
    useEffect(() => {
      if (localStorage.getItem('UserTipo') == 'M') {
        alert('Opção Inválida!');
        navigate('/');
      } else {
          setIDProduto(-1);
          // console.log('idVeiculo', props.match);

          var idVeiculo:number = Number(params.id);
          // console.log('idVeiculo', idVeiculo);

          if (idVeiculo > 0) {  
              formData.id_veiculo = idVeiculo.toString();
              setIDProduto(idVeiculo);            
              api.get(`veiculo/${idVeiculo}`).then(response => {
                // console.log(response.data);
                  setFormData(response.data);                           
              });            
          }
        }
      }, []);    

    async function handleSubmit(event: FormEvent) {
          var regex = '[A-Z]{3}-[0-9][0-9A-Z][0-9]{2}';
          var placa = formData.placa;
          
          if (placa.match(regex)) {
            // console.log(IDProduto);
              if (IDProduto == -1 ) {
                  await api.post(`veiculo`, formData).
                  then(function (response) {
                    
                    if(response.data.sucess) {
                      //alert(`/cadastraveiculo/${response.data.id}/motorista`);
                      navigate(`/cadastraveiculo/${response.data.id}/motorista`);  
                    } else {
                      alert(response.data.result);
                    }
                })
              } else {
                  await api.put(`veiculo/${IDProduto}`, formData).
                  then(function (response) {
                    if(response.data.sucess) {
                      alert('Editado');
                      navigate(`/veiculos`);
                    } else {
                      alert(response.data.result);
                    }
                  });
              };
              
          } else {
              alert('Placa Inválida!')            
          }
    };
    function handleInputChange(event: ChangeEvent<HTMLInputElement>){
        const {id, value} = event.target;
        // let placa1 = value;
        if (id == 'placa') {
          // placa1 = value.toUpperCase();
          // var placa = value.substring(0,3)+value.substring(3,15).replace(/\D/g, "");
          var placa = value;
          
          const placaFormatada = placa.replace(/[^a-zA-Z0-9]/g, '');

          // Insere o hífen e o último dígito
          // const placaFinal = placaFormatada.replace(/\w{3})(\d)(\w)(\d{2})/, '$1-$2$3$4');
          const placaFinal = placaFormatada.replace(/([A-Z]{3})(\d)(\w{1})(\d{2})/, '$1-$2$3$4');


          setFormData({...formData, [id]:placaFinal.toUpperCase()});
        } else {
          setFormData({...formData, [id]:value});
        }
    };
    const handleChange = (event: SelectChangeEvent) => {
      const {value} = event.target;        
      setFormData({...formData, ['tipo']:value});
    };
    return (        
        <Container  className={classes.paper}>
            <Link ref={linkRef} to={`/veiculos`}>
                <FiArrowLeft />
                Voltar
            </Link>
            <Form className={classes.root} ref={formRef} onSubmit={handleSubmit}>
                <fieldset className={classes.titulo}>
                  <h2 className={classes.tituloh2}>Cadastro de Veículo</h2>
                </fieldset>
                <TextField value={formData.id_veiculo} onChange={handleInputChange} className={classes.fieldID} id="id_veiculo" name="id_veiculo" label="ID" />
                <TextField required value={formData.placa} inputProps={{ maxLength: 8 }}   onChange={handleInputChange} className={classes.field} id="placa" name="placa" label="Placa" />
                <TextField required value={formData.ano} type='number' onChange={handleInputChange} className={classes.field} id="ano" name="ano" label="Ano" />
                <TextField required value={formData.modelo} onChange={handleInputChange} className={classes.field} id="modelo" name="modelo" label="Modelo" />
                <TextField value={formData.marca} onChange={handleInputChange} className={classes.field} id="marca" name="marca" label="Marca" />
                <TextField value={formData.cor} onChange={handleInputChange} className={classes.field} id="cor" name="cor" label="Cor" />
                <TextField value={formData.renavam} onChange={handleInputChange} className={classes.field} id="renavam" name="renavam" label="Renavam" />
                <InputLabel required style={{alignItems:'left'}} id="demo-simple-select-label">Tipo de Veículo</InputLabel>
            
                    <Select
                      // labelId="tipo"
                      labelId="demo-simple-select-label"
                      required 
                      id="tipo"
                      className={classes.field} 
                      value={formData.tipo}
                      // label="Tipo de Usuário"
                      label=""
                      placeholder="Tipo de Usuário"
                      // style={{color:'red'}}
                      // required
                      onChange={handleChange}
                    >
                      <MenuItem value={'C'}>Cavalo</MenuItem>
                      <MenuItem value={'R'}>Carroceria</MenuItem>
                      <MenuItem value={'G'}>Geral</MenuItem>
                    </Select>
                <Button
                type="submit"
                    size="small" 
                    variant="contained"
                    color="primary">
                        Salvar
                </Button>                
            </Form>
        </Container>
    )
}

export default VeiculoNovo;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
          //width: '25ch',
        },
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    titulo: {
        borderTop:'none',
        borderRight:'none',
        borderLeft:'none',
        borderBottom: '2px solid #e5e5e6',
        width: '100%',
        padding: '20px',
    },
    tituloh2: {
        textAlign: 'center',
        color: '#444445',
    },
    field:{
        width: '70%',
        fontSize: '22px',
        paddingTop: '13px',
        paddingBottom: '13px',
    },
    fieldID:{
        display: 'none',
    },
    paper: {
        width: '80%',
        backgroundColor: '#Fff',
        border: '1px solid #e5e5e6',
        borderRadius: '8px',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
      
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    formControl: {
     //margin: theme.spacing(0),
      minWidth: '70%',
    },
    selectEmpty: {
      marginTop: '-10px',
      //fontSize: '22px',
        paddingTop: '13px',
        paddingBottom: '13px',
    },
  }));