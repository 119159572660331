const labels = {
    body: {
      noMatch: "Desculpe, nenhum registro encontrado",
      toolTip: "Classificar",
      //columnHeaderTooltip: column => `Sort for ${column.label}`
    },
    pagination: {
      next: "Próxima página",
      previous: "Página anterior",
      rowsPerPage: "Linhas por página:",
      displayRows: "de",
    },
    toolbar: {
      search: "Pesquisar",
      downloadCsv: "Download CSV",
      print: "Imprimir",
      viewColumns: "Exibir colunas",
      filterTable: "Tabela de filtros",
    },
    filter: {
      all: "Tudo",
      title: "Filtros",
      reset: "Reiniciar",
    },
    viewColumns: {
      title: "Mostrar colunas",
      titleAria: "Mostrar/ocultar colunas da tabela",
    },
    selectedRows: {
      text: "Linha(s) selecionada(s)",
      delete: "Excluir",
      deleteAria: "Excluir linhas selecionadas",
    },
  };


export default labels;