import React, {
  useEffect,
  useRef,
  ChangeEvent,
  useState,
  FormEvent,
  useReducer,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useNavigate, useParams } from "react-router-dom";

import api from "../../../services/api";
import ModalConfirm from "../../../component/ModalConfirm";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getEstab } from "../../../services/auth";
import InputLabel from "@mui/material/InputLabel";

interface RouterProps {
  history?: {};
  location?: {};
  match?: {
    params: {
      id: string;
    };
  };
}

const NewUser: React.FC<RouterProps> = (props: RouterProps) => {
  let navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const titleConfirm = "Cadastro de Evento";
  let [value, setState] = useState(true);
  const [msgReturnInsert, setMsgReturnInsert] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPosConfirm, setShowPosConfirm] = useState(false);
  const [formData, setFormData] = useState({
    id_evento: 0,
    id_estabelecimento: 0,
    descricao: "",
    tipo: "1",
    st_ativo: "S",
  });

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    }
    var idEvento: number = Number(params.id);
    if (idEvento > 0) {
      handleBuscaDados(idEvento);
    } else {
      var estab: number = Number(getEstab());
      setFormData({ ...formData, ["id_estabelecimento"]: estab });
    }
  }, []);

  async function handleBuscaDados(idEvento: Number) {
    await api
      .get(`evento/${idEvento}`)
      .then((response) => {
        setFormData(response.data);
      })
      .catch((error) => {
        // console.log('erro', error);
      });
  }
  async function handleSubmit(event: FormEvent) {
    var erro = false;

    console.log("erro", erro);

    if (!erro) {
      setShowConfirm(true);
    } else {
      setState(!value);
    }
  }
  async function handleInsert() {
    // const formEnvio = { ... };
    console.log(formData);
    var response = null;
    if (formData.id_evento == 0) {
      response = await api.post("/evento", formData);
    } else {
      response = await api.put(`/evento/${formData.id_evento}`, formData);
    }
    if (response.data.sucess) {
      // setShowConfirm(true);

      // alert("Usuário cadastrado com Sucesso!");
      navigate(`/eventos`);
    } else {
      //   // console.log(response.data);
      //   alert(response.data.error);
      setMsgReturnInsert(response.data.result);
      setShowPosConfirm(true);
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    // console.log('change', 'mudou');

    // formRef.current?.setErrors({});

    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  }
  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });

    // setAge(event.target.value as string);
  };
  return (
    <Container maxWidth="sm" className={classes.paper}>
      <Form className={classes.root} ref={formRef} onSubmit={handleSubmit}>
        <fieldset className={classes.titulo}>
          <h2 className={classes.tituloh2}>Novo Evento</h2>
        </fieldset>
        <TextField
          required
          // type="email"
          placeholder="Descrição"
          onChange={handleInputChange}
          className={classes.field}
          value={formData.descricao}
          id="descricao"
          name="descricao"
          label="Descrição"
        />
        <InputLabel
          required
          style={{ alignItems: "left", width: "70%" }}
          id="demo-simple-select-label1"
        >
          Tipo de Evento
        </InputLabel>
        <Select
          labelId="demo-simple-select-label1"
          required
          name="tipo"
          className={classes.field}
          value={formData.tipo}
          label="Tipo de Usuário"
          placeholder="Tipo de Evento"
          onChange={handleChange}
        >
          <MenuItem value={"1"}>Soma</MenuItem>
          <MenuItem value={"2"}>Desconta</MenuItem>
        </Select>

        <InputLabel
          required
          style={{ alignItems: "left", width: "70%" }}
          id="demo-simple-select-label"
        >
          Status
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          required
          name="st_ativo"
          className={classes.field}
          value={formData.st_ativo}
          label=""
          placeholder="Status"
          onChange={handleChange}
        >
          <MenuItem value={"S"}>Ativo</MenuItem>
          <MenuItem value={"N"}>Inativo</MenuItem>
        </Select>
        <Button
          className={classes.buttons}
          type="submit"
          size="small"
          variant="contained"
          color="primary"
        >
          {formData.id_evento == 0 ? "Cadastrar" : "Atualizar"}
        </Button>
      </Form>
      <ModalConfirm
        title={titleConfirm}
        description={
          formData.id_evento == 0
            ? "Confirmar Cadastro do Evento?"
            : "Atualizar Cadastro do Evento?"
        }
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          if (show1) {
            handleInsert();
            setShowConfirm(false);
            // setShowPosConfirm(true);
          } else {
            setShowConfirm(false);
          }
        }}
      />
      <ModalConfirm
        title={titleConfirm}
        description={msgReturnInsert}
        // noText="Não"
        // yesText="Sim"
        neutralText="OK"
        confirm={false}
        show={showPosConfirm}
        setShow={(show1: boolean) => {
          // if (show1) {
          setShowPosConfirm(false);
          // window.location.href = '/usuarios';
        }}
      />
    </Container>
  );
};

export default NewUser;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      //width: '25ch',
    },
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttons: {
    height: "55px",
    display: "flex",
    width: "70%",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    alignItems: "center",
  },
  titulo: {
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderBottom: "2px solid #e5e5e6",
    width: "70%",
  },
  tituloh2: {
    textAlign: "center",
    color: "#444445",
  },
  field: {
    width: "70%",
    // height: "75px",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    backgroundColor: "#Fff",
    border: "1px solid #e5e5e6",
    borderRadius: "8px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: "70%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
