import React, {
  useEffect,
  useRef,
  ChangeEvent,
  useState,
  FormEvent,
  useReducer,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useNavigate } from "react-router-dom";
import base64 from "base-64";
import api from "../../services/api";
import ModalConfirm from "../../component/ModalConfirm";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// import { login, loginUser, loginUserEstab } from "../../services/auth";

interface RouterProps {
  history?: {};
  location?: {};
  match?: {
    params: {
      id: string;
    };
  };
}
interface UserTable {
  email: string;
  password: string;
  passwordConfirm: string;
  web: "WEB";
  tipo: string;
  nome: string;
  sobrenome: string;
  end: string;
  tel: string;
  tel2: string;
}

const NewUser: React.FC<RouterProps> = (props: RouterProps) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const user1 = {};
  const titleConfirm = "Cadastro de Usuário";
  let [value, setState] = useState(true);
  const [msgReturnInsert, setMsgReturnInsert] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPosConfirm, setShowPosConfirm] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    //web:'WEB',
    tipo: "",
    nome: "",
    sobrenome: "",
    end: "",
    tel: "",
    tel2: "",
    error: 0,
  });

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    }
  }, []);

  async function handleSubmit(event: FormEvent) {
    // console.log(formData);
    var erro = false;
    if (formData.password != formData.passwordConfirm) {
      erro = true;
      // console.log('erro', 'erro');
      // console.log(formRef.current);
      // formRef.current?.setErrors({passwordConfirm:"erro"})
      formRef.current?.setFieldError(
        "passwordConfirm",
        "A Senha precisa ser igual!"
      );
      setState(!value);
      //   setFormData({...formData, ['passwordConfirm']:formData.passwordConfirm});
      // console.log('erro1', 'erro1');
    }
    if (formData.tel.length > 15) {
      erro = true;
      formRef.current?.setFieldError("tel", "Número de telefone inválido!");
      setState(!value);
      //alert("Número de telefone inválido!");
    }
    console.log("erro", erro);

    if (!erro) {
      setShowConfirm(true);
    } else {
      setState(!value);
      const error1 = formData.error + 1;
      setFormData({ ...formData, ["error"]: error1 });
    }
  }
  async function handleInsert() {
    const encText = base64.encode(formData.password);
    // setFormData({...formData, [id]:value});

    const formEnvio = { ...formData, ["password"]: encText };
    // console.log(formEnvio);
    const response = await api.post("/userconfig", formEnvio);
    if (response.data.sucess) {
      // setShowConfirm(true);

      // alert("Usuário cadastrado com Sucesso!");
      navigate(`/usuarios`);
    } else {
      //   // console.log(response.data);
      //   alert(response.data.error);
      setMsgReturnInsert(response.data.error);
      setShowPosConfirm(true);
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    // console.log('change', 'mudou');

    // formRef.current?.setErrors({});

    const { id, value } = event.target;
    if (id == "tel") {
      var phone = value.replace(/\D/g, "");
      phone = phone.replace(/(\d{2})?(\d{5})?(\d{4})?/, "($1) $2-$3");
      //console.log(phone);
      setFormData({ ...formData, [id]: phone });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  }
  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;

    setFormData({ ...formData, ["tipo"]: value });

    // setAge(event.target.value as string);
  };
  return (
    <Container maxWidth="sm" className={classes.paper}>
      <Form
        className={classes.root}
        ref={formRef}
        onSubmit={handleSubmit}
        id={String(formData.error)}
      >
        <fieldset className={classes.titulo}>
          <h2 className={classes.tituloh2}>Novo Usuário</h2>
        </fieldset>
        <TextField
          required
          // type="email"
          placeholder="Nome"
          onChange={handleInputChange}
          className={classes.field}
          value={formData.nome}
          id="nome"
          name="nome"
          label="Nome"
        />
        <TextField
          required
          // type="email"
          placeholder="Endereço"
          onChange={handleInputChange}
          className={classes.field}
          value={formData.end}
          id="end"
          name="end"
          label="Endereço"
        />
        <TextField
          required
          type="phone"
          placeholder="Telefone"
          onChange={handleInputChange}
          className={classes.field}
          value={formData.tel}
          id="tel"
          name="tel"
          inputProps={{ maxLength: 15 }}
          label="Telefone"
          error={formRef.current?.getFieldError("tel") ? true : false} //pattern:"([0-9]{2}) [0-9]{5}-[0-9]{4}"
          helperText={formRef.current?.getFieldError("tel")}
        />
        <TextField
          required
          type="email"
          placeholder="E-mail"
          onChange={handleInputChange}
          className={classes.field}
          value={formData.email}
          id="email"
          name="email"
          label="E-mail"
        />
        <TextField
          required
          onChange={handleInputChange}
          className={classes.field}
          value={formData.password}
          inputProps={{ minLength: 5 }}
          id="password"
          name="password"
          label="Senha"
          type="password"
          placeholder="Senha"
        />
        {/* {value && */}
        <TextField
          required
          onChange={handleInputChange}
          className={classes.field}
          value={formData.passwordConfirm}
          inputProps={{ minLength: 5 }}
          id="passwordConfirm"
          error={
            formRef.current?.getFieldError("passwordConfirm") ? true : false
          }
          helperText={formRef.current?.getFieldError("passwordConfirm")}
          name="passwordConfirm"
          label="Repita a Senha"
          type="password"
          placeholder="Repita a senha"
        />
        {/* <Box sx={{ minWidth: 120 }}> */}
        <InputLabel
          required
          style={{ alignItems: "left" }}
          id="demo-simple-select-label"
        >
          Tipo de Usuário
        </InputLabel>

        <Select
          // labelId="tipo"
          labelId="demo-simple-select-label"
          required
          id="tipo"
          className={classes.field}
          value={formData.tipo}
          // label="Tipo de Usuário"
          label=""
          placeholder="Tipo de Usuário"
          // style={{color:'red'}}
          // required
          onChange={handleChange}
        >
          <MenuItem value={"A"}>Administrador</MenuItem>
          <MenuItem value={"M"}>Motorista</MenuItem>
        </Select>
        {/* </Box> */}
        <Button
          className={classes.buttons}
          type="submit"
          size="small"
          variant="contained"
          color="primary"
        >
          Cadastrar
        </Button>
      </Form>
      <ModalConfirm
        title={titleConfirm}
        description="Confirmar Cadastro do Usuário?"
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          if (show1) {
            handleInsert();
            setShowConfirm(false);
            // setShowPosConfirm(true);
          } else {
            setShowConfirm(false);
          }
        }}
      />
      <ModalConfirm
        title={titleConfirm}
        description={msgReturnInsert}
        // noText="Não"
        // yesText="Sim"
        neutralText="OK"
        confirm={false}
        show={showPosConfirm}
        setShow={(show1: boolean) => {
          // if (show1) {
          setShowPosConfirm(false);
          // window.location.href = '/usuarios';
        }}
      />
    </Container>
  );
};

export default NewUser;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      //width: '25ch',
    },
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttons: {
    height: "55px",
    display: "flex",
    width: "70%",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    alignItems: "center",
  },
  titulo: {
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderBottom: "2px solid #e5e5e6",
    width: "70%",
  },
  tituloh2: {
    textAlign: "center",
    color: "#444445",
  },
  field: {
    width: "70%",
    height: "75px",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    backgroundColor: "#Fff",
    border: "1px solid #e5e5e6",
    borderRadius: "8px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: "70%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
