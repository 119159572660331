import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as Switch,
  Navigate,
} from "react-router-dom";
// import ReactDOM from "react-dom/client";
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Dashboard from "./pages/dashboard/Default";

import Veiculo from "./pages/Veiculo";
import VeiculoNew from "./pages/Veiculo/new";
import VeiculoReport from "./pages/Veiculo/report";
import VeiculoMotorista from "./pages/Veiculo/vinculamotorista";

// import Transferencia from './pages/Transferencia';
// import TransferenciaNew from './pages/Transferencia/new';

import ViagemNew from "./pages/ViagemNew";
import Viagem from "./pages/Viagem";
import ViagemAcerto from "./pages/Viagem/viagemAcerto";
import ViagemAcertoPrint from "./pages/Viagem/viagemAcertoPrint";
import ViagemMultiAcerto from "./pages/Viagem/viagemMultiAcerto";
import ViagemResumo from "./pages/ViagemNew/viagemResumo";
import ViagemPendente from "./pages/Viagem/viagemPendente";
import ViagemPendenteApp from "./pages/Viagem/viagemPendenteApp";
import ViagemAprovacoes from "./pages/Viagem/viagemAprovacoes";
import ViagemReport from "./pages/Viagem/report";

import Despesa from "./pages/Despesa";
import ViewDespesa from "./pages/Despesa/viewDespesa";
import AppDespesa from "./pages/Despesa/despesaApp";
import DespesaAprova from "./pages/Despesa/indexAprova";

import User from "./pages/User/";
import NewUser from "./pages/User/NewUser";
import NovoCadastroUser from "./pages/User/NovoCadastro";
import NovoCadastroUserMobile from "./pages/User/NovoCadastroMobile";

import Motorista from "./pages/Motorista/";
import NovoMotorista from "./pages/Motorista/NovoMotorista";

import Estabelecimento from "./pages/Estabelecimento";
import EstabelecimentoNew from "./pages/Estabelecimento/new";
import EstabelecimentoNovoCadastro from "./pages/Estabelecimento/NovoCadastro";

import Evento from "./pages/Estabelecimento/Evento";
import EventoNew from "./pages/Estabelecimento/Evento/new";

import Login from "./pages/Login";
import RecuperarSenha from "./pages/Login/recuperarSenha";
import RedefinirSenha from "./pages/Login/redefinirSenha";
import Logout from "./pages/Login/logout";

import Manutencao from "./pages/Manutencao";
import ManutencaoNew from "./pages/Manutencao/new";

import ManutencaoApp from "./pages/Manutencao/indexApp";
import ManutencaoNewApp from "./pages/Manutencao/manutencaoApp";

import { isAuthenticated } from "./services/auth";

// @ts-ignore
const PrivateRoute = ({ children }) => {
  const redirectTo = "/login";
  // const isAuthenticate =  isAuthenticated();
  // console.log('autenticado',isAuthenticated());
  return isAuthenticated() ? children : <Navigate to={redirectTo} />;
};
// const PrivateRoute = ({ component:Component, ...rest }: any) => (
//   <Route
//     {...rest}
//     render={props =>
//       (isAuthenticated() ? (
//         <Component {...props} />
//       ) : (
//         // <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
//         <Navigate to="/login" replace state={{ from: props.location }} />
//       ))
//     }
//   />
// );

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home />,
//     // errorElement: <ErrorPage />,
//   },
//   {
//     path: "viagens",
//     element: <Viagem />,
//   },
//   {
//     path: "usuarios",
//     element: <User />,
//   },
//   {
//     path: "cadastrausuario",
//     element: <NewUser />,
//   },
// ]);
function Rotas() {
  return (
    // <React.StrictMode>
    //   <RouterProvider router={router} />
    // </React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/recuperar" element={<RecuperarSenha />} />
        <Route path="/redefinir/:id" element={<RedefinirSenha />} />

        {localStorage.getItem("mobile") == "true" ? (
          <Route
            path="/"
            element={
              <PrivateRoute>
                <ViagemPendenteApp />
              </PrivateRoute>
            }
          />
        ) : (
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/viagens"
          element={
            <PrivateRoute>
              <Viagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/viagem"
          element={
            <PrivateRoute>
              <ViagemNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/viagem/:id"
          element={
            <PrivateRoute>
              <ViagemNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/viagemacerto/:id"
          element={
            <PrivateRoute>
              <ViagemAcerto />
            </PrivateRoute>
          }
        />
        <Route
          path="/viagemacertoprint/:id"
          element={
            <PrivateRoute>
              <ViagemAcertoPrint />
            </PrivateRoute>
          }
        />
        <Route
          path="/viagemacertos/:id"
          element={
            <PrivateRoute>
              <ViagemMultiAcerto />
            </PrivateRoute>
          }
        />
        <Route
          path="/viagem/:id/despesa"
          element={
            <PrivateRoute>
              <Despesa />
            </PrivateRoute>
          }
        />
        <Route
          path="/viagem/:id/despesa/:cod"
          element={
            <PrivateRoute>
              <Despesa />
            </PrivateRoute>
          }
        />

        <Route
          path="/viagem/:id/despesaapp"
          element={
            <PrivateRoute>
              <AppDespesa />
            </PrivateRoute>
          }
        />
        <Route
          path="/viagem/:id/despesaapp/:cod"
          element={
            <PrivateRoute>
              <AppDespesa />
            </PrivateRoute>
          }
        />

        <Route
          path="/viagemresumo"
          element={
            <PrivateRoute>
              <ViagemResumo />
            </PrivateRoute>
          }
        />

        <Route
          path="/relatorios"
          element={
            <PrivateRoute>
              <ViagemReport />
            </PrivateRoute>
          }
        />

        <Route
          path="/aprovacoes"
          element={
            <PrivateRoute>
              <ViagemAprovacoes />
            </PrivateRoute>
          }
        />
        {localStorage.getItem("mobile") != "true" && (
          <Route
            path="/lancamentos"
            element={
              <PrivateRoute>
                <ViagemPendente />
              </PrivateRoute>
            }
          />
        )}
        {localStorage.getItem("mobile") == "true" && (
          <Route
            path="/lancamentos"
            element={
              <PrivateRoute>
                <ViagemPendenteApp />
              </PrivateRoute>
            }
          />
        )}

        <Route
          path="/usuarios"
          element={
            <PrivateRoute>
              <User />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastrausuario"
          element={
            <PrivateRoute>
              <NewUser />
            </PrivateRoute>
          }
        />

        <Route
          path="/veiculos"
          element={
            <PrivateRoute>
              <Veiculo />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastraveiculo"
          element={
            <PrivateRoute>
              <VeiculoNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastraveiculo/:id"
          element={
            <PrivateRoute>
              <VeiculoNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastraveiculo/:id/motorista"
          element={
            <PrivateRoute>
              <VeiculoMotorista />
            </PrivateRoute>
          }
        />
        <Route
          path="/resumoveiculo/"
          element={
            <PrivateRoute>
              <VeiculoReport />
            </PrivateRoute>
          }
        />

        <Route
          path="/motoristas"
          element={
            <PrivateRoute>
              <Motorista />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastramotorista"
          element={
            <PrivateRoute>
              <NovoMotorista />
            </PrivateRoute>
          }
        />

        <Route
          path="/estabelecimento"
          element={
            <PrivateRoute>
              <Estabelecimento />
            </PrivateRoute>
          }
        />
        <Route
          path="/estabelecimentonew"
          element={
            <PrivateRoute>
              <EstabelecimentoNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/estabelecimentonew/:id"
          element={
            <PrivateRoute>
              <EstabelecimentoNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/estabelecimento/:id/evento"
          element={
            <PrivateRoute>
              <Evento />
            </PrivateRoute>
          }
        />
        <Route
          path="/eventos"
          element={
            <PrivateRoute>
              <Evento />
            </PrivateRoute>
          }
        />
        <Route
          path="/evento"
          element={
            <PrivateRoute>
              <EventoNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/evento/:id"
          element={
            <PrivateRoute>
              <EventoNew />
            </PrivateRoute>
          }
        />
        {localStorage.getItem("mobile") == "true" ? (
          <Route
            path="/manutencoes"
            element={
              <PrivateRoute>
                <ManutencaoApp />
              </PrivateRoute>
            }
          />
        ) : (
          <Route
            path="/manutencoes"
            element={
              <PrivateRoute>
                <Manutencao />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="/manutencoesapp/:id/lancamento"
          element={
            <PrivateRoute>
              <ManutencaoNewApp />
            </PrivateRoute>
          }
        />
        <Route
          path="/manutencoesapp/:id/lancamento/:cod"
          element={
            <PrivateRoute>
              <ManutencaoNewApp />
            </PrivateRoute>
          }
        />
        <Route
          path="/manutencao"
          element={
            <PrivateRoute>
              <ManutencaoNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/manutencao/:id"
          element={
            <PrivateRoute>
              <ManutencaoNew />
            </PrivateRoute>
          }
        />

        {localStorage.getItem("mobile") == "true" ? (
          <Route
            path="/novacadastro/usuario"
            element={<NovoCadastroUserMobile />}
          />
        ) : (
          <Route path="/novacadastro/usuario" element={<NovoCadastroUser />} />
        )}
        <Route
          path="/novacadastro/estabelecimento"
          element={
            <PrivateRoute>
              <EstabelecimentoNovoCadastro />
            </PrivateRoute>
          }
        />

        {/* <PrivateRoute component={ViagemNew} path="/viagem" exact/> */}
        {/* <PrivateRoute component={ViagemNew} path="/viagem/:id" exact/> */}
        {/* <PrivateRoute component={Despesa} path="/viagem/:id/despesa" exact/> */}
        {/* <PrivateRoute component={Viagem} path="/viagens" exact/> */}
        {/* <PrivateRoute component={ViagemAcerto} path="/viagemacerto/:id" exact/>                           */}
        {/* <PrivateRoute component={ViagemPendente} path="/lancamentos" exact/> */}
      </Switch>
    </BrowserRouter>
  );
}
export default Rotas;
// // @ts-ignore
// const Rotas = () => {
//     return (
//         <BrowserRouter>
//             <Switch>
//                 {/* <Route path="/login" element={<PrivateRoute redirectTo="/login">
//                   <Login/>
//                 </PrivateRoute>} /> */}
//                 <Route path="/logout" children={<Logout/>} />

//                 {/* <Route path="/login" element={<Login/>} />
//                 <Route path="/logout" element={<Logout/>} /> */}
//                 {/* <Route path="/signup" component={() => <h1>SignUp</h1>} /> */}
//                 {/* <PrivateRoute path="/home" component={() => <h1>App</h1>} />

//                 <PrivateRoute component={Home} path="/" exact />
//                 <PrivateRoute component={Estabelecimento} path="/estabelecimento" exact />
//                 <PrivateRoute component={EstabelecimentoNew} path="/estabelecimentonew" exact/>
//                 <PrivateRoute component={EstabelecimentoNew} path="/estabelecimento/:id" exact/>

//                 <PrivateRoute component={Localizacao} path="/localizacao/:id" exact/>
//                 <PrivateRoute component={LocalizacaoNew} path="/localizacaonew/:estab" exact/>
//                 <PrivateRoute component={LocalizacaoNew} path="/localizacao/:estab/:loc" exact/>

//                 <PrivateRoute component={Posicao} path="/posicao/:estab/:loc" exact/>
//                 <PrivateRoute component={PosicaoNew} path="/posicaonew/:estab/:loc" exact/>
//                 <PrivateRoute component={PosicaoNew} path="/posicao/:estab/:loc/:pos" exact/>

//                 <PrivateRoute component={Produto} path="/produto" exact/>
//                 <PrivateRoute component={ProdutoNew} path="/produto/:id" exact/>
//                 <PrivateRoute component={ProdutoNew} path="/produtonew" exact/>

//                 <PrivateRoute component={Marca} path="/marca" exact/>
//                 <PrivateRoute component={MarcaNew} path="/marca/:id" exact/>
//                 <PrivateRoute component={MarcaNew} path="/marcanew" exact/>

//                 <PrivateRoute component={Mercadoria} path="/mercadoria" exact/>
//                 <PrivateRoute component={MercadoriaNew} path="/mercadoria/:id" exact/>
//                 <PrivateRoute component={MercadoriaNew} path="/mercadorianew" exact/>

//                 <PrivateRoute component={Transferencia} path="/transferencia" exact/>
//                 <PrivateRoute component={TransferenciaNew} path="/transferencianew" exact/>

//                 <PrivateRoute component={ViagemNew} path="/viagem" exact/>
//                 <PrivateRoute component={ViagemNew} path="/viagem/:id" exact/>
//                 <PrivateRoute component={Despesa} path="/viagem/:id/despesa" exact/>

//                 <PrivateRoute component={Viagem} path="/viagens" exact/>
//                 <PrivateRoute component={ViagemAcerto} path="/viagemacerto/:id" exact/>

//                 <PrivateRoute component={ViagemPendente} path="/lancamentos" exact/>

//                 <PrivateRoute component={User} path="/usuarios" exact/>
//                 <PrivateRoute component={NewUser} path="/cadastrausuario" exact/>

// */}
//                 <Route path="*" element={<Login/>} />
//             </Switch>

//         </BrowserRouter>
//     );
// }

// export default Rotas;
// /**
//  *
//  */
