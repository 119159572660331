import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormHandles, UnformErrors } from "@unform/core";
import { Form } from "@unform/web";
import Container from "@material-ui/core/Container";
import TextField from "@mui/material/TextField";
import { Link, useNavigate, useParams } from "react-router-dom";

import api, { URL } from "../../services/api";
import ImageEvento from "../../component/Img";
import Upload from "../../component/Upload/Upload";

import FileList from "../../component/FileList";
import { Autocomplete } from "@mui/material";

const baseURL = `${URL}/files/`;

interface DespesaProps {
  id_manutencao_seq: number;
  descricao: string;
  chaves: string;
  obs: string;
  qtd: number;
  valor: string;
  foto: string;
}
interface AutocompleteOption {
  label: string;
  id: string;
}

interface FileUpload {
  file: File;
  id: string;
  name: string;
  // readableSize: filesize(file.size),
  preview: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  url: any;
}

interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
      id: string;
    };
  };
}
const formatDate = (date: Date) => {
  var d = new Date(date);
  // console.log(d);
  try {
    let formattedDate = Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(d);

    // console.log(formattedDate);
    return formattedDate;
  } catch (error) {
    return date;
  }
};

const Despesa: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const params = useParams();

  const classes = useStyles();
  const uploadInputRef = useRef(null);
  // const buttonEditRef = useRef<HTMLButtonElement>(null);

  const [ID, setID] = useState(Number);
  // const [visible, setVisible] = useState(Boolean);

  // const [colorEdit, setColorEdit] = useState('inherit');
  // const [textEdit, setTextEdit] = useState('EDITAR');
  const [habitidadoEdicao, setHabitidadoEdicao] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const options = [
    { label: "The Godfather", id: 1 },
    { label: "Pulp Fiction", id: 2 },
  ];
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [placas, setPlacas] = useState<AutocompleteOption[]>([]);
  const [value, setValue] = useState<AutocompleteOption>();
  const [inputValue, setInputValue] = React.useState("");
  const [formData, setFormData] = useState<DespesaProps>({
    id_manutencao_seq: 0,
    chaves: "",
    qtd: 0,
    obs: "",
    foto: "",
    descricao: "",
    valor: "R$ 0,00",
  });
  const formRef = useRef<FormHandles>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState<FileUpload[]>([]);

  useEffect(() => {
    var idPar: number = Number(params.id);
    var IDManutencaoSeq: number = Number(params.cod);

    // var idPar:number = Number(props.match?.params.id);
    // console.log("id viagem", idPar);
    // console.log("id IDManutencaoSeq", IDManutencaoSeq);
    setID(idPar);
    handleBuscaDados(idPar);
    if (!Number.isNaN(IDManutencaoSeq)) {
      handleBuscaDadosLancamento(idPar, IDManutencaoSeq);
    }
  }, []);

  async function handleBuscaDados(idPar: Number) {
    await api
      .get(`evento/desconta`)
      .then((response) => {
        // console.log('retorno',response.data);
        let dados: Array<AutocompleteOption> = [];
        var arrayDados = response.data as Array<AutocompleteOption>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx: any) => {
          // console.log('item222',itemx.placa);
          const placa1 = { label: itemx.descricao, id: itemx.id_evento };
          dados.push(placa1);
        });
        setPlacas(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
      });
  }
  async function handleBuscaDadosLancamento(
    idPar: Number,
    IDManutencaoSeq: number
  ) {
    await api
      .get(`manutencaoapp/${idPar}/${IDManutencaoSeq}`)
      .then((response) => {
        console.log("retorno", response.data);

        if (response.data.message == undefined) {
          setFormData(response.data.evento);
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
      });
  }

  const TipoValor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    return TipoValor.format(value);
  };

  const [validationErrors, setValidationErrors] = useState<UnformErrors>({});

  async function handleSubmit(data: FormEvent) {
    //formData.placa = String(value?.label);

    // console.log(data);
    // console.log(value?.id);
    if (
      formData.valor == "R$ 0,00" ||
      formData.valor == "R$ " ||
      formData.valor == "R$" ||
      formData.valor == "R$ 0"
    ) {
      setValidationErrors({ ["valor"]: "Informe o valor do Lançamento!" });
      // } else if (value == undefined){
      //   setValidationErrors({['evento'] : "Informe o valor do Lançamento!"});
    } else {
      var value1 = formData.valor.replace(/\D/g, "");
      value1 = value1.replace(/(\d)(\d{2})$/, "$1.$2");
      if (value1.length < 1) {
        setValidationErrors({ ["valor"]: "Informe o valor do Lançamento!" });
      } else {
        setValidationErrors({});

        const dataEnv = new FormData();

        var value1 = formData.valor.replace(/\D/g, "");
        value1 = value1.replace(/(\d)(\d{2})$/, "$1.$2");

        // var valueLitros = formData.litros.toString();

        // if (formData.litros > 0) {
        // }
        var valueQtd = formData.qtd.toString();

        // console.log('value1',value1);
        // console.log('valueLitros',valueLitros);
        // console.log('valueQtd',valueQtd);

        // dataEnv.append("foto", uploadedFiles[0]?.file);
        dataEnv.append("qtd", formData.qtd.toString());
        if (formData.id_manutencao_seq > 0) {
          dataEnv.append(
            "id_manutencao_seq",
            formData.id_manutencao_seq.toString()
          );
        } else {
          dataEnv.append(
            "id_manutencao_seq",
            value != undefined ? value?.id.toString() : "0"
          );
        }
        dataEnv.append("descricao", formData.descricao);
        dataEnv.append("chaves", formData.chaves);
        dataEnv.append("valor", value1);
        dataEnv.append("qtd", formData.qtd > 0 ? valueQtd : "0");
        dataEnv.append("obs", formData.obs);

        var mensagem = "Lançamento criado com Sucesso!";
        var ret = null;
        // console.log("dataEnv", dataEnv);

        if (formData.id_manutencao_seq > 0) {
          dataEnv.append("foto", "");
          // console.log("1", 1);

          ret = await api.put(
            `manutencaoapp/${ID}/${formData.id_manutencao_seq}`,
            formData
          );
          mensagem = "Lançamento Atualizado com Sucesso!";
        } else {
          // console.log("2", 2);
          // console.log("formData", formData);

          ret = await api.post(`manutencaoapp/${ID}`, formData);
        }

        if (ret.data.sucess) {
          alert(mensagem);
          navigate("/manutencoes");
        } else {
          alert(ret.data.result);
        }
      }
    }
  }
  async function handleDelete(id: any) {
    // console.log(id);
  }
  function handleUpload(files: File[]) {
    let dados: Array<FileUpload> = [];

    files.map((file) => {
      const file1 = {
        file,
        id: "", //uniqueId(),
        name: file.name,
        // readableSize: filesize(file.size),
        preview: window.URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
      };
      dados.push(file1);
    });
    setUploadedFiles(dados);
  }
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;

    if (id == "valor" || id == "soma") {
      var value1 = value.replace(/\D/g, "");
      value1 = value1.replace(/^0+/, "");
      value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
      value1 = "R$ " + value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
      // console.log('value.length', value.length);//setMsgSucesso(false);
      if (value.length < 3) {
        value1 = "R$ 0,00";
      }
      setFormData({ ...formData, [String(id)]: value1 });
    } else {
      if (id == "media" || id == "litros") {
        let val: number = Number(0);

        try {
          val = Number(parseFloat(value).toFixed(3));
        } catch (error) {}
        setFormData({ ...formData, [id]: val });
      } else {
        setFormData({ ...formData, [id]: value });
      }
    }
  }

  // useEffect(() => {
  //   console.log("mudou formData", formData);
  //   // if (eventoRetornado.current?.label) {
  //   var arrayDados: AutocompleteOption = {
  //     id: formData.id_evento.toString(),
  //     label: formData.desc_evento,
  //   };
  //   // setInputValue(formData.desc_evento);
  //   setValue({
  //     id: formData.id_evento.toString(),
  //     label: formData.desc_evento,
  //   });
  //   // setInputValue(eventoRetornado.current?.label);
  //   // }
  //   // var idPar:number = Number(params.id);
  //   // var idCod:number = Number(params.cod);

  //   // setID(idPar);
  //   // handleBuscaDados(idPar, idCod);
  // }, [formData]);

  const validaValor = async (valor: string) => {
    if (valor == "R$ 0,00") return false;
  };

  return (
    <div style={{ marginLeft: "-20px", marginRight: "-20px" }}>
      {/* <div>
        <Link
          ref={linkRef}
          to={`/lancamentos`}
          style={{ display: "flex", fontSize: "17px" }}
        >
          <BsArrowBarLeft style={{ height: "23px", width: "36px" }} />
          Voltar
        </Link>
      </div> */}
      <div className={classes.root}>
        <Container className={classes.formdados}>
          <Form
            className={classes.dadosViavem}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <fieldset className={classes.titulo}>
              <h2 className={classes.tituloh2}>Novo Lançamento</h2>
            </fieldset>
            <div
              style={{
                marginTop: "4px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.descricao}
                onChange={handleInputChange}
                className={classes.field}
                id="descricao"
                name="descricao"
                label="Descrição"
                variant="outlined"
                style={{ marginBottom: "8px" }}
              />
              <TextField
                disabled={habitidadoEdicao}
                value={formData.chaves}
                onChange={handleInputChange}
                className={classes.field}
                id="chaves"
                name="chaves"
                style={{ marginBottom: "8px" }}
                label="Ex. freio, oleo..."
                variant="outlined"
              />
            </div>
            <TextField
              // type={"number"}
              disabled={habitidadoEdicao}
              value={formData.obs}
              onChange={handleInputChange}
              style={{ marginBottom: "8px" }}
              variant="outlined"
              className={classes.field}
              id="obs"
              name="obs"
              label="Observação"
            />
            <TextField
              type={"number"}
              disabled={habitidadoEdicao}
              value={formData.qtd}
              onChange={handleInputChange}
              className={classes.field}
              variant="outlined"
              style={{ marginBottom: "8px" }}
              id="qtd"
              name="qtd"
              label="Quantidade"
            />
            <TextField
              required
              defaultValue="R$ 0,00"
              error={validationErrors["valor"] == undefined ? false : true}
              helperText={
                validationErrors["valor"] != undefined
                  ? "Informe o Valor da Despesa"
                  : null
              }
              disabled={habitidadoEdicao}
              value={formData.valor}
              onChange={handleInputChange}
              variant="outlined"
              className={classes.field}
              style={{ marginBottom: "8px" }}
              id="valor"
              name="valor"
              label="Valor"
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              {/* <div
                style={{
                  width: "100%",
                  marginTop: 5,
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex",
                  alignItems: "flex-center",
                }}
              >
                {formData.foto == "" && (
                  <>
                    <Upload onUpload={handleUpload} />
                    {!!uploadedFiles.length && (
                      <FileList files={uploadedFiles} onDelete={handleDelete} />
                    )}
                  </>
                )}
                {formData.foto != "" && (
                  <div className={classes.img_div_back}>
                    <img
                      className={classes.img}
                      src={baseURL + formData.foto} //alt={props.alt} onClick={()=>props.onReturn()}/>
                    />
                  </div>
                )}
              </div> */}
            </div>
            <div
              style={{
                width: "100%",
                margin: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              {formData.id_manutencao_seq == 0 && (
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                >
                  Criar Lançamento
                </Button>
              )}
              {formData.id_manutencao_seq > 0 && (
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ width: "100%" }}
                >
                  Salvar Lançamento
                </Button>
              )}
            </div>
          </Form>
        </Container>
      </div>
      <ImageEvento
        show={showImage}
        source={sourceImage}
        onReturn={() => {
          setShowImage(false);
          setSourceImage("");
        }}
      />
    </div>
  );
};
export default Despesa;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      borderRadius: "8px",
    },
    dadosViavem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonExtra: {
      margin: theme.spacing(1),
      backgroundColor: "green",
    },
    button: {
      margin: theme.spacing(1),
    },
    titulo: {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderBottom: "2px solid rgb(0 0 0 / 20%)",
      width: "100%",
      margin: "7px",
      // padding: '20px',
    },
    tituloh2: {
      textAlign: "center",
      color: "#444445",
    },
    field: {
      width: "100%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginTop: "7px",
      marginBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
    },
    fieldID: {
      display: "none",
    },
    buttonHide: {
      display: "none",
    },
    formdados: {
      width: "100%",
      maxWidth: "10280px",
      // backgroundColor: "#Fff",
      //border: "1px solid #e5e5e6",
      borderRadius: "8px",
      paddingBottom: "8px",
      //display: 'flex',
      flexDirection: "row",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
    },
    img: {
      alignItems: "center",
      justifyItems: "center",
      justifyContent: "center",
      flexGrow: 1,
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      height: "100%",
    },
    img_div_back: {
      flexGrow: 1,
      // width: 700,
      height: 400,
      // lineHeight: 200,
      color: "#FFF",
      // position: 'absolute',
      // left: '25%',
      top: "10%",
      zIndex: 100,
      // backgroundColor: 'inherit',
      alignItems: "center",
      justifyItems: "center",
      justifyContent: "center",
      padding: 20,
      backgroundColor: "#e5e5e6",
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "gray",
      boxShadow: "2",
      width: "90%",
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);
