import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
// import MUIDataTable, { MUIDataTableCurrentData } from "mui-datatables";
// import {MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import NewIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
// import AccountTree from '@material-ui/icons/AccountTree';
import ModalConfirm from "../../component/ModalConfirm";

import { BsArrowBarLeft } from "react-icons/bs";
import List from "@material-ui/core/List";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

import api, { URL } from "../../services/api";
// import labels from '../../component/TableLabels';
import ImageEvento from "../../component/Img";
import { Autocomplete } from "@mui/material";
// import Select from 'react-select';
import ItemManutencao from "../../component/ItemManutencao";
import ModalAgendamento from "../../component/ModalAgendamento";

const baseURL = `${URL}/files/`;

interface Item {
  id: number;
  descricao: string;
  chaves: string;
  obs: string;
  valor: number;
  position: number;
  qtd: number;
}
interface AutocompleteOption {
  label: string;
  id: string;
  id_user?: number;
  nome?: string;
}
interface Erros {
  km_fim: boolean;
}

interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
      id: string;
    };
  };
}
const formatDate = (date: Date) => {
  var d = new Date(date);
  d.setHours(d.getHours() + 3); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas

  try {
    let formattedDate = Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(d);

    const dateNow = new Date(formattedDate); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
      monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
        ? `0${monthWithOffset}`
        : monthWithOffset;
    const date1 =
      dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
        ? `0${dateNow.getUTCDate()}`
        : dateNow.getUTCDate();

    const materialDateInput = `${year}-${month}-${date1}`;
    // console.log(formattedDate);
    return materialDateInput;
  } catch (error) {
    return date;
  }
};

const Manutencao: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  // const buttonEditRef = useRef<HTMLButtonElement>(null);

  const [ID, setID] = useState(0);
  // const [visible, setVisible] = useState(Boolean);

  // const [colorEdit, setColorEdit] = useState('inherit');
  const [textButton, setTextButton] = useState("Criar Manutenção");
  const [pladaAgenda, setPladaAgenda] = useState("");
  // const [erros, setErros] = useState<Erros>();
  const [atualiza, setAtualiza] = useState("");
  const [habitidadoEdicao, setHabitidadoEdicao] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [placas, setPlacas] = useState<AutocompleteOption[]>([]);
  const [sourceImage, setSourceImage] = useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [value, setValue] = useState<AutocompleteOption>();
  const [show, setShow] = useState<boolean>(false);

  const [showConfirm, setShowConfirm] = useState(false);
  var titleConfirm = "Manutenção Criada com Sucesso!";
  var descricaoConfirm = "Agendar nova data?";
  const [viagemId, setViagemId] = useState(0);
  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  var data30 = new Date();
  data30.setDate(data30.getDate());

  const [formData, setFormData] = useState({
    id_estabelecimento: 0,
    id_manutencao: 0,
    valor_total: "0",
    orcamento: "R$ 0,00",
    id_motorista: 0,
    placa: "",
    status: "",
    qtd_parcelas: 0,
    itens: [
      {
        id: -1,
        descricao: "",
        chaves: "",
        obs: "",
        valor: 0,
        position: -1,
        qtd: 0,
      },
    ],
    km: 0,
    data_inicio: materialDateInput,
    data_fim: materialDateInput,
    motivo: "",
    descricao: "",
    chaves: "",
    id_veiculo: 0,
  });

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      var idManutencao: number = Number(params.id);

      //var idPar:number = Number(props.match?.params.id);
      if (idManutencao > 0) {
        setID(idManutencao);
        // console.log('id', idManutencao);
      }
      // setErros({km_fim:false});
      handleBuscaDados(idManutencao);
    }
  }, []);

  const formRef = useRef<FormHandles>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);

  async function handleBuscaDados(idPar: Number) {
    if (idPar > 0) {
      await api
        .get(`manutencao/${idPar.toString()}`)
        .then((response) => {
          let dados = response.data;

          dados.data_inicio = formatDate(dados.data_inicio);
          dados.data_fim = formatDate(dados.data_fim);
          // console.log('retorno',dados);

          var value1 = dados.valor_total.replace(/\D/g, "");
          value1 = value1.replace(/^0+/, "");
          value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
          value1 = "" + value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
          // console.log('mudou valor', value1);//setMsgSucesso(false);

          if (dados.valor_total < 1) {
            value1 = "0,00";
          }
          // console.log("mudou valor", value1); //setMsgSucesso(false);

          // dados.valor_total = value1;

          var value1 = dados.orcamento.replace(/\D/g, "");
          value1 = value1.replace(/^0+/, "");
          value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
          value1 = "R$ " + value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
          // console.log('mudou valor', value1);//setMsgSucesso(false);

          if (dados.orcamento < 1) {
            value1 = "0,00";
          }
          dados.orcamento = value1;

          // setFormData({...formData, [String(id)]:value1});
          setTextButton("Salvar");
          setFormData(dados);
          let dadosPlaca: Array<AutocompleteOption> = [];
          var arrayDados = response.data.placa as Array<AutocompleteOption>;
          // console.log('arrayDados',arrayDados);
          // console.log('item222',itemx);
          // if (response.data.placa != '') {
          const placa1 = {
            label: response.data.placa,
            id: response.data.id_veiculo,
          };
          dadosPlaca.push(placa1);

          // }
          setPlacas(dadosPlaca);
          // setValue(placa1);
          // setInputValue(response.data.placa);
        })
        .catch((error) => {
          console.log("erro", error);
        })
        .finally(() => {
          // console.log('finally')
          // handleBuscaPlaca2();
        });
    } else {
      await api
        .get(`veiculos/C','G`)
        .then((response) => {
          // console.log('retorno',response.data);
          let dados: Array<AutocompleteOption> = [];
          var arrayDados = response.data as Array<AutocompleteOption>;
          // console.log('arrayDados',arrayDados);
          arrayDados.map((itemx: any) => {
            // console.log('item222',itemx);
            if (itemx.status == "Ativo") {
              const placa1 = {
                label: itemx.placa,
                id: itemx.id_veiculo,
                id_user: itemx.id_usr,
                nome: itemx.nome,
              };
              dados.push(placa1);
            }
          });
          setPlacas(dados);
        })
        .catch((error) => {
          // console.log('erro', error);
        })
        .finally(() => {
          // console.log('finally')
          // handleBuscaPlaca2();
        });
    }
  }

  const TipoValor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formataValor = (value: any) => {
    console.log("mudou valor", value); //setMsgSucesso(false);

    // var value1 = value.replace(/\D/g, "");
    // value1 = value1.replace(/^0+/, "");
    // value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
    // value1 = value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return TipoValor.format(value);
  };

  async function handleSubmit(event: FormEvent) {
    // formData.placa  = String(value?.label);
    // setPladaAgenda(formData.placa);
    // setShow(true);
    // formData.placa2 = String(value2?.label);

    // console.log(formData);
    // console.log('ID', ID);
    if (ID > 0) {
      if (window.confirm("Salvar Alterações?")) {
        // if (ID > 0 ) {
        console.log(formData);
        await api.put(`manutencao/${ID}`, formData).then(function (response) {
          if (response.data.sucess) {
            alert("Manutenção Salva com Sucesso!");
            navigate("/manutencoes");
          } else {
            console.log(response.data);
          }
        });
      }
    } else {
      if (window.confirm("Criar nova Manutenção?")) {
        // if (ID > 0 ) {
        formData.placa = String(value?.label);

        // console.log(formData);
        await api.post(`manutencao`, formData).then(function (response) {
          // alert(response.data.result);
          // console.log('retorn',response.data);
          if (response.data.sucess) {
            // setViagemId(response.data.id_manutencao);
            setPladaAgenda(formData.placa);
            setShowConfirm(true);
            // alert('Manutenção Criada com Sucesso!');
            // setShow(true);
            // navigate("/manutencoes");
          } else {
            console.log(response.data);
          }
        });
      }
    }
  }

  async function handleSubmitAgenda(data_inicio: string, descricao: string) {
    var dados = {
      [`placa`]: pladaAgenda,
      [`data_inicio`]: data_inicio,
      [`descricao`]: descricao,
      // [`id_veiculo`]:formData.id_veiculo
    };

    await api.post(`manutencao/agenda`, dados).then(function (response) {
      // alert(response.data.result);
      // console.log('retorn',response.data);
      if (response.data.sucess) {
        // setViagemId(response.data.id_manutencao);
        // setShowConfirm(true);
        alert("Manutenção Agendada com Sucesso!");
        navigate("/manutencoes");
      } else {
        console.log(response.data);
      }
    });
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    // setErros({...erros, ['km_fim']:false});
    const { id, value } = event.target;

    if (id == "valor_total" || id == "orcamento") {
      var value1 = value.replace(/\D/g, "");
      value1 = value1.replace(/^0+/, "");
      value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
      value1 = "R$ " + value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
      // console.log('mudou valor', value1);//setMsgSucesso(false);
      if (value.length < 1) {
        value1 = "R$ 0,00";
      }
      setFormData({ ...formData, [String(id)]: value1 });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  }

  function handleInputChangeEvento(
    event: ChangeEvent<HTMLInputElement>,
    linha: number
  ) {
    const { id, value, name } = event.target;

    var campo = id;
    var dados = formData;
    if (id === null || id == undefined) {
      campo = name;
    }
    // console.log(id, name, value, linha);

    var itens1: Item[] = [];
    // var value1 = value;
    var valorTotal = 0;
    dados.itens.map((value1: any, index) => {
      var valorLinha = "";
      if (index == linha) {
        if (campo == "valor") {
          var value2 = value.replace(/\D/g, "");
          value2 = value2.replace(/^0+/, "");
          value2 = value2.replace(/(\d)(\d{2})$/, "$1,$2");
          value2 = "R$ " + value2.replace(/(?=(\d{3})+(\D))\B/g, ".");
          value1 = { ...value1, [campo]: value2 };
        } else {
          value1 = { ...value1, [campo]: value };
        }
        valorLinha = value;
      } else {
        // console.log("value1", value1.valor);
        valorLinha = value1.valor;
      }
      itens1.push(value1);

      //valorTotal += Number(value);
      var value2 = valorLinha.replace(/\D/g, "");
      value2 = value2.replace(/^0+/, "");
      value2 = value2.replace(/(\d)(\d{2})$/, "$1.$2");
      // value2 = value2.replace(/(?=(\d{3})+(\D))\B/g, ".")
      // console.log("value2", value2);
      // console.log("value2 Numver", Number(value2));

      valorTotal += Number(value2);
    });
    // console.log("valorTotal", valorTotal);

    setFormData({
      ...formData,
      ["itens"]: itens1,
      ["valor_total"]: valorTotal.toString(),
    });
  }

  function handleAdicionaEvento(evento: any) {
    var form1 = formData;

    form1.itens.push({
      id: -1,
      descricao: "",
      chaves: "",
      obs: "",
      valor: 0,
      position: -1,
      qtd: 0,
    });
    setFormData(form1);
    setAtualiza(atualiza + 1);
  }

  function handleRemoveEvento(evento: any) {
    var form1 = formData;

    form1.itens.splice(evento.position, 1);

    setFormData(form1);
    setAtualiza(atualiza + 1);
  }

  useEffect(() => {
    // console.log('atualizaou formdata',formData);
  }, [formData]);

  function NVL(value1: any, value2: any) {
    if (value1 == null) return value2;

    return value1;
  }

  const navigateAcerto = () => {
    navigate(`/viagemacerto/${viagemId}`);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;

    setFormData({ ...formData, ["status"]: value });

    // setAge(event.target.value as string);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <div>
        <Link
          ref={linkRef}
          to={`/manutencoes`}
          style={{ display: "flex", fontSize: "17px" }}
        >
          <BsArrowBarLeft style={{ height: "23px", width: "36px" }} />
          Voltar
        </Link>
      </div>
      <div className={classes.root}>
        <Container className={classes.formdados}>
          <Form
            className={classes.dadosViavem}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <fieldset className={classes.titulo}>
              <h2 className={classes.tituloh2}>Cadastro de Manutenção</h2>
            </fieldset>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                style={{ width: "15%", display: "none" }}
                disabled
                value={formData.id_manutencao}
                onChange={handleInputChange}
                className={classes.field}
                id="id_manutencao"
                name="id_manutencao"
                label="ID"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.descricao}
                onChange={handleInputChange}
                className={classes.field}
                id="descricao"
                name="descricao"
                label="Descrição"
              />
              <TextField
                disabled={habitidadoEdicao}
                value={formData.motivo}
                onChange={handleInputChange}
                className={classes.field}
                id="motivo"
                name="motivo"
                label="Motivo"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              {ID > 0 && (
                <TextField
                  disabled={true}
                  value={formData.placa}
                  onChange={handleInputChange}
                  className={classes.field}
                  id="placa"
                  name="placa"
                  label="Placa"
                />
              )}
              {ID == 0 && (
                <>
                  {/* <span style={{marginTop: 10, marginLeft: 20}} className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required">Data Prevista</span> */}
                  <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                      // console.log(newValue);
                      if (newValue?.nome) {
                        // setValue(newValue);
                        // setFormData({...formData, ['id_motorista']:Number(newValue?.id_user) ,['nome_motorista']:newValue?.nome});
                      }
                      setValue(newValue as AutocompleteOption);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      // console.log(newInputValue);

                      setInputValue(newInputValue);
                    }}
                    id="placa"
                    options={placas}
                    disabled={false}
                    className={classes.field}
                    renderInput={(params) => (
                      <TextField {...params} required={false} label="Placa" />
                    )}
                  />
                </>
              )}
              <TextField
                disabled={habitidadoEdicao}
                value={formData.km}
                onChange={handleInputChange}
                className={classes.field}
                id="km"
                name="km"
                label="KM"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              {/* <TextField required disabled={habitidadoEdicao} value={TipoValor.format(formData.valor)} onChange={handleInputChange} className={classes.field} id="valor" name="valor" label="Valor de Frete" /> */}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex",
                  alignItems: "flex-center",
                }}
              >
                <InputLabel
                  required
                  style={{ alignItems: "left", fontSize: "12px" }}
                  id="demo-simple-select-label"
                >
                  Status
                </InputLabel>
                <Select
                  // labelId="tipo"
                  labelId="demo-simple-select-label"
                  required
                  id="status"
                  className={classes.field}
                  value={formData.status}
                  label="Tipo de Usuário"
                  // label=""
                  placeholder="Tipo de Usuário"
                  style={{ height: "40px" }}
                  // required A: Aberto , G: Agendado, R: Realizado , F:Finalizado
                  onChange={handleChange}
                >
                  <MenuItem value={"A"}>Aberto</MenuItem>
                  <MenuItem value={"G"}>Agendado</MenuItem>
                  <MenuItem value={"R"}>Realizado</MenuItem>
                  <MenuItem value={"F"}>Finalizado</MenuItem>
                </Select>
              </div>
              <TextField
                type="date"
                disabled={habitidadoEdicao}
                value={formData.data_inicio}
                // value={formatDate(formData.data_inicio)}
                onChange={handleInputChange}
                className={classes.field}
                id="data_inicio"
                name="data_inicio"
                label="Início da Manutenção"
              />
              <TextField
                type="date"
                disabled={habitidadoEdicao}
                value={formData.data_fim}
                // value={formatDate(formData.data_inicio)}
                onChange={handleInputChange}
                className={classes.field}
                id="data_fim"
                name="data_fim"
                label="Fim da Manutenção"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                disabled={habitidadoEdicao}
                value={formData.orcamento}
                onChange={handleInputChange}
                className={classes.field}
                id="orcamento"
                name="orcamento"
                label="Orçamento"
              />
              <TextField
                disabled={habitidadoEdicao}
                value={formataValor(formData.valor_total)}
                onChange={handleInputChange}
                className={classes.field}
                id="valor_total"
                name="valor_total"
                label="Valor Total"
              />
              <TextField
                disabled={habitidadoEdicao}
                value={formData.qtd_parcelas}
                onChange={handleInputChange}
                className={classes.field}
                id="qtd_parcelas"
                name="qtd_parcelas"
                label="Parcelas"
              />
            </div>
            <TextField
              value={formData.chaves}
              onChange={handleInputChange}
              className={classes.field}
              id="chaves"
              name="chaves"
              label="TAGs."
            />
            <div style={{ width: "99%" }}>
              <fieldset className={classes.titulo}>
                <h2 className={classes.tituloh2}>Itens</h2>
              </fieldset>
            </div>
            <div
              className={classes.tabelas}
              id={atualiza}
              style={{
                display: "flex",
                paddingBottom: "15px",
                flexDirection: "column",
                marginTop: "10px",
                justifyContent: "center",
              }}
            >
              <List style={{ padding: "0", marginTop: "14px" }}>
                {formData.itens.map((value, index) => {
                  // console.log(`eventos`, index);
                  return (
                    <ItemManutencao
                      pos={index}
                      dados={formData.itens[index]}
                      form={formData}
                      count={formData.itens.length - 1}
                      onRemoveLinha={handleRemoveEvento}
                      handleInputChangeEvento={handleInputChangeEvento}
                      handleAdicionaEvento={handleAdicionaEvento}
                    />
                  );
                })}
              </List>
              <Button
                type="button"
                // id={`${props.pos}-qtdqff`}
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleAdicionaEvento}
                // className={classes.removeLinha}
                style={{
                  width: "30%",
                  alignItems: "center",
                  display: "flex",
                  margin: "auto",
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  minWidth: "90px",
                  justifyContent: "center",
                }}
              >
                <span
                  id={`qtddqff`}
                  style={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    paddingLeft: "5px",
                    paddingRight: "10px",
                    minWidth: "90px",
                    justifyContent: "center",
                  }}
                >
                  Adicionar Linha
                </span>
              </Button>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                justifyContent: "space-around",
              }}
            >
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                // onClick={handleSubmit}
                style={{ width: "35%" }}
              >
                {textButton}
              </Button>
            </div>
          </Form>
        </Container>
      </div>
      <ModalAgendamento
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={show}
        placaInformada={String(pladaAgenda)}
        setShow={(show1: boolean, data_inicio: string, descricao: string) => {
          // alert(show1);
          if (show1) {
            setShow(false);
            handleSubmitAgenda(data_inicio, descricao);
            // navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShow(false);
            // navigate(`/lancamentos`);
          }
        }}
      />
      <ImageEvento
        show={showImage}
        source={sourceImage}
        onReturn={() => {
          setShowImage(false);
          setSourceImage("");
        }}
      />
      <ModalConfirm
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            setPladaAgenda(formData.placa);
            setShowConfirm(false);
            setShow(true);
            // navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirm(false);
            navigate(`/manutencoes`);
          }
        }}
      />
    </div>
  );
};
export default Manutencao;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex",
      alignItems: "flex-center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      // paddingBottom: '8px',
    },
    tabela: {
      // width: '50%',
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "8px",
    },
    dadosViavem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonExtra: {
      margin: theme.spacing(1),
      backgroundColor: "green",
    },
    button: {
      margin: theme.spacing(1),
    },
    titulo: {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderBottom: "2px solid rgb(0 0 0 / 20%)",
      width: "100%",
      marginBottom: "5px",
    },
    tituloh2: {
      textAlign: "center",
      marginTop: "3px",
      color: "#444445",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
    },
    select: {
      width: "90%",
      fontSize: "18px",
      // paddingTop: '7px',
      // paddingBottom: '7px',
      // marginLeft:'5px',
      // marginRight:'5px',
      color: "black",
    },
    fieldID: {
      display: "none",
    },
    buttonHide: {
      display: "none",
    },
    formdados: {
      width: "100%",
      maxWidth: "10280px",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      paddingBottom: "8px",
      //display: 'flex',
      flexDirection: "row",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
    },
  })
);
