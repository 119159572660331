import React, { useEffect, useRef, ChangeEvent, useState, FormEvent } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Link,useNavigate, useParams } from 'react-router-dom';
import { FiArrowLeft} from 'react-icons/fi';
import api from '../../services/api';
import { Autocomplete } from '@mui/material';

interface RouterProps {//} {
  history?: {};
  location?: {};
  id?:string;
  path?: string;
  match?: {params: {
    id: string}};    
};
 
interface AutocompleteOption {
  label: string;
  id: string;
}

const ProdutoNew: React.FC<RouterProps> = (props:RouterProps) => {
  const params = useParams();
    let navigate   = useNavigate();
    const classes = useStyles();
    const [motorista, setMotoristas] = useState<AutocompleteOption[]>([]);
    const [value, setValue] = useState<AutocompleteOption>();
    const [inputValue, setInputValue] = React.useState('');
    const [formData, setFormData] = useState({
      id_veiculo:'',
      placa:'',
      ano:'',
      modelo:'',
      marca:'',
      cor:'',
      renavan:'',
      id_mercadoria:'',
    });

    const formRef = useRef<FormHandles>(null);
    const linkRef = useRef<HTMLAnchorElement>(null);
    
    useEffect(() => {
        if (localStorage.getItem('UserTipo') == 'M') {
          alert('Opção Inválida!');
          navigate('/');
        } else {
          var idVeiculo:number = Number(params.id);
          buscaVeiculo(idVeiculo);
          buscaMotoristas();        
        }
      }, []);  
      
      async function buscaVeiculo(idVeiculo:number) {
        if (idVeiculo > 0) {  
          formData.id_veiculo = idVeiculo.toString();
          await api.get(`veiculo/${idVeiculo}`).then(response => {
            // console.log(response.data);
              setFormData(response.data);                           
          });            
        }
      };

      async function buscaMotoristas() {
        // busca usuarios motoristas
        await api.get(`userconfig/M`).then(response => {
          let dados : Array<AutocompleteOption>= [];
          var arrayDados = response.data;
          arrayDados.users.map((itemx:any) => {
            const user1 = {label:itemx.nome, id:itemx.id_user_config}
            dados.push(user1);
          });
          setMotoristas(dados);                     
        });
      };

    async function handleSubmit(event: FormEvent) {
        const data = {id_veiculo:formData.id_veiculo, id_motorista:value?.id}
        await api.post(`veiculomotorista`, data).
          then(function (response) {
            if (response.data.sucess) {
                navigate(`/veiculos`);  
            } else {
              alert(response.data.result);
            }
        })
    };
    function handleInputChange(event: ChangeEvent<HTMLInputElement>){
        const {id, value} = event.target;
        // let placa1 = value;
        if (id == 'placa') {
          // placa1 = value.toUpperCase();
          setFormData({...formData, [id]:value.toUpperCase()});

        } else {
          setFormData({...formData, [id]:value});
        }
    };

    return (        
        <Container  className={classes.paper}>
            <Link ref={linkRef} to={`/veiculos`}>
                <FiArrowLeft />
                Voltar
            </Link>
            <Form className={classes.root} ref={formRef} onSubmit={handleSubmit}>
                <fieldset className={classes.titulo}>
                  <h2 className={classes.tituloh2}>Vincular Motorista</h2>
                </fieldset>
                <TextField value={formData.id_veiculo} onChange={handleInputChange} className={classes.fieldID} id="id_veiculo" name="id_veiculo" label="ID" />
                <TextField required value={formData.placa} onChange={handleInputChange} className={classes.field} id="placa" name="placa" label="Placa" />
                
                <Autocomplete
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue as AutocompleteOption);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      id="id_motorista"
                      options={motorista}
                      disabled={false}
                      className={classes.field}
                      renderInput={(params) => <TextField {...params} required={true} label="Motorista" />}
                    />
                
                {/* <TextField required value={formData.ano} type='number' onChange={handleInputChange} className={classes.field} id="ano" name="ano" label="Ano" />
                <TextField required value={formData.modelo} onChange={handleInputChange} className={classes.field} id="modelo" name="modelo" label="Modelo" />
                <TextField value={formData.marca} onChange={handleInputChange} className={classes.field} id="marca" name="marca" label="Marca" />
                <TextField value={formData.cor} onChange={handleInputChange} className={classes.field} id="cor" name="cor" label="Cor" />
                <TextField value={formData.renavan} onChange={handleInputChange} className={classes.field} id="renavan" name="renavan" label="Renavam" /> */}
                {/* <FormControl required className={classes.formControl}>
                  <InputLabel id="id_mercadoria">Mercadoria</InputLabel>
                  <Select
                    labelId="id_mercadoria"
                    id="id_mercadoria"
                    name="id_mercadoria"
                    value={formData.id_mercadoria}
                    onChange={handleChangeMercadoria}
                    className={classes.selectEmpty}
                  >
                    {
                      formMercadoria.map(item =>( <MenuItem value={item.id_mercadoria}>{item.nm_mercadoria}</MenuItem>))
                    }
                  </Select>
                </FormControl> */}
                {/* <FormControl required className={classes.formControl}>
                  <InputLabel id="renavan">Marca</InputLabel>
                  <Select
                    labelId="renavan"
                    id="renavan"
                    name="renavan"
                    value={formData.renavan}
                    onChange={handleChangeMarca}
                    className={classes.selectEmpty}
                  >
                    {
                      formMarca.map(item =>( <MenuItem value={item.renavan}>{item.nm_marca}</MenuItem>))
                    }
                  </Select>
                </FormControl> */}
                {/* <FormControl required className={classes.formControl}>
                  <InputLabel id="cor">Condição</InputLabel>
                  <Select
                    labelId="cor"
                    id="cor"
                    name="cor"
                    value={formData.cor}
                    onChange={handleChangeCondicao}
                    className={classes.selectEmpty}
                  >
                    <MenuItem value='N'>Novo</MenuItem>
                    <MenuItem value='U'>Usado</MenuItem>                    
                  </Select>
                </FormControl> */}
                {/* <TextField multiline
          rows={4} value={formData.marca} onChange={handleInputChange} className={classes.field} id="marca" name="marca" label="Descrição" /> */}
                <Button
                type="submit"
                    size="small" 
                    variant="contained"
                    color="primary">
                        Salvar
                </Button>                
            </Form>
        </Container>
    )
}

export default ProdutoNew;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
          //width: '25ch',
        },
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    titulo: {
        borderTop:'none',
        borderRight:'none',
        borderLeft:'none',
        borderBottom: '2px solid #e5e5e6',
        width: '100%',
    },
    tituloh2: {
        textAlign: 'center',
        color: '#444445',
    },
    field:{
        width: '70%',
        fontSize: '22px',
        paddingTop: '13px',
        paddingBottom: '13px',
    },
    fieldID:{
        display: 'none',
    },
    paper: {
        width: '80%',
        backgroundColor: '#Fff',
        border: '1px solid #e5e5e6',
        borderRadius: '8px',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
      
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    formControl: {
     //margin: theme.spacing(0),
      minWidth: '70%',
    },
    selectEmpty: {
      marginTop: '-10px',
      //fontSize: '22px',
        paddingTop: '13px',
        paddingBottom: '13px',
    },
  }));