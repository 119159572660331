import React, { useEffect, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
// import PhotoIcon from '@material-ui/icons/Photo';
// import Photo from '../../public/photo.png';

// import { useField } from '@unform/core';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img_div: {
      
    },
    img: {
      alignItems:'center',
      justifyItems:'center',
      justifyContent:'center',
      flexGrow: 1,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    img_div_back: {
      flexGrow: 1,
      // width: 700,
      // height: 700,
      // lineHeight: 200,
      color: '#FFF',
      position: 'absolute',
      // left: '25%',
      top: '10%',
      zIndex:200,
      // backgroundColor: 'inherit',
      alignItems:'center',
      justifyItems:'center',
      justifyContent:'center',
      padding: 20,
      backgroundColor: '#e5e5e6',
      borderRadius:10,
      borderWidth: 1,
      borderColor:'gray',
      boxShadow: '2',
      width: '90%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  }),
);
interface HeaderProps {
  source?: string,
  alt?: string,
  show: boolean,
  onReturn:() => void
}

const Img: React.FC<HeaderProps> = (props) => {
  const classes = useStyles();

  // const { fieldName, defaultValue, registerField, error } = useField(name);

  // useEffect(() => {
  //   console.log('url imagem', props.source);
  // });

  return (
    <>
      { props.show && 
        <div className={classes.img_div_back} onClick={()=>props.onReturn()}>
          <div className={classes.img_div} onClick={()=>props.onReturn()}>
            { props.source &&
              <img className={classes.img} src={props.source} alt={props.alt} onClick={()=>props.onReturn()}/>
            }
            { !props.source &&
              <img className={classes.img} src='../../public/photo.png' ></img>
            }
          </div>
        </div>
       }
    </>
  );
};

export default Img;