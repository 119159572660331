import React, {useState, ChangeEvent, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Button as Button1} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Modal from 'react-bootstrap/Modal';

interface Props {
  title: string;
  description:string;
  noText?:string;
  yesText?:string;
  neutralText?:string;
  placaInformada?:string;
  show: boolean;
  confirm?:boolean;
  setShow(show:boolean, data_inicio: string, descricao:string):void;
};
interface FormData {
  data_inicio:Date,
  descricao:string,
}

const ModalRedefinir: React.FC<Props> = ({ title, description, noText, yesText, show, neutralText, placaInformada, confirm, setShow, ...rest }) => {

  const dateNow = new Date(); // Creating a new date object with the current date and time
  dateNow.setMonth(dateNow.getMonth() + 6); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas

  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();
      const materialDateInput = `${year}-${month}-${date}`;

  const [formData, setFormData] = useState({placa:'', data_inicio: materialDateInput, descricao:''});

  useEffect(()=>{
    // alert(placaInformada);
    const materialDateInput = `${year}-${month}-${date}`;

    setFormData({placa:String(placaInformada), data_inicio: materialDateInput, descricao:''});
  }, [placaInformada]);

  const handleClose = () => setShow(false,formData.data_inicio, formData.descricao);
  const handleConfirm = () => setShow(true,formData.data_inicio, formData.descricao);
  const handleCloseCancel = () => setShow(false,formData.data_inicio, formData.descricao);

  function handleInputChange(event: ChangeEvent<HTMLInputElement>){
    const {id, value} = event.target;
    setFormData({...formData, [id]:String(value)});
  }

  return (
    <>
      <Modal style={{marginTop:100}} show={show} onHide={handleCloseCancel}>
          <Form //className={classes.dadosViavem} ref={formRef} 
              onSubmit={handleClose}>
                <Modal.Header closeButton>
            <Modal.Title>Defina o Próximo Agendamento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className="mb-3">
                  {/* <span style={{marginTop: 10, marginLeft: 20}} className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required">Data Prevista</span> */}
                <TextField 
                    required 
                    type="text" 
                    value={formData.placa} 
                    // onChange={handleInputChange} 
                    style={{
                      width: '90%',
                      fontSize: '22px',
                      paddingTop: '7px',
                      paddingBottom: '7px',
                      marginLeft:'5px', 
                      marginRight:'5px',
                      color:'black'
                    }}
                    id="placa" 
                    name="placa" 
                    label="Placa"
                    disabled={true} 
                    />
              </div>
              <div className="mb-3">
                {/* <span style={{marginTop: 10, marginLeft: 20}} className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required">Data Prevista</span> */}
                <TextField 
                    required 
                    type="date" 
                    value={formData.data_inicio} 
                    onChange={handleInputChange} 
                    style={{
                      width: '90%',
                      fontSize: '22px',
                      paddingTop: '7px',
                      paddingBottom: '7px',
                      marginLeft:'5px', 
                      marginRight:'5px',
                      color:'black'
                    }}
                    id="data_inicio" 
                    name="data_inicio"
                    label="Data Prevista"
                    />
              </div>
              <div className="mb-3">
                <TextField 
                    required
                    value={formData.descricao} 
                    onChange={handleInputChange}
                    style={{
                      width: '90%',
                      fontSize: '22px',
                      paddingTop: '7px',
                      paddingBottom: '7px',
                      marginLeft:'5px', 
                      marginRight:'5px',
                      color:'black'
                    }}
                    id="descricao" 
                    name="descricao" 
                    label="Descrição" 
                    placeholder='Ex. Verificar os freios...'
                    focused={true}
                    />
              </div>
          </Modal.Body>
          <Modal.Footer style={{justifyContent:'space-between'}}>
            <Button1 variant="secondary" onClick={handleCloseCancel}>
              Cancelar
            </Button1>
            <Button1 variant="primary" 
                          type="submit"
                          onClick={handleConfirm}
                          >
              Agendar
            </Button1>
          </Modal.Footer>
          </Form>
      </Modal>
    </>
  );
};
export default ModalRedefinir;