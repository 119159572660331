import React, { useEffect,useState } from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import MainCard from '../../../ui-component/cards/MainCard';
import TotalIncomeCard from '../../../ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import { grey500, primary200, secondary200, warningDark, warningLight } from '../../../assets/scss/themejs';

// const warning_dark = '#ffc107';
// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${warningDark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${warningDark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME LIGHT CARD ||============================== //

const TotalIncomeLightCard = ({ isLoading,dados}) => {
    const theme  = useTheme();
    const [dadosAgenda, setDadosAgenda] = useState({placa:'', data_inicio:'', descricao:'', id_manutencao:0});
    let navigate   = useNavigate();

    useEffect(() => {
        try {
            if (dados.agendados[1]) {
                if (dados.agendados[1].placa != '') {
                    // console.log('dados1',dados);
                    const dateNow = new Date(dados.agendados[1].data_inicio); // Creating a new date object with the current date and time
                    const year = dateNow.getFullYear(); // Getting current year from the created Date object
                    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
                    const month = // Setting current Month number from current Date object
                        monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
                        ? `0${monthWithOffset}`
                        : monthWithOffset;
                    const date =
                        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
                        ? `0${dateNow.getUTCDate()}`
                        : dateNow.getUTCDate();

                    const materialDateInput = `${date}/${month}/${year}`;
                    
                    var agenda = {id_manutencao:dados.agendados[1].id_manutencao, placa:dados.agendados[1].placa, data_inicio:materialDateInput, descricao:dados.agendados[1].descricao}
                    setDadosAgenda(agenda);
                }
            }
        } catch(error) {

        }
    }, [dados]);
    function handleManutencao() {
        if (dadosAgenda.id_manutencao > 0 ) { 
            navigate(`/manutencao/${dadosAgenda.id_manutencao}`);        
        }
    };

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false} onClick={handleManutencao}>
                    <Box sx={{ p: 2, padding:'11px' }}>
                        <List sx={{ py: 0 }}>
                            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            backgroundColor: secondary200,
                                            color: '#fff',
                                        }}
                                    >
                                        <StorefrontTwoToneIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    onClick={()=>{}}
                                    sx={{
                                        cursor: 'pointer',
                                        py: 0,
                                        mt: 0.45,
                                        mb: 0.45
                                    }}
                                    primary={<Typography variant="h4" sx={{fontSize: '1.2rem'}}>
                                        {dadosAgenda.placa != '' && 
                                            <>
                                                Data: {dadosAgenda.data_inicio} -- Veículo: {dadosAgenda.placa}
                                            </>
                                        }
                                        {dadosAgenda.placa == '' && 
                                            <>
                                                Sem Dados...
                                            </>
                                        }
                                        </Typography>}
                                    secondary={
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                color: grey500,
                                                mt: 0.5, 
                                            }}
                                        >
                                            {dadosAgenda.descricao}

                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalIncomeLightCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalIncomeLightCard;
