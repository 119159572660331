import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AccountTree from "@material-ui/icons/AccountTree";
import NoteAdd from "@material-ui/icons/NoteAdd";

import "bootstrap/dist/css/bootstrap.min.css";
import api, { URL } from "../../services/api";
import ModalConfirm from "../../component/ModalConfirm";
import { Button as Button1 } from "react-bootstrap";
import { Form } from "@unform/web";
import TextField from "@material-ui/core/TextField";

import Modal from "react-bootstrap/Modal";
import ImageEvento from "../../component/Img";

const baseURL = `${URL}/files/`;

interface Item {
  id_viagem: number;
  cd_viagem: number;
  comissao: number;
  comissao_viagem: number;
  desconta: number;
  descricao_viagem: string;
  destino_viagem: string;
  destino?: string;
  peso: number;
  id_estabelecimento: number;
  id_motorista: number;
  id_veiculo_1: number;
  id_veiculo_2: number;
  km_medio_viagem: number;
  mercadoria_viagem: string;
  nome_motorista: string;
  obs_viagem: string;
  origem_viagem: string;
  placa_veiculo: string;
  saldo: number;
  soma: number;
  st_ativo: string;
  valor_total: number;
  valor: number;
}
interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
    };
  };
}

const Viagem: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const buttonEditRef = useRef<HTMLButtonElement>(null);
  const divGrid = useRef<HTMLDivElement>(null);
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [responsive, setResponsive] = useState("simple");
  const [ID, setID] = useState(Number);
  // const data_chegada = useState(Date);
  // const km_final     = useState(Number);
  const [visible, setVisible] = useState(false);

  const [itens, setItens] = useState({});
  const marcado = useRef(0);
  var titleConfirm = "Viagem Finalizada com Sucesso!";
  var descricaoConfirm = "Fazer Acerto?";
  var titleConfirmEdit = "Editar Despesa";
  var descricaoConfirmEdit = "Editar Despesa?";
  const [lancamentos, setLancamentos] = useState([]);
  const listaItens = useRef(Array<Item>());
  const [refresh, doRefresh] = useState(0);
  const [IDLancamento, setIDLancamento] = useState(Number);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const [formData, setFormData] = useState({
    id_estabelecimento: 0,
    id_manutencao: "",
    codigo: "",
    mercadoria: "",
    descricao: "",
    origem: "",
    destino: "",
    valor: 0,
    comissao: 0,
    km_inicio: "",
    km_fim: "",
    km_total: "",
    lt_viagem: 0,
    obs_viagem: "",
    nome_motorista: "",
    placa: "",
    placa2: "",
    soma: 0,
    desconta: 0,
    saldo: 0,
    km_final: 0,
    comissao_vl: 0,
    valor_total: 0,
    data_inicio: new Date(),
    data_fim: new Date(),
  });

  const navigateAcerto = () => {
    // let selecionado = itens[ID] as Item;
    // navigate(`/viagemacerto/${selecionado.id_viagem}`);
  };

  const handleClose = async () => {
    setShow(false);
    // let selecionado = itens[ID] as Item;
    await api
      .post(`finalizaViagem/${formData.id_manutencao}/F`, formData)
      .then(function (response) {
        if (response.data.sucess) {
          setShowConfirm(true);
        } else {
          // console.log(response.data);
        }
      });
  };
  const handleCloseCancel = async () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    // console.log('descontos',descontos);
    handleBuscaDados();
    // handleBuscaLancamentos();
  }, []);

  useEffect(() => {
    doRefresh((prev) => refresh + 2);
  }, [listaItens.current]);

  useEffect(() => {
    //if (marcado != -1) {
    // console.log('click');

    //}
    if (divGrid.current?.className == "makeStyles-grid70-21") {
      if ((listaItens.current.length = 0)) {
        // console.log('70-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    } else if (divGrid.current?.className == "makeStyles-grid100-22") {
      if (listaItens.current.length > 0) {
        // console.log('100-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    }
  }, [marcado]);

  const handleBuscaDados = async () => {
    // console.log('busca viagensfinalizadas/F');
    // passando C de Concluido
    var id_manutencao = 0;
    await api
      .get(`manutencaoandamento`)
      .then((response) => {
        // console.log("retorno", response.data);
        id_manutencao = response.data[0].id_manutencao;
        setFormData(response.data[0]);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
      });
    handleBuscaLancamentos(id_manutencao);
  };
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    // console.log(tableMeta);
    // console.log(updateValue);
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return nf.format(value);
  };
  const handleClickNew = () => {
    navigate("/estabelecimentonew");
  };
  const handleClickEditDespesa = () => {
    if (IDLancamento >= 0) {
      // let selecionado = itens[ID] as Item;
      // navigate(`/viagem/${formData.id_manutencao}/despesaapp/${IDDespesa}`);
      navigate(
        `/manutencoesapp/${formData.id_manutencao}/lancamento/${IDLancamento}`
      );
    } else {
      alert("Nenhuma Despesa Selecionada!");
    }
  };
  async function handleClickPosicao() {
    setShow(!show);
  }
  const handleClickNewLancamento = () => {
    // console.log(selecionado);

    if (ID >= 0) {
      navigate(`/manutencoesapp/${formData.id_manutencao}/lancamento`);
    } else {
      alert("Nenhuma Manutenção Aberta!");
    }
  };
  function handleClickEditLancamento(id_manutencao_seq: number) {
    //console.log(id_manutencao_seq);
    setIDLancamento(id_manutencao_seq);
    setShowConfirmEdit(true);
  }
  const handleClickNewViagemResumo = () => {
    navigate(`/viagemresumo`);
  };
  const handleOnReturn = async () => {
    marcado.current = 0;
    setID(-1);
    listaItens.current = Array<Item>();
    //await alert('Viagem(s) Finalizada(s)!');
    doRefresh(0);
    handleBuscaDados();
  };
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  }
  async function handleBuscaLancamentos(id_manutencao: Number) {
    await api.get(`manutencaoandamento/${id_manutencao}`).then((response) => {
      //descontos
      // console.log('descontos', response.data)
      setLancamentos(response.data);
    });
  }
  const formatDate = (date: Date) => {
    var d = new Date(date);
    d.setHours(d.getHours() + 3); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas
    // console.log(d);
    try {
      let formattedDate = Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(d);

      // console.log(formattedDate);
      return formattedDate;
    } catch (error) {
      return date;
    }
  };
  return (
    <div style={{ paddingLeft: "0", marginRight: "0" }}>
      <div className={classes.root}>
        <div className={classes.buttons}>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            style={{ visibility: "hidden" }}
            className={classes.buttonAcerto}
            onClick={(event) => handleClickPosicao()}
            startIcon={<AccountTree />}
          >
            Finalizar
          </Button>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            // className={classes.buttonExtra}
            onClick={(event) => handleClickNewLancamento()}
            startIcon={<NoteAdd />}
          >
            Lançar Itens
          </Button>
        </div>
      </div>
      <div className={classes.grid}>
        <div style={{ flexDirection: "column", width: "100%" }}>
          <h2 style={{ textAlign: "center", marginTop: "7px" }}>
            Manutenção Aberta
          </h2>
          <TextField
            disabled
            type="text"
            value={formData.descricao}
            onChange={handleInputChange}
            style={{ width: "100%", fontSize: 12 }}
            // className={classes.field}
            id="descricao"
            name="descricao"
            label="Descrição"
          />
          <div
            style={{
              flexDirection: "row",
              width: "100%",
              display: "flex",
              marginTop: "2px",
            }}
          >
            <TextField
              disabled
              type="text"
              value={formData.placa}
              onChange={handleInputChange}
              // className={classes.field}
              // style={{ marginRight: "5px" }}
              id="placa"
              name="placa"
              label="Placa"
            />
            <TextField
              disabled
              type="text"
              value={formatDate(formData.data_inicio)}
              onChange={handleInputChange}
              style={{ marginLeft: "5px" }}
              id="inicio"
              name="inicio"
              label="Data de Início"
            />
            <TextField
              disabled
              type="text"
              value={formataValor(formData.valor_total, null, null)}
              onChange={handleInputChange}
              style={{ marginLeft: "5px" }}
              id="valor_total"
              name="valor_total"
              label="Valor Total"
            />
          </div>
        </div>
      </div>

      <div className={classes.tabelas}>
        <div
          className={classes.tabela}
          style={{
            width: "100%",
            borderRadius: 20,
          }}
        >
          {lancamentos.map((lancamento: any, index: any) => (
            <div className={classes.post}>
              <div
                className={classes.postClick}
                onClick={(event) =>
                  handleClickEditLancamento(lancamento.id_manutencao_seq)
                }
              >
                <div className={classes.postHeader}>
                  <div
                    style={{ justifyContent: "end" }}
                    className={classes.postPropertyHeader}
                  >
                    <span
                      style={{ marginRight: "10px" }}
                      className={classes.postHeaderValue}
                    >
                      Valor:
                    </span>
                    <TextField
                      style={{}}
                      inputProps={{ readOnly: true }}
                      className={classes.postHeaderValue}
                      value={formataValor(lancamento.valor, null, null)}
                    />
                  </div>
                </div>
                <div className={classes.postDados}>
                  <div className={classes.postPropertyHeader}>
                    <span className={classes.postProperty}>Descrição:</span>
                    <TextField
                      inputProps={{ readOnly: true }}
                      style={{ textAlign: "center", paddingLeft: "0px" }}
                      className={classes.postValue}
                      value={lancamento.descricao}
                    />
                  </div>
                  <div className={classes.postPropertyHeader}>
                    <span className={classes.postProperty}>Quantidade:</span>

                    <TextField
                      inputProps={{ readOnly: true }}
                      className={classes.postValue}
                      value={lancamento.qtd}
                    />
                  </div>
                  {/* <div className={classes.postPropertyHeader}>
                    <span className={classes.postProperty}>Valor:</span>

                    <TextField
                      inputProps={{ readOnly: true }}
                      className={classes.postValue}
                      value={formataValor(despesa.valor, null, null)}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* } */}
      <Modal style={{ marginTop: 100 }} show={show} onHide={handleCloseCancel}>
        <Form //className={classes.dadosViavem} ref={formRef}
          onSubmit={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Finalizar Viagem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1"> */}
            <div className="mb-3">
              {/* <div style={{marginTop: 10, marginLeft: 20}}> */}
              <span
                style={{ marginTop: 10, marginLeft: 20 }}
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required"
              >
                Data da Chegada
              </span>
              {/* </div> */}
              <TextField
                required
                type="date"
                value={formData.data_fim}
                onChange={handleInputChange}
                // className="mb-3"//
                className={classes.field}
                id="data_chegada"
                name="data_chegada"
                // label="Data da Chegada"
              />
            </div>
            <div className="mb-3">
              {/* <h2>Km Final</h2> */}
              <TextField
                required
                type="number"
                value={formData.km_final}
                onChange={handleInputChange}
                className={classes.field}
                id="km_final"
                name="km_final"
                label="KM Final"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button1 variant="secondary" onClick={handleCloseCancel}>
              Fechar
            </Button1>
            <Button1 variant="primary" type="submit" onClick={handleClose}>
              Finalizar
            </Button1>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalConfirm
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirm(false);
          }
        }}
      />
      <ModalConfirm
        title={titleConfirmEdit}
        description={descricaoConfirmEdit}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirmEdit}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            // navigateAcerto();
            handleClickEditDespesa();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirmEdit(false);
          }
        }}
      />
      <div>
        <ImageEvento
          show={showImage}
          source={sourceImage}
          onReturn={() => {
            setShowImage(false);
            setSourceImage("");
          }}
        />
      </div>
    </div>
  );
};
export default Viagem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // display: 'flex',
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      marginRight: "0px",

      // paddingLeft:'10px',
      // margin: theme.spacing(1),
    },
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    grid: {
      display: "flex",
      flexDirection: "row",
    },
    grid70: {
      display: "none",
      width: "65%",
    },
    grid100: {
      display: "none",
      width: "100%",
    },
    field: {
      textAlign: "center",
      width: "90%",
      fontSize: "22px",
      paddingTop: "0px",
      paddingBottom: "0px",
      marginTop: "0px",
      marginBottom: "0px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
      // height: '40px'
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonAcerto: {
      marginLeft: "2px",
      //  alignItems: 'flex-start',
      backgroundColor: "green",
    },
    buttonsRight: {
      flexDirection: "row",

      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    buttons: {
      flexDirection: "row",
      justifyContent: "space-between",
      display: "flex",
      // justifyContent: 'flex-end',
      // alignItems: 'flex-end',
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonHide: {
      display: "none",
    },
    resumo: {
      width: "100%",
      //display: 'flex',
      flexDirection: "column",
      backgroundColor: "#Fff",
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    postClick: {
      width: "100%",
      // flexDirection: 'row',
      // justifyContent: 'space-between',
    },
    postHeader: {
      flexDirection: "row",
      // padding: 20,
      alignItems: "center",
    },
    postHeaderValue: {
      color: "#1A0657",
      fontWeight: "bold",
      fontSize: 20,
    },
    postHeaderProperty: {
      alignItems: "center",
      color: "#7472F2",
      marginLeft: 5,
      // fontWeight: 'bold',
      fontSize: 14,
      display: "flex",
      width: "100%",
    },
    postPropertyHeader: {
      alignItems: "inherit",
      display: "flex",
      width: "100%",
    },
    postProperty: {
      fontSize: 14,
      color: "#1A0657",
      // fontWeight:'bold'
    },
    postPropertyValor: {
      fontSize: 12,
      color: "#41414d",
      fontWeight: "bold",
      alignItems: "flex-end",
    },
    postValue: {
      // width: "100%",
      // marginTop: 4,
      fontSize: 12,
      color: "#737380",
      textAlign: "center",
      // display: 'flex',
      paddingLeft: "5px",
      // paddingTop: "2px",
      // paddingBottom: "2px",
      width: "100%",
      marginLeft: 8,
      // marginRight: 8,
    },
    postDetailsButton: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    postDetailsText: {
      color: "#e02041",
      fontSize: 15,
      fontWeight: "bold",
    },
    postDados: {
      marginTop: 3,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    post: {
      // marginLeft: 10,
      // marginRight: 10,
      // marginTop: 5,
      marginBottom: 6,
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      padding: 10,
      // paddingLeft:30,
      backgroundColor: "#FFFFFF",
      borderRadius: 10,
      borderColor: "#C5CADA",
      // borderColor: '#C5CADA',
      justifyContent: "center",
      borderWidth: 0.6,
      borderLeft: "2px solid rgb(0 0 0 / 20%)",
    },
    postAndroid: {
      // borderRightColor: 'red',
      borderRightWidth: 10,
      marginHorizontal: 10,
      height: 94,
      paddingHorizontal: 15,
      justifyContent: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: 10,
      // borderColor: '#9f9dfa',
      borderLeftColor: "#C5CADA",
      borderTopColor: "#C5CADA",
      borderBottomColor: "#C5CADA",
      borderWidth: 0.6,
      marginTop: 5,
    },
    // tss-1akey0g-MUIDataTableHeadCell-data :{}
  })
);
