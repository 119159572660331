// paper & background
export const paper = '#ffffff';

// primary
export const primaryLight = '#eef2f6';
export const primaryMain = '#2196f3';
export const primaryDark = '#1e88e5';
export const primary200 = '#90caf9';
export const primary800 = '#1565c0';

// secondary
export const secondaryLight = '#ede7f6';
export const secondaryMain = '#673ab7';
export const secondaryDark = '#5e35b1';
export const secondary200 = '#b39ddb';
export const secondary800 = '#4527a0';

// success Colors
export const successLight = '#b9f6ca';
export const success200 = '#69f0ae';
export const successMain = '#00e676';
export const successDark = '#00c853';

// error
export const errorLight = '#ef9a9a';
export const errorMain = '#f44336';
export const errorDark = '#c62828';

// orange
export const orangeLight = '#fbe9e7';
export const orangeMain = '#ffab91';
export const orangeDark = '#d84315';

// warning
export const warningLight = '#fff8e1';
export const warningMain = '#ffe57f';
export const warningDark = '#ffc107';

// grey
export const grey50 = '#F8FAFC';
export const grey100 = '#EEF2F6';
export const grey200 = '#E3E8EF';
export const grey300 = '#CDD5DF';
export const grey500 = '#697586';
export const grey600 = '#4B5565';
export const grey700 = '#364152';
export const grey900 = '#121926';

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
export const darkBackground = '#1a223f'; // level 3
export const darkPaper = '#111936'; // level 4

// dark 800 & 900
export const darkLevel1 = '#29314f'; // level 1
export const darkLevel2 = '#212946'; // level 2

// primary dark
export const darkPrimaryLight = '#eef2f6';
export const darkPrimaryMain = '#2196f3';
export const darkPrimaryDark = '#1e88e5';
export const darkPrimary200 = '#90caf9';
export const darkPrimary800 = '#1565c0';

// secondary dark
export const darkSecondaryLight = '#d1c4e9';
export const darkSecondaryMain = '#7c4dff';
export const darkSecondaryDark = '#651fff';
export const darkSecondary200 = '#b39ddb';
export const darkSecondary800 = '#6200ea';

// text variants
export const darkTextTitle = '#d7dcec';
export const darkTextPrimary = '#bdc8f0';
export const darkTextSecondary = '#8492c4';

// ==============================|| JAVASCRIPT ||============================== //

// :export {
//     // paper & background
//     paper: $paper;

//     // primary
//     primaryLight: $primaryLight;
//     primary200: $primary200;
//     primaryMain: $primaryMain;
//     primaryDark: $primaryDark;
//     primary800: $primary800;

//     // secondary
//     secondaryLight: $secondaryLight;
//     secondary200: $secondary200;
//     secondaryMain: $secondaryMain;
//     secondaryDark: $secondaryDark;
//     secondary800: $secondary800;

//     // success
//     successLight: $successLight;
//     success200: $success200;
//     successMain: $successMain;
//     successDark: $successDark;

//     // error
//     errorLight: $errorLight;
//     errorMain: $errorMain;
//     errorDark: $errorDark;

//     // orange
//     orangeLight: $orangeLight;
//     orangeMain: $orangeMain;
//     orangeDark: $orangeDark;

//     // warning
//     warningLight: $warningLight;
//     warningMain: $warningMain;
//     warningDark: $warningDark;

//     // grey
//     grey50: $grey50;
//     grey100: $grey100;
//     grey200: $grey200;
//     grey300: $grey300;
//     grey500: $grey500;
//     grey600: $grey600;
//     grey700: $grey700;
//     grey900: $grey900;

//     // ==============================|| DARK THEME VARIANTS ||============================== //

//     // paper & background
//     darkPaper: $darkPaper;
//     darkBackground: $darkBackground;

//     // dark 800 & 900
//     darkLevel1: $darkLevel1;
//     darkLevel2: $darkLevel2;

//     // text variants
//     darkTextTitle: $darkTextTitle;
//     darkTextPrimary: $darkTextPrimary;
//     darkTextSecondary: $darkTextSecondary;

//     // primary dark
//     darkPrimaryLight: $darkPrimaryLight;
//     darkPrimaryMain: $darkPrimaryMain;
//     darkPrimaryDark: $darkPrimaryDark;
//     darkPrimary200: $darkPrimary200;
//     darkPrimary800: $darkPrimary800;

//     // secondary dark
//     darkSecondaryLight: $darkSecondaryLight;
//     darkSecondaryMain: $darkSecondaryMain;
//     darkSecondaryDark: $darkSecondaryDark;
//     darkSecondary200: $darkSecondary200;
//     darkSecondary800: $darkSecondary800;
// }
