import PropTypes from "prop-types";
import { useState, useEffect } from "react";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";

// third-party
import Chart from "react-apexcharts";

// project imports
import MainCard from "../../../ui-component/cards/MainCard";
import SkeletonTotalOrderCard from "../../../ui-component/cards/Skeleton/EarningCard";

// import ChartDataMonth from './chart-data/total-order-month-line-chart';
// import ChartDataYear from './chart-data/total-order-year-line-chart';

// assets
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  primary200,
  primary800,
  primaryDark,
} from "../../../assets/scss/themejs";

// const primary_dark = '#1e88e5';
// const primary800 = '#1565c0';
// const primary200 = '#90caf9';
const CardWrapper = styled(MainCard)(({ theme }) => ({
  backgroundColor: primaryDark,
  color: "#fff",
  overflow: "hidden",
  position: "relative",
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    background: primary800,
    borderRadius: "50%",
    zIndex: 1,
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    zIndex: 1,
    width: 210,
    height: 210,
    background: primary800,
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

// ==============================|| DASHBOARD - TOTAL ORDER LINE CHART CARD ||============================== //

const TotalOrderLineChartCard = ({ isLoading, dados }) => {
  const theme = useTheme();

  const [timeValue, setTimeValue] = useState(false);
  const handleChangeTime = (event, newValue) => {
    setTimeValue(newValue);
  };

  useEffect(() => {
    setchartDataMonth({
      type: "line",
      height: 90,
      options: {
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#fff"],
        fill: {
          type: "solid",
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        yaxis: {
          min: 0,
          max: 5,
        },
        tooltip: {
          theme: "dark",
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: "Total Order",
          },
          marker: {
            show: false,
          },
        },
      },
      series: [
        {
          name: "series1",
          data: dados.detKML30, //[45, 66, 41, 89, 25, 44, 9, 54]
        },
      ],
    });
    setChartDataYear({
      type: "line",
      height: 90,
      options: {
        chart: {
          sparkline: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#fff"],
        fill: {
          type: "solid",
          opacity: 1,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        yaxis: {
          min: 0,
          max: 5,
        },
        tooltip: {
          theme: "dark",
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            title: "Total Order",
          },
          marker: {
            show: false,
          },
        },
      },
      series: [
        {
          name: "series1",
          data: dados.detKML90, //[45, 66, 41, 89, 25, 44, 9, 54]
        },
      ],
    });
  }, [dados]);
  // ChartDataYear
  const [chartDataMonth, setchartDataMonth] = useState({
    type: "line",
    height: 90,
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#fff"],
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      yaxis: {
        min: 0,
        max: 100,
      },
      tooltip: {
        theme: "dark",
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: "Total Order",
        },
        marker: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "series1",
        data: [45, 66, 41, 89, 25, 44, 9, 54],
      },
    ],
  });
  const [chartDataYear, setChartDataYear] = useState({
    type: "line",
    height: 90,
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#fff"],
      fill: {
        type: "solid",
        opacity: 1,
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      yaxis: {
        min: 0,
        max: 100,
      },
      tooltip: {
        theme: "dark",
        fixed: {
          enabled: false,
        },
        x: {
          show: false,
        },
        y: {
          title: "Total Order",
        },
        marker: {
          show: false,
        },
      },
    },
    series: [
      {
        name: "Viagem",
        data: [
          45, 66, 41, 89, 66, 41, 89, 25, 44, 45, 66, 41, 83, 49, 54, 45, 25,
          44, 9, 54, 25, 44, 9, 54, 45, 66, 41, 89, 25, 44, 9, 54, 45, 66, 41,
          89, 25, 44, 9, 54,
        ],
      },
    ],
  });

  return (
    <>
      {isLoading ? (
        <SkeletonTotalOrderCard />
      ) : (
        <CardWrapper border={false} content={false}>
          <Box sx={{ p: 2.25 }} style={{ minHeight: "200px" }}>
            <Grid container direction="column">
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Avatar
                      variant="rounded"
                      sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.largeAvatar,
                        backgroundColor: primary800,
                        color: "#fff",
                        mt: 1,
                      }}
                    >
                      <LocalMallOutlinedIcon fontSize="inherit" />
                    </Avatar>
                    {/* <TextField
                                            sx={{
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                color: primary200
                                            }}
                                        > */}
                    {/* Melhor KM/L */}
                    {/* </TextField> */}
                  </Grid>
                  <Grid item>
                    <Button
                      disableElevation
                      variant={timeValue ? "contained" : "text"}
                      size="small"
                      sx={{ color: "inherit" }}
                      onClick={(e) => handleChangeTime(e, true)}
                    >
                      30 Dias
                    </Button>
                    <Button
                      disableElevation
                      variant={!timeValue ? "contained" : "text"}
                      size="small"
                      sx={{ color: "inherit" }}
                      onClick={(e) => handleChangeTime(e, false)}
                    >
                      90 Dias
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sx={{ mb: 0.75 }}>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Grid container alignItems="center">
                      <Grid item>
                        {timeValue ? (
                          <>
                            <Typography
                              sx={{
                                fontSize: "1.2rem",
                                fontWeight: 500,
                                mr: 1,
                                mt: 1.5,
                                mb: 0,
                              }}
                            >
                              {dados.melhor_km.max == "NaN"
                                ? "--"
                                : dados.melhor_km.max}{" "}
                              KM/L
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "1.5rem",
                                fontWeight: 500,
                                mr: 1,
                                mt: 0,
                                mb: 0.75,
                              }}
                            >
                              {dados.melhor_km.placa}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography
                              sx={{
                                fontSize: "1.2rem",
                                fontWeight: 500,
                                mr: 1,
                                mt: 1.5,
                                mb: 0,
                              }}
                            >
                              {dados.melhor_km.max == "NaN"
                                ? "--"
                                : dados.melhor_km.max}{" "}
                              KM/L
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "1.5rem",
                                fontWeight: 500,
                                mr: 1,
                                mt: 0,
                                mb: 0.75,
                              }}
                            >
                              {dados.melhor_km.placa}
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item>
                        {/* <Avatar
                                                    sx={{
                                                        ...theme.typography.smallAvatar,
                                                        cursor: 'pointer',
                                                        backgroundColor: primary200,
                                                        color: primaryDark
                                                    }}
                                                >
                                                    <ArrowDownwardIcon fontSize="inherit" sx={{ transform: 'rotate3d(1, 1, 1, 45deg)' }} />
                                                </Avatar> */}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: "0.95rem",
                            fontWeight: 500,
                            color: primary200,
                          }}
                        >
                          Melhor KM/L
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    {timeValue ? (
                      <Chart {...chartDataMonth} />
                    ) : (
                      <Chart {...chartDataYear} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardWrapper>
      )}
    </>
  );
};

TotalOrderLineChartCard.propTypes = {
  isLoading: PropTypes.bool,
};

export default TotalOrderLineChartCard;
