import React, { useEffect, useRef, ChangeEvent, useState, FormEvent, useReducer } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
// import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {useNavigate} from 'react-router-dom';
import base64 from 'base-64';
import api from '../../services/api';
import ModalConfirm from '../../component/ModalConfirm';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import MobileStepper from '@mui/material/MobileStepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import {} from 'Yup';

// import { login, loginUser, loginUserEstab } from "../../services/auth";

interface RouterProps {
  history?: {};
  location?: {};
  match?: {params: {
    id: string}};    
}; 
interface UserTable {
  email: string,
  password:string,
  passwordConfirm:string,
  web:'WEB',
  tipo: string, 
  nome: string,
  sobrenome: string,
  end: string,
  tel: string, 
  tel2: string
}

const steps = ['Apresentação','Usuário', 'Empresa', 'Eventos', 'Veículo', 'Finalizar'];


const NovoCadastroUser: React.FC<RouterProps> = (props:RouterProps) => {
    let navigate   = useNavigate();
    const classes = useStyles();
    const user1 = {};
    const titleConfirm = 'Novo Cadastro';
    let [value, setState] = useState(true);
    const [msgReturnInsert, setMsgReturnInsert] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);
    const [showPosConfirm, setShowPosConfirm] = useState(false);
    
    const [keyUsuario, setKeyUsuario] = useState(0);
    const [showError, setShowError] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [formData, setFormData] = useState({email: '',emailConfirm:'',
      password:'',
      passwordConfirm:'',
      razao_social:'',
      tipo: '', 
      nome: '',
      sobrenome: '',
      end: '',
      tel: '',
      fone: '',
      email_empresa: '',
      qtd_veiculos: 0, 
      usa_frete: '',
      cnpj: '',
      cpf: '',
      eventos:[{descricao:'Frete', tipo:'1'}, {descricao:'Refeição', tipo:'2'}, {descricao:'Pedágio', tipo:'2'}, {descricao:'Combustível', tipo:'2'}],
      placa: '', ano:0, modelo: '', marca: '', cor: '',tipo_veiculo: '', renavam: ''
    });

//     const [formData, setFormData] = useState({
//     ano:"2012",cnpj: "33.243.434/3333-23",cor: "",email: "chaianescunha@gmail.com",emailConfirm: "chaianescunha@gmail.com",
// email_empresa: "chaianescunha@gmail.com",end: "Rua Políbio Pinheiro",
// eventos:[{descricao:'Frete', tipo:'1'}, {descricao:'Refeição', tipo:'2'}, {descricao:'Pedágio', tipo:'2'}, {descricao:'Combustível', tipo:'2'}],
// fone:"47999159693",marca: "",modelo: "",nome: "Chaiane Santos Cunha",password: "123456",passwordConfirm: "123456",placa: "UTY-3927",
// qtd_veiculos: "1",razao_social: "Chaiane Cunha",renavam: "",sobrenome: "",tel: "47999-159693",tipo:"",
// tipo_veiculo:"G",usa_frete: "S"    });

    const formRef = useRef<FormHandles>(null);
    const formRefUser = useRef<FormHandles>(null);
    const formRefEmpresa = useRef<FormHandles>(null);
    const formRefVeiculo = useRef<FormHandles>(null);
    
    useEffect(() => {
          if (localStorage.getItem('UserTipo') == 'M') {
            alert('Opção Inválida!');
            navigate('/');
          }
      }, []);    

    async function handleSubmit(event: FormEvent) {

      
      console.log('formRef.current',formRef.current);
      formRef.current?.setErrors({});
      // formRef.current?.c
      //validação de usuário
      var stepErro = -99;
      var erro = false;
      stepErro = 0;

      
      if (!erro) {
        setShowConfirm(true);
      }
      
    };
    async function handleInsert() {
      setOpen(true);

      const encText = base64.encode(formData.password);
      // setFormData({...formData, [id]:value});

      const formEnvio = {...formData, ['password']:encText}
      // console.log(formEnvio);
      const response = await api.post("/cadastrese", formEnvio);
      if (response.data.sucess) {
        setOpen(false);
        setShowConfirm(false);
        setMsgReturnInsert('Cadastro Realizado com Sucesso!');
        setShowPosConfirm(true)
        // alert("Usuário cadastrado com Sucesso!");
        // navigate(`/login`);
      } else {
        setOpen(false);
        //   // console.log(response.data);
        setShowConfirm(false);
        // alert(response.data.error);
        setMsgReturnInsert(response.data.error);
        setShowError(true);
      }
    }

    function handleInputChange(event: ChangeEvent<HTMLInputElement>){
          
        const {id, value} = event.target;   
        if (id == 'cnpj' || id == 'cpf') {
           // não deixa ser digitado nenhuma letra
            if (value.length<19) {
                var v = value.replace(/\D+/g, '');
                if (v.length <= 11) {
                  v = v.replace(/(\d{3})(\d)/, "$1.$2")
                  v = v.replace(/(\d{3})(\d)/, "$1.$2")
                  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
                } else {
                  v = v.replace(/^(\d{2})(\d)/, "$1.$2")
                  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
                  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
                  v = v.replace(/(\d{4})(\d)/, "$1-$2")
                }
                setFormData({...formData, [id]:v});
            }
        } else if (id == 'tel') {
          //(34) 23727-7237
          //47999159693
          //(32) 48774-73479328
          var phone = value.substring(0,15).replace(/\D/g, "");
          phone = phone.replace(/(\d{2})?(\d{5})?(\d{4})?/, "($1) $2-$3")
          //console.log(phone);
          setFormData({...formData, [id]:phone.substring(0,15)});
        } else if (id == 'fone') {
          
          var phone = value.substring(0,15).replace(/\D/g, "");
          phone = phone.replace(/(\d{2})?(\d{5})?(\d{4})?/, "($1) $2-$3")
          //console.log(phone);
          setFormData({...formData, [id]:phone.substring(0,15)});
        } else if (id == 'placa') {
          
          var placa = value;
          const placaFormatada = placa.replace(/[^a-zA-Z0-9]/g, '');

          const placaFinal = placa.replace(/([A-Z]{3})([0-9])([A-Z0-9])([0-9]{2})/, '$1-$2$3$4');
          console.log(placaFinal);
          setFormData({...formData, [id]:placaFinal.toUpperCase()});
        } else {
          setFormData({...formData, [id]:value});
        }
    };

    const handleChangeFrete = (event: SelectChangeEvent) => {
      const {value} = event.target;
      setFormData({...formData, ['usa_frete']:value});
    };
    const handleChangeTipoVeiculo = (event: SelectChangeEvent) => {
      const {value} = event.target;
      setFormData({...formData, ['tipo_veiculo']:value});
    };


    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepOptional = (step: number) => {
      return step === 2;
    };

    const isStepSkipped = (step: number) => {
      return skipped.has(step);
    };

    const handleNext = (evento:FormEvent) => {
      // evento.preventDefault();
      var segue = 'S';
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      formRef.current?.setErrors({});

      if (activeStep == 1) {
        if (formData.password != formData.passwordConfirm) {
          segue = 'N';
          // console.log('error2222', formRefUser.current);
          setMsgReturnInsert('Favor conferir as senhas informadas');
          setShowError(true);


        } 
      }
      if (segue == 'S') {
          // console.log('segue1', segue);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSkipped(newSkipped);
      } else {
          // console.log('segue2', segue);
          // setKeyUsuario(keyUsuario+1);
          // setState(!value);

      }
    };
  //   useEffect(() => {
  //     setKeyUsuario(keyUsuario+1);
  // }, [formRef.current]);   

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };

    const handleReset = () => {
      setActiveStep(0);
    };

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };

    return (        
        <Container maxWidth='md' className={classes.paper} style={{  }}>
          {/* <Form className={classes.root} ref={formRef} onSubmit={handleSubmit} noValidate={false}> */}

              <Box style={{ width: 'auto' }}>
                <Stepper activeStep={activeStep} >
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === 0 &&
                  <React.Fragment>
                    <Typography gutterBottom variant="h4" component="div"  style={{ marginTop: 20, textAlign:'center' }}>
                      Apresentação
                    </Typography>
                    <Divider variant="middle" />
                    <div style={{textAlign:'center', fontSize:'18px'}}>
                        <p>Se você está associado a uma empresa que já está cadastrada,</p> 
                        <p>por favor, solicite o cadastro ao administrador da sua empresa.</p>
                        <br></br>
                        <br></br>
                        Caso contrário, clique em "INICIAR" para prosseguir...
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                          Pular
                        </Button>
                      )}
                      <Button onClick={handleNext}>
                        Iniciar
                      </Button>
                    </Box>
                  </React.Fragment>
                }
                {/*  dados do usuário */}
                {activeStep === 1 &&
                  <React.Fragment key={keyUsuario}>
                    <Typography gutterBottom variant="h4" component="div"  style={{ marginTop: 20, textAlign:'center' }}>
                      Dados do Usuário
                    </Typography>
                    <Divider variant="middle" />
                    <Form className={classes.root} ref={formRefUser} onSubmit={handleNext}>
                        <div className={classes.inputs} >
                            <TextField required={value}  placeholder="Nome" onChange={handleInputChange}  style={{ width: '100%'}}
                              className={classes.field}  value={formData.nome} id="nome"  name="nome"  label="Nome" 
                              error={formRef.current?.getFieldError("nome")?true:false}
                              helperText={formRef.current?.getFieldError("nome")}                      
                              />
                        </div>
                        <div className={classes.inputs} >
                            <TextField required  onChange={handleInputChange}  className={classes.field} 
                              value={formData.cpf} id="cpf"  name="cpf"  label="CPF" inputProps={{ maxLength: 14,minLength: 14, }}
                              error={formRef.current?.getFieldError("cpf")?true:false}
                              placeholder="999.999.999-99" 
                              helperText={formRef.current?.getFieldError("cpf")}     />
                            <TextField required  type="phone" onChange={handleInputChange}  className={classes.field} 
                              value={formData.tel} id="tel"  name="tel" inputProps={{ maxLength: 15,minLength: 15, }} label="Telefone"
                              placeholder="(99) 9999-9999" 
                              // pattern="(\([0-9]{2}\))\s([9]{1})?([0-9]{4})-([0-9]{4})" 
                              title="Número de telefone precisa ser no formato (99) 9999-9999"
                              error={formRef.current?.getFieldError("tel")?true:false}                        //pattern:"([0-9]{2}) [0-9]{5}-[0-9]{4}"
                              helperText={formRef.current?.getFieldError("tel")}    />
                        </div>
                        <div className={classes.inputs} >
                            <TextField required  placeholder="Endereço" onChange={handleInputChange}  className={classes.field} 
                              value={formData.end} id="end"  name="end"  label="Endereço" style={{ width: '100%'}}
                              error={formRef.current?.getFieldError("end")?true:false}
                              helperText={formRef.current?.getFieldError("end")}     />
                        </div>
                        <div className={classes.inputs} >
                            <TextField required type="email" placeholder="E-mail" onChange={handleInputChange} 
                              className={classes.field}  value={formData.email} id="email"  name="email"  label="E-mail" 
                              error={formRef.current?.getFieldError("email")?true:false}
                              helperText={formRef.current?.getFieldError("email")}    />
                            <TextField required  type="email" placeholder="E-mail" onChange={handleInputChange} 
                              className={classes.field}  value={formData.emailConfirm} id="emailConfirm"  name="emailConfirm"  label="Confirme o E-mail" 
                              error={formRef.current?.getFieldError("emailConfirm")?true:false}
                              helperText={formRef.current?.getFieldError("emailConfirm")}    />
                        </div>
                        <div className={classes.inputs} >
                            <TextField  required  onChange={handleInputChange}  className={classes.field}  style={{ }}
                              value={formData.password} inputProps={{ minLength: 8 }} id="password"  name="password"  label="Senha"
                              type="password" placeholder="Senha" 
                              error={formRef.current?.getFieldError("password")?true:false}
                              helperText={formRef.current?.getFieldError("password")}    />
                            <TextField  required  onChange={handleInputChange}  style={{}} className={classes.field} 
                              value={formData.passwordConfirm} inputProps={{ minLength: 8 }} id="passwordConfirm" 
                              error={formRef.current?.getFieldError("passwordConfirm")?true:false}
                              helperText={formRef.current?.getFieldError("passwordConfirm")}
                              name="passwordConfirm"  label="Confirme a Senha" type="password" placeholder="Repita a senha" /> 
                        </div>            
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 , width: '100%'}}>
                      <Button
                        color="inherit"
                        disabled={activeStep === Number(0)}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Voltar
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button type="submit">
                        Próximo
                      </Button>
                    </Box>
                    </Form>
                  </React.Fragment>
                }
                {/*  dados do estabelecimento */}
                {activeStep === Number(2) &&
                  <React.Fragment>
                    <Typography gutterBottom variant="h4" component="div"  style={{ marginTop: 20, textAlign:'center' }}>
                      Dados do Estabelecimento
                    </Typography>
                    <Divider variant="middle" />
                    <Form className={classes.root} ref={formRefEmpresa} onSubmit={handleNext}>
                        <div className={classes.inputs} >
                            <TextField required value={formData.cnpj} onChange={handleInputChange} className={classes.field} id="cnpj" name="cnpj" label="CNPJ" inputProps={{ minLength: 18 }}/>
                            <TextField required value={formData.razao_social} onChange={handleInputChange} className={classes.field} id="razao_social" name="razao_social" label="Razão Social" />
                        </div>
                        <div className={classes.inputs} >
                            <TextField required type="telephone" value={formData.fone} onChange={handleInputChange} className={classes.field} id="fone" name="fone" label="Telefone" />
                            <TextField required type="email" value={formData.email_empresa} onChange={handleInputChange} className={classes.field} id="email_empresa" name="email_empresa" label="E-mail" />
                        </div>
                        <div className={classes.inputs} >
                            <TextField required type='number' value={formData.qtd_veiculos} onChange={handleInputChange} className={classes.field} id="qtd_veiculos" name="qtd_veiculos" label="Quantidade Máx. de Veículos" />
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}} >
                                <InputLabel style={{alignItems:'left',marginLeft: '10px',}} id="demo-simple-select-label">Usa Valor de Frete?</InputLabel>
                                <Select labelId="Usa_Frete" required  id="usa_frete" className={classes.field}  style={{height: '45px'}}
                                  value={formData.usa_frete} label="" placeholder="Usa Frete?" onChange={handleChangeFrete}>    
                                  <MenuItem value={'S'}>Sim</MenuItem>
                                  <MenuItem value={'N'}>Não</MenuItem>
                                </Select>
                            </div>
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 , width: '100%'}}>
                          <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                          >
                            Voltar
                          </Button>
                          <Box sx={{ flex: '1 1 auto' }} />
                          {isStepOptional(activeStep) && (
                            <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                              Sem CNPJ? Pular
                            </Button>
                          )}
                          <Button type='submit'>
                            Próximo
                          </Button>
                        </Box>
                    </Form>
                  </React.Fragment>
                }
                {/*  dados de eventos */}
                {activeStep === Number(3) &&
                  <React.Fragment>
                    <Typography gutterBottom variant="h4" component="div"  style={{ marginTop: 20, textAlign:'center' }}>
                      Eventos de Despesas
                    </Typography>
                    <Divider variant="middle" />
                    {/* <Form className={classes.root}  onSubmit={handleSubmit}> */}
                        <Divider textAlign="left">Exemplos pré-cadastrados</Divider>
                        <List style={{ padding: '0', marginTop:'14px'}}>
                              { formData.eventos.map((value, index ) => {
                                return (
                                  <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                    <div className={classes.inputs} >
                                        <TextField required value={formData.eventos[index].descricao} onChange={handleInputChange} className={classes.field} id="qtd_veiculos" name="qtd_veiculos" label="Descrição" />
                                        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}} >
                                            <InputLabel required style={{alignItems:'left',marginLeft: '10px',}} id="demo-simple-select-label">Tipo?</InputLabel>
                                            <Select labelId="tipo" required  id="tipo" className={classes.field}  style={{height: '45px'}}
                                              value={formData.eventos[index].tipo} label="" placeholder="Tipo do Evento" onChange={handleChangeFrete}>    
                                              <MenuItem value={'1'}>Soma</MenuItem>
                                              <MenuItem value={'2'}>Desconta</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                    <fieldset style={{borderTop:'none', borderRight:'none', borderLeft:'none', borderBottom: '2px solid #ede5e6', width: '100%',marginBottom:'10px'}}/>
                                  </div>
                                )})
                              }
                        </List>
                    {/* </Form> */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Voltar
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                          Skip
                        </Button>
                      )}
                      <Button onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finalizar Cadastro' : 'Próximo'}
                      </Button>
                    </Box>
                  </React.Fragment>
                }
                {/*  dados do veiculos */}
                {activeStep === Number(4) &&
                  <React.Fragment>
                    <Typography gutterBottom variant="h4" component="div"  style={{ marginTop: 20, textAlign:'center' }}>
                      Veículo
                    </Typography>
                    <Divider variant="middle" />
                    <Form className={classes.root} ref={formRefVeiculo} onSubmit={handleNext}>
                        {/* <Divider textAlign="left">Exemplos pré-cadastrados</Divider> */}
                        <div className={classes.inputs} >
                            <TextField required value={formData.placa} inputProps={{ maxLength: 8, pattern:"([A-Z]{3})(-)([0-9])([A-Z0-9])([0-9]{2})"  }}  onChange={handleInputChange} className={classes.field} 
                                      id="placa" name="placa" label="Placa" 
                                      placeholder='XXX-9X99'
                                      helperText={formRef.current?.getFieldError("placa")}
                                      error={formRef.current?.getFieldError("placa")?true:false}

                                      />
                            <TextField required value={formData.ano} type='number' onChange={handleInputChange} className={classes.field} id="ano" name="ano" label="Ano" />
                        </div>
                        <div className={classes.inputs} >
                            <TextField value={formData.marca} onChange={handleInputChange} className={classes.field} id="marca" name="marca" label="Marca" />
                            <TextField value={formData.cor} onChange={handleInputChange} className={classes.field} id="cor" name="cor" label="Cor" />
                        </div>
                        <div className={classes.inputs} >
                            <TextField value={formData.renavam} onChange={handleInputChange} className={classes.field} id="renavam" name="renavam" label="Renavam" />
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}} >
                              <InputLabel required style={{alignItems:'left',marginLeft: '10px',}} id="demo-simple-select-label">Tipo de Veículo</InputLabel>
                                <Select labelId="demo-simple-select-label" required id="tipo_veiculo" className={classes.field}  value={formData.tipo_veiculo}
                                  placeholder="Tipo de Usuário" onChange={handleChangeTipoVeiculo} style={{height: '45px'}}>
                                  <MenuItem value={'C'}>Cavalo</MenuItem> <MenuItem value={'R'}>Carroceria</MenuItem> <MenuItem value={'G'}>Geral</MenuItem>
                                </Select>
                            </div>
                        </div>

                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, width: '100%' }}>
                        <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Voltar
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                            Skip
                          </Button>
                        )}
                        <Button //onClick={handleNext} 
                        type="submit">
                          {activeStep === steps.length - 1 ? 'Finalizar Cadastro' : 'Próximo'}
                        </Button>
                      </Box>
                    </Form>

                  </React.Fragment>
                }
                {activeStep === Number(5) &&
                  <React.Fragment>
                    
                    <Typography gutterBottom variant="h4" component="div"  style={{ marginTop: 20, textAlign:'center' }}>
                      Finalizar Cadastro
                    </Typography>
                    <Divider variant="middle" />
                    <Form className={classes.root} onSubmit={handleSubmit}>
                        <div className={classes.inputs} >
                            <TextField disabled={true} required  placeholder="Nome" onChange={handleInputChange}  style={{ width: '100%'}}
                              className={classes.field}  value={formData.nome} id="nome"  name="nome"  label="Nome" />
                        </div>
                        <div className={classes.inputs} >
                            <TextField disabled={true} required type="email" placeholder="E-mail" onChange={handleInputChange} style={{ width: '100%'}} 
                              className={classes.field}  value={formData.email} id="email"  name="email"  label="E-mail" />
                        </div>
                        <div className={classes.inputs} >
                            <TextField disabled={true} required type="cpf" placeholder="CPF" onChange={handleInputChange} style={{ width: '100%'}} 
                              className={classes.field}  value={formData.cpf} id="cpf"  name="cpf"  label="CPF" />
                        </div>
                        <Divider textAlign="left" style={{ marginBottom: '10px'}} >Dados da Empresa</Divider>
                        {(formData.cnpj != '' && formData.cnpj.length > 10)&& 
                          <div className={classes.inputs} >
                              <TextField disabled={true} required value={formData.cnpj} onChange={handleInputChange} className={classes.field} id="cnpj" name="cnpj" label="CNPJ/CPF" inputProps={{ minLength: 18 }}/>
                              <TextField disabled={true} required value={formData.razao_social} onChange={handleInputChange} className={classes.field} id="razao_social" name="razao_social" label="Razão Social" />
                          </div>     
                        }
                        {formData.cpf != '' && 
                          <div className={classes.inputs} >
                              <TextField disabled={true} required value={formData.cpf} onChange={handleInputChange} className={classes.field} id="cpf" name="cpf" label="CPF" inputProps={{ minLength: 18 }}/>
                              <TextField disabled={true} required value={formData.nome} onChange={handleInputChange} className={classes.field} id="nome" name="nome" label="Nome" />
                          </div>     
                        }
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, width:'100%' }}>
                        <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Voltar
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                          //onClick={handleSubmit}
                          type="submit"
                          size="small" 
                          variant="contained"
                          color="primary">
                              Finalizar Cadastro
                        </Button>
                      </Box>
                    </Form>

                  </React.Fragment>
                }
              </Box>
          {/* </Form> */}
          {/* <Button onClick={handleOpen}>Show backdrop</Button> */}
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
              <CircularProgress color="inherit" />
          </Backdrop>
          <ModalConfirm
              // title={titleConfirm}
              title="Confirmar Cadastro?"
              description=""
              noText="Não"
              yesText="Sim"
              confirm={true}
              show={showConfirm}
              setShow={(show1:boolean)=>{
                if (show1) {
                  handleInsert();
                  setShowConfirm(false);
                  // setShowPosConfirm(true);
                } else {
                  setShowConfirm(false);
                }
              }}
            />
            <ModalConfirm
              title={titleConfirm}
              description={msgReturnInsert}
              neutralText="OK"
              confirm={false}
              show={showPosConfirm}
              setShow={(show1:boolean)=>{
                  navigate(`/login`);

                  // setShowPosConfirm(false);
              }}
            />
            <ModalConfirm
            title={titleConfirm}
            description={msgReturnInsert}
            neutralText="OK"
            confirm={false}
            show={showError}
            setShow={(show1:boolean)=>{
                setShowError(false);
            }}
          />
        </Container>
    )
}

export default NovoCadastroUser;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
          //width: '25ch',
        },
        width: 'auto',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    buttons: {
      height:'55px',
      display: 'flex',
      width: '30%',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      alignItems: 'center',
    },
    titulo: {
        borderTop:'none',
        borderRight:'none',
        borderLeft:'none',
        borderBottom: '2px solid #e5e5e6',
        width: '70%',
        fontFamily: '"Segoe UI"',
        // fontFamily: 'Roboto',
        
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: 400,
    },
    tituloh2: {
        textAlign: 'center',
        color: '#444445',
    },
    field:{
        minWidth:'300px',
        height:'75px',
        marginLeft: '10px',
        marginRight: '10px',
    }, 
    inputs : { 
      display: 'flex', 
      flexDirection: 'row', 
      width: '100%',
        '@media (max-width: 500px)': {
          flexDirection: 'column', 
          minHeight: '48px'
        },
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
        // width:'80%',
        width: 'auto', 
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#Fff',
        border: '1px solid #e5e5e6',
        borderRadius: '8px',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },      
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));