import PropTypes, { string } from 'prop-types';
import { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom";

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

// project imports
import MainCard from '../../../ui-component/cards/MainCard';
import TotalIncomeCard from '../../../ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { primary200, primary800, primaryDark, primaryLight } from '../../../assets/scss/themejs';

// styles
const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: primaryDark,
    color: primaryLight,
    overflow: 'hidden',
    position: 'relative',
    // borderRadius: '10px',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${primary200} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${primary200} -14.02%, rgba(144, 202, 249, 0) 77.58%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));

// ==============================|| DASHBOARD - TOTAL INCOME DARK CARD ||============================== //

const TotalIncomeDarkCard = ({ isLoading, dados }) => {
    const theme = useTheme();
    let navigate   = useNavigate();

    const [dadosAgenda, setDadosAgenda] = useState({placa:'', data_inicio:'', descricao:'', id_manutencao:0});

    useEffect(() => {
        try {
            // console.log('dados0',dados);

            if (dados.agendados[0]) {
                if (dados.agendados[0].placa != '') {
                    // console.log('dados0',dados.agendados[0].data_inicio);
                    const dateNow = new Date(dados.agendados[0].data_inicio); // Creating a new date object with the current date and time
                    const year = dateNow.getFullYear(); // Getting current year from the created Date object
                    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
                    const month = monthWithOffset.toString().length < 2 ? `0${monthWithOffset}`: monthWithOffset;
                    const date  = dateNow.getUTCDate().toString().length < 2 ? `0${dateNow.getUTCDate()}`: dateNow.getUTCDate();

                    const materialDateInput = `${date}/${month}/${year}`;

                    var agenda = {id_manutencao:dados.agendados[0].id_manutencao, placa:dados.agendados[0].placa, data_inicio:materialDateInput, descricao:dados.agendados[0].descricao}
                    setDadosAgenda(agenda);
                } else {
                    var agenda = {placa:'', data_inicio:'', descricao:'', id_manutencao:0}
                    setDadosAgenda(agenda);
                }
            } else {
                var agenda = {placa:'', data_inicio:'', descricao:'', id_manutencao:0}
                setDadosAgenda(agenda);
            }
        } catch(error) {

        }
    }, [dados]);

    function handleManutencao() {
        if (dadosAgenda.id_manutencao > 0 ) { 
            navigate(`/manutencao/${dadosAgenda.id_manutencao}`);        
        } 
    };

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false} onClick={handleManutencao}>
                    <Box sx={{ p: 2 , padding:'11px'}}>
                        <List sx={{ py: 0 }}>
                            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            backgroundColor: primary800,
                                            color: '#fff'
                                        }}
                                    >
                                        <TableChartOutlinedIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        py: 0,
                                        mt: 0.45,
                                        mb: 0.45
                                    }}
                                    primary={
                                        <>
                                        {/* <Typography variant="subtitle2" sx={{ color: primaryLight, mt: 0.25 }}>
                                            {dadosAgenda.descricao}
                                        </Typography> */}
                                        <Typography variant="h4" sx={{ color: '#fff', fontSize: '1.2rem' }}>
                                            {dadosAgenda.placa != '' && 
                                                <>
                                                    Data: {dadosAgenda.data_inicio} -- Veículo: {dadosAgenda.placa}
                                                </>
                                            }
                                            {dadosAgenda.placa == '' && 
                                                <>
                                                    Sem Dados...
                                                </>
                                            }
                                        </Typography>
                                        </>
                                    }
                                    secondary={
                                        <Typography variant="subtitle2" sx={{ color: primaryLight, mt: 0.25 }}>
                                            {dadosAgenda.descricao}
                                        </Typography>
                                    }
                                    
                                />
                            </ListItem>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalIncomeDarkCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalIncomeDarkCard;
