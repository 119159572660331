import React, { useEffect, useState, useRef} from 'react';
import MUIDataTable from "mui-datatables";
import {useNavigate} from "react-router-dom";
import {MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AccountTree from '@material-ui/icons/AccountTree';
import ModalConfirm from '../../component/ModalConfirm';
import ModalRedefinir from '../../component/ModalRedefinir';
import NoteAdd from '@material-ui/icons/NoteAdd';
import base64 from 'base-64';

import api from '../../services/api';
import labels from '../../component/TableLabels';

interface Item {
  id_user_config:number,
  email: string,
  password: string,
  tipo: string,
  nome: string,
  sobrenome: string,
  end: string, 
  tel: string, 
  tel2: string,
}
interface HeaderProps {
  title?: string,
  match?: {params: {
    marca: string}};
}

const Users: React.FC<HeaderProps> = (props) => {
  let navigate   = useNavigate();
  const classes = useStyles();
  const titleConfirm = 'Cadastro de Usuário';
  const buttonEditRef = useRef<HTMLButtonElement>(null);
  const divGrid = useRef<HTMLDivElement>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showRedefinir, setShowRedefinir] = useState(false);

  const [refresh, doRefresh] = useState(0);
  const [ID, setID] = useState(Number);
  const [visible, setVisible] = useState(true);

  const [itens, setItens] = useState(Array<Item>());
  const marcado = useRef(0);
  const listaItens = useRef(Array<Item>());
  const [rowsSelected, setRowsSelected] = useState(Array<any>());
  
  useEffect(()=>{
    if (localStorage.getItem('UserTipo') == 'M') {
      alert('Opção Inválida!');
      navigate('/');
    } else {
      handleBuscaDados();
    }
  }, []);
  useEffect(()=>{
    doRefresh(prev => prev + 1);
  }, [listaItens.current]);
  useEffect(()=>{
    if (divGrid.current?.className =='makeStyles-grid70-21') {
        if (listaItens.current.length = 0) {
          // console.log('70-');
          divGrid.current?.classList.toggle(classes.grid70);        
          divGrid.current?.classList.toggle(classes.grid100);
        }
      } else if (divGrid.current?.className =='makeStyles-grid100-22') {
        if (listaItens.current.length > 0) {
          // console.log('100-');
          divGrid.current?.classList.toggle(classes.grid70);
          divGrid.current?.classList.toggle(classes.grid100);
        }
      } 
  }, [marcado]);

  const handleBuscaDados = async ( ) => {
      await api.get(`userconfig`).then(response => {
        // console.log('retorno',response.data)
        if (response.data.sucess) {
          setItens(response.data.estabs);
        }
      }).catch((error) => {
        // console.log('erro', error);
      }
    );
  }
  const columns = [ {
    name: "id_user_config",
    label: "ID",
    options: {
     filter: false,
     sort: false,
     display: false,
    }
   },{
    name: "email",
    label: "E-mail",
    options: {
     filter: true,
     sort: false,
     //display: false,
    }
   },{
    name: "nome",
    label: "Nome",
    options: {
     filter: true,
     sort: false,
     print: false
    }
   },{
    name: "sobrenome",
    label: "Sobrenome",
    options: {
     filter: true,
     sort: false
    }
   },{
    name: "tipo",
    label: "Tipo",
    options: {
     filter: true,
     sort: false,
     customBodyRender: (value:any, tableMeta:any, updateValue:any) => {
        if (value == 'A') {
          return 'Administrador';
        } else if (value == 'M') {
          return 'Motorista';
        }
      }
    }
   }
  ];
  var selectRow:number = -1;
  var SelectableRows: SelectableRows = 'single';
  var options :MUIDataTableOptions = {    
    onRowSelectionChange:(rowsSelectedData, allRows, rowsSelected) => {
      // console.log('1-',divGrid.current?.className);
      setRowsSelected(rowsSelected as Array<any>);
      let y1 = rowsSelected as number[];
      selectRow = -1;
      marcado.current = 0;
      setID(-1);
      setVisible(true);      
      // listaItens.current = Array<Item>();
      y1.map((numero:number) => {
        let item = itens[numero] as Item;
        // listaItens.current.push(item);
        selectRow = numero;
        setVisible(false);
        setID(numero);        
      });
      doRefresh(prev => prev + 1);
    },
    rowsSelected: rowsSelected,
    selectableRowsHeader: itens.length > 0,
    selectableRows: SelectableRows,  
    textLabels: labels,  
    selectableRowsOnClick: true,
    // selectToolbarPlacement: 'replace' | 'above' | 'none';
    // filter: false,
    // print: false,
    // download: false,
    // viewColumns: false,
    responsive: 'standard',
    rowsPerPage: 15,
    rowsPerPageOptions:[15,20,50,100],
    // pagination:false,
    
  }
  const handleClickInactive = ( ) => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      // console.log(selecionado);
      setShowConfirm(true);
      // navigate(`/viagemacerto/${selecionado.id_viagem}`);
    } else {
      alert('Nenhum Item Selecionado!');
    }
  };
  const handleClickRedefine = ( ) => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      console.log(selecionado);
      setShowRedefinir(true);
      // navigate(`/viagemacerto/${selecionado.id_viagem}`);
    } else {
      alert('Nenhum Item Selecionado!');
    }
  };
  async function handleConfirm() {
    let selecionado = itens[ID] as Item;

    const response = await api.post(`/userconfig_inactivate/${selecionado.id_user_config}`);
    if (response.data.sucess) {
      setRowsSelected([])
      handleBuscaDados(); 
    }
  }
  const handleClickNewUser = ( ) => {
      navigate(`/cadastrausuario`);
  };

  async function handleInsert(password:string) {
    const encText = base64.encode(password);
    // setFormData({...formData, [id]:value});
    let selecionado = itens[ID] as Item;

    const formEnvio = {['password']:encText}
    // console.log(formEnvio);
    const response = await api.post(`/redefinir/user/${selecionado.id_user_config}`, formEnvio);
    if (response.data.sucess) {
      // setShowConfirm(true);
    
      alert("Senha Redefinida com Sucesso!");
      setRowsSelected([])
      handleBuscaDados(); 
      // navigate(`/login`);
      // setMsgReturnInsert(response.data.msg);
      // setShowPosConfirm(true);
    } else {
    //   // console.log(response.data);
      alert(response.data.error);
      // setMsgReturnInsert(response.data.error);
      // setShowPosConfirm(true);
    }
  }
  return (
    <div>
      <div className={classes.root}>
        <Button
          ref={buttonEditRef}
          //disabled = {visible}
          size="small" 
          variant="contained"
          color="primary"
          // className={classes.buttonExtra}
          onClick={(event) => handleClickNewUser()}
          startIcon={<NoteAdd />}
            >Novo Usuário
          </Button>
          <div className={classes.buttons}>
            {!visible && 
              <Button
              ref={buttonEditRef}
              disabled = {visible}
              size="small" 
              variant="contained"
              color="secondary"
              style={{marginRight:'5px', backgroundColor:'green'}}
              // className={classes.buttonAcerto}
              onClick={(event) => handleClickRedefine()}
              startIcon={<AccountTree />}
                >Redefinir Senha
              </Button>
            } 
            <Button
            ref={buttonEditRef}
            disabled = {visible}
            size="small" 
            variant="contained"
            color="secondary"
            // className={classes.buttonAcerto}
            onClick={(event) => handleClickInactive()}
            startIcon={<AccountTree />}
              >Inativar
            </Button>
          </div>
      </div>
      <div className={classes.grid}>
        <div className={listaItens.current.length > 0 ? classes.grid70 : classes.grid100} ref={divGrid}>
          <MUIDataTable 
            title={`Usuários`}
            data={itens}
            columns={columns}
            options={options}
            />
        </div>
      </div>
      <ModalConfirm
          title={titleConfirm}
          description="Inativar Usuário?"
          noText="Não"
          yesText="Sim"
          confirm={true}
          show={showConfirm}
          setShow={(show1:boolean)=>{
            if (show1) {
              handleConfirm();
              setShowConfirm(false);
              // setShowPosConfirm(true);
            } else {
              setShowConfirm(false);
            }
          }}
        />
        <ModalRedefinir
          title="Redefirnir Senha"
          email={itens[ID]?.email}
          id={itens[ID]?.id_user_config}
          description="Redefirnir Senha?"
          noText="Não"
          yesText="Sim"
          confirm={true}
          show={showRedefinir}
          setShow={(show1:boolean, password:string)=>{
            if (show1) {              
              handleInsert(password);
              setShowRedefinir(false);
              // setShowPosConfirm(true);
            } else {
              setShowRedefinir(false);
            }
          }}
        />
    </div>   
  );
}
export default Users;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'flex-end',
         alignItems: 'flex-start',
         justifyContent:'space-between',
         margin: theme.spacing(1),
    },grid: {
      display: 'flex',
      flexDirection: 'row',
     },grid70: {
        width: '65%',
     },grid100: {
        width: '100%',
     },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },buttonAcerto: {
      marginLeft: theme.spacing(1),
      //  alignItems: 'flex-start',
      backgroundColor:'green'
    },
    buttons: {
      flexDirection:'row',
      
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonHide: {      
      display: 'none',
    },resumo: {
      width: '100%',
      //display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#Fff',
      alignItems: 'center',
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      borderRadius: '5px',
      marginLeft: '5px',
      marginRight: '5px',
    }
  }),
);