import React,{ useEffect, useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import EarningCard from './EarningCard';
// import PopularCard from './PopularCard';
import TotalOrderLineChartCard from './TotalOrderLineChartCard';
import TotalIncomeDarkCard from './TotalIncomeDarkCard';
import TotalIncomeLightCard from './TotalIncomeLightCard';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import { gridSpacing } from '../../../store/constant';
import Info from '@material-ui/icons/Info';
import Backdrop from '@mui/material/Backdrop';

import { darkBackground} from '../../../assets/scss/themejs';

import ModalConfirm from '../../../component/ModalConfirm';
import { Typography } from '@mui/material';

import '../../../assets/scss/style.scss';
// import config from '../../../config';
// import base64 from 'base-64';
import api from '../../../services/api';
import {USER_MANUAL} from '../../../services/auth';
import { number, string } from 'prop-types';
// import { login, loginUser, loginUserEstab } from "../../services/auth";

// ==============================|| DEFAULT DASHBOARD ||============================== //


const Dashboard = () => {
    // let navigate   = useNavigate();
    const [openManual, setOpenManual] = React.useState(false);

    const [isLoading, setLoading] = useState(true);
    // const [melhorKM, setMelhorKM] = useState({placa: '', max: ''});
    // const [maisRentavel, setMaisRentavel] = useState({id_viagem:number, placa: '', porcento: 0});
    // const [ultimasViagens, setUltimasViagens] = useState([{id_viagem:number, placa: '', comissao_vl: number, valor: number,    diesel: number,    arla: number,    pedagio: number,    gorjetas: number,    reparos: number}]);
    const [dados, setDados] = useState({
        detKML30:[],
        detKML90:[], 
        melhor_km:{placa: '', max: ''}, 
        maisRentavel:{id_viagem:number, placa: '', porcento: 0}, 
        ultimasViagens:[{
            id_viagem:number, 
            placa: '', 
            comissao_vl: number, 
            valor: number,    
            diesel: number,    
            arla: number,    
            pedagio: number,    
            gorjetas: number,    
            reparos: number}],
        agendados:[{
            data_inicio:Date,
            descricao:string,
            placa:string}]
        });
    // let ultimasViagens = null;
    //{ id_viagem: 14, placa: 'AAA-0000', porcento: 88 }
    useEffect(() => {
        // console.log(localStorage.getItem(USER_MANUAL));
        if (localStorage.getItem(USER_MANUAL) != 'true') {            
            setOpenManual(true);
        }
        handleBuscaDados();
        // setLoading(false);
        handleBuscaDadosAgenda();

    }, []);
    
    
    async function handleBuscaDados() {
        // console.log('id', idPar);
        await api.get(`dashboard`).then(response => {
            if (response.data.error){

            } else {
                let dadosRetorno = {detKML30:response.data.detKML30 ,detKML90:response.data.detKML90 , melhor_km:response.data.melhor_km, maisRentavel:response.data.maisRentavel, ultimasViagens:response.data.ultimasViagens};
                // console.log('dadosRetorno',dadosRetorno) 
                if (dadosRetorno.maisRentavel == undefined) {
                    dadosRetorno.maisRentavel = {id_viagem:number, placa: '', porcento: 0};
                }
                if (dadosRetorno.melhor_km == undefined) {
                    dadosRetorno.melhor_km = {placa: '', max: ''};
                }
                setDados(dadosRetorno);
            }
        }).finally({
        });

        
    }

    async function handleBuscaDadosAgenda() {

        await api.get(`manutencao/agenda`).then(response => {
            // console.log('dadosRetorno',response.data) 
            var agendados = [];
            if (response.data.error){
                setDados({...dados, ['agendados']:agendados});
            } else {
                response.data.map((agendas) => {
                    // console.log('agendas',agendas) 
                    let dadosRetorno = {id_manutencao:agendas.id_manutencao, data_inicio:agendas.data_inicio ,descricao:agendas.descricao , placa:agendas.placa};
                    agendados.push(dadosRetorno);
                });

                setDados({...dados, ['agendados']:agendados});
            }
        }).finally({
            
        });
        setLoading(false);

    };

    return (

        <Grid  style={{marginTop:'10px', }}container spacing={gridSpacing}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openManual}>
              {/* <CircularProgress color="inherit" /> */}
              <ModalConfirm
              title={'Acessar o manual do sistema?'}
              description={`Você pode acessar o manual do sistema clicando em `}
              description2={` no menu lateral.`}
            //   neutralText="OK"
                icon={<Info style={{marginLeft: '4px'}} />}
              confirm={true}
              noText={'Não'} 
              yesText={'Sim'}
              show={openManual}
              setShow={(show1)=>{
                if (show1) {
                    window.open('http://manual.siga.tec.br/', '_blank');
                }
                localStorage.setItem(USER_MANUAL, 'true');
                setOpenManual(false);
              }}
            />
            </Backdrop>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item lg={4} md={6} sm={6} xs={12}>
                        <EarningCard isLoading={isLoading} dados={dados}
                        />
                    </Grid>
                    <Grid item lg={4} md={8} sm={6} xs={12}>
                        <TotalOrderLineChartCard isLoading={isLoading} dados={dados}
                        />
                    </Grid>
                    
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Typography
                        sx={{
                            fontSize: '0.95rem',
                            fontWeight: 500,
                            color: darkBackground,
                            // backgroundColor:secondary800,
                            paddingLeft: '5px',
                            paddingTop: '2px',
                            paddingBottom: '2px',
                            marginBottom: '2px',
                            borderRadius: '8px',
                            textAlign: 'center'
                        }}
                    >
                        Próximas Manutenções...
                    </Typography>
                        <Grid container spacing={gridSpacing}>
                            <Grid item sm={6} xs={12} md={6} lg={12} sx={{cursor:'pointer'}}>
                                <TotalIncomeDarkCard isLoading={isLoading} 
                                // isLoading={false}
                                dados={dados} 
                                />
                            </Grid>
                            <Grid item sm={6} xs={12} md={6} lg={12}  sx={{cursor:'pointer'}}>
                                <TotalIncomeLightCard isLoading={isLoading} 
                                // isLoading={false}
                                dados={dados}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} md={12}>
                        <TotalGrowthBarChart isLoading={isLoading} 
                                // isLoading={false}
                                dados={dados}/>
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                        <PopularCard isLoading={isLoading} />
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
