import React, { useEffect, useRef, ChangeEvent, useState, FormEvent } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useNavigate, useParams } from 'react-router-dom';

import base64 from 'base-64';
import api from '../../services/api';
import ModalConfirm from '../../component/ModalConfirm';


interface RouterProps {
  history?: {};
  location?: {};
  match?: {params: {
    id: string}};    
}; 

const Login: React.FC<RouterProps> = (props:RouterProps) => {
    let navigate   = useNavigate();
    const titleConfirm = 'Redefinir Senha';
    let [value, setState] = useState(true);
    const classes = useStyles();
    const params = useParams();
    const [showConfirm, setShowConfirm] = useState(false);
    const [msgReturnInsert, setMsgReturnInsert] = useState('');
    const [showPosConfirm, setShowPosConfirm] = useState(false);
    const [idRedefinicao, setIdRedefinicao] = useState<String>('');

    

    const [formData, setFormData] = useState({
      email:'',
      password:'',
      passwordConfirm:'',
      password_redefinido:''
    });

    const formRef = useRef<FormHandles>(null);
    
    useEffect(() => {
        var idRedefinicao:String = String(params.id);
        setIdRedefinicao(idRedefinicao);
        // var originalUrl = '/redefinir/sduashduash*8s';
        // console.log(originalUrl.includes('/redefinir'));
        handleBuscaDados(idRedefinicao);
    }, []);    

    async function handleBuscaDados(idRedefinicao:String ) {
      if (idRedefinicao != '' &&  idRedefinicao != null) {
        await api.get(`redefinir/${idRedefinicao}`).then(response => {
          if (response.data.sucess) {
            console.log('user', response.data.user);
            setFormData(response.data.user);

          } else {
            console.log('error', response.data.error);
          }         

        }).catch((error) => {
          console.log('erro', error);
        }
        ).finally(() => {
            // console.log('finally')
            // handleBuscaPlaca2();
          }
        );
      }
    };

    async function handleSubmit(event: FormEvent) {
      // console.log(formData);
      var erro = false;
      if (formData.password != formData.passwordConfirm) {
          erro = true;
          formRef.current?.setFieldError('passwordConfirm', "A Nova Senha precisa ser igual!");
          setState(!value);          
      }      
      if (!erro) {
          setShowConfirm(true);
      }      
    };

    async function handleInsert() {
      const encText = base64.encode(formData.password);
      const encText1 = base64.encode(formData.password_redefinido);
      // setFormData({...formData, [id]:value});

      const formEnvio = {...formData, ['password']:encText, ['password_redefinido']:encText1}
      // console.log(formEnvio);
      const response = await api.post(`/redefinir/${idRedefinicao}`, formEnvio);
      if (response.data.sucess) {
        // setShowConfirm(true);
      
        alert("Senha Alterada com Sucesso!");
        navigate(`/login`);
        // setMsgReturnInsert(response.data.msg);
        // setShowPosConfirm(true);
      } else {
      //   // console.log(response.data);
      //   alert(response.data.error);
        setMsgReturnInsert(response.data.error);
        setShowPosConfirm(true);
      }
    }
    function handleInputChange(event: ChangeEvent<HTMLInputElement>){
        const {id, value} = event.target;        
        setFormData({...formData, [id]:value});
    };
    
    return (        
      <Container maxWidth="sm" className={classes.paper}>
          <Form className={classes.root} ref={formRef} onSubmit={handleSubmit}>
              <fieldset className={classes.titulo}>
                <h2 className={classes.tituloh2}>Redefinir Senha</h2>
              </fieldset>
              
              <TextField required 
                type="email"
                placeholder="E-mail"
                // onChange={handleInputChange} 
                disabled={true}
                
                className={classes.field} 
                id="email" 
                value={formData.email}
                name="email" 
                label="E-mail" />
              <TextField 
                required 
                onChange={handleInputChange} 
                className={classes.field} 
                value={formData.password_redefinido}
                id="password_redefinido" 
                name="password_redefinido" 
                label="Senha Recebida por E-Mail"
                type="password_redefinido"
                placeholder="Senha Recebida por E-Mail" />
              <TextField 
                  required 
                  onChange={handleInputChange} 
                  className={classes.field} 
                  value={formData.password}
                  inputProps={{ minLength: 5 }}             
                  id="password" 
                  name="password" 
                  label="Nova Senha"
                  type="password"
                  placeholder="Nova Senha" />
                  {/* {value && */}
                <TextField 
                  required 
                  onChange={handleInputChange} 
                  className={classes.field} 
                  value={formData.passwordConfirm}
                  inputProps={{ minLength: 5 }}             
                  id="passwordConfirm" 
                  error={formRef.current?.getFieldError("passwordConfirm")?true:false}
                  helperText={formRef.current?.getFieldError("passwordConfirm")}
                  name="passwordConfirm" 
                  label="Repita a Nova Senha"
                  type="password"                  
                  placeholder="Repita a Nova senha" />  
              <Button
              className={classes.buttons} 
                type="submit"
                  size="small" 
                  variant="contained"
                  color="primary">
                      Redefinir
              </Button>      
              <p>Se você esqueceu sua senha, clique no link abaixo para redefini-la:</p>

              <a href="/recuperar">Esqueci minha senha</a>       
          </Form>
          <ModalConfirm
              title={titleConfirm}
              description="Redefinir Senha do Usuário?"
              noText="Não"
              yesText="Sim"
              confirm={true}
              show={showConfirm}
              setShow={(show1:boolean)=>{
                if (show1) {
                  handleInsert();
                  setShowConfirm(false);
                  // setShowPosConfirm(true);
                } else {
                  setShowConfirm(false);
                }
              }}
            />
            <ModalConfirm
              title={titleConfirm}
              description={msgReturnInsert}
              // noText="Não"
              // yesText="Sim"
              neutralText="OK"
              confirm={false}
              show={showPosConfirm}
              setShow={(show1:boolean)=>{
                // if (show1) {
                  setShowPosConfirm(false);
                // window.location.href = '/usuarios';


              }}
            />
      </Container>
    )
}

export default Login;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
          //width: '25ch',
        },
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    buttons: {
      height:'55px',
      display: 'flex',
      width: '70%',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      alignItems: 'center',
    },
    titulo: {
        borderTop:'none',
        borderRight:'none',
        borderLeft:'none',
        borderBottom: '2px solid #e5e5e6',
        width: '70%',
    },
    tituloh2: {
        textAlign: 'center',
        color: '#444445',
    },
    field:{
        width: '70%',
        height:'75px',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
        backgroundColor: '#Fff',
        border: '1px solid #e5e5e6',
        borderRadius: '8px',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
      
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    
    formControl: {
      margin: theme.spacing(1),
      minWidth: '70%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));