import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import NewIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
import AccountTree from "@material-ui/icons/AccountTree";
import NoteAdd from "@material-ui/icons/NoteAdd";
import Visualisar from "@material-ui/icons/ViewList";
import PhotoIcon from "@material-ui/icons/Photo";

import "bootstrap/dist/css/bootstrap.min.css";
// import NoteAdd from '@material-ui/icons/AddRoad';

// import { FiArrowLeft} from 'react-icons/fi';
// import GridViagemDespesas from '../../component/GridViagemDespesas';

import api, { URL } from "../../services/api";
import labels from "../../component/TableLabels";
import GridItensManutencao from "../../component/GridItensManutencao";
import ModalConfirm from "../../component/ModalConfirm";
import { Button as Button1 } from "react-bootstrap";
//import Form from 'react-bootstrap/Form';
import { Form } from "@unform/web";
import TextField from "@material-ui/core/TextField";

import Modal from "react-bootstrap/Modal";
import ImageEvento from "../../component/Img";
import ModalAgendamento from "../../component/ModalAgendamento";

const baseURL = `${URL}/files/`;

interface Item {
  chaves: string;
  data_fim: string;
  data_inicio: string;
  descricao: string;
  fornecedor: string;
  id_estabelecimento: number;
  id_manutencao: number;
  id_user_config: number;
  id_veiculo: number;
  km: number;
  motivo: string;
  orcamento: number;
  placa: string;
  qtd_parcelas: number;
  status: string;
  valor_total: number;
}

interface ItemManutencao {
  id_estabelecimento: number;
  id_manutencao: number;
  id_user_config: number;
  id_veiculo: number;
  chaves: string;
  descricao: string;
  obs: string;
  qtd: number;
  status: string;
  valor: number;
}

interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
    };
  };
}
interface FimViagem {
  data_chegada: Date;
  km_final: number;
}
/**
"id_manutencao": 3,
"descricao_viagem": "Carga Netto",
"valor": "18400.80",
"nome_motorista": "Samuel",
"placa_veiculo": "AAW-9D43",
"soma": "0",
"desconta": "2400.20",
 "saldo": 9188.5,
    "comissao": 275.655,
    "total": 8912.845
*/

const Viagem: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const buttonEditRef = useRef<HTMLButtonElement>(null);
  const divGrid = useRef<HTMLDivElement>(null);
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [responsive, setResponsive] = useState("simple");
  const [ID, setID] = useState(Number);
  const [pladaAgenda, setPladaAgenda] = useState("");
  // const data_chegada = useState(Date);
  // const km_final     = useState(Number);
  const [filtro, setFiltro] = useState(["Aberto"]);

  const [visible, setVisible] = useState(true);
  const [finalizar, setFinalizar] = useState(true);
  const [lancarDespesas, setLancarDespesas] = useState(true);

  const [itens, setItens] = useState([]);
  const marcado = useRef(0);
  var titleConfirm = "Viagem Finalizada com Sucesso!";
  var descricaoConfirm = "Fazer Acerto?";
  var titleConfirmEdit = "Editar Despesa";
  var descricaoConfirmEdit = "Editar Despesa?";
  const [descontos, setDescontos] = useState([]);
  const listaItens = useRef(Array<Item>());
  const [refresh, doRefresh] = useState(0);
  const [IDDespesa, setIDDespesa] = useState(Number);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const [formData, setFormData] = useState<FimViagem>({
    data_chegada: new Date(),
    km_final: 0,
  });

  useEffect(() => {
    if (
      localStorage.getItem("UserTipo") == "M" &&
      localStorage.getItem("ManutApp") != "S"
    ) {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      handleBuscaDados();
    }
  }, []);

  const handleBuscaDados = async () => {
    // console.log('busca viagensfinalizadas/F');
    await api
      .get(`manutencao`)
      .then((response) => {
        //@ts-ignore
        var manuts = [];
        response.data.map((manut: any) => {
          // console.log("valor_total", Number(manut.valor_total));
          // console.log("soma_itens", Number(manut.soma_itens));

          if (Number(manut.valor_total) < Number(manut.soma_itens)) {
            manut.valor_total = manut.soma_itens;
          }
          manuts.push(manut);
        });
        //@ts-ignore
        setItens(manuts);
        // setItens(response.data);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
      });
  };

  const navigateAcerto = () => {
    let selecionado = itens[ID] as Item;
    navigate(`/viagemacerto/${selecionado.id_manutencao}`);
  };

  const handleClose = async () => {
    setShow(false);
    let selecionado = itens[ID] as Item;
    await api
      .post(`finalizaViagem/${selecionado.id_manutencao}/F`, formData)
      .then(function (response) {
        if (response.data.sucess) {
          if (localStorage.getItem("UserTipo") != "M") {
            setShowConfirm(true);
          } else {
            alert("Viagem Finalizada com Sucesso!");
            navigate("/");
          }
        } else {
          // console.log(response.data);
        }
      });
  };
  const handleCloseCancel = async () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    doRefresh((prev) => prev + 1);
  }, [listaItens.current]);

  useEffect(() => {
    //if (marcado != -1) {
    // console.log('click');

    //}
    if (divGrid.current?.className == "makeStyles-grid70-21") {
      if ((listaItens.current.length = 0)) {
        // console.log('70-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    } else if (divGrid.current?.className == "makeStyles-grid100-22") {
      if (listaItens.current.length > 0) {
        // console.log('100-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    }
  }, [marcado]);

  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return nf.format(value);
  };

  const formataStatus = (value: any, tableMeta: any, updateValue: any) => {
    switch (value) {
      case "A":
        return "Aberto";
      case "G":
        return "Agendado";
      case "R":
        return "Realizado";
      case "F":
        return "Finalizado";
    }
  };
  const columns = [
    {
      name: "id_manutencao",
      label: "ID",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "placa",
      label: "Veículo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        //display: false,
      },
    },
    {
      name: "motivo",
      label: "Motivo",
      options: {
        filter: true,
        sort: false,
        print: false,
      },
    },
    {
      name: "orcamento",
      label: "Orçado",
      options: {
        filter: true,
        sort: true,
        //  customHeadLabelRender:(columnMeta : object) => {return columnMeta?.textLabels},
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "valor_total",
      label: "Valor Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataStatus(value, tableMeta, updateValue);
        },
        filterList: filtro,
      },
    },
    {
      name: "data_inicio",
      label: "Dt. Início",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formatDate(value).toString();
        },
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "data_fim",
      label: "Dt. Fim",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formatDate(value).toString();
        },

        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "km",
      label: "KM",
      options: {
        filter: true,
        sort: true,
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
  ];
  const columnsDespesas = [
    {
      name: "id_manutencao_seq",
      label: "id_manutencao_seq",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "chaves",
      label: "Chaves",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "obs",
      label: "Obs.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "qtd",
      label: "Qtd.",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  var selectRow: number = -1;
  var SelectableRows: SelectableRows = "single";
  var options: MUIDataTableOptions = {
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setDescontos([]);
      let y1 = rowsSelected as number[];
      selectRow = -1;
      marcado.current = 0;
      setID(-1);
      setVisible(true);
      setLancarDespesas(true);
      setFinalizar(true);
      y1.map((numero: number) => {
        let item = itens[numero] as Item;
        console.log(item);
        selectRow = numero;
        setVisible(false);
        setID(numero);
        if (item.status == "A" || item.status == "G") {
          setLancarDespesas(false);
          setFinalizar(false);
        }
      });
      doRefresh((prev) => prev + 1);
    },
    selectableRowsHeader: itens.length > 0,
    selectableRows: SelectableRows,
    textLabels: labels,
    selectableRowsOnClick: true,
    responsive: "standard",
    // responsiveScroll

    rowsPerPage: 15,
    rowsPerPageOptions: [15, 20, 50, 100],
  };
  var SelectableRowsDespesas: SelectableRows = "none";
  var optionsDespesas: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const id = Number(rowData[0]);
      setIDDespesa(id);
      if (rowData[1] != "") {
        setSourceImage(baseURL + rowData[1]);
      } else {
        setSourceImage("");
      }
      // setShowImage(true);
      // console.log('click numero---', rowData);
    },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (evt: any) => {
        // alert("Double click!");
        setShowConfirmEdit(true);
      },
    }),
    selectableRows: SelectableRowsDespesas,
    textLabels: labels,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
  };
  const formatDate = (date: Date) => {
    if (date == null) {
      return "";
    }
    var d = new Date(date);
    d.setHours(d.getHours() + 3); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas
    // console.log(d);
    try {
      let formattedDate = Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(d);

      // console.log(formattedDate);
      return formattedDate;
    } catch (error) {
      return date;
    }
  };

  const handleClickEditDespesa = () => {
    if (IDDespesa >= 0) {
      let selecionado = itens[ID] as Item;
      navigate(`/manutencao/${selecionado.id_manutencao}/despesa/${IDDespesa}`);
    } else {
      alert("Nenhuma Despesa Selecionada!");
    }
  };
  async function handleClickPosicao() {
    setShow(!show);
  }
  const handleClickNewDespesa = () => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      // console.log(selecionado);
      navigate(`/manutencao/${selecionado.id_manutencao}`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };

  const handleNovoAgendamento = () => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      console.log(selecionado.placa);
      setPladaAgenda(selecionado.placa);
      setShow(true);
      // navigate(`/manutencao/${selecionado.id_manutencao}`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  const handleClickNew = () => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      // console.log(selecionado);
      navigate(`/manutencao`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  const handleOnReturn = async () => {
    marcado.current = 0;
    setID(-1);
    listaItens.current = Array<Item>();
    //await alert('Viagem(s) Finalizada(s)!');
    doRefresh(0);
    handleBuscaDados();
  };
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  }
  async function handleBuscaDespesas() {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      // console.log(selecionado);
      // navigate(`/viagem/${selecionado.id_manutencao}/despesa`);
      await api
        .get(`manutencaoitens/${selecionado.id_manutencao}`)
        .then((response) => {
          //descontos
          // console.log('descontos', response.data)
          setDescontos(response.data);
        });
    } else {
      alert("Nenhum Item Selecionado!");
    }
  }
  async function handleSubmitAgenda(data_inicio: string, descricao: string) {
    var dados = {
      [`placa`]: pladaAgenda,
      [`data_inicio`]: data_inicio,
      [`descricao`]: descricao,
      // [`id_veiculo`]:formData.id_veiculo
    };

    await api.post(`manutencao/agenda`, dados).then(function (response) {
      // alert(response.data.result);
      // console.log('retorn',response.data);
      if (response.data.sucess) {
        // setViagemId(response.data.id_manutencao);
        // setShowConfirm(true);
        alert("Manutenção Agendada com Sucesso!");
        // navigate("/manutencoes");
      } else {
        console.log(response.data);
      }
    });
  }
  return (
    <div>
      <div className={classes.root}>
        <div className={classes.buttonsRight}>
          {localStorage.getItem("UserTipo") != "M" && (
            <>
              <Button
                ref={buttonEditRef}
                //disabled = {visible}
                size="small"
                variant="contained"
                color="primary"
                // className={classes.buttonExtra}
                onClick={(event) => handleClickNew()}
                startIcon={<NoteAdd />}
              >
                Nova Manutenção
              </Button>
              <Button
                ref={buttonEditRef}
                disabled={visible}
                size="small"
                variant="contained"
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={(event) => handleNovoAgendamento()}
                startIcon={<NoteAdd />}
              >
                Novo Agendamento
              </Button>
            </>
          )}
        </div>
        <div className={classes.buttons}>
          <Button
            ref={buttonEditRef}
            disabled={lancarDespesas}
            size="small"
            variant="contained"
            color="secondary"
            // className={classes.buttonExtra}
            onClick={(event) => handleClickNewDespesa()}
            startIcon={<NoteAdd />}
          >
            Lançar Itens
          </Button>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="primary"
            style={{ marginLeft: 10 }}
            onClick={(event) => handleBuscaDespesas()}
            startIcon={<Visualisar />}
          >
            Ver Itens Lançados
          </Button>
          <Button
            ref={buttonEditRef}
            disabled={finalizar}
            size="small"
            variant="contained"
            color="secondary"
            className={classes.buttonAcerto}
            onClick={(event) => handleClickPosicao()}
            startIcon={<AccountTree />}
          >
            Finalizar
          </Button>
        </div>
      </div>
      <div className={classes.grid}>
        <div
          className={
            listaItens.current.length > 0 ? classes.grid70 : classes.grid100
          }
          ref={divGrid}
        >
          <MUIDataTable
            title={`Manutenções`}
            data={itens}
            columns={columns}
            options={options}
          />
        </div>
        {/* <GridItensManutencao array={listaItens.current} refresh={refresh} onReturn={handleOnReturn}/> */}
      </div>
      {descontos.length > 0 && (
        <div className={classes.tabelas}>
          <div className={classes.tabela} style={{ width: "100%" }}>
            <MUIDataTable
              title={`Itens`}
              data={descontos}
              columns={columnsDespesas}
              options={optionsDespesas}
            />
          </div>
        </div>
      )}
      <ModalAgendamento
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={show}
        placaInformada={String(pladaAgenda)}
        setShow={(show1: boolean, data_inicio: string, descricao: string) => {
          // alert(show1);
          if (show1) {
            setShow(false);
            handleSubmitAgenda(data_inicio, descricao);
            // navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShow(false);
            // navigate(`/lancamentos`);
          }
        }}
      />
      {/* <Modal style={{marginTop:100}} show={show} onHide={handleCloseCancel}>
          <Form //className={classes.dadosViavem} ref={formRef} 
              onSubmit={handleClose}>
                <Modal.Header closeButton>
            <Modal.Title>Finalizar Viagem</Modal.Title>
          </Modal.Header>
          <Modal.Body>

                <div className="mb-3">
                    <span style={{marginTop: 10, marginLeft: 20}} className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required">Data da Chegada</span>
                  <TextField 
                      required 
                      type="date" 
                      value={formData.data_chegada} 
                      onChange={handleInputChange} 
                      // className="mb-3"//
                      className={classes.field} 
                      id="data_chegada" 
                      name="data_chegada" 
                      // label="Data da Chegada" 
                      />
                </div>
              <div className="mb-3">
                <TextField 
                      required 
                      type="number" 
                      value={formData.km_final} 
                      onChange={handleInputChange} 
                      className={classes.field}
                      id="km_final" 
                      name="km_final" 
                      label="KM Final" 
                      />
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button1 variant="secondary" onClick={handleCloseCancel}>
              Fechar
            </Button1>
            <Button1 variant="primary" 
                          type="submit"
                          onClick={handleClose}
                          >
              Finalizar
            </Button1>
          </Modal.Footer>
          </Form>
      </Modal> */}
      <ModalConfirm
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirm(false);
          }
        }}
      />
      <ModalConfirm
        title={titleConfirmEdit}
        description={descricaoConfirmEdit}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirmEdit}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            // navigateAcerto();
            handleClickEditDespesa();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirmEdit(false);
          }
        }}
      />
      <div>
        <ImageEvento
          show={showImage}
          source={sourceImage}
          onReturn={() => {
            setShowImage(false);
            setSourceImage("");
          }}
        />
      </div>
    </div>
  );
};
export default Viagem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: 'flex-end',
      alignItems: "flex-start",
      justifyContent: "space-between",
      margin: theme.spacing(1),
    },
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    grid: {
      display: "flex",
      flexDirection: "row",
    },
    grid70: {
      width: "65%",
    },
    grid100: {
      width: "100%",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonAcerto: {
      marginLeft: theme.spacing(1),
      //  alignItems: 'flex-start',
      backgroundColor: "green",
    },
    buttonsRight: {
      flexDirection: "row",

      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    buttons: {
      flexDirection: "row",

      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonHide: {
      display: "none",
    },
    resumo: {
      width: "100%",
      //display: 'flex',
      flexDirection: "column",
      backgroundColor: "#Fff",
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    // tss-1akey0g-MUIDataTableHeadCell-data :{}
  })
);
