import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { Autocomplete } from '@material-ui/lab';
// import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
// import api from '../services/api';
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import { InputLabel } from "@mui/material";

import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root1: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    prods: {
      display: "flex",
      flex: 1,
      //justifyContent: 'flex-end',
      //alignItems: 'left',
      width: "100%",
    },
    removeLinha: {
      // width: '20px',
      fontSize: "12px",
      color: "#FF0000",
      // marginLeft: '3px',
    },
  })
);

interface HeaderProps {
  pos: number;
  handleAdicionaEvento(evento: any): void;
  form: {};
  handleInputChangeEvento(
    event: SelectChangeEvent<HTMLInputElement>,
    linha: number
  ): void;
  eventos: AutocompleteOption[];
  count: number;
}
interface AutocompleteOption {
  label: string;
  id: string;
  id_user?: number;
  nome?: string;
}

const DespesaResumo: React.FC<HeaderProps> = (props) => {
  const classes = useStyles();
  const qtdRef = useRef<HTMLInputElement>(null);

  const [erroEvento, setErroEvento] = useState(false);
  const [erroDescricao, setErroDescricao] = useState(false);
  const [erroLocal, setErroLocal] = useState(false);
  const [erroValor, setErroValor] = useState(false);

  const [habitidadoEdicao, setHabitidadoEdicao] = useState(false);
  // const [eventos, setEventos] = useState<AutocompleteOption[]>([]);
  // const [inputValue, setInputValue] = React.useState('');
  // const [posicaoValue, setPosicaoValue] = React.useState('');
  // const [value, setValue] = useState(Number);
  const [eventoAtual, setEventoAtual] = useState({
    id: -1,
    descricao: "",
    local: "",
    valor: 0,
    position: -1,
    litros: 0,
  });

  useEffect(() => {
    // console.log('posicao', props.pos);
    // console.log('props.count', props.count);

    const position = "position";
    setEventoAtual({ ...eventoAtual, [position]: props.pos });

    // setEventos(props.eventos);
  }, []);
  const TipoValor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formataValor = (value: any) => {
    try {
      var value1 = value.replace(/\D/g, "");
      value1 = value1.replace(/^0+/, "");
      value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
      value1 = "R$ " + value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
      return value1;
    } catch (error) {}
    return TipoValor.format(value);
  };

  function handleInputChangeSelect(event: SelectChangeEvent<HTMLInputElement>) {
    // console.log('linha', linha);
    // console.log(event.);
    // console.log(event.target.name);
    setEventoAtual({ ...eventoAtual, [event.target.name]: event.target.value });
    props.handleInputChangeEvento(event, eventoAtual.position);
  }

  function handleAdicionaEvento() {
    setErroEvento(false);
    setErroDescricao(false);
    setErroLocal(false);
    setErroValor(false);
    if (eventoAtual.id == -1) {
      setErroEvento(true);
    } else if (eventoAtual.descricao == "") {
      setErroDescricao(true);
    } else if (eventoAtual.local == "") {
      setErroLocal(true);
    } else if (eventoAtual.valor == 0) {
      setErroValor(true);
    } else {
      props.handleAdicionaEvento(eventoAtual);
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    var value1 = null;
    // if (id == 'valor') {
    //     console.log('mudou valor', value);//setMsgSucesso(false);
    //     value1 = value.replace(/\D/g, "");
    //     value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
    //     value1 = 'R$ '+value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
    //     console.log('mudou valor', value1);//setMsgSucesso(false);
    //     // if (value.length < 1) {
    //     //   value1 = 'R$ 0,00';
    //     // }
    // } else {
    value1 = value;
    // }
    setEventoAtual({ ...eventoAtual, [id]: value1 });
    props.handleInputChangeEvento(event, eventoAtual.position);
  }
  return (
    <ListItem
      style={{ padding: "0", marginBottom: "8px" }}
      className={classes.prods} //id={`linha_${props.pos}`} >
    >
      <div
        style={{
          flexDirection: "row",
          padding: "5px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormControl variant="standard" sx={{ width: "25%" }}>
          <InputLabel
            id="demo-simple-select-standard-label"
            style={{ marginLeft: "5px" }}
          >
            Tipo da Despesa
          </InputLabel>
          <Select
            error={erroEvento}
            labelId="demo-simple-select-label"
            required
            id="id"
            name="id"
            // className={classes.select}
            // value={String(eventoAtual.id)}
            label="Tipo da Despesa"
            // placeholder="Tipo da Despesa"
            // style={{color:'red'}}
            style={{
              //width: '25%',
              fontSize: "15px",
              marginLeft: "5px",
              marginRight: "5px",
              // color:'black',
            }}
            // required
            onChange={(event: SelectChangeEvent<HTMLInputElement>) => {
              // handleInputChangeSelect(event, props.pos);
              handleInputChangeSelect(event);
            }}
          >
            {props.eventos.map((text, index) => (
              <MenuItem key={index} value={text.id}>
                {text.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          required
          disabled={habitidadoEdicao}
          value={eventoAtual.descricao}
          onChange={handleInputChange}
          //className={classes.field}
          // helperText="Descrição Inválida"
          error={erroDescricao}
          id="descricao"
          style={{
            width: "25%",
            fontSize: "22px",
            paddingTop: "7px",
            paddingBottom: "7px",
            marginLeft: "5px",
            marginRight: "5px",
            color: "black",
          }}
          name="descricao"
          label="Descrição"
        />
        <TextField
          required
          disabled={habitidadoEdicao}
          value={eventoAtual.local}
          onChange={handleInputChange}
          // className={classes.field}
          // helperText="Local Inválido"
          error={erroLocal}
          id="local"
          style={{
            width: "25%",
            fontSize: "22px",
            paddingTop: "7px",
            paddingBottom: "7px",
            marginLeft: "5px",
            marginRight: "5px",
            color: "black",
          }}
          name="local"
          label="Local"
        />
        <TextField
          required
          disabled={habitidadoEdicao}
          value={formataValor(eventoAtual.valor)}
          onChange={handleInputChange}
          // helperText="Valor Inválido"
          error={erroValor}
          style={{
            width: "15%",
            fontSize: "22px",
            paddingTop: "7px",
            paddingBottom: "7px",
            marginLeft: "5px",
            marginRight: "5px",
            color: "black",
          }}
          label="Valor"
          id="valor"
          name="valor"
        />
        <TextField
          type={"number"}
          required
          disabled={habitidadoEdicao}
          value={eventoAtual.litros}
          onChange={handleInputChange}
          // helperText="Valor Inválido"
          //error={erroValor}

          style={{
            width: "10%",
            fontSize: "22px",
            paddingTop: "7px",
            paddingBottom: "7px",
            marginLeft: "5px",
            marginRight: "5px",
            color: "black",
          }}
          label="Litros"
          id="litros"
          name="litros"
        />
        {props.count == props.pos && (
          <Button
            type="button"
            // id={`${props.pos}-qtdqff`}
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleAdicionaEvento}
            // className={classes.removeLinha}
          >
            <span
              id={`qtddqff`}
              style={{
                width: "100%",
                paddingLeft: "5px",
                paddingRight: "10px",
                minWidth: "90px",
              }}
            >
              Adicionar
            </span>
          </Button>
        )}
        {props.count != props.pos && (
          <Button
            type="button"
            // id={`${props.pos}-qtdqff`}
            variant="outlined"
            size="small"
            color="secondary"
            // onClick={props.onRemoveLinha}
            className={classes.removeLinha}
          >
            <span
              id={`qtddqff`}
              style={{
                width: "100%",
                paddingLeft: "5px",
                paddingRight: "10px",
                minWidth: "90px",
              }}
            >
              Remover
            </span>
          </Button>
        )}
      </div>
    </ListItem>
  );
};

export default DespesaResumo;
