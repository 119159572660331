import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FormHandles } from "@unform/core";
import Button from "@material-ui/core/Button";
import NoteAdd from "@material-ui/icons/NoteAdd";
import PhotoIcon from "@material-ui/icons/Photo";
import Container from "@material-ui/core/Container";
import { Autocomplete } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import "bootstrap/dist/css/bootstrap.min.css";
import api, { URL } from "../../services/api";
import labels from "../../component/TableLabels";
import GridViagmDespesas from "../../component/GridViagemDespesas";
import ModalConfirm from "../../component/ModalConfirm";
import { Form } from "@unform/web";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@material-ui/core/TextField";

// import Modal from 'react-bootstrap/Modal';
import ImageEvento from "../../component/Img";

const baseURL = `${URL}/files/`;

interface Item {
  id_viagem: number;
  cd_viagem: number;
  comissao: number;
  comissao_viagem: number;
  desconta: number;
  descricao_viagem: string;
  destino_viagem: string;
  peso: number;
  id_estabelecimento: number;
  id_motorista: number;
  id_veiculo_1: number;
  id_veiculo_2: number;
  km_medio_viagem: number;
  mercadoria_viagem: string;
  nome_motorista: string;
  obs_viagem: string;
  origem_viagem: string;
  placa_veiculo: string;
  saldo: number;
  soma: number;
  st_ativo: string;
  total: number;
  valor: number;
}
interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
    };
  };
}
interface FimViagem {
  data_chegada: Date;
  km_final: number;
}
interface AutocompleteOptionPlaca {
  label: string;
  id: string;
  id_user?: number;
  nome?: string;
}
interface AutocompleteOptionMotorista {
  label: string;
  id: string;
  id_user?: number;
  nome?: string;
}
interface AutocompleteOptionStatus {
  label: string;
  id: string;
}

const Viagem: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const buttonEditRef = useRef<HTMLButtonElement>(null);
  const divGrid = useRef<HTMLDivElement>(null);
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");

  const [ID, setID] = useState(Number);
  // const data_chegada = useState(Date);
  // const km_final     = useState(Number);
  const [isLoading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);

  const [itens, setItens] = useState([]);
  const marcado = useRef(0);
  var titleConfirm = "Viagem Finalizada com Sucesso!";
  var descricaoConfirm = "Fazer Acerto?";
  var titleConfirmEdit = "Editar Despesa";
  var descricaoConfirmEdit = "Editar Despesa?";
  const [descontos, setDescontos] = useState([]);
  const listaItens = useRef(Array<Item>());
  const [refresh, doRefresh] = useState(0);
  const [IDDespesa, setIDDespesa] = useState(Number);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const [valueStatus, setValueStatus] = useState<AutocompleteOptionStatus[]>([
    { id: "T", label: "Todos" },
  ]);
  const [statusLista, setStatusLista] = useState<AutocompleteOptionMotorista[]>(
    [
      { id: "T", label: "Todos" },
      { id: "A", label: "Em Andamento" },
      { id: "F", label: "Finalizada" },
      { id: "C", label: "Concluída" },
    ]
  );
  const [valueMotorista, setValueMotorista] =
    useState<AutocompleteOptionMotorista>();
  const [motoristas, setMotoristas] = useState<AutocompleteOptionMotorista[]>(
    []
  );
  const [placas, setPlacas] = useState<AutocompleteOptionPlaca[]>([]);
  const [placas2, setPlacas2] = useState<AutocompleteOptionPlaca[]>([]);
  const [value, setValue] = useState<AutocompleteOptionPlaca>();
  const [value2, setValue2] = useState<AutocompleteOptionPlaca>();
  const [inputValue, setInputValue] = React.useState("");
  const [inputValue2, setInputValue2] = React.useState("");
  const [inputValueMotorista, setInputValueMotorista] = React.useState("");
  const [inputValueStatus, setInputValueStatus] = React.useState("");
  // const target = {} as NavigateOptions;

  var data30 = new Date();
  data30.setDate(data30.getDate() - 60);

  const [formData, setFormData] = useState({
    id_estabelecimento: 0,
    id_viagem: "",
    codigo: "",
    mercadoria: "",
    descricao: "",
    origem: "",
    destino: "",
    valor: 0,
    comissao: 0,
    km_inicio: "",
    km_fim: "",
    km_total: "",
    lt_viagem: 0,
    obs_viagem: "",
    nome_motorista: "",
    placa: "",
    placa2: "",
    status: "T",
    soma: 0,
    desconta: 0,
    saldo: 0,
    comissao_vl: 0,
    total: 0,
    data_saida: null,
    id_motorista: 0,
    data_chegada: null,
    grupStatus: "",
  });
  const formRef = useRef<FormHandles>(null);

  const navigateAcerto = () => {
    let selecionado = itens[ID] as Item;
    navigate(`/viagemacerto/${selecionado.id_viagem}`);
  };

  const handleClose = async () => {
    setShow(false);
    let selecionado = itens[ID] as Item;
    await api
      .post(`finalizaViagem/${selecionado.id_viagem}/F`, formData)
      .then(function (response) {
        if (response.data.sucess) {
          setShowConfirm(true);
        } else {
          // console.log(response.data);
        }
      });
  };
  const handleCloseCancel = async () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    // var idPar:number = Number(props.match?.params.id);
    // console.log('id', idPar)
    // setID(idPar);
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      handleBuscaDadosPlaca(0);
    }
  }, []);

  async function handleBuscaDadosPlaca(idPar: Number) {
    await api
      .get(`veiculos/C`)
      .then((response) => {
        // console.log('retorno',response.data);
        let dados: Array<AutocompleteOptionPlaca> = [];
        var arrayDados = response.data as Array<AutocompleteOptionPlaca>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx: any) => {
          // console.log('item222',itemx);
          if (itemx.status == "Ativo") {
            const placa1 = {
              label: itemx.placa,
              id: itemx.id_veiculo,
              id_user: itemx.id_usr,
              nome: itemx.nome,
            };
            dados.push(placa1);
          }
        });
        setPlacas(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
        handleBuscaPlaca2();
      });
  }
  async function handleBuscaPlaca2() {
    await api
      .get(`veiculos/R`)
      .then((response) => {
        // console.log('retorno',response.data);
        let dados: Array<AutocompleteOptionPlaca> = [];
        var arrayDados = response.data as Array<AutocompleteOptionPlaca>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx: any) => {
          const placa1 = {
            label: itemx.placa,
            id: itemx.id_veiculo,
            id_user: itemx.id_usr,
            nome: itemx.nome,
          };
          dados.push(placa1);
        });
        setPlacas2(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        handleBuscaMotoristas();
      });
  }
  async function handleBuscaMotoristas() {
    await api.get(`userconfig/M`).then((response) => {
      let dados: Array<AutocompleteOptionMotorista> = [];
      var arrayDados = response.data;
      arrayDados.users.map((itemx: any) => {
        const user1 = { label: itemx.nome, id: itemx.id_user_config };
        dados.push(user1);
      });
      setMotoristas(dados);
    });
  }

  useEffect(() => {
    doRefresh((prev) => prev + 1);
  }, [listaItens.current]);

  useEffect(() => {
    if (divGrid.current?.className == "makeStyles-grid70-21") {
      if ((listaItens.current.length = 0)) {
        // console.log('70-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    } else if (divGrid.current?.className == "makeStyles-grid100-22") {
      if (listaItens.current.length > 0) {
        // console.log('100-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    }
  }, [marcado]);

  const handleBuscaDados = async () => {
    await api
      .post(`relatorio`, formData)
      .then((response) => {
        // console.log('retorno',response.data)
        setItens(response.data);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
        setLoading(false);
      });
  };
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    // console.log(tableMeta);
    // console.log(updateValue);
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return nf.format(value);
  };
  const columns = [
    {
      name: "id_viagem",
      label: "ID",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "placa",
      label: "Veículo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nome",
      label: "Motorista",
      options: {
        filter: true,
        sort: true,
        print: false,
      },
    },
    {
      name: "mercadoria",
      label: "Mercadoria",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "destino",
      label: "Destino",
      options: {
        filter: true,
        sort: true,
        //display: false,
      },
    },
    {
      name: "data_saida",
      label: "Saída",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formatDate(value).toString();
        },
        //display: false,
      },
    },
    {
      name: "data_chegada",
      label: "Chegada",
      options: {
        filter: true,
        sort: true,
        //display: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formatDate(value).toString();
        },
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formatDate(value)}
      },
    },
    {
      name: "status",
      label: "Situação",
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "peso",
      label: "Peso",
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: "valor",
      label: "Frete",
      options: {
        filter: true,
        sort: true,
        //  customHeadLabelRender:(columnMeta : object) => {return columnMeta?.textLabels},
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "comissao_vl",
      label: "Comissão",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "km_total",
      label: "KM Total",
      options: {
        filter: true,
        sort: true,
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "km_medio",
      label: "KM/L",
      options: {
        filter: true,
        sort: true,
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "soma",
      label: "Adi. (+)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "desconta",
      label: "Des. (-)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "saldo",
      label: "Saldo",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "total",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  const columnsDespesas = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "litros",
      label: "Litros",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "media",
      label: "Média",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "foto",
      label: "Foto",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShowImage(true)}
            >
              {" "}
              <PhotoIcon />
            </div>
          );
        },
      },
    },
  ];
  var selectRow: number = -1;
  var SelectableRows: SelectableRows = "none";
  const CustomToolbarLoading = ({}) => {
    return (
      <>
        {isLoading && (
          <CircularProgress sx={{ display: "flex" }} color="inherit" />
        )}
      </>
    );
  };
  var options: MUIDataTableOptions = {
    customToolbar: CustomToolbarLoading,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setDescontos([]);
      let y1 = rowsSelected as number[];
      selectRow = -1;
      marcado.current = 0;
      setID(-1);
      setVisible(true);
      y1.map((numero: number) => {
        let item = itens[numero] as Item;
        selectRow = numero;
        setVisible(false);
        setID(numero);
      });
      doRefresh((prev) => prev + 1);
    },
    onRowClick: (rowData) => {
      const id = Number(rowData[0]);
      setIDDespesa(id);
    },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (evt: any) => {
        // console.log(row);
        // alert("Double click!");
        // setShowConfirmEdit(true);
        let selecionado = itens[ID] as Item;
        // window.open(`/viagemacerto/${selecionado.id_viagem}/despesa/${IDDespesa}`);
        window.open(`/viagemacerto/${row[0]}`, "_blank");
        // navigate(`/viagemacerto/7`, {});
      },
    }),
    selectableRowsHeader: itens.length > 0,
    selectableRows: SelectableRows,
    textLabels: labels,
    selectableRowsOnClick: true,
    responsive: "standard",
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 20, 50, 100],
    print: false,
    downloadOptions: {
      filename: "Relatório.csv",
      separator: ",",
    },
  };
  var SelectableRowsDespesas: SelectableRows = "none";
  var optionsDespesas: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const id = Number(rowData[0]);
      setIDDespesa(id);
      if (rowData[1] != "") {
        setSourceImage(baseURL + rowData[1]);
      } else {
        setSourceImage("");
      }
      // setShowImage(true);
      // console.log('click numero---', rowData);
    },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (evt: any) => {
        // alert("Double click!");
        setShowConfirmEdit(true);
      },
    }),
    selectableRows: SelectableRowsDespesas,
    textLabels: labels,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "simple",
    downloadOptions: {
      filename: "relatório.csv",
      separator: ",",
    },
  };
  //  var downloadOptions: MUIDataTable
  const handleClickEditDespesa = () => {
    if (IDDespesa >= 0) {
      let selecionado = itens[ID] as Item;
      navigate(`/viagem/${selecionado.id_viagem}/despesa/${IDDespesa}`);
    } else {
      alert("Nenhuma Despesa Selecionada!");
    }
  };
  const handleClickNewViagem = () => {
    navigate(`/viagem`);
  };
  const handleOnReturn = async () => {
    marcado.current = 0;
    setID(-1);
    listaItens.current = Array<Item>();
    //await alert('Viagem(s) Finalizada(s)!');
    doRefresh(0);
    // handleBuscaDados();
  };
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  }
  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setFormData({ ...formData, ["status"]: value });
  };
  async function handleSubmit(event: FormEvent) {
    formData.placa = String(value?.label) || "";
    formData.placa2 = String(value2?.label) || "";
    formData.status = String(valueStatus[0]?.id) || "T";
    formData.id_motorista = Number(valueMotorista?.id) || 0;
    var grupStatus = "";

    const linhas = valueStatus.length;
    // console.log(grupStatus);
    if (linhas == 0) {
      grupStatus = `('T')`;
    } else {
      if (linhas == 1) {
        grupStatus = `('${valueStatus[0].id}')`;
      } else {
        // console.log(linhas);
        var linhaAtual = 0;
        grupStatus = "(";
        while (linhaAtual < linhas) {
          grupStatus += `'${valueStatus[linhaAtual].id}'`;
          linhaAtual = linhaAtual + 1;
          if (linhaAtual < linhas) {
            grupStatus += `,`;
          }
        }
        grupStatus += ")";
      }
    }
    formData.grupStatus = grupStatus;
    // console.log(grupStatus);
    setLoading(true);
    handleBuscaDados();
  }
  const formatDate = (date: Date) => {
    var d = new Date(date);
    d.setHours(d.getHours() + 3); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas
    // console.log(d);
    try {
      let formattedDate = Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(d);

      // console.log(formattedDate);
      return formattedDate;
    } catch (error) {
      return date;
    }
  };
  return (
    <div>
      <div className={classes.root}>
        <Button
          ref={buttonEditRef}
          size="small"
          variant="contained"
          color="primary"
          onClick={(event) => handleClickNewViagem()}
          startIcon={<NoteAdd />}
        >
          Nova Viagem
        </Button>
        <div className={classes.buttons} />
      </div>
      <div className={classes.grid}>
        <Container className={classes.formdados}>
          <Form
            className={classes.dadosViavem}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <fieldset className={classes.titulo}>
              <h2 className={classes.tituloh2}>Relatórios de Viagens</h2>
            </fieldset>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                style={{ width: "15%" }}
                type="number"
                value={formData.codigo}
                onChange={handleInputChange}
                className={classes.field}
                id="codigo"
                name="codigo"
                label="Código"
              />
              <TextField
                value={formData.descricao}
                onChange={handleInputChange}
                className={classes.field}
                id="descricao"
                name="descricao"
                label="Descrição"
              />
              <TextField
                value={formData.mercadoria}
                onChange={handleInputChange}
                className={classes.field}
                id="mercadoria"
                name="mercadoria"
                label="Mercadoria"
              />
              <TextField
                value={formData.origem}
                onChange={handleInputChange}
                className={classes.field}
                id="origem"
                name="origem"
                label="Origem"
              />
              <TextField
                value={formData.destino}
                onChange={handleInputChange}
                className={classes.field}
                id="destino"
                name="destino"
                label="Destino"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "row",
                justifyContent: "flex",
              }}
            >
              <Autocomplete
                multiple
                // limitTags={2}
                id="multiple-limit-tags"
                options={statusLista}
                getOptionLabel={(option) => option.label}
                defaultValue={[statusLista[0]]}
                renderInput={(params) => (
                  <TextField {...params} label="Status" placeholder="Status" />
                )}
                className={classes.field}
                onChange={(event, newValue) => {
                  setValueStatus(newValue as AutocompleteOptionStatus[]);
                }}
              />
              <Autocomplete
                value={valueMotorista}
                onChange={(event, newValue) => {
                  setValueMotorista(newValue as AutocompleteOptionMotorista);
                }}
                inputValue={inputValueMotorista}
                onInputChange={(event, newInputValue) => {
                  setInputValueMotorista(newInputValue);
                }}
                id="motorista"
                options={motoristas}
                disabled={false}
                className={classes.field}
                renderInput={(params) => (
                  <TextField {...params} label="Motorista" />
                )}
              />
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue as AutocompleteOptionPlaca);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="placa"
                options={placas}
                disabled={false}
                className={classes.field}
                renderInput={(params) => (
                  <TextField {...params} label="Placa" />
                )}
              />
              <Autocomplete
                value={value2}
                onChange={(event, newValue) => {
                  setValue2(newValue as AutocompleteOptionPlaca);
                }}
                inputValue={inputValue2}
                onInputChange={(event, newInputValue) => {
                  setInputValue2(newInputValue);
                }}
                id="placa2"
                options={placas2}
                disabled={false}
                className={classes.field}
                renderInput={(params) => (
                  <TextField {...params} label="Placa 2" />
                )}
              />
              <TextField
                type={"date"}
                value={formData.data_saida}
                onChange={handleInputChange}
                className={classes.field}
                id="data_saida"
                name="data_saida"
              />
              <TextField
                type={"date"}
                value={formData.data_chegada}
                onChange={handleInputChange}
                className={classes.field}
                id="data_chegada"
                name="data_chegada"
              />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                style={{ width: "35%" }}
              >
                Buscar
              </Button>
            </div>
          </Form>
        </Container>
      </div>
      <div className={classes.grid} style={{ marginTop: "10px" }}>
        <div
          className={
            listaItens.current.length > 0 ? classes.grid70 : classes.grid100
          }
          ref={divGrid}
        >
          <MUIDataTable
            title={`Relatório`}
            data={itens}
            columns={columns}
            options={options}
          />
        </div>
        <GridViagmDespesas
          array={listaItens.current}
          refresh={refresh}
          onReturn={handleOnReturn}
        />
      </div>
      {descontos.length > 0 && (
        <div className={classes.tabelas}>
          <div className={classes.tabela} style={{ width: "100%" }}>
            <MUIDataTable
              title={`Viagens`}
              data={descontos}
              columns={columnsDespesas}
              options={optionsDespesas}
            />
          </div>
        </div>
      )}

      <ModalConfirm
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirm(false);
          }
        }}
      />
      <ModalConfirm
        title={titleConfirmEdit}
        description={descricaoConfirmEdit}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirmEdit}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            // navigateAcerto();
            handleClickEditDespesa();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirmEdit(false);
          }
        }}
      />
      <div>
        <ImageEvento
          show={showImage}
          source={sourceImage}
          onReturn={() => {
            setShowImage(false);
            setSourceImage("");
          }}
        />
      </div>
    </div>
  );
};
export default Viagem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: 'flex-end',
      alignItems: "flex-start",
      justifyContent: "space-between",
      margin: theme.spacing(1),
    },
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    grid: {
      display: "flex",
      flexDirection: "row",
    },
    grid70: {
      width: "65%",
    },
    grid100: {
      width: "100%",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonAcerto: {
      marginLeft: theme.spacing(1),
      //  alignItems: 'flex-start',
      backgroundColor: "green",
    },
    buttons: {
      flexDirection: "row",

      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonHide: {
      display: "none",
    },
    resumo: {
      width: "100%",
      //display: 'flex',
      flexDirection: "column",
      backgroundColor: "#Fff",
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    formdados: {
      width: "100%",
      maxWidth: "10280px",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      paddingBottom: "8px",
      //display: 'flex',
      flexDirection: "row",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
    },
    titulo: {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderBottom: "2px solid rgb(0 0 0 / 20%)",
      width: "100%",
      padding: "20px",
      marginBottom: "5px",
    },
    tituloh2: {
      textAlign: "center",
      color: "#444445",
    },
    dadosViavem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
    },
    // tss-1akey0g-MUIDataTableHeadCell-data :{}
  })
);
