import React, { useEffect, useRef, ChangeEvent, useState, FormEvent } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Link,useNavigate, useParams } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FiArrowLeft} from 'react-icons/fi';
import api from '../../services/api';


interface FormData {
    name: string;
    email: string;
    //nome,fone,email
  };
  interface RouterProps {
    history?: {};
    location?: {};
    match?: {params: {
        id: string}};    
  };
 

const NovoCadastro: React.FC<RouterProps> = (props:RouterProps) => {
    let navigate   = useNavigate();
    const classes = useStyles();
    const params = useParams();

    var idSelect: number = -1;
    var [ID, setID] = useState(Number);
    const [formData, setFormData] = useState({
        id_estabelecimento: '',
        codigo: '',
        nome: '',
        fone: '',
        email: '',
        qtd_veiculos: 1,
        usa_frete: 'S'
    });

    const formRef = useRef<FormHandles>(null);

    useEffect(() => {
      if (localStorage.getItem('UserTipo') != 'S') {
        alert('Opção Inválida!');
        navigate('/');
      } else {
        setID(-1);
        // var id:number = Number(props.match?.params.id);
        var id:number = Number(params.id);

        // alert(id);

        if (id > 0) {
            idSelect = id;

            api.get(`estabelecimento/${idSelect}`).then(response => {
                // alert(response.data);

                setFormData(response.data);
            });
            setID(idSelect);
        }
      }
      }, []);    

    async function handleSubmit(event: FormEvent) {
        if (ID == -1 ) {
            await api.post('estabelecimento', formData).
            then(function (response) {
              // alert(response.data.result);

              if (response.data.sucess) {
                alert('Cadastrado com Sucesso!');
                navigate("/estabelecimento");
              } else {
                alert(response.data.result);
              }
          })
        } else {
            await api.put(`estabelecimento/${ID}`, formData);
            alert('Editado com Sucesso!');
            navigate("/estabelecimento");

        };
    };
    function handleInputChange(event: ChangeEvent<HTMLInputElement>){
        const {id, value} = event.target;
        setFormData({...formData, [id]:value});
    };
    const handleBack = ( ) => {
        navigate(`/estabelecimento`);
      };
      const handleChange = (event: SelectChangeEvent) => {
        const {value} = event.target;        
  
        setFormData({...formData, ['usa_frete']:value});
  
        // setAge(event.target.value as string);
      };
    return (
        
        <Container className={classes.paper}>
            <Button
            size="small" 
            variant="contained"
            color="primary"
            className={classes.buttonVoltar}
            onClick={(event) => handleBack()}
            startIcon={<FiArrowLeft />}
          >Voltar
          </Button>
            <Form className={classes.root} ref={formRef} onSubmit={handleSubmit}>
                <fieldset className={classes.titulo}>
                    <h2 className={classes.tituloh2}>Cadastro de Estabelecimento</h2>
                </fieldset>
                <TextField value={formData.id_estabelecimento} onChange={handleInputChange} className={classes.fieldID} id="id_estabelecimento" name="id_estabelecimento" label="ID" />
                <TextField required type='number' value={formData.codigo} onChange={handleInputChange} className={classes.field} id="codigo" name="codigo" label="Código" />
                <TextField required value={formData.nome} onChange={handleInputChange} className={classes.field} id="nome" name="nome" label="Nome" />
                <TextField required type="telephone" value={formData.fone} onChange={handleInputChange} className={classes.field} id="fone" name="fone" label="Telefone" />
                <TextField required type="email" value={formData.email} onChange={handleInputChange} className={classes.field} id="email" name="email" label="E-mail" />
                <TextField required type='number' value={formData.qtd_veiculos} onChange={handleInputChange} className={classes.field} id="qtd_veiculos" name="qtd_veiculos" label="Quantidade Máx. de Veículos" />
                <InputLabel required style={{alignItems:'left'}} id="demo-simple-select-label">Usa Valor de Frete?</InputLabel>

            
                    <Select
                      // labelId="tipo"
                      labelId="demo-simple-select-label"
                      required 
                      id="usa_frete"
                      className={classes.field} 
                      value={formData.usa_frete}
                      // label="Tipo de Usuário"
                      label=""
                      placeholder="Tipo de Usuário"
                      // style={{color:'red'}}
                      // required
                      onChange={handleChange}
                    >
                      <MenuItem value={'S'}>Sim</MenuItem>
                      <MenuItem value={'N'}>Não</MenuItem>
                    </Select>
                <Button
                type="submit"
                    size="small" 
                    variant="contained"
                    color="primary">
                        Salvar
                </Button>
                
            </Form>
        </Container>
    )
}

export default NovoCadastro;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
          //width: '25ch',
        },
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    buttonVoltar: {
        //color: 'black',
        margin: theme.spacing(1),
        backgroundColor:'gray'
      },
    titulo: {
        borderTop:'none',
        borderRight:'none',
        borderLeft:'none',
        borderBottom: '2px solid #e5e5e6',
        width: '100%',
    },
    tituloh2: {
        textAlign: 'center',
        color: '#444445',
    },
    field:{
      width: '90%',
      fontSize: '22px',
      paddingTop: '13px',
      paddingBottom: '13px',
    },
    fieldID:{
        display: 'none',
    },
    paper: {
        width: '80%',
        backgroundColor: '#Fff',
        border: '1px solid #e5e5e6',
        borderRadius: '8px',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
      
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }));