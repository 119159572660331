import React, { useEffect, useState, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { useNavigate, useParams } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import NewIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { FiArrowLeft } from "react-icons/fi";
import { getEstab } from "../../../services/auth";

import api from "../../../services/api";
import labels from "../../../component/TableLabels";

interface Item {
  descricao: number;
  id_localizacao: number;
  id_evento: number;
  id_estabelecimento: number;
  tipo: string;
  st_ativo: string;
}
interface HeaderProps {
  title?: string;
  match?: {
    params: {
      estab: string;
      loc: string;
    };
  };
}

const Posicao: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const buttonEditRef = useRef<HTMLButtonElement>(null);

  const [itens, setItens] = useState([]);
  const [visible, setVisible] = useState(Boolean);
  var [IDSelecionado, setIDSelecionado] = useState(Number);
  var [IDEstalecimento, setIDEstalecimento] = useState(Number);

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      handleBuscaDados();
    }
  }, []);

  const handleBuscaDados = () => {
    setIDEstalecimento(-1);
    setIDSelecionado(-1);
    // var id:number = Number(params.id);

    var estab: number = Number(getEstab());
    // alert(estab);

    // var loc:number = Number(props.match.params.loc);
    if (estab > 0) {
      setIDEstalecimento(estab);
      api.get(`evento`).then((response) => {
        // alert(response.data);
        setItens(response.data);
      });
    } else {
      navigate("/");
    }
  };

  const columns = [
    {
      //id_evento, id_estabelecimento, descricao, tipo, st_ativo
      name: "id_evento",
      label: "ID Evento",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "id_estabelecimento",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tipo",
      label: "Tipo Despesa",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value == 1) {
            return "Soma";
          } else {
            return "Desconta";
          }
        },
      },
    },
    {
      name: "st_ativo",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value == "S") {
            return "Ativo";
          } else {
            return "Inativo";
          }
        },
      },
    },
  ];

  const handleClickEdit = () => {
    if (IDSelecionado >= 0) {
      let selecionado = itens[IDSelecionado] as Item;

      navigate(`/evento/${selecionado.id_evento}`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  const handleClickNew = () => {
    navigate(`/evento`);
  };

  const handleBack = () => {
    // navigate(`/localizacao/${IDEstalecimento}`);
  };

  var SelectableRows: SelectableRows = "single";
  var options: MUIDataTableOptions = {
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      let y1 = rowsSelected as number[];
      setVisible(true);
      setIDSelecionado(-1);
      y1.map((numero: number) => {
        setIDSelecionado(numero);
        setVisible(false);
      });
    },
    textLabels: labels,
    selectableRows: SelectableRows,
    onRowsDelete: () => {
      let selecionado = itens[IDSelecionado] as Item;
      api
        .delete(
          `/posicoesestab/${selecionado.id_estabelecimento}/${selecionado.id_localizacao}/${selecionado.id_evento}`
        )
        .then(function (response) {
          alert(response.data);
          handleBuscaDados();
        });
    },

    filter: false,
    print: false,
    download: false,
    viewColumns: false,
  };

  return (
    <div>
      <div className={classes.root}>
        <div>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.buttonVoltar}
            onClick={(event) => handleBack()}
            startIcon={<FiArrowLeft />}
          >
            Voltar
          </Button>
        </div>
        <div>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={(event) => handleClickEdit()}
            startIcon={<EditIcon />}
          >
            Editar
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(event) => handleClickNew()}
            className={classes.button}
            startIcon={<NewIcon />}
          >
            Novo
          </Button>
        </div>
      </div>
      <MUIDataTable
        title={`Eventos do Estabelecimento`}
        data={itens}
        columns={columns}
        options={options}
      />
    </div>
  );
};
export default Posicao;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonVoltar: {
      //color: 'black',
      margin: theme.spacing(1),
      backgroundColor: "gray",
    },
    buttonHide: {
      display: "none",
    },
  })
);
