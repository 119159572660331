import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

interface Props {
  title: string;
  description:string;
  description2?:string;
  noText?:string;
  yesText?:string;
  neutralText?:string;
  show: boolean;
  confirm?:boolean;
  icon?:string,
  setShow(show:boolean):void;
};

const ModalConfirm: React.FC<Props> = ({ title, description, noText, yesText, show, neutralText, confirm,icon, description2,setShow, ...rest }) => {

  const handleClose = () => setShow(false);
  const handleConfirm = () => setShow(true);

  return (
    <>
      <Modal style={{marginTop:270, zIndex: 9999}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title style={{textAlign: 'center',width: '100%'}} >{title}</Modal.Title>
        </Modal.Header>
        {description &&
            <Modal.Body>
              <Form style={{textAlign: 'center',width: '100%'}}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1">
                  <Form.Label>{description}</Form.Label>
                  {icon &&
                    icon
                  }
                  {description2 && description2}
                </Form.Group>
              </Form>
            </Modal.Body>
        }
        <Modal.Footer style={{justifyContent:'space-around'}}>
          {confirm &&
            <>
              <Button style={{minWidth:100}} variant="secondary" onClick={handleClose}>
                {noText}
              </Button>
              <Button style={{minWidth:100}} variant="primary" onClick={handleConfirm}>
                {yesText}
              </Button>
            </>
          }
          {!confirm &&
            <Button style={{minWidth:100}} variant="primary" onClick={handleConfirm}>
              {neutralText}
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalConfirm;