import React, { useEffect, useState, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import NewIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Report from "@material-ui/icons/Report";
import AddLinkIcon from "@mui/icons-material/AddLink";
import CircularProgress from "@mui/material/CircularProgress";
import AssessmentIcon from "@mui/icons-material/Assessment";

import api from "../../services/api";
import labels from "../../component/TableLabels";

interface Item {
  id_veiculo: number;
  placa: number;
  ano: string;
  modelo: string;
  marca: string;
  cor: string;
  renavam: number;
  nome: string;
}

interface HeaderProps {
  title?: string;
  match?: {
    params: {
      prod: string;
    };
  };
}

const Produto: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);

  const classes = useStyles();
  const buttonEditRef = useRef<HTMLButtonElement>(null);

  const [itens, setItens] = useState([]);
  const [visible, setVisible] = useState(Boolean);
  var [IDSelecionado, setIDSelecionado] = useState(Number);
  const [filtro, setFiltro] = useState(["Ativo"]);

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      handleBuscaDados();
    }
  }, []);

  const handleBuscaDados = () => {
    api.get(`veiculo`).then((response) => {
      // console.log(response.data);
      setItens(response.data);
      setVisible(true);
      setLoading(false);
    });
  };
  const columns = [
    {
      name: "id_veiculo",
      label: "ID Veículo",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "placa",
      label: "Placa",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "tipo",
      label: "Tipo",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value == "C") {
            return "Cavalo";
          } else if (value == "R") {
            return "Carroceria";
          } else {
            return "Geral";
          }
        },
      },
    },
    {
      name: "ano",
      label: "Ano",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "modelo",
      label: "Modelo",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "marca",
      label: "Marca",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "cor",
      label: "Cor",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "renavam",
      label: "Renavam",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "nome",
      label: "Motorista",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        filterList: filtro,
      },
    },
  ];

  const handleClickEdit = () => {
    if (IDSelecionado >= 0) {
      let selecionado = itens[IDSelecionado] as Item;

      navigate(`/cadastraveiculo/${selecionado.id_veiculo}`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  const handleClickVincular = () => {
    if (IDSelecionado >= 0) {
      let selecionado = itens[IDSelecionado] as Item;
      navigate(`/cadastraveiculo/${selecionado.id_veiculo}/motorista`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  const handleClickResumo = () => {
    // if (IDSelecionado >= 0) {
    //   let selecionado = itens[IDSelecionado] as Item;
    //   navigate(`/resumoveiculo/${selecionado.id_veiculo}`);
    // } else {
    navigate(`/resumoveiculo/`);
    // }
  };
  const handleClickNew = () => {
    navigate(`/cadastraveiculo`);
  };
  const CustomToolbarLoading = ({}) => {
    return (
      <>
        {isLoading && (
          <CircularProgress sx={{ display: "flex" }} color="inherit" />
        )}
      </>
    );
  };
  var SelectableRows: SelectableRows = "single";
  var options: MUIDataTableOptions = {
    customToolbar: CustomToolbarLoading,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      let y1 = rowsSelected as number[];
      setVisible(true);
      y1.map((numero: number) => {
        setIDSelecionado(numero);
        setVisible(false);
      });
      // setFiltro(['']);
    },
    textLabels: labels,
    selectableRows: SelectableRows,
    onRowsDelete: () => {
      let selecionado = itens[IDSelecionado] as Item;
      api
        .delete(`/produto/${selecionado.id_veiculo}`)
        .then(function (response) {
          alert(response.data.result);
          handleBuscaDados();
        });
    },
    // filterType: 'checkbox',
    filter: true,
    print: false,
    download: false,
    viewColumns: false,
  };

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.buttons}>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={(event) => handleClickVincular()}
            startIcon={<AddLinkIcon />}
          >
            Vincular Motorista
          </Button>
          <Button
            ref={buttonEditRef}
            disabled={!visible}
            size="small"
            variant="contained"
            color="secondary"
            className={classes.buttonResumo}
            onClick={(event) => handleClickResumo()}
            startIcon={<AssessmentIcon />}
          >
            Resumo do Veículo
          </Button>
        </div>
        <div>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={(event) => handleClickEdit()}
            startIcon={<EditIcon />}
          >
            Editar
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(event) => handleClickNew()}
            className={classes.button}
            startIcon={<NewIcon />}
          >
            Novo
          </Button>
        </div>
      </div>
      {/* {isLoading ? (
                <Backdrop sx={{ color: '#fff'}} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
        ) : ( */}
      <MUIDataTable
        title={`Veículos`}
        data={itens}
        columns={columns}
        options={options}
      />
      {/* )} */}
    </div>
  );
};
export default Produto;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: 'flex-end',
      alignItems: "flex-start",
      justifyContent: "space-between",
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonResumo: {
      marginLeft: theme.spacing(1),
      //  alignItems: 'flex-start',
      backgroundColor: "green",
    },
    buttonHide: {
      display: "none",
    },
    buttons: {
      flexDirection: "row",

      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  })
);
