import React, {
  useEffect,
  useRef,
  ChangeEvent,
  useState,
  FormEvent,
} from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
// import Button from '@material-ui/core/Button';
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { useNavigate } from "react-router-dom";
import base64 from "base-64";
import api from "../../services/api";
import LoadingButton from "@mui/lab/LoadingButton";

import { login, loginUser, loginUserEstab } from "../../services/auth";
// import { Email } from '@material-ui/icons';

interface RouterProps {
  history?: {};
  location?: {};
  match?: {
    params: {
      id: string;
    };
  };
}

const Login: React.FC<RouterProps> = (props: RouterProps) => {
  let navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    web: "WEB",
  });

  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    // var originalUrl = '/redefinir/sduashduash*8s';
    // console.log(originalUrl.includes('/redefinir'));
  }, []);

  async function handleSubmit(event: FormEvent) {
    setLoading(true);

    // const v2 = base64.encode('teste');
    const encText = base64.encode(formData.password);
    const formEnvio = { email: formData.email, password: encText, web: "WEB" };
    const response = await api.post("/login", formEnvio);
    // console.log(response.data);
    //alert(response.data);
    if (response.data.sucess) {
      // console.log("response.data.token", response.data);
      // alert(response.data.estabs[0].mot_lanc_manut);
      login(response.data.token);
      loginUser(response.data.id_user);
      localStorage.setItem("UserTipo", response.data.tipo_user);
      localStorage.setItem("UserName", response.data.nome);
      if (response.data.estabs.length > 1) {
        alert("Selecione o Estabelecimento Desejado");
        navigate(`/estabelecimento`);
      } else {
        loginUserEstab(response.data.estabs[0].id_estabelecimento);
        localStorage.setItem("Empresa", response.data.estabs[0].nome);
        localStorage.setItem("UsaFrete", response.data.estabs[0].usa_frete);
        localStorage.setItem(
          "aprova_despesa_aut",
          response.data.estabs[0].aprova_despesa_aut
        );

        localStorage.setItem(
          "ManutApp",
          response.data.estabs[0].mot_lanc_manut
        );
        localStorage.setItem(
          "MaxVeiculo",
          response.data.estabs[0].qtd_veiculos
        );
      }
      window.location.href = "/";
      navigate(`/`);
    } else {
      // console.log(response.data);
      alert(response.data.error);
    }
    setLoading(false);
  }
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  }

  return (
    <Container maxWidth="sm" className={classes.paper}>
      <Form className={classes.root} ref={formRef} onSubmit={handleSubmit}>
        <fieldset className={classes.titulo}>
          <h2 className={classes.tituloh2}>Entrar</h2>
        </fieldset>

        <TextField
          required
          type="email"
          placeholder="E-mail"
          onChange={handleInputChange}
          className={classes.field}
          id="email"
          name="email"
          label="E-mail"
        />
        <TextField
          required
          onChange={handleInputChange}
          className={classes.field}
          id="password"
          name="password"
          label="Senha"
          type="password"
          placeholder="Senha"
        />
        {/* <Button
                className={classes.buttons} 
                  type="submit"
                    size="small" 
                    variant="contained"
                    color="primary">
                        Entrar
                </Button>  */}
        <LoadingButton
          loading={isLoading}
          // startIcon={<SaveIcon />}

          // loadingIndicator="Recuperando Senha..."
          className={isLoading ? classes.buttonsLoad : classes.buttons}
          // type="submit"
          // onClick={handleSubmit}
          size="small"
          variant="contained"
          // color="primary"
          // loading={false}
          type="submit"
          loadingPosition="start"
          // startIcon={<Email />}
          // variant="outlined"
        >
          {isLoading ? <>Entrando...</> : <>Entrar</>}
        </LoadingButton>
        <p>
          Se você esqueceu sua senha, clique no link abaixo para redefini-la:
        </p>

        <a href="/recuperar">Esqueceu a senha?</a>

        <br></br>
        <p>Ainda não tem cadastro?</p>
        <span>
          <a href="/novacadastro/usuario">Cadastre-se Aqui</a> e ganhe 30 dias
          grátis.
        </span>
      </Form>
    </Container>
  );
};

export default Login;

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      //width: '25ch',
    },
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  buttons: {
    height: "55px",
    display: "flex",
    width: "70%",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    alignItems: "center",
  },
  buttonsLoad: {
    height: "55px",
    display: "flex",
    width: "70%",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    alignItems: "center",
    backgroundColor: "gray",
  },
  titulo: {
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderBottom: "2px solid #e5e5e6",
    width: "70%",
  },
  tituloh2: {
    textAlign: "center",
    color: "#444445",
  },
  field: {
    width: "70%",
    height: "75px",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    backgroundColor: "#Fff",
    border: "1px solid #e5e5e6",
    borderRadius: "8px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: "70%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
