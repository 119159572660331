import React, {useState, ChangeEvent, useEffect, useRef} from 'react';
import Button from 'react-bootstrap/Button';
import { FormHandles } from '@unform/core';
// import Form from 'react-bootstrap/Form';
import { Form } from '@unform/web';

import {Button as Button1} from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import Modal from 'react-bootstrap/Modal';

interface Props {
  email: string;
  title: string;
  description:string;
  noText?:string;
  yesText?:string;
  neutralText?:string;
  id: number;
  show: boolean;
  confirm?:boolean;
  setShow(show:boolean, password:string):void;
};
interface FormData {
  data_inicio:Date,
  descricao:string,
}

const ModalConfirm: React.FC<Props> = ({ title, description, noText, yesText, show, neutralText, confirm,id,  email, setShow, ...rest }) => {

  const [formData, setFormData] = useState({id:0, email:'', password: '', passwordConfirm:''});
  const formRef = useRef<FormHandles>(null);
  // let [value, setState] = useState(true);

  useEffect(()=>{
    // alert(placaInformada);
    // const materialDateInput = `${year}-${month}-${date}`;
    setFormData({email, id,  password: '', passwordConfirm:''});
    // setFormData({placa:String(placaInformada), data_inicio: materialDateInput, passwordConfirm:''});
  }, [email]);

  const handleClose = () => setShow(false,'');
  // const handleConfirm = () => setShow(true,'', '');
  const handleCloseCancel = () => setShow(false,'');

  function handleConfirm(){
   
      var erro = false;
      if (formData.password != formData.passwordConfirm) {
        erro = true;
          // console.log('erro', 'erro');
          // console.log(formRef.current);
        // formRef.current?.setErrors({passwordConfirm:"erro"})
        formRef.current?.setFieldError('passwordConfirm', "A Senha precisa ser igual!");
        // setState(!value);
        //   setFormData({...formData, ['passwordConfirm']:formData.passwordConfirm});
          console.log('erro1', 'erro1');
          
      }  else {
        setShow(true, formData.password);
      }
  }
  function handleInputChange(event: ChangeEvent<HTMLInputElement>){
    const {id, value} = event.target;
    setFormData({...formData, [id]:String(value)});
  }
  
  return (
    <>
      <Modal style={{marginTop:100}} show={show} onHide={handleCloseCancel}>
          <Form ref={formRef} //className={classes.dadosViavem} ref={formRef} 
              onSubmit={handleClose}>
                <Modal.Header closeButton>
            <Modal.Title style={{textAlign: 'center', width: '100%'}}>{title}</Modal.Title>
          </Modal.Header>
            <Modal.Body>
                <TextField required 
                  type="email"
                  placeholder="E-mail"
                  disabled={true}
                  style={{
                    width: '90%',
                    fontSize: '22px',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    marginLeft:'5px', 
                    marginRight:'5px',
                    color:'black'
                  }}
                  id="email" 
                  value={formData.email}
                  name="email" 
                  label="E-mail" />
                <TextField 
                    required 
                    onChange={handleInputChange} 
                    style={{
                      width: '90%',
                      fontSize: '22px',
                      paddingTop: '7px',
                      paddingBottom: '7px',
                      marginLeft:'5px', 
                      marginRight:'5px',
                      color:'black'
                    }}
                    value={formData.password}
                    inputProps={{ minLength: 6 }}             
                    id="password" 
                    name="password" 
                    label="Nova Senha"
                    type="password"
                    placeholder="Nova Senha" />
                <TextField 
                  required 
                  onChange={handleInputChange} 
                  style={{
                    width: '90%',
                    fontSize: '22px',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    marginLeft:'5px', 
                    marginRight:'5px',
                    color:'black'
                  }}
                  value={formData.passwordConfirm}
                  inputProps={{ minLength: 6 }}             
                  id="passwordConfirm" 
                  error={formRef.current?.getFieldError("passwordConfirm")?true:false}
                  helperText={formRef.current?.getFieldError("passwordConfirm")}
                  name="passwordConfirm" 
                  label="Repita a Nova Senha"
                  type="password"                  
                  placeholder="Repita a Nova senha" />  
               
          </Modal.Body>
          <Modal.Footer style={{justifyContent:'space-between'}}>
            <Button1 variant="secondary" onClick={handleCloseCancel}>
              Cancelar
            </Button1>
            <Button1 variant="primary" 
                          type="submit"
                          onClick={handleConfirm}
                          >
              Redefinir
            </Button1>
          </Modal.Footer>
          </Form>
      </Modal>
    </>
  );
};
export default ModalConfirm;