import axios, {AxiosRequestConfig, RawAxiosRequestHeaders} from  'axios';
import { getToken, getEstab, getUser } from "./auth";
// import {useNavigate} from 'react-router-dom';
//import {} from 'react-router-dom'


const isSecureConnection = window.location.protocol === 'https:';
      // console.log( 'window.location.protocol', window.location.hostname);

// export const URL = 'http://18.230.78.178:3333';
// var URL = 'https://api.siga.tec.br';

// if (isSecureConnection) {
//   URL = 'https://api.siga.tec.br';

//   // export const URL = 'https://34.229.221.192:3333/'; // EUA

// } else {
//   URL = 'http://api.siga.tec.br';

//   // export const URL = 'http://34.229.221.192:3333/'; // EUA

// };
export  const URL =  (function () {
    // return 'http://192.168.68.101:3333';

    if (window.location.hostname === 'localhost') {
      return 'http://localhost:3333';
    }
    if (isSecureConnection) {
      // console.log( 'isSecureConnection', isSecureConnection);

      // return '/https://api.siga.tec.br';
      // return 'http://api2.siga.tec.br:3333/';
      return 'https://api.siga.tec.br';
    } else {
      // console.log( 'NOT isSecureConnection', isSecureConnection);
      return 'http://3.91.81.20:3333'; // EUA
    }
})();


// = 'http://34.229.221.192:3333/'; // EUA
// export const URL = 'api-siga-eua-1071466561.us-east-1.elb.amazonaws.com';

// export const URL = 'https://localhost:3333';
// export const URL = 'http://192.168.68.111:3333';

const api = axios.create({
    // baseURL: process.env.REACT_APP_API_URL
    baseURL: URL//'http://18.230.78.178:3333'//process.env.REACT_APP_API_URL AWS

});

api.interceptors.request.use(async function (config:AxiosRequestConfig)  {
    const token   = getToken();
    const id_user = getUser();
    const id_estabelecimento = getEstab();
    if (token) {
      //console.log( 'token', token);
      // if (config) {
        let header = {} as RawAxiosRequestHeaders;
        header['token'] = `${token}`;
        header['id_user'] = id_user;
        header['id_estabelecimento'] = id_estabelecimento;
        // config.headers(RawAxiosRequestHeaders)
        config.headers = header;
        // config.headers['token'] = `${token}`;
        // config.headers['id_user'] = id_user;
        // config.headers['id_estabelecimento'] = id_estabelecimento;
      // }
    }
  // console.log( 'id_user', id_user);
  // console.log( 'id_estabelecimento', id_estabelecimento);
  return config;
  });
  // api.interceptors.response.use(async config => {
    
  //     console.log( 'config.status', config.status);
      
  // return config;
  // });

  api.interceptors.response.use(function (response) {
    // console.log( 'config.status', response.status);

    return response;
  }, function (error) {
      if (401 === error.response.status) {
        // console.log( 'config.status', error.response.status);
        window.location.href = '/logout';
          
      } else {
          return Promise.reject(error);
      }
  });

export default api;