import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import MUIDataTable from "mui-datatables";
import { useReactToPrint } from "react-to-print";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { useNavigate, useParams } from "react-router-dom";

import api, { URL } from "../../services/api";
import labels from "../../component/TableLabels";
import ImageEvento from "../../component/Img";

const baseURL = `${URL}/files/`;

interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
      id: string;
    };
  };
}

const formatDate = (date: Date) => {
  var d = new Date(date);
  d.setHours(d.getHours() + 3); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas
  // console.log(d);
  try {
    let formattedDate = Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(d);

    return formattedDate;
  } catch (error) {
    return date;
  }
};

const ViagemAcerto: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();

  const [ID, setID] = useState(Number);
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [adicionais, setAdicionais] = useState([]);
  const [descontos, setDescontos] = useState([]);
  const [formData, setFormData] = useState({
    id_estabelecimento: 0,
    id_viagem: "",
    codigo: "",
    mercadoria: "",
    descricao: "",
    origem: "",
    destino: "",
    valor: 0,
    comissao: 0,
    km_inicio: "",
    km_fim: "",
    km_total: "",
    lt_viagem: 0,
    obs_viagem: "",
    nome_motorista: "",
    placa: "",
    placa2: "",
    soma: 0,
    desconta: 0,
    saldo: 0,
    comissao_vl: 0,
    total: 0,
    data_saida: new Date(),
    data_chegada: new Date(),
  });
  const formRef = useRef<FormHandles>(null);
  const componentRef = useRef<any>();
  const marginTop = "10px";
  const marginRight = "10px";
  const marginBottom = "10px";
  const marginLeft = "10px";
  // const pageStyle = `@page { size: auto; margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }  @media print {    html,    body {      height: initial!important;      overflow: initial!important; - webkit - print - color - adjust: exact;    }  }`;
  // const pageStyle ="  @page {    size: A4 landscape;  }";
  const pageStyle = `
  @media all {
    .page -
      break {
        display: none;
      }
  }
  
  @media print {
    html,
    body {
      height: initial!important;
      overflow: initial!important; -
      webkit - print - color - adjust: exact;
    }
  }
  
  @media print {
    .page -
      break {
        margin - top: 1 rem;
        display: block;
        page -
          break -before: auto;
      }
  }
  
  @page {
    size: auto;
    margin: 20 mm;
  }
`;
  const handlePrint = useReactToPrint({
    // onBeforePrint: () => {
    //   setHabitidadoEdicao(true);
    //   alert("antes");
    // },

    content: () => componentRef.current,
    // documentTitle: "AcertoViagem",
    pageStyle: pageStyle,

    onAfterPrint: () => {
      window.close();
    },
  });

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      var idPar: number = Number(params.id);

      // console.log('id', idPar)
      setID(idPar);
      handleBuscaDados(idPar);
    }
  }, []);

  async function handleBuscaDados(idPar: Number) {
    // console.log('id', idPar);
    await api.get(`viagemcusto/A/${idPar}`).then((response) => {
      if (response.data.length > 0) {
        if (response.data[0].st_ativo == "C") {
        } else if (response.data[0].st_ativo != "F") {
          alert("Viagem ainda não Finalizada!");
          navigate("/viagens");
        }
        setFormData(response.data[0]);
      } else {
        navigate("/viagens");
      }
    });

    await api
      .get(`eventoviagem/${idPar}/1`)
      .then((response) => {
        //adicionais
        setAdicionais(response.data);
      })
      .catch((error) => {
        console.log("erro", error);
      })
      .finally(() => {
        //console.log('id333333', idPar);
      });
    await api.get(`eventoviagem/${idPar}/2`).then((response) => {
      //descontos
      setDescontos(response.data);
    });
    handlePrint();
  }
  const TipoValor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    return TipoValor.format(value);
  };
  const columns = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  const columnsDespesas = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "litros",
      label: "Litros",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "media",
      label: "Média",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  var SelectableRows: SelectableRows = "none";
  var options: MUIDataTableOptions = {
    selectableRows: SelectableRows,
    textLabels: labels,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    pagination: false,
  };

  return (
    <div style={{ marginTop: "10px" }} ref={componentRef}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-center",
          paddingRight: "6px",
        }}
      ></div>
      <div className={classes.root}>
        <Container className={classes.formdados}>
          <Form
            className={classes.dadosViavem}
            ref={formRef}
            onSubmit={() => {}}
          >
            <fieldset className={classes.titulo}>
              <h2 className={classes.tituloh2}>Acerto de Viagem</h2>
            </fieldset>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                style={{ width: "15%" }}
                value={formData.id_viagem}
                //
                className={classes.field}
                id="id_viagem"
                name="id_viagem"
                label="ID"
              />
              <TextField
                style={{ width: "15%" }}
                type="number"
                value={formData.codigo}
                className={classes.field}
                id="codigo"
                name="codigo"
                label="Código"
              />
              <TextField
                value={formData.descricao}
                className={classes.field}
                id="descricao"
                name="descricao"
                label="Descrição"
              />
              <TextField
                value={formData.mercadoria}
                className={classes.field}
                id="mercadoria"
                name="mercadoria"
                label="Mercadoria"
              />
              <TextField
                value={formData.origem}
                className={classes.field}
                id="origem"
                name="origem"
                label="Origem"
              />
              <TextField
                value={formData.destino}
                className={classes.field}
                id="destino"
                name="destino"
                label="Destino"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                value={formData.km_inicio}
                className={classes.field}
                id="km_inicio"
                name="km_inicio"
                label="Km Inical"
              />
              <TextField
                value={formData.km_fim}
                className={classes.field}
                id="km_fim"
                name="km_fim"
                label="Km Final"
              />
              <TextField
                value={formData.km_total}
                className={classes.field}
                id="km_total"
                name="km_total"
                label="Km Percorrido"
              />
              <TextField
                value={formData.lt_viagem}
                className={classes.field}
                id="litros"
                name="litros"
                label="Diesel Litros"
              />
              <TextField
                value={(Number(formData.km_total) / formData.lt_viagem).toFixed(
                  2
                )}
                className={classes.field}
                id="km_litro"
                name="km_litro"
                label="Km/L"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                value={formData.nome_motorista}
                className={classes.field}
                id="nome_motorista"
                name="nome_motorista"
                label="Motorista"
              />
              <TextField
                value={formData.placa}
                className={classes.field}
                id="placa"
                name="placa"
                label="Placa"
              />
              <TextField
                value={formData.placa2}
                className={classes.field}
                id="placa2"
                name="placa2"
                label="Placa 2"
              />
              <TextField
                value={formatDate(formData.data_saida)}
                defaultValue={formData.data_saida}
                className={classes.field}
                id="data_saida"
                name="data_saida"
                label="Data de Saída"
              />
              <TextField
                value={formatDate(formData.data_chegada)}
                className={classes.field}
                id="data_chegada"
                name="data_chegada"
                label="Data de Chegada"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                value={formData.comissao}
                className={classes.field}
                id="comissao"
                name="comissao"
                label="Comissão em %"
              />
              <TextField
                value={TipoValor.format(formData.valor)}
                className={classes.field}
                id="valor"
                name="valor"
                label="Valor de Frete"
              />
              <TextField
                value={TipoValor.format(formData.soma)}
                className={classes.field}
                id="soma"
                name="soma"
                label="Adi. (+)"
              />
              <TextField
                value={TipoValor.format(formData.desconta)}
                className={classes.field}
                id="desconta"
                name="desconta"
                label="Des. (-)"
              />
              <TextField
                value={TipoValor.format(formData.saldo)}
                className={classes.field}
                id="saldo"
                name="saldo"
                label="Saldo"
              />
              <TextField
                value={TipoValor.format(formData.comissao_vl)}
                className={classes.field}
                id="comissao_vl"
                name="comissao_vl"
                label="Comissão em R$"
              />
              <TextField
                value={TipoValor.format(formData.total)}
                className={classes.field}
                id="total"
                name="total"
                label="Total"
              />
            </div>
            <TextField
              value={formData.obs_viagem}
              className={classes.field}
              id="obs_viagem"
              name="obs_viagem"
              label="Obs."
            />
          </Form>
        </Container>
      </div>
      <div className={classes.tabelas}>
        <div
          className={classes.tabela}
          style={{ width: "45%", paddingRight: "5px" }}
        >
          <MUIDataTable
            title={`Adicionais`}
            data={adicionais}
            columns={columns}
            options={options}
          />
        </div>
        <div
          className={classes.tabela}
          style={{ width: "55%", paddingLeft: "5px" }}
        >
          <MUIDataTable
            title={`Despesas`}
            data={descontos}
            columns={columnsDespesas}
            options={options}
          />
        </div>
      </div>
      <ImageEvento
        show={showImage}
        source={sourceImage}
        onReturn={() => {
          setShowImage(false);
          setSourceImage("");
        }}
      />
    </div>
  );
};
export default ViagemAcerto;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "8px",
    },
    dadosViavem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonExtra: {
      margin: theme.spacing(1),
      backgroundColor: "green",
    },
    button: {
      margin: theme.spacing(1),
    },
    titulo: {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderBottom: "2px solid rgb(0 0 0 / 20%)",
      width: "100%",
      padding: "20px",
      marginBottom: "5px",
    },
    tituloh2: {
      textAlign: "center",
      color: "#444445",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    fieldID: {
      display: "none",
    },
    buttonHide: {
      display: "none",
    },
    formdados: {
      width: "100%",
      maxWidth: "10280px",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      paddingBottom: "8px",
      //display: 'flex',
      flexDirection: "row",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
    },
  })
);
// codigo: 1
// comissao: 3610
// created_at: null
// data_chegada: "2022-02-22T03:00:00.000Z"
// data_concluiu: null
// data_finalizou: "2022-02-24T16:45:18.000Z"
// data_saida: "2022-02-09T03:00:00.000Z"
// desconta: "0"
// descricao: "Tetsr"
// destino: "SP "
// diesel_LT: null
// diesel_RS: null
// id_estabelecimento: 1
// id_motorista: null
// id_user_config: 5
// id_veiculo_1: 7
// id_veiculo_2: 1
// id_viagem: 5
// km_fim: "558"
// km_inicio: "542315"
// km_medio: "0"
// km_total: -541757
// mercadoria: "Cebola "
// obs: " "
// origem: "Petro "
// peso: "0.00"
// placa: "WKI-93D9"
// saldo: 19000
// soma: "0"
// st_ativo: "F"
// tipo_acerto: "B"
// total: 15390
// updated_at: null
// usu_concluiu: null
// usu_finalizou: 5
// valor: "19000.00"
