import React, { useEffect, useState, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AccountTree from "@material-ui/icons/AccountTree";
import NoteAdd from "@material-ui/icons/NoteAdd";
import CircularProgress from "@mui/material/CircularProgress";

import api from "../../services/api";
import labels from "../../component/TableLabels";
import GridViagmDespesas from "../../component/GridViagemDespesas";
import { MdScanner } from "react-icons/md";
// import { ifError } from 'assert';

interface Item {
  id_viagem: number;
  cd_viagem: number;
  comissao: number;
  comissao_viagem: number;
  desconta: number;
  descricao_viagem: string;
  destino_viagem: string;
  peso: number;
  id_estabelecimento: number;
  id_motorista: number;
  id_veiculo_1: number;
  id_veiculo_2: number;
  km_medio_viagem: number;
  mercadoria_viagem: string;
  nome_motorista: string;
  obs_viagem: string;
  origem_viagem: string;
  placa_veiculo: string;
  placa: string;
  saldo: number;
  soma: number;
  st_ativo: string;
  total: number;
  valor: number;
  status: string;
}
interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
    };
  };
}

const Viagem: React.FC<HeaderProps> = (props) => {
  const [isLoading, setLoading] = useState(true);
  let navigate = useNavigate();
  const classes = useStyles();
  const buttonEditRef = useRef<HTMLButtonElement>(null);
  const divGrid = useRef<HTMLDivElement>(null);
  // const grid = useRef<MUIDataTable>(null);

  const [ID, setID] = useState(Number);
  const [viagensSelecionadas, setViagensSelecionadas] = useState(
    Array<Number>()
  );

  const [visible, setVisible] = useState(true);

  const [itens, setItens] = useState([]);
  const marcado = useRef(0);
  const listaItens = useRef(Array<Item>());
  const [refresh, doRefresh] = useState(0);
  const [filtro, setFiltro] = useState(["Finalizada"]);
  const [placa, setPlaca] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      handleBuscaDados();
    }
  }, []);
  useEffect(() => {
    doRefresh((prev) => prev + 1);
  }, [listaItens.current]);
  useEffect(() => {
    if (divGrid.current?.className == "makeStyles-grid70-21") {
      if ((listaItens.current.length = 0)) {
        // console.log('70-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    } else if (divGrid.current?.className == "makeStyles-grid100-22") {
      if (listaItens.current.length > 0) {
        // console.log('100-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    }
  }, [marcado]);

  const handleBuscaDados = async () => {
    // console.log('busca viagensfinalizadas/F');
    // passando C de Concluido
    await api
      .get(`viagensfinalizadas/F`)
      .then((response) => {
        // console.log('retorno',response.data)
        setItens(response.data);
      })
      .catch((error) => {
        console.log("erro handleBuscaDados index viagem", error);
      })
      .finally(() => {
        // console.log('finally')
        setLoading(false);
      });
  };
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return nf.format(value);
  };
  const columns = [
    {
      name: "id_viagem",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        //  display: false,
        filterList: filtro,
      },
    },
    {
      name: "placa",
      label: "Veículo",
      options: {
        filter: true,
        sort: true,
        filterList: placa,
      },
    },
    {
      name: "nome",
      label: "Motorista",
      options: {
        filter: true,
        sort: false,
        print: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "peso",
      label: "Peso",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return parseInt(value) + " Ton.";
        },
      },
    },
    {
      name: "valor",
      label: "Frete",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "comissao_vl",
      label: "Comissão",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "soma",
      label: "Adi. (+)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "desconta",
      label: "Des. (-)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "saldo",
      label: "Saldo",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "total",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  var selectRow: number = -1;
  var SelectableRows: SelectableRows = "multiple";
  // var SelectableRows: SelectableRows = "single";
  const CustomToolbarLoading = ({}) => {
    return (
      <>
        {isLoading && (
          <CircularProgress sx={{ display: "flex" }} color="inherit" />
        )}
      </>
    );
  };
  var options: MUIDataTableOptions = {
    customToolbar: CustomToolbarLoading,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      let y1 = rowsSelected as number[];
      // console.log('1-',y1.length);
      selectRow = -1;
      marcado.current = 0;
      setID(-1);
      setVisible(true);
      var viagens = Array<Number>();
      if (y1.length == 0) {
        setPlaca([]);
      }
      //@ts-ignore
      var placaSelecionada = [];
      // listaItens.current = Array<Item>();
      y1.map((numero: number) => {
        let item = itens[numero] as Item;
        if (placaSelecionada.length == 0) {
          viagens.push(item.id_viagem);
          placaSelecionada.push(item.placa);
          //@ts-ignore
          setPlaca(placaSelecionada);
          //@ts-ignore
        } else if (placaSelecionada[0] != item.placa) {
          alert("Selecione apendas viagens da mesma placa");
        } else {
          viagens.push(item.id_viagem);
        }
        // listaItens.current.push(item);
        // console.log('1-',item);

        selectRow = numero;
        setVisible(false);
        setID(numero);
      });
      setViagensSelecionadas(viagens);
      // if (listaItens.current.length > 0) {
      //   marcado.current = 1;
      // }
      doRefresh((prev) => prev + 1);
    },

    selectableRowsHeader: itens.length > 0,
    selectableRows: SelectableRows,
    textLabels: labels,
    // selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    // selectToolbarPlacement: 'replace' | 'above' | 'none';
    // filter: false,
    // print: false,
    // download: false,
    // viewColumns: false,
    responsive: "standard",
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 20, 50, 100],
    onRowsDelete(rowsDeleted, newTableData) {
      if (window.confirm("Concelar Viagem?") == true) {
        handleCancelar();
      }
      doRefresh((prev) => prev + 1);
    },
  };
  const handleCancelar = async () => {
    let selecionado = itens[ID] as Item;
    await api
      .put(`/viagem/cancelar/${selecionado.id_viagem}`)
      .then((response) => {
        if (response.data.sucess) {
          alert("Viagem Cancelada!");
        }
      })
      .catch((error) => {})
      .finally(() => {
        handleBuscaDados();
      });
  };
  const handleClickNew = () => {
    navigate("/estabelecimentonew");
  };
  const handleClickEdit = () => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;

      navigate(`/viagem/${selecionado.id_viagem}`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  const handleClickAcerto = () => {
    // console.log(viagensSelecionadas);
    if (viagensSelecionadas.length > 1) {
      let selecionados = "";
      viagensSelecionadas.map((valor: any, index: number) => {
        //  console.log('valor', valor);
        //  console.log(viagensSelecionadas[index]);
        if (selecionados != "") {
          selecionados = selecionados + ",";
        }
        selecionados = selecionados + valor;
      });
      console.log(selecionados);

      // console.log(selecionado);
      navigate(`/viagemacertos/${selecionados}`);
    } else {
      if (ID >= 0) {
        let selecionado = itens[ID] as Item;
        // console.log(selecionado);
        navigate(`/viagemacerto/${selecionado.id_viagem}`);
      } else {
        alert("Nenhum Item Selecionado!");
      }
    }
  };
  const handleClickNewDespesa = () => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      // console.log(selecionado);
      navigate(`/viagem/${selecionado.id_viagem}/despesa`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  const handleClickNewViagem = () => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      // console.log(selecionado);
      navigate(`/viagem`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  const handleOnReturn = async () => {
    marcado.current = 0;
    setID(-1);
    listaItens.current = Array<Item>();
    //await alert('Viagem(s) Finalizada(s)!');
    doRefresh(0);
    handleBuscaDados();
  };
  return (
    <div>
      <div className={classes.root}>
        <Button
          ref={buttonEditRef}
          //disabled = {visible}
          size="small"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={(event) => handleClickNewViagem()}
          startIcon={<NoteAdd />}
        >
          Nova Viagem
        </Button>
        <div className={classes.buttons}>
          {/* <Button
            ref={buttonEditRef}
            disabled = {visible}
            size="small" 
            variant="contained"
            color="secondary"
            // className={classes.buttonExtra}
            onClick={(event) => handleClickNewDespesa()}
            startIcon={<NoteAdd />}
              >Lançar Despesa
            </Button> */}
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            className={classes.buttonAcerto}
            onClick={(event) => handleClickAcerto()}
            startIcon={<AccountTree />}
          >
            Acerto
          </Button>
        </div>
      </div>
      <div className={classes.grid}>
        <div
          className={
            listaItens.current.length > 0 ? classes.grid70 : classes.grid100
          }
          ref={divGrid}
        >
          <MUIDataTable
            title={`Viagens Aguardando Acerto`}
            data={itens}
            columns={columns}
            options={options}
          />
        </div>
        <GridViagmDespesas
          array={listaItens.current}
          refresh={refresh}
          onReturn={handleOnReturn}
        />
      </div>
    </div>
  );
};
export default Viagem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: 'flex-end',
      alignItems: "flex-start",
      justifyContent: "space-between",
      margin: theme.spacing(1),
    },
    grid: {
      display: "flex",
      flexDirection: "row",
    },
    grid70: {
      width: "65%",
    },
    grid100: {
      width: "100%",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonAcerto: {
      marginLeft: theme.spacing(1),
      //  alignItems: 'flex-start',
      backgroundColor: "green",
    },
    buttons: {
      flexDirection: "row",

      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonHide: {
      display: "none",
    },
    resumo: {
      width: "100%",
      //display: 'flex',
      flexDirection: "column",
      backgroundColor: "#Fff",
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
  })
);
