import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import MUIDataTable from "mui-datatables";
import { useReactToPrint } from "react-to-print";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import NewIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
import Print from "@material-ui/icons/Print";
import { BsArrowBarLeft } from "react-icons/bs";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Link, useNavigate, useParams } from "react-router-dom";

import api, { URL } from "../../services/api";
import labels from "../../component/TableLabels";
import ImageEvento from "../../component/Img";

const baseURL = `${URL}/files/`;

interface Item {
  id_viagem: number;
  descricao: string;
  origem: string;
  destino: string;
  valor: string;
  comissao: string;
  km_total: string;
  obs_viagem: string;
  nome_motorista: string;
  placa: string;
  placa2: string;
  data_saida: Date;
  data_chegada: Date;
}
interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
      id: string;
    };
  };
}
/**
table.integer('codigo').notNullable();
table.string('descricao', 280).notNullable();
table.string('mercadoria', 280).notNullable();
table.string('origem', 280).notNullable();
table.string('destino', 280).notNullable();
table.decimal('valor', 12,2).notNullable();
table.decimal('comissao', 12,2).notNullable();
table.string('km_total', 280).notNullable();
table.string('obs_viagem', 1000).notNullable();
table.string('st_ativo', 1).notNullable();        
table.integer('id_motorista')
      .references('id_motorista')
      .inTable('motorista');
table.integer('id_veiculo_1')
      .references('id_veiculo')
      .inTable('veiculo');
table.integer('id_veiculo_2')
*/
const formatDate = (date: Date) => {
  var d = new Date(date);
  d.setHours(d.getHours() + 3); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas
  // console.log(d);
  try {
    let formattedDate = Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(d);

    // console.log(formattedDate);
    return formattedDate;
  } catch (error) {
    return date;
  }
};

const ViagemAcerto: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const [concluida, setConcluida] = useState(false);

  const [ID, setID] = useState(Number);
  const [habitidadoEdicao, setHabitidadoEdicao] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [adicionais, setAdicionais] = useState([]);
  const [descontos, setDescontos] = useState([]);
  const [formData, setFormData] = useState({
    id_estabelecimento: 0,
    id_viagem: "",
    codigo: "",
    mercadoria: "",
    descricao: "",
    origem: "",
    destino: "",
    valor: 0,
    comissao: 0,
    km_inicio: "",
    km_fim: "",
    km_total: "",
    lt_viagem: 0,
    obs_viagem: "",
    nome_motorista: "",
    placa: "",
    placa2: "",
    soma: 0,
    desconta: 0,
    saldo: 0,
    comissao_vl: 0,
    total: 0,
    data_saida: new Date(),
    data_chegada: new Date(),
  });
  const formRef = useRef<FormHandles>(null);
  const printRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  // const ReactPDFPrint = () => {
  const componentRef = useRef<any>();
  const marginTop = "10px";
  const marginRight = "10px";
  const marginBottom = "10px";
  const marginLeft = "10px";
  // const pageStyle = `@page { size: auto; margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }  @media print {    html,    body {      height: initial!important;      overflow: initial!important; - webkit - print - color - adjust: exact;    }  }`;
  // const pageStyle ="  @page {    size: A4 landscape;  }";
  const pageStyle = `
  @media all {
    .page -
      break {
        display: none;
      }
  }
  
  @media print {
    html,
    body {
      height: initial!important;
      overflow: initial!important; -
      webkit - print - color - adjust: exact;
    }
  }
  
  @media print {
    .page -
      break {
        margin - top: 1 rem;
        display: block;
        page -
          break -before: auto;
      }
  }
  
  @page {
    size: auto;
    margin: 20 mm;
  }
`;
  const handlePrint = useReactToPrint({
    // onBeforePrint: () => {
    //   setHabitidadoEdicao(true);
    //   alert("antes");
    // },

    content: () => componentRef.current,
    documentTitle: "AcertoViagem",
    pageStyle: pageStyle,

    // onAfterPrint: () => {
    //   setHabitidadoEdicao(false);
    //   alert("depois");
    // },
  });
  // useEffect(() => {
  //   alert("trocou");
  // }, [habitidadoEdicao]);

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      var idPar: number = Number(params.id);

      // console.log('id', idPar)
      setID(idPar);
      handleBuscaDados(idPar);
    }
  }, []);

  async function handleBuscaDados(idPar: Number) {
    // console.log('id', idPar);
    await api.get(`viagemcusto/A/${idPar}`).then((response) => {
      // console.log('viagem', response.data)
      // console.log('viagem0000', response.data[0])
      // console.log('tamanho',response.data.length)
      if (response.data.length > 0) {
        //console.log('viagem0000', response.data[0].st_ativo)
        if (response.data[0].st_ativo == "C") {
          alert("Viagem já Concluída!");
          setConcluida(true);
          // navigate("/viagens");
        } else if (response.data[0].st_ativo != "F") {
          alert("Viagem ainda não Finalizada!");
          navigate("/viagens");
        }
        setFormData(response.data[0]);
      } else {
        // console.log('tamanho',response.data)

        navigate("/viagens");
      }
    });
    // console.log('id222222', idPar);

    await api
      .get(`eventoviagem/${idPar}/1`)
      .then((response) => {
        //adicionais
        setAdicionais(response.data);
      })
      .catch((error) => {
        console.log("erro", error);
      })
      .finally(() => {
        //console.log('id333333', idPar);
      });
    await api.get(`eventoviagem/${idPar}/2`).then((response) => {
      //descontos
      // console.log('descontos', response.data)
      setDescontos(response.data);
    });
  }
  const TipoValor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    return TipoValor.format(value);
  };
  const columns = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  const columnsDespesas = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        sort: false,
        //display: false,
      },
    },
    {
      name: "litros",
      label: "Litros",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "media",
      label: "Média",
      options: {
        filter: true,
        sort: false,
        // customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  var selectRow: number = -1;
  var SelectableRows: SelectableRows = "none";
  var options: MUIDataTableOptions = {
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      let y1 = rowsSelected as number[];
      selectRow = -1;
      setID(-1);
      // setVisible(true);
      // console.log('1');
      y1.map((numero: number) => {
        selectRow = numero;
        // setVisible(false);
        setID(numero);
        // console.log('numero---',numero);
      });
    },

    onRowClick: (rowData) => {
      // navigate('edit/form')
      // caminhoImagem.current = baseURL+evento.foto;

      setSourceImage(baseURL + rowData[1]);
      setShowImage(true);
      // console.log('click numero---', baseURL+rowData[1]);
    },
    selectableRows: SelectableRows,
    textLabels: labels,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
  };

  async function handleClickEditSave() {
    if (window.confirm("Salvar Edição da Viagem(s)?")) {
      setHabitidadoEdicao(true);
      // console.log(formData);
      await api.put(`viagem/${formData.codigo}`, formData).then((response) => {
        if (response.data.sucess) {
          alert("Viagem Atualizada!");
        } else {
          alert(response.data.result);
        }
      });
    }
  }
  async function handleClickReabrir() {
    if (window.confirm("Reabrir Viagem?")) {
      await api.post(`finalizaViagem/${ID}/R`).then(function (response) {
        //alert(response.data.result);
        // console.log('retorn',response.data);
        if (response.data.sucess) {
          alert("Viagem Reaberta com Sucesso!");
          navigate("/lancamentos");
        }
      });
    }
  }
  const handleClickEdit = () => {
    if (habitidadoEdicao) {
      // setColorEdit('inherit');
      // setTextEdit('EDITAR');
      setHabitidadoEdicao(false);
    } else {
      // setColorEdit('secondary');
      // setTextEdit('SALVAR');
      setHabitidadoEdicao(true);
    }
    if (ID >= 0) {
      //let selecionado = itens[ID] as Item;
      //history(`/viagem/${selecionado.id_viagem}`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  async function handleSubmit(event: FormEvent) {
    // console.log(formData);
    // console.log('ID', ID);
    if (ID > 0) {
      // console.log(formData);
      if (window.confirm("Finalizar Viagem?")) {
        await api
          .post(`finalizaViagem/${ID}/C`, {
            km_total: Number(formData.km_total),
            km_medio: (Number(formData.km_total) / formData.lt_viagem).toFixed(
              2
            ),
            diesel_LT: formData.lt_viagem,
          })
          .then(function (response) {
            //alert(response.data.result);
            // console.log('retorn',response.data);
            if (response.data.sucess) {
              alert("Viagem Finalizada com Sucesso!");
              navigate("/viagens");
            } else {
              console.log(response.data);
            }
          });
      }
    } else {
      //await api.put(`localizacoesestab/${IDEstab}/${IDLocalizacao}`, formData);
      // alert('Editado');
    }
    //history(`/localizacao/${IDEstab}`);
  }
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    if (id == "comissao") {
      const comissao_vl = formData.valor * (Number(value) / 100);
      // console.log('id campo',id);
      // console.log('comissao',comissao_vl);
      setFormData({
        ...formData,
        [String(id)]: value,
        ["comissao_vl"]: Number(comissao_vl),
      });
      // setFormData({...formData, [});
    } else {
      setFormData({ ...formData, [id]: value });
    }
  }

  return (
    <div style={{ marginTop: "10px" }} ref={componentRef}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-center",
          paddingRight: "6px",
        }}
      >
        <Link
          ref={linkRef}
          to={`/viagens`}
          style={{ display: "flex", fontSize: "17px" }}
        >
          <BsArrowBarLeft style={{ height: "23px", width: "36px" }} />
          Voltar
        </Link>
        <div>
          <Link to={`/viagemacertoprint/${ID}`} target="_blank">
            <Print onClick={(event) => {}} />
          </Link>
          <Print onClick={(event) => handlePrint()} />
        </div>
      </div>
      <div className={classes.root}>
        <Container className={classes.formdados}>
          <Form
            className={classes.dadosViavem}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <fieldset className={classes.titulo}>
              <h2 className={classes.tituloh2}>Acerto de Viagem</h2>
            </fieldset>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                style={{ width: "15%" }}
                disabled
                value={formData.id_viagem}
                onChange={handleInputChange}
                className={classes.field}
                id="id_viagem"
                name="id_viagem"
                label="ID"
              />
              <TextField
                style={{ width: "15%" }}
                required
                disabled
                type="number"
                value={formData.codigo}
                onChange={handleInputChange}
                className={classes.field}
                id="codigo"
                name="codigo"
                label="Código"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.descricao}
                onChange={handleInputChange}
                className={classes.field}
                id="descricao"
                name="descricao"
                label="Descrição"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.mercadoria}
                onChange={handleInputChange}
                className={classes.field}
                id="mercadoria"
                name="mercadoria"
                label="Mercadoria"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.origem}
                onChange={handleInputChange}
                className={classes.field}
                id="origem"
                name="origem"
                label="Origem"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.destino}
                onChange={handleInputChange}
                className={classes.field}
                id="destino"
                name="destino"
                label="Destino"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.km_inicio}
                onChange={handleInputChange}
                className={classes.field}
                id="km_inicio"
                name="km_inicio"
                label="Km Inical"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.km_fim}
                onChange={handleInputChange}
                className={classes.field}
                id="km_fim"
                name="km_fim"
                label="Km Final"
              />
              <TextField
                required
                disabled
                value={formData.km_total}
                onChange={handleInputChange}
                className={classes.field}
                id="km_total"
                name="km_total"
                label="Km Percorrido"
              />
              <TextField
                required
                disabled
                value={formData.lt_viagem}
                onChange={handleInputChange}
                className={classes.field}
                id="litros"
                name="litros"
                label="Diesel Litros"
              />
              <TextField
                required
                disabled
                value={(Number(formData.km_total) / formData.lt_viagem).toFixed(
                  2
                )}
                onChange={handleInputChange}
                className={classes.field}
                id="km_litro"
                name="km_litro"
                label="Km/L"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                required
                disabled
                value={formData.nome_motorista}
                onChange={handleInputChange}
                className={classes.field}
                id="nome_motorista"
                name="nome_motorista"
                label="Motorista"
              />
              <TextField
                required
                disabled
                value={formData.placa}
                onChange={handleInputChange}
                className={classes.field}
                id="placa"
                name="placa"
                label="Placa"
              />
              <TextField
                disabled
                value={formData.placa2}
                onChange={handleInputChange}
                className={classes.field}
                id="placa2"
                name="placa2"
                label="Placa 2"
              />
              <TextField
                disabled={habitidadoEdicao}
                value={formatDate(formData.data_saida)}
                // type="date"
                // value={formData.data_saida}
                defaultValue={formData.data_saida}
                onChange={handleInputChange}
                className={classes.field}
                id="data_saida"
                name="data_saida"
                label="Data de Saída"
              />
              <TextField
                disabled={habitidadoEdicao}
                value={formatDate(formData.data_chegada)}
                onChange={handleInputChange}
                className={classes.field}
                id="data_chegada"
                name="data_chegada"
                label="Data de Chegada"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.comissao}
                onChange={handleInputChange}
                className={classes.field}
                id="comissao"
                name="comissao"
                label="Comissão em %"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formData.valor)}
                onChange={handleInputChange}
                className={classes.field}
                id="valor"
                name="valor"
                label="Valor de Frete"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formData.soma)}
                onChange={handleInputChange}
                className={classes.field}
                id="soma"
                name="soma"
                label="Adi. (+)"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formData.desconta)}
                onChange={handleInputChange}
                className={classes.field}
                id="desconta"
                name="desconta"
                label="Des. (-)"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formData.saldo)}
                onChange={handleInputChange}
                className={classes.field}
                id="saldo"
                name="saldo"
                label="Saldo"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formData.comissao_vl)}
                onChange={handleInputChange}
                className={classes.field}
                id="comissao_vl"
                name="comissao_vl"
                label="Comissão em R$"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formData.total)}
                onChange={handleInputChange}
                className={classes.field}
                id="total"
                name="total"
                label="Total"
              />
            </div>
            <TextField
              value={formData.obs_viagem}
              onChange={handleInputChange}
              className={classes.field}
              id="obs_viagem"
              name="obs_viagem"
              label="Obs."
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              {!concluida && (
                <>
                  {!habitidadoEdicao && (
                    <Button
                      onClick={(event) => handleClickEditSave()}
                      size="small"
                      variant="contained"
                      color="secondary"
                      style={{ width: "35%" }}
                    >
                      SALVAR
                    </Button>
                  )}
                  {habitidadoEdicao && (
                    <Button
                      onClick={(event) => handleClickEdit()}
                      size="small"
                      variant="contained"
                      color="inherit"
                      style={{ width: "35%" }}
                    >
                      EDITAR
                    </Button>
                  )}
                  {habitidadoEdicao && (
                    <Button
                      type="submit"
                      size="small"
                      variant="contained"
                      color="primary"
                      style={{ width: "35%" }}
                    >
                      Finalizar
                    </Button>
                  )}
                </>
              )}
              {concluida && (
                <Button
                  onClick={(event) => handleClickReabrir()}
                  size="small"
                  variant="contained"
                  color="default"
                  style={{ width: "35%" }}
                >
                  Reabrir
                </Button>
              )}
            </div>
          </Form>
        </Container>
      </div>
      <div className={classes.tabelas}>
        <div
          className={classes.tabela}
          style={{ width: "45%", paddingRight: "5px" }}
        >
          <MUIDataTable
            title={`Adicionais`}
            data={adicionais}
            columns={columns}
            options={options}
          />
        </div>
        <div
          className={classes.tabela}
          style={{ width: "55%", paddingLeft: "5px" }}
        >
          <MUIDataTable
            title={`Despesas`}
            data={descontos}
            columns={columnsDespesas}
            options={options}
          />
        </div>
      </div>
      <ImageEvento
        show={showImage}
        source={sourceImage}
        onReturn={() => {
          setShowImage(false);
          setSourceImage("");
        }}
      />
    </div>
  );
};
export default ViagemAcerto;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "8px",
    },
    dadosViavem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonExtra: {
      margin: theme.spacing(1),
      backgroundColor: "green",
    },
    button: {
      margin: theme.spacing(1),
    },
    titulo: {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderBottom: "2px solid rgb(0 0 0 / 20%)",
      width: "100%",
      padding: "20px",
      marginBottom: "5px",
    },
    tituloh2: {
      textAlign: "center",
      color: "#444445",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    fieldID: {
      display: "none",
    },
    buttonHide: {
      display: "none",
    },
    formdados: {
      width: "100%",
      maxWidth: "10280px",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      paddingBottom: "8px",
      //display: 'flex',
      flexDirection: "row",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
    },
  })
);
// codigo: 1
// comissao: 3610
// created_at: null
// data_chegada: "2022-02-22T03:00:00.000Z"
// data_concluiu: null
// data_finalizou: "2022-02-24T16:45:18.000Z"
// data_saida: "2022-02-09T03:00:00.000Z"
// desconta: "0"
// descricao: "Tetsr"
// destino: "SP "
// diesel_LT: null
// diesel_RS: null
// id_estabelecimento: 1
// id_motorista: null
// id_user_config: 5
// id_veiculo_1: 7
// id_veiculo_2: 1
// id_viagem: 5
// km_fim: "558"
// km_inicio: "542315"
// km_medio: "0"
// km_total: -541757
// mercadoria: "Cebola "
// obs: " "
// origem: "Petro "
// peso: "0.00"
// placa: "WKI-93D9"
// saldo: 19000
// soma: "0"
// st_ativo: "F"
// tipo_acerto: "B"
// total: 15390
// updated_at: null
// usu_concluiu: null
// usu_finalizou: 5
// valor: "19000.00"
