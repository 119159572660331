import React, { useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { logout } from "../../services/auth";

interface RouterProps {
  history?: {};
  location?: {};
  match?: {params: {
    id: string}};    
}; 

const Login: React.FC<RouterProps> = (props:RouterProps) => {
    let navigate   = useNavigate();
    
    useEffect(() => {
        logout();
        // navigate(`/login`);
      window.location.href = '/login';

      }, []);
    
    return (        
        <a></a>
    )
}

export default Login;
