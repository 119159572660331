export const TOKEN_KEY = "@siga-Token";
export const USER_KEY = "@siga-user";
export const USER_ESTAB_KEY = "@siga-user_estab";
export const USER_MANUAL = "@siga-manual";
export const isAuthenticated = () => {
  //return false;
  
  try {
    if (!localStorage.hasOwnProperty(TOKEN_KEY)) {
      return false; // skip keys like "setItem", "getItem" etc
    }
    return true;
  } catch (e) {
    // declarações para manipular quaisquer exceções não especificadas
    return false;
  }
  
};
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getUser  = () => localStorage.getItem(USER_KEY);
export const getEstab = () => localStorage.getItem(USER_ESTAB_KEY);

export const login = (token:string) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const loginUser = (user:string) => {
  localStorage.setItem(USER_KEY, user);
};
export const loginUserEstab = (user_estab:string) => {
  localStorage.setItem(USER_ESTAB_KEY, user_estab);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(USER_ESTAB_KEY);
  localStorage.removeItem('Empresa');
  localStorage.removeItem('UserName');
  localStorage.removeItem('SelectedIndex')
  localStorage.removeItem('SelectedText');
  localStorage.removeItem('UserTipo');
  localStorage.removeItem('Empresa');
  localStorage.removeItem('UserName');
  localStorage.removeItem('UsaFrete');
  localStorage.removeItem('MaxVeiculo');
};