import PropTypes, { array } from 'prop-types';
import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
// import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, TextField, Typography,CardContent } from '@mui/material';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import SkeletonTotalGrowthBarChart from '../../../ui-component/cards/Skeleton/TotalGrowthBarChart';
import MainCard from '../../../ui-component/cards/MainCard';
import { gridSpacing } from '../../../store/constant';

// chart data
import chartData from './chart-data/total-growth-bar-chart';
import { darkPrimaryLight, grey200, grey500, primary200, primary800, primaryDark, primaryLight, secondary200, secondary800, secondaryDark, secondaryLight, secondaryMain, warningDark } from '../../../assets/scss/themejs';
import { ar } from 'date-fns/locale';

const status = [
    {
        value: 'today',
        label: 'Today'
    },
    {
        value: 'month',
        label: 'This Month'
    },
    {
        value: 'year',
        label: 'This Year'
    }
];
const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(210.04deg, ${warningDark} -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
        borderRadius: '50%',
        top: -30,
        right: -180
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
        background: `linear-gradient(140.9deg, ${warningDark} -14.02%, rgba(144, 202, 249, 0) 70.50%)`,
        borderRadius: '50%',
        top: -160,
        right: -130
    }
}));
// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const TotalGrowthBarChart = ({ isLoading, dados }) => {
    // const [value, setValue] = useState('today');
    const theme = useTheme();
    const [chartData, setChartData] = useState({
        height: 480,
        type: 'bar',
        options: {
            chart: {
                id: 'bar-chart',
                stacked: true,
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%'
                }
            },
            xaxis: {
                type: 'category',
                categories: [' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ']
            },
            legend: {
                show: true,
                fontSize: '14px',
                fontFamily: `'Roboto', sans-serif`,
                position: 'bottom',
                offsetX: 20,
                labels: {
                    useSeriesColors: false
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 15,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                show: true
            }
        },
        series: [
            {
                name: 'Diesel',
                data: [35, 125, 35, 35, 35, 80, 35, 20, 35, 45]
            },
            {
                name: 'Arla',
                data: [35, 15, 15, 35, 65, 40, 80, 25, 15, 85]
            },
            {
                name: 'Pedágio',
                data: [35, 15, 15, 35, 65, 40, 80, 25, 15, 85]
            },
            {
                name: 'Reparos',
                data: [35, 15, 15, 35, 65, 40, 80, 25, 15, 85]
            },
            {
                name: 'Gorjetas',
                data: [35, 15, 15, 35, 65, 40, 80, 25, 15, 85]
            },
            {
                name: 'Comissão',
                data: [35, 145, 35, 35, 20, 105, 100, 10, 65, 45]
            },
            {
                name: 'Frete',
                data: [120, 100, 175, 160, 100, 105, 100, 100, 100, 138]
            }
        ]
    });
    // const customization = useSelector((state) => state.customization);

    // const { navType } = customization;
    const { primary } = "#1e88e5";
    const TipoValor = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      const formataValor = (value) => {
        return Number(value);
      }

    useEffect(() => {
        // console.log('ultimasViagens',  dados.ultimasViagens);
        // console.log('isLoading',  isLoading);
        let numero = Math.floor(Math.random() * 101);
        let id_viagems = [];
        let placas = [];
        let comissoes = [];
        let fretes = [];
        let diesel = [];
        let arla = [];
        let pedagio = [];
        let gorjetas = [];
        let reparos = [];
        // if (ultimasViagens != null && ultimasViagens[0].valor != numero) {
        if (numero === numero) {
            dados.ultimasViagens.map(function(viagem_item) {
                // console.log(viagem_item);
                id_viagems.push(viagem_item.id_viagem_item);
                placas.push(viagem_item.placa);
                comissoes.push(formataValor(viagem_item.comissao_vl));
                fretes.push(formataValor( viagem_item.valor));
                diesel.push(formataValor(viagem_item.diesel));
                arla.push(formataValor(viagem_item.arla));
                pedagio.push(formataValor(viagem_item.pedagio));
                gorjetas.push(formataValor(viagem_item.gorjetas));
                reparos.push(formataValor(viagem_item.reparos));
            });
            setChartData({
                height: 480,
                type: 'bar',
                options: {
                    chart: {
                        id: 'bar-chart',
                        stacked: true,
                        toolbar: {
                            show: true
                        },
                        zoom: {
                            enabled: true
                        }
                    },
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                legend: {
                                    position: 'bottom',
                                    offsetX: -10,
                                    offsetY: 0
                                }
                            }
                        }
                    ],
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '50%'
                        }
                    },
                    xaxis: {
                        type: 'category',
                        categories:placas//['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    },
                    legend: {
                        show: true,
                        fontSize: '14px',
                        fontFamily: `'Roboto', sans-serif`,
                        position: 'bottom',
                        offsetX: 20,
                        labels: {
                            useSeriesColors: false
                        },
                        markers: {
                            width: 16,
                            height: 16,
                            radius: 5
                        },
                        itemMargin: {
                            horizontal: 15,
                            vertical: 8
                        }
                    },
                    fill: {
                        type: 'solid'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    grid: {
                        show: true
                    }
                },
                series: [
                    {
                        name: 'Diesel',
                        data: diesel//[35, 125, 35, 35, 35, 80, 35, 20, 35, 45]
                    },
                    {
                        name: 'Arla',
                        data: arla//[35, 15, 15, 35, 65, 40, 80, 25, 15, 85]
                    },
                    {
                        name: 'Pedágio',
                        data: pedagio// [35, 15, 15, 35, 65, 40, 80, 25, 15, 85]
                    },
                    {
                        name: 'Reparos',
                        data: reparos//[35, 15, 15, 35, 65, 40, 80, 25, 15, 85]
                    },
                    {
                        name: 'Gorjetas',
                        data: gorjetas//[35, 15, 15, 35, 65, 40, 80, 25, 15, 85]
                    },
                    {
                        name: 'Comissão',
                        data: comissoes//[35, 145, 35, 35, 20, 105, 100, 10, 65, 45]
                    },
                    {
                        name: 'Frete',
                        data: fretes//[120, 100, 175, 160, 100, 105, 100, 100, 100, 138]
                    }
                ]
            });
        }

        const newChartData = {
            ...chartData.options,
            colors: [primary200, primaryDark, secondaryMain, secondaryLight,  primary800, primaryLight,secondary200],
            xaxis: {
                labels: {
                    style: { //aqui as cores da descrição das colunas
                        colors: [primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary, primary]
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: [primary]
                    }
                }
            },
            grid: {
                borderColor: grey200
            },
            tooltip: {
                theme: 'light'
            },
            legend: {
                labels: {
                    colors: grey500
                }
            }
        };

        // do not load chart when loading
        if (!isLoading) {
            ApexCharts.exec(`bar-chart`, 'updateOptions', newChartData);
        }
    }, [ primary200, primaryDark, secondaryMain, secondaryLight, primary, darkPrimaryLight, grey200, isLoading, grey500, dados]);

    

    return (
        <>
            {isLoading ? (
                <SkeletonTotalGrowthBarChart />
            ) : (
                <MainCard border={false} content={false}>
                    <CardContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Grid container direction="column" spacing={1}>
                                            <Grid item>
                                                <Typography variant="subtitle2">Análise por Viagem</Typography>
                                            </Grid>
                                            <Grid item>
                                                {/* <Typography variant="h3">R$ 2.324,00</Typography> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {/* <TextField
                                            id="standard-select-currency"
                                            select
                                            value={value}
                                            onChange={(e) => setValue(e.target.value)}
                                        >
                                            {status.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Chart {...chartData} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </>
    );
};

TotalGrowthBarChart.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalGrowthBarChart;
