import React,{useEffect,useState,ChangeEvent} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { SelectChangeEvent } from '@mui/material/Select';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root1: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    prods: {
      display: 'flex',
        flex: 1,
        //justifyContent: 'flex-end',
        //alignItems: 'left',
        width: '100%',
    },
    removeLinha: {
      // width: '20px',
      fontSize: '12px',
      color: '#FF0000',
      // marginLeft: '3px',
      
    }
  }),
);


interface HeaderProps {
  pos:number,
  handleAdicionaEvento(evento:any):void,
  onRemoveLinha(evento:any):void,
  form:{},
  handleInputChangeEvento(event: SelectChangeEvent<HTMLInputElement>, linha:number):void,
  eventos?:AutocompleteOption[],
  count:number,
  dados:{id:number, descricao:string, chaves:string, obs:string, valor:number, position:number, qtd:number}

}
interface AutocompleteOption {
  label: string;
  id: string;
  id_user?:number;
  nome?:string;
}

const ItemManutencao: React.FC<HeaderProps> = (props) => {
  const classes = useStyles();
  
  const [erroDescricao, setErroDescricao] = useState(false);
  const [errochaves, setErrochaves] = useState(false);
  const [erroValor, setErroValor] = useState(false);
  
  const [habitidadoEdicao, setHabitidadoEdicao] = useState(false);
  const [eventoAtual, setEventoAtual] = useState({id:-1, descricao:'', chaves:'', obs:'', valor:0, position:-1, qtd:0});

  
  useEffect(() => {    
    const position = 'position';
    setEventoAtual({...props.dados, [position]:props.pos});
    // console.log(eventoAtual);
  }, [props.dados]);

  const TipoValor = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const formataValor = (value:any) => {
      try {
          var value1 = value.replace(/\D/g, "");
          value1 = value1.replace(/^0+/, '');
          value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
          value1 = 'R$ '+value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
          return value1;
      } catch(error){}
      return TipoValor.format(value);
  }

  function handleAdicionaEvento() {
          // console.log('handleAdicionaEvento', eventoAtual);//setMsgSucesso(false);
      setErroDescricao(false);
      if (eventoAtual.descricao == '') {
        setErroDescricao(true);
      } else  {
        props.handleAdicionaEvento(eventoAtual);
      }
  }
  
  function handleDeleteEvento() {
      // console.log('handleAdicionaEvento', eventoAtual);//setMsgSucesso(false);
      
        props.onRemoveLinha(eventoAtual);
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>){
      const {id, value} = event.target;
      var value1 = null;
        value1 = value;
        // console.log('mudou valor', value1);//setMsgSucesso(false);
      setEventoAtual({...eventoAtual, [id]:value1});      
      props.handleInputChangeEvento(event, eventoAtual.position);
  };

  return (    
      <ListItem style={{ padding: '0', marginBottom: '8px'}} className={classes.prods} //id={`linha_${props.pos}`} >
      >
        <div style={{flexDirection:'row', padding:'5px', width:'100%',display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
            
            <TextField required disabled={habitidadoEdicao} value={eventoAtual.descricao} onChange={handleInputChange}
                  error={erroDescricao}
                  id="descricao" 
                  style={{width: '25%',
                  fontSize: '22px',
                  paddingTop: '7px',
                  paddingBottom: '7px',
                  marginLeft:'5px', 
                  marginRight:'5px',
                  color:'black'}}
                  name="descricao" 
                  label="Descrição" />
            <TextField disabled={habitidadoEdicao} value={eventoAtual.chaves} onChange={handleInputChange} 
                error={errochaves}
                id="chaves" 
                style={{width: '25%',
                  fontSize: '22px',
                  paddingTop: '7px',
                  paddingBottom: '7px',
                  marginLeft:'5px', 
                  marginRight:'5px',
                  color:'black'}}
                name="chaves" label="TAGs" />
            <TextField disabled={habitidadoEdicao} value={eventoAtual.obs} onChange={handleInputChange}
                  id="obs" 
                  style={{width: '25%',
                  fontSize: '22px',
                  paddingTop: '7px',
                  paddingBottom: '7px',
                  marginLeft:'5px', 
                  marginRight:'5px',
                  color:'black'}}
                  name="obs" 
                  label="Observação" />
            <TextField type={'number'}  disabled={habitidadoEdicao} value={eventoAtual.qtd} onChange={handleInputChange} 
                style={{width: '10%',
                  fontSize: '22px',
                  paddingTop: '7px',
                  paddingBottom: '7px',
                  marginLeft:'5px', 
                  marginRight:'5px',
                  color:'black'}}
                  label="Quantidade" 
                id="qtd" name="qtd" />
            <TextField  disabled={habitidadoEdicao} value={formataValor( eventoAtual.valor)} onChange={handleInputChange} 
                error={erroValor}                
                style={{width: '15%',
                  fontSize: '22px',
                  paddingTop: '7px',
                  paddingBottom: '7px',
                  marginLeft:'5px', 
                  marginRight:'5px',
                  color:'black'}}
                  label="Valor" 
                id="valor" name="valor" />
            
            { (props.count == props.pos) && 
                <>
                    <Button 
                    type='button'
                    // id={`${props.pos}-qtdqff`} 
                    variant='outlined'
                    size="small" 
                    color='secondary'
                    onClick={handleDeleteEvento}
                    className={classes.removeLinha}> 
                    <span id={`qtddqff`} style={{ width: '100%', paddingLeft: '5px', paddingRight: '10px', minWidth: '90px' }}>Remover</span>
                  </Button>
                  {/* <Button 
                      type='button'
                      // id={`${props.pos}-qtdqff`} 
                      variant='outlined'
                      size="small" 
                      color='primary'
                      onClick={handleAdicionaEvento}
                      // className={classes.removeLinha}
                      > 
                      <span id={`qtddqff`} style={{ width: '100%' , paddingLeft: '5px', paddingRight: '10px', minWidth: '90px'}}>Adicionar</span>
                    </Button> */}
                </>
            }
            { (props.count != props.pos) && 
                <Button 
                  type='button'
                  // id={`${props.pos}-qtdqff`} 
                  variant='outlined'
                  size="small" 
                  color='secondary'
                  onClick={handleDeleteEvento}
                  className={classes.removeLinha}> 
                  <span id={`qtddqff`} style={{ width: '100%', paddingLeft: '5px', paddingRight: '10px', minWidth: '90px' }}>Remover</span>
                </Button>
            }
        </div>
      </ListItem>
      
  );
}

export default ItemManutencao;
