import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import MUIDataTable, { MUIDataTableCurrentData } from "mui-datatables";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import NewIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
// import AccountTree from '@material-ui/icons/AccountTree';
import ModalConfirm from "../../component/ModalConfirm";

import { BsArrowBarLeft } from "react-icons/bs";
import List from "@material-ui/core/List";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Link, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import api, { URL } from "../../services/api";
import labels from "../../component/TableLabels";
import ImageEvento from "../../component/Img";
import { Autocomplete } from "@mui/material";
// import Select from 'react-select';
import DespesaResumo from "../../component/DespesaResumo";

const baseURL = `${URL}/files/`;

interface Item {
  id_viagem: number;
  descricao: string;
  origem: string;
  destino: string;
  valor: string;
  comissao: string;
  km_total: string;
  obs_viagem: string;
  nome_motorista: string;
  placa: string;
  placa2: string;
  data_saida: Date;
  data_chegada: Date;
}
interface Evento {
  id: number;
  descricao: string;
  local: string;
  valor: number;
  litros: number;
}
interface AutocompleteOption {
  label: string;
  id: string;
  id_user?: number;
  nome?: string;
}
interface Erros {
  km_fim: boolean;
}

interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
      id: string;
    };
  };
}
const formatDate = (date: Date) => {
  var d = new Date(date);
  // console.log(d);
  try {
    let formattedDate = Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(d);

    // console.log(formattedDate);
    return formattedDate;
  } catch (error) {
    return date;
  }
};

const ViagemResumo: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  // const buttonEditRef = useRef<HTMLButtonElement>(null);
  const [showValorFrete, setShowValorFrete] = useState(true);

  const [ID, setID] = useState(Number);
  // const [visible, setVisible] = useState(Boolean);

  // const [colorEdit, setColorEdit] = useState('inherit');
  // const [textEdit, setTextEdit] = useState('EDITAR');
  const [erros, setErros] = useState<Erros>();
  const [atualiza, setAtualiza] = useState("");
  const [habitidadoEdicao, setHabitidadoEdicao] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [eventos, setEventos] = useState<AutocompleteOption[]>([]);
  const [placas, setPlacas] = useState<AutocompleteOption[]>([]);
  const [placas2, setPlacas2] = useState<AutocompleteOption[]>([]);
  const [value, setValue] = useState<AutocompleteOption>();
  const [value2, setValue2] = useState<AutocompleteOption>();
  const [inputValue, setInputValue] = React.useState("");
  const [descontos, setDescontos] = useState([{}, {}, {}, {}, {}]);
  const [inputValue2, setInputValue2] = React.useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  var titleConfirm = "Viagem Criada com Sucesso!";
  var descricaoConfirm = "Fazer Acerto?";
  const [viagemId, setViagemId] = useState(0);
  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  var data30 = new Date();
  data30.setDate(data30.getDate());

  const [formData, setFormData] = useState({
    id_estabelecimento: 0,
    id_viagem: "",
    codigo: 0,
    mercadoria: "",
    descricao: "",
    origem: "",
    destino: "",
    valor: "R$ 0,00",
    comissao: 0,
    id_motorista: 0,
    obs_viagem: "",
    nome_motorista: "",
    km_inicio: "",
    km_fim: "",
    km_total: 0,
    lt_viagem: 0,
    km_lt: "0",
    placa: "",
    placa2: "",
    soma: 0,
    peso: 0,
    comissao_vl: 0,
    data_saida: materialDateInput,
    data_chegada: materialDateInput,
    eventos: [{ id: -1, descricao: "", local: "", valor: 0, litros: 0 }],
  });

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      //verifica se usa frete
      if (localStorage.getItem("UsaFrete") != "S") {
        setShowValorFrete(false);
      }
      var idPar: number = Number(props.match?.params.id);
      // console.log('id', idPar)
      setErros({ km_fim: false });
      setID(idPar);
      handleBuscaDadosEventos();
      handleBuscaDados(idPar);
    }
  }, []);

  async function handleBuscaDadosEventos() {
    await api
      .get(`evento/desconta`)
      .then((response) => {
        // console.log('retorno',response.data);
        let dados: Array<AutocompleteOption> = [];
        var arrayDados = response.data as Array<AutocompleteOption>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx: any) => {
          // console.log('item222',itemx.placa);
          const ev1 = { label: itemx.descricao, id: itemx.id_evento };
          dados.push(ev1);
        });
        setEventos(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
      });
  }
  const columns = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <Select
              labelId="demo-simple-select-label"
              required
              id="tipo"
              className={classes.select}
              // value={formData.eventos[0].id}
              // label="Tipo de Usuário"
              label=""
              placeholder="Tipo de Usuário"
              // style={{color:'red'}}
              // required
              // onChange={handleInputChange}
            >
              {eventos.map((text, index) => (
                <MenuItem key={index} value={index}>
                  {text.label}
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
        //display: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <TextField
              required
              disabled={habitidadoEdicao} //value={formData.eventos[0].descricao} onChange={handleInputChange}
              className={classes.field}
              id="destino"
              name="destino"
            />
          );
        },
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        sort: false,
        //display: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <TextField
              required
              disabled={habitidadoEdicao} //value={formData.eventos[0].local} onChange={handleInputChange}
              className={classes.field}
              id="local"
              name="local"
            />
          );
        },
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <TextField
              type={"number"}
              required
              disabled={habitidadoEdicao} //value={formData.eventos[0].valor} onChange={handleInputChange}
              className={classes.field}
              id="valor"
              name="valor"
            />
          );
        },
      },
    },
  ];

  var selectRow: number = -1;
  var SelectableRows: SelectableRows = "none";
  var options: MUIDataTableOptions = {
    onTableChange: (action, tableState) => {
      // console.log('1');
      // this.xhrRequest('my.api.com/tableData', result => {
      //   this.setState({ data: result });
      // });
    },
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      let y1 = rowsSelected as number[];
      selectRow = -1;
      setID(-1);
      // setVisible(true);
      // console.log('1');
      y1.map((numero: number) => {
        selectRow = numero;
        // setVisible(false);
        setID(numero);
        // console.log('numero---',numero);
      });
    },

    onRowClick: (rowData) => {
      // navigate('edit/form')
      // caminhoImagem.current = baseURL+evento.foto;
      // setSourceImage(rowData)
      // setShowImage(true);
      // console.log('click numero---data----', rowData);
      // console.log('click numero---', rowData[0]);
      // console.log('click numero---', rowData[1]);
      // console.log('click numero---', rowData[2]);
      // console.log('click numero---', rowData[3]);
      // console.log('click numero---', rowData[4]);
      // console.log('click numero---', rowData[5]);
    },

    selectableRows: SelectableRows,
    textLabels: labels,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
  };

  const formRef = useRef<FormHandles>(null);
  const Grid = useRef<MUIDataTableCurrentData>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);

  async function handleBuscaDados(idPar: Number) {
    await api
      .get(`veiculos/C','G`)
      .then((response) => {
        // console.log('retorno',response.data);
        let dados: Array<AutocompleteOption> = [];
        var arrayDados = response.data as Array<AutocompleteOption>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx: any) => {
          // console.log('item222',itemx);
          if (itemx.status == "Ativo") {
            const placa1 = {
              label: itemx.placa,
              id: itemx.id_veiculo,
              id_user: itemx.id_usr,
              nome: itemx.nome,
            };
            dados.push(placa1);
          }
        });
        setPlacas(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
        handleBuscaPlaca2();
      });
  }
  async function handleBuscaPlaca2() {
    await api
      .get(`veiculos/R`)
      .then((response) => {
        // console.log('retorno',response.data);
        let dados: Array<AutocompleteOption> = [];
        var arrayDados = response.data as Array<AutocompleteOption>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx: any) => {
          // console.log('item222',itemx);
          // if (itemx.status == 'Ativo') {
          const placa1 = {
            label: itemx.placa,
            id: itemx.id_veiculo,
            id_user: itemx.id_usr,
            nome: itemx.nome,
          };
          dados.push(placa1);
          // }
        });
        setPlacas2(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
      });
  }
  const TipoValor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    return TipoValor.format(value);
  };

  async function handleSubmit(event: FormEvent) {
    formData.placa = String(value?.label);
    formData.placa2 = String(value2?.label);

    // console.log(formData);
    // console.log('ID', ID);
    if (window.confirm("Criar nova Viagem?")) {
      // if (ID > 0 ) {
      // console.log(formData);
      await api.post(`viagemwebresumo`, formData).then(function (response) {
        //alert(response.data.result);
        // console.log('retorn',response.data);
        if (response.data.sucess) {
          setViagemId(response.data.id_viagem);
          setShowConfirm(true);
          // alert('Viagem Criada com Sucesso!');
          // navigate("/lancamentos");
        } else {
          console.log(response.data);
        }
      });
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    setErros({ ...erros, ["km_fim"]: false });
    const { id, value } = event.target;
    if (id == "valor" || id == "soma") {
      var value1 = value.replace(/\D/g, "");
      value1 = value1.replace(/^0+/, "");
      value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
      value1 = "R$ " + value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
      // console.log('mudou valor', value1);//setMsgSucesso(false);
      if (value.length < 1) {
        value1 = "R$ 0,00";
      }
      setFormData({ ...formData, [String(id)]: value1 });
    } else {
      if (id == "comissao") {
        var taxa = value.replace(/\D/g, "");
        var frete = formData.valor.replace(/\D/g, "");
        frete = frete.replace(/(\d)(\d{2})$/, "$1.$2");

        const comissao_vl = Number(frete) * (Number(taxa) / 100);
        // console.log('id campo',id);
        // console.log('comissao',comissao_vl);
        setFormData({
          ...formData,
          [String(id)]: taxa,
          ["comissao_vl"]: comissao_vl,
        });
        // setFormData({...formData, [});
      } else {
        if (id == "km_fim") {
          // console.log('inicio, fim',formData.km_inicio, value );

          const km_total = Number(value) - Number(formData.km_inicio);
          if (km_total < 0) {
            setErros({ ...erros, ["km_fim"]: true });
          }
          setFormData({ ...formData, [id]: value, km_total });
        } else {
          setFormData({ ...formData, [id]: value });
        }
      }
    }
  }

  function handleInputChangeEvento(
    event: ChangeEvent<HTMLInputElement>,
    linha: number
  ) {
    const { id, value, name } = event.target;

    var campo = id;
    var dados = formData;
    if (id === null || id == undefined) {
      campo = name;
    }
    // console.log('campo', campo);//setMsgSucesso(false);

    var litros = 0;
    var kmlitro = "0";
    var eventos1: Evento[] = [];
    var value1 = value;
    dados.eventos.map((value1: Evento, index) => {
      // console.log('value1', value1);//setMsgSucesso(false);

      if (index == linha) {
        value1 = { ...value1, [campo]: value };
      }
      litros = Number(litros) + Number(value1.litros);
      // console.log('mudou value1.litros', value1.litros);//setMsgSucesso(false);
      // console.log('mudou litros', litros);//setMsgSucesso(false);

      eventos1.push(value1);
    });
    if (litros > 0 && formData.km_total > 0) {
      kmlitro = Number(formData.km_total / litros).toFixed(2);
    }
    setFormData({
      ...formData,
      ["lt_viagem"]: litros,
      ["km_lt"]: kmlitro,
      ["eventos"]: eventos1,
    });
  }

  function handleAdicionaEvento(evento: any) {
    // console.log(evento);
    var form1 = formData;
    form1.eventos.push({
      id: -1,
      descricao: "",
      local: "",
      valor: 0,
      litros: 0,
    });
    // form1.eventos.push(evento);

    // const litros= Number(formData.lt_viagem + Number(evento.litros));
    // // const km_total = formData.km_total;
    // form1.lt_viagem = litros;

    // setFormData({...formData, ['lt_viagem']:litros});
    setFormData(form1);
    setAtualiza(atualiza + 1);
  }

  function handleRemoveEvento(evento: any) {
    // console.log(evento);
    var form1 = formData;
    form1.eventos.push({
      id: -1,
      descricao: "",
      local: "",
      valor: 0,
      litros: 0,
    });
    // form1.eventos.push(evento);

    // const litros= Number(formData.lt_viagem + Number(evento.litros));
    // // const km_total = formData.km_total;
    // form1.lt_viagem = litros;

    // setFormData({...formData, ['lt_viagem']:litros});
    setFormData(form1);
    setAtualiza(atualiza + 1);
  }

  useEffect(() => {
    calculaComissao();
  }, [formData.valor]);

  async function calculaComissao() {
    var taxa = formData.comissao; //.replace(/\D/g, "");
    var frete = formData.valor.replace(/\D/g, "");
    frete = frete.replace(/(\d)(\d{2})$/, "$1.$2");

    const comissao_vl = Number(frete) * (Number(taxa) / 100);
    setFormData({ ...formData, comissao_vl: comissao_vl });
  }

  function NVL(value1: any, value2: any) {
    if (value1 == null) return value2;

    return value1;
  }

  const navigateAcerto = () => {
    navigate(`/viagemacerto/${viagemId}`);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <div>
        <Link
          ref={linkRef}
          to={`/viagens`}
          style={{ display: "flex", fontSize: "17px" }}
        >
          <BsArrowBarLeft style={{ height: "23px", width: "36px" }} />
          Voltar
        </Link>
      </div>
      <div className={classes.root}>
        <Container className={classes.formdados}>
          <Form
            className={classes.dadosViavem}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <fieldset className={classes.titulo}>
              <h2 className={classes.tituloh2}>Nova Viagem Resumida</h2>
            </fieldset>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                style={{ width: "15%", display: "none" }}
                disabled
                value={formData.id_viagem}
                onChange={handleInputChange}
                className={classes.field}
                id="id_viagem"
                name="id_viagem"
                label="ID"
              />
              <TextField
                style={{ width: "15%", marginRight: 13 }}
                disabled={habitidadoEdicao}
                type="number"
                value={formData.codigo}
                onChange={handleInputChange}
                className={classes.field}
                id="codigo"
                name="codigo"
                label="Código"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.descricao}
                onChange={handleInputChange}
                className={classes.field}
                id="descricao"
                name="descricao"
                label="Descrição"
              />
              <TextField
                required={showValorFrete}
                disabled={habitidadoEdicao}
                value={formData.mercadoria}
                onChange={handleInputChange}
                className={classes.field}
                id="mercadoria"
                name="mercadoria"
                label="Mercadoria"
              />
              <TextField
                required={showValorFrete}
                disabled={habitidadoEdicao}
                value={formData.origem}
                onChange={handleInputChange}
                className={classes.field}
                id="origem"
                name="origem"
                label="Origem"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.destino}
                onChange={handleInputChange}
                className={classes.field}
                id="destino"
                name="destino"
                label="Destino"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  if (newValue?.nome) {
                    setFormData({
                      ...formData,
                      ["id_motorista"]: Number(newValue?.id_user),
                      ["nome_motorista"]: newValue?.nome,
                    });
                  }
                  setValue(newValue as AutocompleteOption);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="placa"
                options={placas}
                disabled={false}
                className={classes.field}
                renderInput={(params) => (
                  <TextField {...params} required={true} label="Placa" />
                )}
              />
              <Autocomplete
                value={value2}
                onChange={(event, newValue) => {
                  setValue2(newValue as AutocompleteOption);
                }}
                inputValue={inputValue2}
                onInputChange={(event, newInputValue) => {
                  setInputValue2(newInputValue);
                }}
                id="placa2"
                options={placas2}
                disabled={false}
                className={classes.field}
                renderInput={(params) => (
                  <TextField {...params} label="Placa 2" />
                )}
              />
              {/* <TextField disabled value={formData.placa2} onChange={handleInputChange} className={classes.field} id="placa2" name="placa2" label="Placa 2" /> */}
              <TextField
                required
                disabled
                value={formData.nome_motorista}
                onChange={handleInputChange}
                className={classes.field}
                id="nome_motorista"
                name="nome_motorista"
                label="Motorista"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.km_inicio}
                onChange={handleInputChange}
                className={classes.field}
                id="km_inicio"
                name="km_inicio"
                label="Km Inical"
              />
              <TextField
                error={erros?.km_fim}
                required
                disabled={habitidadoEdicao}
                value={formData.km_fim}
                onChange={handleInputChange}
                className={classes.field}
                id="km_fim"
                name="km_fim"
                label="Km Final"
              />
              <TextField
                required
                disabled
                value={formData.km_total}
                onChange={handleInputChange}
                className={classes.field}
                id="km_total"
                name="km_total"
                label="Km Percorrido"
              />
              <TextField
                required
                disabled
                value={formData.lt_viagem}
                onChange={handleInputChange}
                className={classes.field}
                id="litros"
                name="litros"
                label="Combustível Litros"
              />
              <TextField
                required
                disabled
                value={formData.km_lt}
                onChange={handleInputChange}
                className={classes.field}
                id="km_litro"
                name="km_litro"
                label="Km/L"
              />

              {/* <TextField disabled={habitidadoEdicao} value={formatDate(formData.data_chegada)} onChange={handleInputChange} className={classes.field} id="data_chegada" name="data_chegada" label="Data de Chegada" /> */}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              {/* <TextField required disabled={habitidadoEdicao} value={TipoValor.format(formData.valor)} onChange={handleInputChange} className={classes.field} id="valor" name="valor" label="Valor de Frete" /> */}
              <TextField
                type="date"
                disabled={habitidadoEdicao}
                value={formData.data_saida}
                // value={formatDate(formData.data_saida)}
                onChange={handleInputChange}
                className={classes.field}
                id="data_saida"
                name="data_saida"
                label="Data de Saída"
              />
              <TextField
                type="date"
                disabled={habitidadoEdicao}
                value={formData.data_chegada}
                // value={formatDate(formData.data_saida)}
                onChange={handleInputChange}
                className={classes.field}
                id="data_chegada"
                name="data_chegada"
                label="Data de Chegada"
              />
              {showValorFrete && (
                <>
                  <TextField
                    required
                    disabled={habitidadoEdicao}
                    value={formData.valor}
                    onChange={handleInputChange}
                    className={classes.field}
                    id="valor"
                    name="valor"
                    label="Valor de Frete"
                  />
                  <TextField
                    required
                    disabled={habitidadoEdicao}
                    value={formData.comissao}
                    onChange={handleInputChange}
                    className={classes.field}
                    id="comissao"
                    name="comissao"
                    label="Comissão em %"
                  />
                  <TextField
                    required
                    disabled
                    value={TipoValor.format(formData.comissao_vl)}
                    onChange={handleInputChange}
                    className={classes.field}
                    id="comissao_vl"
                    name="comissao_vl"
                    label="Comissão em R$"
                  />
                </>
              )}
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.soma}
                onChange={handleInputChange}
                className={classes.field}
                id="soma"
                name="soma"
                label="Adi. (+)"
              />
              {/* <TextField required disabled={habitidadoEdicao} value={TipoValor.format(formData.total)} onChange={handleInputChange} className={classes.field} id="total" name="total" label="Total" /> */}
            </div>
            <TextField
              value={formData.obs_viagem}
              onChange={handleInputChange}
              className={classes.field}
              id="obs_viagem"
              name="obs_viagem"
              label="Obs."
            />
            {/* <div style={{width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                      <Button
                          type="submit"
                          size="small" 
                          variant="contained"
                          color="primary"
                          style={{width: '35%'}}>
                              Criar Viagem
                      </Button>
                  </div> */}
            <div style={{ width: "99%" }}>
              <fieldset className={classes.titulo}>
                <h2 className={classes.tituloh2}>Despesas</h2>
              </fieldset>
            </div>
            <div className={classes.tabelas} id={atualiza}>
              <List style={{ padding: "0", marginTop: "14px" }}>
                {formData.eventos.map((value, index) => {
                  // console.log(`eventos`, index);
                  return (
                    <DespesaResumo
                      key={index}
                      pos={index}
                      eventos={eventos}
                      form={formData}
                      count={formData.eventos.length - 1}
                      handleInputChangeEvento={handleInputChangeEvento}
                      handleAdicionaEvento={handleAdicionaEvento}
                    />
                  );
                })}
              </List>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                justifyContent: "space-around",
              }}
            >
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                // onClick={handleSubmit}
                style={{ width: "35%" }}
              >
                Criar Viagem
              </Button>
            </div>
          </Form>
        </Container>
      </div>
      <ImageEvento
        show={showImage}
        source={sourceImage}
        onReturn={() => {
          setShowImage(false);
          setSourceImage("");
        }}
      />
      <ModalConfirm
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirm(false);
            navigate(`/lancamentos`);
          }
        }}
      />
    </div>
  );
};
export default ViagemResumo;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex",
      alignItems: "flex-center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      // paddingBottom: '8px',
    },
    tabela: {
      // width: '50%',
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "8px",
    },
    dadosViavem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonExtra: {
      margin: theme.spacing(1),
      backgroundColor: "green",
    },
    button: {
      margin: theme.spacing(1),
    },
    titulo: {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderBottom: "2px solid rgb(0 0 0 / 20%)",
      width: "100%",
      marginBottom: "5px",
    },
    tituloh2: {
      textAlign: "center",
      color: "#444445",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
    },
    select: {
      width: "90%",
      fontSize: "18px",
      // paddingTop: '7px',
      // paddingBottom: '7px',
      // marginLeft:'5px',
      // marginRight:'5px',
      color: "black",
    },
    fieldID: {
      display: "none",
    },
    buttonHide: {
      display: "none",
    },
    formdados: {
      width: "100%",
      maxWidth: "10280px",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      paddingBottom: "8px",
      //display: 'flex',
      flexDirection: "row",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
    },
  })
);
// codigo: 1
// comissao: 3610
// created_at: null
// data_chegada: "2022-02-22T03:00:00.000Z"
// data_concluiu: null
// data_finalizou: "2022-02-24T16:45:18.000Z"
// data_saida: "2022-02-09T03:00:00.000Z"
// desconta: "0"
// descricao: "Tetsr"
// destino: "SP "
// diesel_LT: null
// diesel_RS: null
// id_estabelecimento: 1
// id_motorista: null
// id_user_config: 5
// id_veiculo_1: 7
// id_veiculo_2: 1
// id_viagem: 5
// km_fim: "558"
// km_inicio: "542315"
// km_medio: "0"
// km_total: -541757
// mercadoria: "Cebola "
// obs: " "
// origem: "Petro "
// peso: "0.00"
// placa: "WKI-93D9"
// saldo: 19000
// soma: "0"
// st_ativo: "F"
// tipo_acerto: "B"
// total: 15390
// updated_at: null
// usu_concluiu: null
// usu_finalizou: 5
// valor: "19000.00"
