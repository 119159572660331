import React, { useEffect, useRef, ChangeEvent, useState, FormEvent } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
//import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {useNavigate} from 'react-router-dom';
// import base64 from 'base-64';
import api from '../../services/api';

// import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Email } from '@material-ui/icons';
// import { login, loginUser, loginUserEstab } from "../../services/auth";

interface RouterProps {
  history?: {};
  location?: {};
  match?: {params: {
    id: string}};    
}; 

const Login: React.FC<RouterProps> = (props:RouterProps) => {
    let navigate   = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const classes = useStyles();
    const [formData, setFormData] = useState({
      email:''
    });

    const formRef = useRef<FormHandles>(null);
    
    useEffect(() => {
          
    }, []);    
    
    async function handleSubmitRecuperar(event: FormEvent) {
      setLoading(true);
      // const v2 = base64.encode('teste');
      const formEnvio = {email:formData.email}
      const response  = await api.post("/recuperar", formEnvio);
      setLoading(false);

      if (response.data.sucess) {
        alert(response.data.msg);
        window.location.href = '/login';
        // navigate(`/login`);
      } else {
        // console.log(response.data);
        alert(response.data.error);
      }
    };
    async function handleSubmit(event: FormEvent) {

      // const v2 = base64.encode('teste');
      // const encText = base64.encode(formData.password);
      const formEnvio = {email:formData.email}
      alert(formEnvio);
      const response = await api.post("/recuperar", formEnvio);
      // console.log(response.data);
      alert(response.data);
      // if (response.data.sucess) {
      //   console.log('response.data.token', response.data);
      // //   login(response.data.token);
      // //   loginUser(response.data.id_user);
      // //   localStorage.setItem('UserTipo', response.data.tipo_user);
      // //   localStorage.setItem('UserName', response.data.nome);
      // //   if (response.data.estabs.length > 1) {
      // //     alert("Selecione o Estabelecimento Desejado");
      // //     navigate(`/estabelecimento`);
      // //   } else {
      // //     loginUserEstab(response.data.estabs[0].id_estabelecimento);
      // //     localStorage.setItem('Empresa', response.data.estabs[0].nome);
      // //     localStorage.setItem('UsaFrete', response.data.estabs[0].usa_frete);
      // //     localStorage.setItem('MaxVeiculo', response.data.estabs[0].qtd_veiculos);
      // //   }
      // // window.location.href = '/';
      // // navigate(`/`);
      // } else {
      //   // console.log(response.data);
      //   alert(response.data.error);
      // }
    };
    function handleInputChange(event: ChangeEvent<HTMLInputElement>){
        const {id, value} = event.target;        
        setFormData({...formData, [id]:value});
    };
    
    return (
        <>
        {/* {isLoading ? (
            <SkeletonEarningCard />
          ) : ( */}
              <Container maxWidth="sm" className={classes.paper}>
                  <Form className={classes.root} ref={formRef} onSubmit={handleSubmitRecuperar}>
                      <fieldset className={classes.titulo}>
                        <h2 className={classes.tituloh2}>Recuperar Senha</h2>
                      </fieldset>
                      
                      <TextField required 
                        type="email"
                        placeholder="E-mail"
                        onChange={handleInputChange} 
                        className={classes.field} 
                        id="email" 
                        name="email" 
                        label="E-mail" />
                      
                      <LoadingButton
                          loading={isLoading}
                          // startIcon={<SaveIcon />}

                          // loadingIndicator="Recuperando Senha..." 
                          className={classes.buttons} 
                          // type="submit"
                          onClick={handleSubmitRecuperar}
                          size="small" 
                          variant="contained"
                          color="primary"
                          // loading={false}
                          loadingPosition="start"
                          startIcon={<Email />}
                          // variant="outlined"
                          >
                              Recuperar
                      </LoadingButton>
                      
{/* 
            <Stack direction="row" spacing={2}>
              <LoadingButton loading variant="outlined">
                Submit
              </LoadingButton>
              <LoadingButton loading loadingIndicator="Loading…" variant="outlined">
                Fetch data
              </LoadingButton>
              <LoadingButton
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined"
              >
                Save
              </LoadingButton>
            </Stack> */}
 

                      {/* <a href="/login">Retornar ao Login</a>        */}

                  </Form>
              </Container>
          {/* ) */}
        {/* } */}
      </>
    )
}

export default Login;

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
          margin: theme.spacing(1),
          //width: '25ch',
        },
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    buttons: {
      height:'55px',
      display: 'flex',
      width: '70%',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
      alignItems: 'center',
    },
    titulo: {
        borderTop:'none',
        borderRight:'none',
        borderLeft:'none',
        borderBottom: '2px solid #e5e5e6',
        width: '70%',
    },
    tituloh2: {
        textAlign: 'center',
        color: '#444445',
    },
    field:{
        width: '70%',
        height:'75px',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
        backgroundColor: '#Fff',
        border: '1px solid #e5e5e6',
        borderRadius: '8px',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
      
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    
    formControl: {
      margin: theme.spacing(1),
      minWidth: '70%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));