import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import NoteAdd from "@material-ui/icons/NoteAdd";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import BallotIcon from "@material-ui/icons/Ballot";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import Construction from "@mui/icons-material/Construction";
import Category from "@material-ui/icons/Category";
// import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Info from "@material-ui/icons/Info";
import GroupAdd from "@material-ui/icons/GroupAdd";
import DirectionsCar from "@material-ui/icons/DirectionsCar";

import AppBarTop from "./component/AppBarTop";
import Rotas from "./routes";
import { isAuthenticated } from "./services/auth";
// import pattern from "./assets/images/pattern4k.png"; // ' /assets/images/logo.svg';

//import DrawerLeft from './component/DrawerLeft';

const drawerWidth = 240;

export default function ClippedDrawer() {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedText, setSelectedText] = React.useState("Início");

  const [open, setOpen] = React.useState(false);
  const [mobile, setMobile] = React.useState(false);

  const handleDrawerOpenClose = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    text: string
  ) => {
    localStorage.setItem("SelectedIndex", index.toString());
    localStorage.setItem("SelectedText", text);

    // setSelectedIndex(index);
    // setSelectedText(text);
  };
  // const array = ['Viagens','Lançamentos', 'Usuários' ];//,'Marca','Mercadoria', 'Estabelecimentos', 'Transferência'];
  const [array, setArray] = useState([
    "Início",
    "Viagens",
    "Lançamentos",
    "Usuários",
    "Veículos",
    "Manutenções",
    "Relatórios",
    "Eventos",
  ]);
  // const array = ['Viagens','Lançamentos', 'Usuários','Veículos'];
  const [arrayRoutes, setArrayRoutes] = useState([
    "dashboard",
    "viagens",
    "lancamentos",
    "usuarios",
    "veiculos",
    "manutencoes",
    "relatorios",
    "eventos",
  ]);
  // const arrayRoutes = ['viagens','lancamentos','usuarios','veiculos'];
  useEffect(() => {
    // console.log('UserTipo',localStorage.getItem('UserTipo'));
    if (localStorage.getItem("SelectedIndex")) {
      setSelectedIndex(Number(localStorage.getItem("SelectedIndex")));
      setSelectedText(String(localStorage.getItem("SelectedText")));
    }

    localStorage.setItem("mobile", "false");

    //handleDrawerClose();
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      localStorage.setItem("mobile", "true");

      setMobile(true);
      // setArray(['Início','Lançamentos'])
      // setArrayRoutes(['dashboard','lancamentos'])
    } else {
      setMobile(false);
    }
    // se for motorista tira as opções
    if (localStorage.getItem("UserTipo") == "M") {
      setArray(["Início", "Lançamentos"]);
      setArrayRoutes(["dashboard", "lancamentos"]);
      if (localStorage.getItem("ManutApp") == "S") {
        setArray(["Início", "Lançamentos", "Manutenções"]);
        setArrayRoutes(["dashboard", "lancamentos", "manutencoes"]);
      }
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      // header
      <AppBarTop
        title={selectedText}
        handleDrawerOpenClose={handleDrawerOpenClose}
        open={open}
        logado={isAuthenticated()}
      />
      {/* {!mobile && */}
      {localStorage.getItem("UserTipo") != null && (
        <Drawer
          //className={classes.drawer}
          variant="permanent"
          //classes={{           paper: classes.drawerPaper,        }}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [mobile ? classes.drawerCloseMobile : classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [mobile ? classes.drawerCloseMobile : classes.drawerClose]: !open,
            }),
          }}
        >
          <Toolbar />
          {localStorage.getItem("UserTipo") == "M" ? (
            <List>
              {array.map((text, index) => (
                <a href={`/${arrayRoutes[index]}`}>
                  <ListItem
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, index, text)}
                    button
                    key={index}
                  >
                    <ListItemIcon title={text}>
                      {index === 0 ? <HomeIcon /> : <span />}
                      {index === 1 ? <NoteAdd /> : <span />}

                      {index === 2 &&
                      localStorage.getItem("ManutApp") == "S" ? (
                        <Construction />
                      ) : (
                        <span />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                </a>
              ))}
            </List>
          ) : (
            <List>
              {array.map((text, index) => (
                <a href={`/${arrayRoutes[index]}`}>
                  <ListItem
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, index, text)}
                    button
                    key={index}
                  >
                    <ListItemIcon title={text}>
                      {index === 0 ? <HomeIcon /> : <span />}

                      {index === 1 ? <LocalShippingIcon /> : <span />}
                      {index === 2 ? <NoteAdd /> : <span />}

                      {index === 3 ? <GroupAdd /> : <span />}

                      {/* {index === 3 ? <RecentActors /> : <span />} */}

                      {index === 4 ? <DirectionsCar /> : <span />}
                      {index === 5 ? <Construction /> : <span />}

                      {index === 6 ? <AssessmentIcon /> : <span />}

                      {index === 7 ? <Category /> : <span />}
                      {index === 8 ? <HomeIcon /> : <span />}
                      {index === 8 ? <BallotIcon /> : <span />}
                      {index === 9 ? <HomeIcon /> : <span />}
                      {index === 10 ? <LocalActivityIcon /> : <span />}
                      {index === 11 ? <CompareArrowsIcon /> : <span />}
                      {index === 12 ? <Construction /> : <span />}
                      {/*index === 13 ? <AssessmentIcon /> : <span />}
                          {index === 13 ? <ShoppingCartIcon /> : <span />} */}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                </a>
              ))}
            </List>
          )}

          <Divider />
          <List>
            <a href={`http://manual.siga.tec.br`} target="_blank">
              <ListItem
                //selected={selectedIndex === index}
                //onClick={(event) => handleListItemClick(event, index, text)}
                button
                key={1}
              >
                <ListItemIcon title={"Manual"}>
                  <Info />
                </ListItemIcon>
                <ListItemText primary={"Manual"} />
              </ListItem>
            </a>
          </List>
        </Drawer>
      )}
      {/* } */}
      <main className={classes.content}>
        <Rotas />
      </main>
    </div>
    //</BrowserRouter>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) - 10,
    },
  },
  drawerCloseMobile: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) - 10,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    padding: theme.spacing(1),
    marginLeft: -40,
    marginTop: 45,
    marginRight: 10,
  },
}));
/**
 * 
 
 
 * 
 * 
 */
