import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate, useParams } from "react-router-dom";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FormHandles } from "@unform/core";
import { darkBackground } from "../../assets/scss/themejs";
import Button from "@material-ui/core/Button";
// import NoteAdd from "@material-ui/icons/NoteAdd";

import Container from "@material-ui/core/Container";
import { Autocomplete } from "@mui/material";
// import { SelectChangeEvent } from "@mui/material/Select";
import "bootstrap/dist/css/bootstrap.min.css";
import api, { URL } from "../../services/api";
import labels from "../../component/TableLabels";
import ModalConfirm from "../../component/ModalConfirm";
import { Form } from "@unform/web";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@material-ui/core/TextField";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUp from "@mui/icons-material/ArrowCircleUp";
// import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

// import Modal from 'react-bootstrap/Modal';
import ImageEvento from "../../component/Img";

const baseURL = `${URL}/files/`;

interface Item {
  id_viagem: number;
  cd_viagem: number;
  comissao: number;
  comissao_viagem: number;
  desconta: number;
  descricao_viagem: string;
  destino_viagem: string;
  peso: number;
  id_estabelecimento: number;
  id_motorista: number;
  id_veiculo_1: number;
  id_veiculo_2: number;
  km_medio_viagem: number;
  mercadoria_viagem: string;
  nome_motorista: string;
  obs_viagem: string;
  origem_viagem: string;
  placa_veiculo: string;
  saldo: number;
  soma: number;
  st_ativo: string;
  total: number;
  valor: number;
}
interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
    };
  };
}
interface AutocompleteOptionPlaca {
  label: string;
  id: string;
}
interface AutocompleteOptionMotorista {
  label: string;
  id: string;
  id_user?: number;
  nome?: string;
}
interface AutocompleteOptionStatus {
  label: string;
  id: string;
}

const Viagem: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();

  const buttonEditRef = useRef<HTMLButtonElement>(null);
  const divGrid = useRef<HTMLDivElement>(null);
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");

  const [ID, setID] = useState(Number);
  // const data_chegada = useState(Date);
  // const km_final     = useState(Number);
  const [isLoading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);

  const [itens, setItens] = useState([]);
  const [itensManutencao, setItensManutencao] = useState([]);
  const marcado = useRef(0);
  var titleConfirm = "Viagem Finalizada com Sucesso!";
  var descricaoConfirm = "Fazer Acerto?";
  var titleConfirmEdit = "Editar Despesa";
  var descricaoConfirmEdit = "Editar Despesa?";
  const [descontos, setDescontos] = useState([]);
  const listaItens = useRef(Array<Item>());
  const [refresh, doRefresh] = useState(0);
  const [IDDespesa, setIDDespesa] = useState(Number);
  const [IDManutencao, setIDManutencao] = useState(Number);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const [valueStatus, setValueStatus] = useState<AutocompleteOptionStatus[]>([
    { id: "T", label: "Todos" },
  ]);
  const [statusLista, setStatusLista] = useState<AutocompleteOptionMotorista[]>(
    [
      { id: "T", label: "Todos" },
      { id: "A", label: "Em Andamento" },
      { id: "F", label: "Finalizada" },
      { id: "C", label: "Concluída" },
    ]
  );

  const [valueMotorista, setValueMotorista] =
    useState<AutocompleteOptionMotorista>();
  const [motoristas, setMotoristas] = useState<AutocompleteOptionMotorista[]>(
    []
  );
  const [placas, setPlacas] = useState<AutocompleteOptionPlaca[]>([]);
  const [placas2, setPlacas2] = useState<AutocompleteOptionPlaca[]>([]);
  const [value, setValue] = useState<AutocompleteOptionPlaca>();
  const [value2, setValue2] = useState<AutocompleteOptionPlaca>();
  const [inputValue, setInputValue] = React.useState("");
  const [inputValue2, setInputValue2] = React.useState("");
  // const [inputValueMotorista, setInputValueMotorista] = React.useState("");
  // const [inputValueStatus, setInputValueStatus] = React.useState("");
  const [ignored, newState] = useState(0);
  const [openViagens, setOpenViagens] = useState(0);
  const [openManutencao, setOpenManutencao] = useState(0);
  var IDVeiculo = 0;
  // const target = {} as NavigateOptions;

  var data30 = new Date();
  data30.setDate(data30.getDate() - 60);

  const [formData, setFormData] = useState({
    id_estabelecimento: 0,
    id_viagem: "",
    placa: "",
    placa2: "",
    status: "T",
    data_saida: null,
    id_motorista: 0,
    data_chegada: null,
    grupStatus: "",
  });

  const [formDataViagens, setFormDataViagens] = useState({
    qtd_viagens: 0,
    total_frete: 0,
    total_adi: 0,
    total_desc: 0,
    total_saldo: 0,
    total_comissao: 0,
    total_geral: 0,
  });

  const [formDataManutencoes, setFormDataManutencoes] = useState({
    qtd_manutencoes: 0,
    total_geral: 0,
  });

  const formRef = useRef<FormHandles>(null);

  const navigateAcerto = () => {
    let selecionado = itens[ID] as Item;
    navigate(`/viagemacerto/${selecionado.id_viagem}`);
  };

  const handleClose = async () => {
    setShow(false);
    let selecionado = itens[ID] as Item;
    await api
      .post(`finalizaViagem/${selecionado.id_viagem}/F`, formData)
      .then(function (response) {
        if (response.data.sucess) {
          setShowConfirm(true);
        } else {
          // console.log(response.data);
        }
      });
  };

  useEffect(() => {
    // var idVeic: number = Number(params.id);
    // if (idVeic > 0) {
    //   IDVeiculo = idVeic;
    // }
    // console.log("idVeic", idVeic);
    // setID(idPar);
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      handleBuscaDadosPlaca(0);
    }
  }, []);

  async function handleBuscaDadosPlaca(idPar: Number) {
    await api
      .get(`veiculos`)
      .then((response) => {
        // console.log("response.data", response.data);

        let dados: Array<AutocompleteOptionPlaca> = [];
        var arrayDados = response.data as Array<AutocompleteOptionPlaca>;
        arrayDados.map((itemx: any) => {
          const placa1 = {
            label: itemx.placa,
            id: itemx.id_veiculo,
          };
          dados.push(placa1);
        });
        setPlacas(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
        // setValue({label:'', ['id']:idPar});
        handleBuscaPlaca2();
      });
  }
  async function handleBuscaPlaca2() {
    await api
      .get(`veiculos/R`)
      .then((response) => {
        // console.log('retorno',response.data);
        let dados: Array<AutocompleteOptionPlaca> = [];
        var arrayDados = response.data as Array<AutocompleteOptionPlaca>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx: any) => {
          const placa1 = {
            label: itemx.placa,
            id: itemx.id_veiculo,
            id_user: itemx.id_usr,
            nome: itemx.nome,
          };
          dados.push(placa1);
        });
        setPlacas2(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // handleBuscaMotoristas();
      });
  }
  useEffect(() => {
    doRefresh((prev) => prev + 1);
  }, [listaItens.current]);

  useEffect(() => {
    if (divGrid.current?.className == "makeStyles-grid70-21") {
      if ((listaItens.current.length = 0)) {
        // console.log('70-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    } else if (divGrid.current?.className == "makeStyles-grid100-22") {
      if (listaItens.current.length > 0) {
        // console.log('100-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    }
  }, [marcado]);

  const handleBuscaDados = async () => {
    await api
      .post(`relatorio/viagem`, formData)
      .then((response) => {
        // console.log('retorno',response.data)
        var qtd_viagens = 0;
        var total_frete = 0;
        var total_adi = 0;
        var total_desc = 0;
        var total_saldo = 0;
        var total_comissao = 0;
        var total_geral = 0;

        //@ts-ignore
        response.data.map((viagem, index1) => {
          // console.log(viagem);
          qtd_viagens += 1;
          total_frete += Number(viagem.valor);
          total_adi += viagem.soma;
          total_desc += viagem.desconta;
          total_saldo += viagem.saldo;
          total_comissao += Number(viagem.comissao_vl);
          total_geral += viagem.total;
        });

        setItens(response.data);
        setFormDataViagens({
          qtd_viagens,
          total_frete,
          total_adi,
          total_desc,
          total_saldo,
          total_comissao,
          total_geral,
        });
      })
      .catch((error) => {
        console.log("erro", error);
      })
      .finally(() => {
        console.log("finally");
        // setLoading(false);
      });
    //busca manutenções
    await api
      .post(`relatorio/manutencao`, formData)
      .then((response) => {
        // console.log('retorno',response.data)

        var qtd_manutencoes = 0;
        var total_geral = 0;

        var manutencoes = new Array();
        var ids = new Array();
        var color = "";
        //@ts-ignore
        response.data.map((manutencao, index1) => {
          // console.log("ids", ids);
          var adicionou = false;
          color = "item";

          ids.map((id, index) => {
            // console.log("id", id);

            if (id == manutencao.id_manutencao) {
              adicionou = true;
              // exit;
            }
          });

          if (!adicionou) {
            color = "capa";
            var item = {
              data_fim: manutencao.data_fim,
              data_inicio: manutencao.data_inicio,
              descricao: manutencao.descricao,
              id_manutencao: manutencao.id_manutencao,
              km: manutencao.km,
              motivo: manutencao.motivo,
              orcamento: manutencao.orcamento,
              valor_total: manutencao.valor_total,
              desc: "",
              qtd: "",
              valor: "",
              color: color,
            };
            //capa
            manutencoes.push(item);
            qtd_manutencoes += 1;
            total_geral += Number(manutencao.valor_total);
          }
          ids.push(manutencao.id_manutencao);
          color = "item";
          // item
          item = {
            data_fim: "",
            data_inicio: "",
            descricao: "",
            id_manutencao: manutencao.id_manutencao,
            km: "",
            motivo: "",
            orcamento: "",
            valor_total: "",
            desc: manutencao.desc,
            qtd: manutencao.qtd,
            valor: manutencao.valor,
            color: color,
          };
          manutencoes.push(item);
        });

        setFormDataManutencoes({
          qtd_manutencoes,
          total_geral,
        });
        //@ts-ignore
        setItensManutencao(manutencoes);
        // setItensManutencao(response.data);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
        setLoading(false);
      });
  };

  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    if (value == "") {
      return "";
    }
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return nf.format(value);
  };

  const formataLinha = (value: any, tableMeta: any, updateValue: any) => {
    var linha = tableMeta.rowData[0];
    var meuHTML = `<div style="background-color: ${tableMeta.rowData[0]};text-align: center;"><strong>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 15l-6 6-1.42-1.42L15.17 16H4V4h2v10h9.17l-3.59-3.58L13 9l6 6z"/></svg></strong></div>`;

    if (linha == "capa") {
      meuHTML = `<div style="text-align: center;"><strong>${value}</strong></div>`;
    } else {
      meuHTML = `<div style="background-color: ${tableMeta.rowData[0]};text-align: center;"><strong>
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 15l-6 6-1.42-1.42L15.17 16H4V4h2v10h9.17l-3.59-3.58L13 9l6 6z"/></svg></strong></div>`;
    }

    return <div dangerouslySetInnerHTML={{ __html: meuHTML }} />;
  };

  const formataStatus = (value: any, tableMeta: any, updateValue: any) => {
    switch (value) {
      case "A":
        return "Aberto";
      case "G":
        return "Agendado";
      case "R":
        return "Realizado";
      case "F":
        return "Finalizado";
    }
  };

  const columns = [
    {
      name: "id_viagem",
      label: "ID",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "placa",
      label: "Veículo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nome",
      label: "Motorista",
      options: {
        filter: true,
        sort: true,
        print: false,
      },
    },
    {
      name: "mercadoria",
      label: "Mercadoria",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "destino",
      label: "Destino",
      options: {
        filter: true,
        sort: true,
        //display: false,
      },
    },
    {
      name: "data_saida",
      label: "Saída",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formatDate(value).toString();
        },
        //display: false,
      },
    },
    {
      name: "data_chegada",
      label: "Chegada",
      options: {
        filter: true,
        sort: true,
        //display: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formatDate(value).toString();
        },
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formatDate(value)}
      },
    },
    {
      name: "status",
      label: "Situação",
      options: {
        display: true,
        filter: true,
        sort: true,
      },
    },
    {
      name: "peso",
      label: "Peso",
      options: {
        display: false,
        filter: true,
        sort: true,
      },
    },
    {
      name: "valor",
      label: "Frete",
      options: {
        filter: true,
        sort: true,
        //  customHeadLabelRender:(columnMeta : object) => {return columnMeta?.textLabels},
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "comissao_vl",
      label: "Comissão",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "km_total",
      label: "KM Total",
      options: {
        filter: true,
        sort: true,
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "km_medio",
      label: "KM/L",
      options: {
        filter: true,
        sort: true,
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "soma",
      label: "Adi. (+)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "desconta",
      label: "Des. (-)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "saldo",
      label: "Saldo",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "total",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];

  const columnsManutencoes = [
    {
      name: "color",
      label: "TIPO",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "id_manutencao",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataLinha(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "placa",
      label: "Veículo",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        //display: false,
      },
    },
    {
      name: "motivo",
      label: "Motivo",
      options: {
        filter: true,
        sort: false,
        print: false,
      },
    },
    {
      name: "orcamento",
      label: "Orçado",
      options: {
        filter: true,
        sort: true,
        //  customHeadLabelRender:(columnMeta : object) => {return columnMeta?.textLabels},
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "valor_total",
      label: "Valor Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataStatus(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "data_inicio",
      label: "Dt. Início",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formatDate(value).toString();
        },
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "data_fim",
      label: "Dt. Fim",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formatDate(value).toString();
        },

        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "km",
      label: "KM",
      options: {
        filter: true,
        sort: true,
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "desc",
      label: "Desc. Item",
      options: {
        filter: true,
        sort: true,
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "qtd",
      label: "Qtd. Item",
      options: {
        filter: true,
        sort: true,
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "valor",
      label: "Valor Item",
      options: {
        filter: true,
        sort: true,
        //  customBodyRender: (value:any, tableMeta:any, updateValue:any) => {return formataValor(value, tableMeta, updateValue)}
      },
    },
    {
      name: "id_manutencao",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
  ];

  var selectRow: number = -1;
  var SelectableRows: SelectableRows = "none";
  const CustomToolbarLoading = ({}) => {
    return (
      <>
        {isLoading && (
          <CircularProgress sx={{ display: "flex" }} color="inherit" />
        )}
      </>
    );
  };

  var options: MUIDataTableOptions = {
    customToolbar: CustomToolbarLoading,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setDescontos([]);
      let y1 = rowsSelected as number[];
      selectRow = -1;
      marcado.current = 0;
      setID(-1);
      setVisible(true);
      y1.map((numero: number) => {
        let item = itens[numero] as Item;
        selectRow = numero;
        setVisible(false);
        setID(numero);
      });
      doRefresh((prev) => prev + 1);
    },
    onRowClick: (rowData) => {
      const id = Number(rowData[0]);
      setIDDespesa(id);
    },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (evt: any) => {
        // console.log(row);
        // alert("Double click!");
        // setShowConfirmEdit(true);
        let selecionado = itens[ID] as Item;
        // window.open(`/viagemacerto/${selecionado.id_viagem}/despesa/${IDDespesa}`);
        window.open(`/viagemacerto/${row[0]}`, "_blank");
        // navigate(`/viagemacerto/7`, {});
      },
    }),
    selectableRowsHeader: itens.length > 0,
    selectableRows: SelectableRows,
    textLabels: labels,
    selectableRowsOnClick: true,
    responsive: "standard",
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 20, 50, 100],
    print: false,
    downloadOptions: {
      filename: "Relatório.csv",
      separator: ",",
    },
  };

  var optionsManutencoes: MUIDataTableOptions = {
    customToolbar: CustomToolbarLoading,
    onRowClick: (rowData) => {
      const id = Number(rowData[14]);
      // console.log("id", id);
      setIDManutencao(id);
    },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (evt: any) => {
        // console.log("row", row);
        const id = Number(row[14]);

        window.open(`/manutencao/${id}`, "_blank");
      },
    }),
    selectableRowsHeader: itens.length > 0,
    selectableRows: SelectableRows,
    textLabels: labels,
    selectableRowsOnClick: true,
    responsive: "standard",
    rowsPerPage: 15,
    rowsPerPageOptions: [15, 20, 50, 100],
    print: true,
    downloadOptions: {
      filename: "Manutenções.csv",
      separator: ",",
    },
  };

  var SelectableRowsDespesas: SelectableRows = "none";
  var optionsDespesas: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const id = Number(rowData[0]);
      setIDDespesa(id);
      if (rowData[1] != "") {
        setSourceImage(baseURL + rowData[1]);
      } else {
        setSourceImage("");
      }
      // setShowImage(true);
      // console.log('click numero---', rowData);
    },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (evt: any) => {
        // alert("Double click!");
        setShowConfirmEdit(true);
      },
    }),
    selectableRows: SelectableRowsDespesas,
    textLabels: labels,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "simple",
    downloadOptions: {
      filename: "relatório.csv",
      separator: ",",
    },
  };

  //  var downloadOptions: MUIDataTable
  const handleClickEditDespesa = () => {
    if (IDDespesa >= 0) {
      let selecionado = itens[ID] as Item;
      navigate(`/viagem/${selecionado.id_viagem}/despesa/${IDDespesa}`);
    } else {
      alert("Nenhuma Despesa Selecionada!");
    }
  };

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  }

  async function handleSubmit(event: FormEvent) {
    formData.placa = String(value?.label) || "";
    formData.placa2 = String(value2?.label) || "";
    formData.status = String(valueStatus[0]?.id) || "T";
    formData.id_motorista = Number(valueMotorista?.id) || 0;
    var grupStatus = "";

    const linhas = valueStatus.length;
    // console.log(grupStatus);
    if (linhas == 0) {
      grupStatus = `('T')`;
    } else {
      if (linhas == 1) {
        grupStatus = `('${valueStatus[0].id}')`;
      } else {
        // console.log(linhas);
        var linhaAtual = 0;
        grupStatus = "(";
        while (linhaAtual < linhas) {
          grupStatus += `'${valueStatus[linhaAtual].id}'`;
          linhaAtual = linhaAtual + 1;
          if (linhaAtual < linhas) {
            grupStatus += `,`;
          }
        }
        grupStatus += ")";
      }
    }
    formData.grupStatus = grupStatus;
    // console.log(grupStatus);
    setLoading(true);
    handleBuscaDados();
  }

  const formatDate = (date: Date) => {
    var d = new Date(date);
    d.setHours(d.getHours() + 3); // adicionado mais 3 porque vem como data, e quando joga no New date coloca -3 horas
    try {
      let formattedDate = Intl.DateTimeFormat("pt-BR", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(d);
      return formattedDate;
    } catch (error) {
      return date;
    }
  };

  async function handleMostraViagens(idPar: String) {
    const id1 = Number(idPar);
    setOpenViagens(openViagens == 1 ? 0 : 1);

    if (id1 > 0 && id1 != undefined) {
      var dados = formData;
      // setOpen(open == 1 ? 0 : 1);

      // dados.map((data, index) => {
      //   if (data.id_viagem == idPar) {
      //     dados[index].open = dados[index].open == 1 ? 0 : 1;
      //   } else {
      //     // opens1.push(data.open);
      //   }
      // });

      // setFormData(dados);
      // newState(ignored + 1);
    }
  }

  async function handleMostraManutencao(idPar: String) {
    const id1 = Number(idPar);
    setOpenManutencao(openManutencao == 1 ? 0 : 1);
  }

  const TipoValor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.buttons} />
      </div>
      <div className={classes.grid}>
        <Container className={classes.formdados}>
          <Form
            className={classes.dadosViavem}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <fieldset className={classes.titulo}>
              <h2 className={classes.tituloh2}>Resumo do Veículo</h2>
            </fieldset>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "row",
                justifyContent: "flex",
              }}
            >
              <Autocomplete
                multiple
                // limitTags={2}
                id="multiple-limit-tags"
                options={statusLista}
                getOptionLabel={(option) => option.label}
                defaultValue={[statusLista[0]]}
                renderInput={(params) => (
                  <TextField {...params} label="Status" placeholder="Status" />
                )}
                className={classes.field}
                onChange={(event, newValue) => {
                  setValueStatus(newValue as AutocompleteOptionStatus[]);
                }}
              />
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue as AutocompleteOptionPlaca);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="placa"
                options={placas}
                disabled={false}
                className={classes.field}
                renderInput={(params) => (
                  <TextField {...params} label="Placa" />
                )}
              />
              <Autocomplete
                value={value2}
                onChange={(event, newValue) => {
                  setValue2(newValue as AutocompleteOptionPlaca);
                }}
                inputValue={inputValue2}
                onInputChange={(event, newInputValue) => {
                  setInputValue2(newInputValue);
                }}
                id="placa2"
                options={placas2}
                disabled={false}
                className={classes.field}
                renderInput={(params) => (
                  <TextField {...params} label="Placa 2" />
                )}
              />
              <TextField
                type={"date"}
                value={formData.data_saida}
                onChange={handleInputChange}
                className={classes.field}
                id="data_saida"
                name="data_saida"
              />
              <TextField
                type={"date"}
                value={formData.data_chegada}
                onChange={handleInputChange}
                className={classes.field}
                id="data_chegada"
                name="data_chegada"
              />
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                style={{ width: "35%" }}
              >
                Buscar
              </Button>
            </div>
          </Form>
        </Container>
      </div>
      <div
        className={classes.grid}
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex",
        }}
      >
        {/* mostra detalhes */}
        {isLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex",
          }}
        >
          <div
            style={{
              width: "100%",
              marginBottom: "10px",
              padding: "10px",
              border: "1px solid #e5e5e6",
              borderRadius: "8px",
              boxShadow:
                "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                required
                disabled
                value={formDataViagens.qtd_viagens}
                onChange={handleInputChange}
                className={classes.field}
                id="qtd_viagens"
                name="qtd_viagens"
                label="Total de Viagens"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formDataViagens.total_frete)}
                onChange={handleInputChange}
                className={classes.field}
                id="total_frete"
                name="total_frete"
                label="Valor Total de Frete"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formDataViagens.total_adi)}
                onChange={handleInputChange}
                className={classes.field}
                id="total_adi"
                name="total_adi"
                label="Adi. (+)"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formDataViagens.total_desc)}
                onChange={handleInputChange}
                className={classes.field}
                id="total_desc"
                name="total_desc"
                label="Des. (-)"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formDataViagens.total_saldo)}
                onChange={handleInputChange}
                className={classes.field}
                id="total_saldo"
                name="total_saldo"
                label="Saldo Geral"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formDataViagens.total_comissao)}
                onChange={handleInputChange}
                className={classes.field}
                id="total_comissao"
                name="total_comissao"
                label="Comissão Total (R$)"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formDataViagens.total_geral)}
                onChange={handleInputChange}
                className={classes.field}
                id="total_geral"
                name="total_geral"
                label="Total Geral"
              />
            </div>

            {openViagens == 0 && (
              <div style={{ textAlign: "center" }}>
                <ArrowCircleDownIcon
                  className={classes.textos}
                  sx={{
                    cursor: "pointer",
                    width: "45px",
                    height: "45px",
                    color: "#04b01a", //verde
                  }}
                  onClick={(event) => handleMostraViagens("")}
                />
              </div>
            )}
            {openViagens == 1 && (
              <div style={{ textAlign: "center" }}>
                <ArrowCircleUp
                  className={classes.textos}
                  sx={{
                    cursor: "pointer",
                    width: "45px",
                    height: "45px",
                    color: "#d91616", //vermelho
                  }}
                  onClick={(event) => handleMostraViagens("")}
                />
              </div>
            )}
            {/* Se era pra mostrar abre os detalhes */}
            {openViagens == 1 && (
              <div
                key={ignored}
                style={{
                  flexDirection: "column",
                  width: "100%",
                  display: "flex",
                }}
              >
                <MUIDataTable
                  title={`Viagens`}
                  data={itens}
                  columns={columns}
                  options={options}
                />
              </div>
            )}
          </div>
        </div>
        {/* mostra manutenções */}
        {isLoading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <div
          key={1}
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex",
          }}
        >
          <div
            key={1}
            style={{
              width: "100%",
              marginBottom: "10px",
              padding: "10px",
              border: "1px solid #e5e5e6",
              borderRadius: "8px",
              boxShadow:
                "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                required
                onChange={handleInputChange}
                value={formDataManutencoes.qtd_manutencoes}
                className={classes.field}
                id="qtd_manutencoes"
                name="qtd_manutencoes"
                label="Qtd. de Manutenções"
              />
              <TextField
                required
                disabled
                value={TipoValor.format(formDataManutencoes.total_geral)}
                onChange={handleInputChange}
                className={classes.field}
                id="total_geral"
                name="total_geral"
                label="Valor Total das Manutenções"
              />
            </div>

            {openManutencao == 0 && (
              <div style={{ textAlign: "center" }}>
                <ArrowCircleDownIcon
                  className={classes.textos}
                  sx={{
                    cursor: "pointer",
                    width: "45px",
                    height: "45px",
                    color: "#04b01a",
                  }}
                  onClick={(event) => handleMostraManutencao("")}
                />
              </div>
            )}
            {openManutencao == 1 && (
              <div style={{ textAlign: "center" }}>
                <ArrowCircleUp
                  className={classes.textos}
                  sx={{
                    cursor: "pointer",
                    width: "45px",
                    height: "45px",
                    color: "#d91616",
                  }}
                  onClick={(event) => handleMostraManutencao("")}
                />
              </div>
            )}
            {/* Se era pra mostrar abre os detalhes */}
            {openManutencao == 1 && (
              <div
                id="teste"
                // className={classes.tabelas}
                key={ignored}
                style={{
                  flexDirection: "column",
                  width: "100%",
                  display: "flex",
                }}
              >
                <MUIDataTable
                  title={`Manutenções`}
                  data={itensManutencao}
                  columns={columnsManutencoes}
                  options={optionsManutencoes}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalConfirm
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirm(false);
          }
        }}
      />
      <ModalConfirm
        title={titleConfirmEdit}
        description={descricaoConfirmEdit}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirmEdit}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            // navigateAcerto();
            handleClickEditDespesa();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirmEdit(false);
          }
        }}
      />
      <div>
        <ImageEvento
          show={showImage}
          source={sourceImage}
          onReturn={() => {
            setShowImage(false);
            setSourceImage("");
          }}
        />
      </div>
    </div>
  );
};
export default Viagem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: 'flex-end',
      alignItems: "flex-start",
      justifyContent: "space-between",
      margin: theme.spacing(1),
    },
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    grid: {
      display: "flex",
      flexDirection: "row",
    },
    grid70: {
      width: "65%",
    },
    grid100: {
      width: "100%",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonAcerto: {
      marginLeft: theme.spacing(1),
      //  alignItems: 'flex-start',
      backgroundColor: "green",
    },
    buttons: {
      flexDirection: "row",

      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonHide: {
      display: "none",
    },
    resumo: {
      width: "100%",
      //display: 'flex',
      flexDirection: "column",
      backgroundColor: "#Fff",
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    formdados: {
      width: "100%",
      maxWidth: "10280px",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      paddingBottom: "8px",
      //display: 'flex',
      flexDirection: "row",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
    },
    titulo: {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderBottom: "2px solid rgb(0 0 0 / 20%)",
      width: "100%",
      padding: "20px",
      marginBottom: "5px",
    },
    tituloh2: {
      textAlign: "center",
      color: "#444445",
    },
    dadosViavem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
    },
    textos: {
      fontSize: "0.95rem",
      fontWeight: 500,
      color: darkBackground,
      // backgroundColor:secondary800,
      paddingLeft: "5px",
      paddingTop: "2px",
      paddingBottom: "2px",
      marginBottom: "2px",
      borderRadius: "8px",
      textAlign: "center",
    },
    textosHidden: {
      display: "nome",
    },
    // tss-1akey0g-MUIDataTableHeadCell-data :{}
  })
);
