import React, { useEffect, useState, useRef, FormEvent, ChangeEvent} from 'react';
// import MUIDataTable from "mui-datatables";
// import {MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import NewIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
// import AccountTree from '@material-ui/icons/AccountTree';
import { BsArrowBarLeft} from 'react-icons/bs';
import { FormHandles, UnformErrors } from '@unform/core';
import { Form } from '@unform/web';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
// import FilledInput from '@material-ui/core/';
import { Link,useNavigate,useParams } from 'react-router-dom';
// import IconButton from '@material-ui/core/IconButton';

import api, {URL} from '../../services/api';
// import labels from '../../component/TableLabels';
import ImageEvento from '../../component/Img';
// import SelectFile from '../../component/selectFile';
import Upload from '../../component/Upload/Upload';

import FileList from "../../component/FileList";
import { Autocomplete } from '@mui/material';
// import Select from 'react-select';


const baseURL = `${URL}/files/`;

interface DespesaProps {
  // id_estabelecimento: number,
    // id_viagem:string,
    id_evento:number, 
    local:string, 
    litros:number, 
    media:number, 
    foto:string, 
    codigo: string,
    descricao:string,
    valor: string,
    // data_saida:Date
}
interface AutocompleteOption {
  label: string;
  id: string;
}

interface FileUpload {
  file:File,
  id: string,
  name: string,
  // readableSize: filesize(file.size),
  preview: string,
  progress: number,
  uploaded: boolean,
  error: boolean,
  url: any,
}

interface HeaderProps {
  title?: string,
  match?: {params: {
    marca: string,id: string}};
}
const formatDate = (date:Date) => {
    var d = new Date(date);
        // console.log(d);
    try {
        let formattedDate = Intl.DateTimeFormat('pt-BR', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          }).format(d);
    
          // console.log(formattedDate);
        return formattedDate;
    } catch(error) {
      return date;
    }
}

const Despesa: React.FC<HeaderProps> = (props) => {
  let navigate   = useNavigate();
  const params = useParams();

  const classes = useStyles();
  const uploadInputRef = useRef(null);
  // const buttonEditRef = useRef<HTMLButtonElement>(null);

  const [ID, setID] = useState(Number);
  // const [visible, setVisible] = useState(Boolean);

  
  // const [colorEdit, setColorEdit] = useState('inherit');
  // const [textEdit, setTextEdit] = useState('EDITAR');
  const [habitidadoEdicao, setHabitidadoEdicao] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const options = [
    { label: 'The Godfather', id: 1 },
    { label: 'Pulp Fiction', id: 2 },
  ]
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState('');
  const [placas, setPlacas] = useState<AutocompleteOption[]>([]);
  const [value, setValue] = useState<AutocompleteOption>();
  const [inputValue, setInputValue] = React.useState('');
  const [formData, setFormData] = useState<DespesaProps>({
    // id_estabelecimento: 0,
    // id_viagem:'',
    id_evento:0, 
    local:'', 
    litros:0, 
    media:0, 
    foto:'', 
    codigo: '',
    descricao:'',
    valor: 'R$ 0,00',
    // data_saida:new Date(),
    // data_chegada:new Date(),
});
  const formRef = useRef<FormHandles>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState<FileUpload[]>([]);
  
  useEffect(()=>{
    var idPar:number = Number(params.id);

    // var idPar:number = Number(props.match?.params.id);
    // console.log('id viagem', idPar)
    setID(idPar);
    handleBuscaDados(idPar);
  }, []);

  async function handleBuscaDados(idPar:Number ) {
      
      await api.get(`evento/desconta`).then(response => {
        // console.log('retorno',response.data);
        let dados : Array<AutocompleteOption>= [];
        var arrayDados = response.data as Array<AutocompleteOption>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx:any) => {
          // console.log('item222',itemx.placa);
          const placa1 = {label:itemx.descricao, id:itemx.id_evento}
          dados.push(placa1);
        });
        setPlacas(dados);
      }).catch((error) => {
        // console.log('erro', error);
      }
    ).finally(() => {
          // console.log('finally')
        }
      );
  }
  const TipoValor = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const formataValor = (value:any, tableMeta:any, updateValue:any) => {
    return TipoValor.format(value);
  }
  
  async function handleClickEditSave( ) {

    //formData.placa = String(value?.label);

    

    // if (window.confirm("Salvar Edição da Viagem(s)?")) {
      //setHabitidadoEdicao(true);
      // console.log(formData);
      // await api.put(`viagem/${formData.codigo}`, formData).then(response => {
      //   if (response.data.sucess) {
      //       alert('Viagem Atualizada!');
      //   } else {
      //       alert(response.data.result)
      //   }
      // });

    // }
  }
  // const validationErrors:UnformErrors = {}; 
  const [validationErrors, setValidationErrors] = useState<UnformErrors>({});

  async function handleSubmit(data: FormEvent) {
    //formData.placa = String(value?.label);

    // console.log(data);
    // console.log(value?.id);
    if (formData.valor == "R$ 0,00" || formData.valor == "R$ " || formData.valor == "R$" || formData.valor == "R$ 0") {
        setValidationErrors({['valor'] : "Informe o valor da Despesa!"});
    // } else if (value == undefined){
    //   setValidationErrors({['evento'] : "Informe o valor da Despesa!"});
     } else {
      var value1 = formData.valor.replace(/\D/g, "");
      value1 = value1.replace(/(\d)(\d{2})$/, "$1.$2");
      if (value1.length < 1 ) {
        setValidationErrors({['valor'] : "Informe o valor da Despesa!"});
      } else {

          setValidationErrors({});   

          const dataEnv = new FormData();

          // const foto = {
          //   uri: uploadedFiles[0]?.file,
          //   type: 'image/jpg', //dados.current.type,
          //   name : 'imagem'
          // }
          // console.log('litros', data.litros);

          var value1 = formData.valor.replace(/\D/g, "");
          value1 = value1.replace(/(\d)(\d{2})$/, "$1.$2");
            // value1 = value1.replace(/(?=(\d{3})+(\D))\B/g, ".");

          var valueLitros = formData.litros.toString();

          if (formData.litros > 0 ) {
            // valueLitros = valueLitros.replace(/\D/g, "");
            // valueLitros = valueLitros.replace(/(\d)(\d{2})$/, "$1.$2");
            // valueLitros = valueLitros.replace(/(?=(\d{3})+(\D))\B/g, ".");
          }
          var valueMedia = formData.media.toString();

          
          // console.log('value1',value1);
            // console.log('valueLitros',valueLitros);
            // console.log('valueMedia',valueMedia);

          dataEnv.append('foto',uploadedFiles[0]?.file);
          dataEnv.append('codigo',formData.codigo);
          dataEnv.append('id_evento',(value != undefined ? value?.id.toString(): "0"));
          dataEnv.append('descricao',formData.descricao);
          dataEnv.append('local',formData.local);
          dataEnv.append('valor',value1);
          dataEnv.append('litros', (formData.litros > 0 ? valueLitros: "0"));
          dataEnv.append('media', (formData.media > 0 ? valueMedia: "0"));
          const ret = await api.post(`eventoviagem/${ID}`,dataEnv);
              // console.log('response.data', ret.data);

            if (ret.data.sucess) {
              // console.log('response.data', ret.data);
      
              alert('Despesa criada com Sucesso!');
              navigate("/lancamentos");
            } else {
              alert(ret.data.result);
            }
          // }
      }
    }
  };
  async function handleDelete(id:any) {
    // console.log(id);
  }
  function handleUpload( files:File[]) {
    let dados : Array<FileUpload>= [];

    files.map(file => {
      const file1 = {
          file,
           id: '',//uniqueId(),
          name: file.name,
          // readableSize: filesize(file.size),
          preview: window.URL.createObjectURL(file),
          progress: 0,
          uploaded: false,
          error: false,
          url: null,
      }
      dados.push(file1);

    });
    setUploadedFiles(dados);

  }
  function handleInputChange(event: ChangeEvent<HTMLInputElement>){
      const {id, value} = event.target;
      
      if (id == 'valor' || id == 'soma') {
      
        var value1 = value.replace(/\D/g, "");
        value1 = value1.replace(/^0+/, '');
        value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
        value1 = 'R$ '+value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
        // console.log('value.length', value.length);//setMsgSucesso(false);
        if (value.length < 3) {
          value1 = 'R$ 0,00';
        }
        setFormData({...formData, [String(id)]:value1});

      } else {
        if (id == 'media' || id == 'litros') {
          let val:number = Number(0);

          try {
            val = Number(parseFloat(value).toFixed(3));
          } catch(error) {}
          setFormData({...formData, [id]:val});
        
        } else {
          setFormData({...formData, [id]:value});
        }
      }
  };

  const validaValor = async(valor:string) => {
    if (valor == "R$ 0,00")
      return false;
  }

  return (
    <div style={{marginTop:'10px'}}>
      <div>
          <Link ref={linkRef} to={`/lancamentos`} style={{display: 'flex', fontSize: '17px'}}>
              <BsArrowBarLeft style={{height: '23px', width: '36px'}}/>
              Voltar
          </Link>
      </div>
      <div className={classes.root}>
      <Container className={classes.formdados}>
            <Form className={classes.dadosViavem} ref={formRef} onSubmit={handleSubmit}>
                <fieldset className={classes.titulo}>
                  <h2 className={classes.tituloh2}>Nova Despesa</h2>
                </fieldset>
                <div style={{width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex', alignItems: 'flex-center'}}>
                  {/* <TextField style={{width:'15%',display: 'none'}} disabled value={formData.id_viagem} onChange={handleInputChange} className={classes.field} id="id_viagem" name="id_viagem" label="ID" /> */}
                  <TextField style={{width:'15%', marginRight:13}} 
                      defaultValue={"0"}
                      disabled={habitidadoEdicao} type='number' value={formData.codigo} onChange={handleInputChange} className={classes.field} id="codigo" name="codigo" label="Código" />
                  <TextField required disabled={habitidadoEdicao} value={formData.descricao} onChange={handleInputChange} className={classes.field} id="descricao" name="descricao" label="Descrição" />
                  <TextField required disabled={habitidadoEdicao} value={formData.local} onChange={handleInputChange} className={classes.field} id="local" name="local" label="Local" />
                </div>
                <div style={{width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex', alignItems: 'flex-center'}}>
                  <Autocomplete
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue as AutocompleteOption);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      id="evento"
                      // name="evento"
                      options={placas}
                      disabled={false}
                      style={{width:'70%'}}
                      className={classes.field}
                      renderInput={(params) => <TextField {...params} 
                      required={true} 
                      label="Evento" />}
                    />
                    <div style={{width:'100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex', alignItems: 'flex-center'}}>
                      <Upload onUpload={handleUpload}/>
                      {!!uploadedFiles.length && (
                        <FileList files={uploadedFiles} onDelete={handleDelete} />
                      )}
                    </div>
                </div>
                <div style={{marginTop:5, width:'100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex', alignItems: 'flex-center'}}>
                  <TextField required
                      defaultValue="R$ 0,00"
                      error={validationErrors['valor'] == undefined ? false:true}
                      helperText={validationErrors['valor'] != undefined? "Informe o Valor da Despesa" : null}
                      disabled={habitidadoEdicao} 
                      value={formData.valor}
                      onChange={handleInputChange} 
                      className={classes.field} 
                      id="valor" 
                      name="valor" 
                      label="Valor" />
                  <TextField type={"number"} disabled={habitidadoEdicao} value={formData.litros} onChange={handleInputChange} className={classes.field} id="litros" name="litros" label="Qtd. em Litros" />
                  <TextField type={"number"} disabled={habitidadoEdicao} value={formData.media} onChange={handleInputChange} className={classes.field} id="media" name="media" label="Média KM/L" />
                </div>
                <div style={{width:'100%', margin:10, display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                    <Button
                        type="submit"
                        size="small" 
                        variant="contained"
                        color="primary"
                        style={{width: '35%'}}>
                            Criar Despesa
                    </Button>
                </div>
            </Form>
        </Container>
      </div>
    <ImageEvento 
        show={showImage} 
        source={sourceImage} 
        onReturn={()=>{
          setShowImage(false);
          setSourceImage('');
          }
        } 
      />
    </div>
    
  );
}
export default Despesa;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabelas: {
      marginTop: '10px',
      width:'100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex',
        alignItems: 'flex-center',
    },
    tabela:{
      width: '50%',
    },
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
        //justifyContent: 'flex-end',
        alignItems: 'center',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        borderRadius: '8px',
    },
    dadosViavem: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
        //justifyContent: 'flex-end',
        alignItems: 'center',
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },buttonExtra: {
      margin: theme.spacing(1),
      backgroundColor:'green'
    },
    button: {
      margin: theme.spacing(1),
    },
    titulo: {
      borderTop:'none',
      borderRight:'none',
      borderLeft:'none',
      borderBottom: '2px solid rgb(0 0 0 / 20%)',
      width: '100%',
      margin:'7px',
      padding: '20px',
  },
  tituloh2: {
      textAlign: 'center',
      color: '#444445',
  },
  field:{
    width: '90%',
    fontSize: '22px',
    paddingTop: '7px',
    paddingBottom: '7px',
    marginLeft:'5px', 
    marginRight:'5px',
    color:'black'
  },
  fieldID:{
      display: 'none',
  },
  buttonHide: {      
      display: 'none',
  },formdados: {
      width: '100%',
      maxWidth: '10280px',
      backgroundColor: '#Fff',
      border: '1px solid #e5e5e6',
      borderRadius: '8px',
      paddingBottom: '8px',
      //display: 'flex',
        flexDirection: 'row',
        //justifyContent: 'flex-end',
      alignItems: 'center',
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
      
    },
  }),
);