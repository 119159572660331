import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import NewIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
// import AccountTree from '@material-ui/icons/AccountTree';
import { BsArrowBarLeft } from "react-icons/bs";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Link, useNavigate } from "react-router-dom";

import api, { URL } from "../../services/api";
import ImageEvento from "../../component/Img";
import { Autocomplete } from "@mui/material";
// import Select from 'react-select';

const baseURL = `${URL}/files/`;

interface Item {
  id_viagem: number;
  descricao: string;
  origem: string;
  destino: string;
  valor: string;
  comissao: string;
  km_total: string;
  obs_viagem: string;
  nome_motorista: string;
  placa: string;
  placa2: string;
  data_saida: Date;
  data_chegada: Date;
}
interface AutocompleteOption {
  label: string;
  id: string;
  id_user?: number;
  nome?: string;
}
// ou

interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
      id: string;
    };
  };
}
const formatDate = (date: Date) => {
  var d = new Date(date);
  // console.log(d);
  try {
    let formattedDate = Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(d);

    // console.log(formattedDate);
    return formattedDate;
  } catch (error) {
    return date;
  }
};

const ViagemAcerto: React.FC<HeaderProps> = (props) => {
  let navigate = useNavigate();
  const classes = useStyles();
  // const buttonEditRef = useRef<HTMLButtonElement>(null);

  const [ID, setID] = useState(Number);
  // const [visible, setVisible] = useState(Boolean);

  // const [colorEdit, setColorEdit] = useState('inherit');
  // const [textEdit, setTextEdit] = useState('EDITAR');
  const [habitidadoEdicao, setHabitidadoEdicao] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const options = [
    { label: "The Godfather", id: 1 },
    { label: "Pulp Fiction", id: 2 },
  ];
  const [showImage, setShowImage] = useState(false);
  const [showValorFrete, setShowValorFrete] = useState(true);
  const [sourceImage, setSourceImage] = useState("");
  const [placas, setPlacas] = useState<AutocompleteOption[]>([]);
  const [placas2, setPlacas2] = useState<AutocompleteOption[]>([]);
  const [value, setValue] = useState<AutocompleteOption>();
  const [value2, setValue2] = useState<AutocompleteOption>();
  const [inputValue, setInputValue] = React.useState("");
  const [inputValue2, setInputValue2] = React.useState("");
  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  var data30 = new Date();
  data30.setDate(data30.getDate());

  const [formData, setFormData] = useState({
    id_estabelecimento: 0,
    id_viagem: "",
    codigo: 0,
    mercadoria: "",
    descricao: "",
    origem: "",
    destino: "",
    valor: "R$ 0,00",
    comissao: 0,
    km_inicio: "",
    id_motorista: 0,
    obs_viagem: "",
    nome_motorista: "",
    placa: "",
    placa2: "",
    soma: 0,
    peso: 0,
    comissao_vl: 0,
    data_saida: materialDateInput,
    // data_chegada:new Date(),
  });
  const formRef = useRef<FormHandles>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (localStorage.getItem("UserTipo") == "M") {
      alert("Opção Inválida!");
      navigate("/");
    } else {
      //verifica se usa frete
      if (localStorage.getItem("UsaFrete") != "S") {
        setShowValorFrete(false);
      }
      var idPar: number = Number(props.match?.params.id);
      // console.log('id', idPar)
      setID(idPar);
      handleBuscaDados(idPar);
    }
  }, []);

  async function handleBuscaDados(idPar: Number) {
    await api
      .get(`veiculos/C','G`)
      .then((response) => {
        // console.log('retorno',response.data);
        let dados: Array<AutocompleteOption> = [];
        var arrayDados = response.data as Array<AutocompleteOption>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx: any) => {
          // console.log('item222',itemx);
          if (itemx.status == "Ativo") {
            const placa1 = {
              label: itemx.placa,
              id: itemx.id_veiculo,
              id_user: itemx.id_usr,
              nome: itemx.nome,
            };
            dados.push(placa1);
          }
        });
        setPlacas(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
        handleBuscaPlaca2();
      });
  }
  async function handleBuscaPlaca2() {
    await api
      .get(`veiculos/R`)
      .then((response) => {
        // console.log('retorno',response.data);
        let dados: Array<AutocompleteOption> = [];
        var arrayDados = response.data as Array<AutocompleteOption>;
        // console.log('arrayDados',arrayDados);
        arrayDados.map((itemx: any) => {
          // console.log('item222',itemx);
          // if (itemx.status == 'Ativo') {
          const placa1 = {
            label: itemx.placa,
            id: itemx.id_veiculo,
            id_user: itemx.id_usr,
            nome: itemx.nome,
          };
          dados.push(placa1);
          // }
        });
        setPlacas2(dados);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
      });
  }
  const TipoValor = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    return TipoValor.format(value);
  };

  async function handleSubmit(event: FormEvent) {
    formData.placa = String(value?.label);
    formData.placa2 = String(value2?.label);

    // console.log(formData);
    if (window.confirm("Criar nova Viagem?")) {
      // console.log(formData);
      await api.post(`viagemweb`, formData).then(function (response) {
        //alert(response.data.result);
        // console.log('retorn',response.data);
        if (response.data.sucess) {
          alert("Viagem Criada com Sucesso!");
          navigate("/lancamentos");
        } else {
          console.log(response.data);
        }
      });
    }
  }
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    if (id == "valor" || id == "soma") {
      var value1 = value.replace(/\D/g, "");
      value1 = value1.replace(/^0+/, "");
      value1 = value1.replace(/(\d)(\d{2})$/, "$1,$2");
      value1 = "R$ " + value1.replace(/(?=(\d{3})+(\D))\B/g, ".");
      // console.log('mudou valor', value1);//setMsgSucesso(false);
      if (value.length < 1) {
        value1 = "R$ 0,00";
      }
      setFormData({ ...formData, [String(id)]: value1 });
    } else {
      if (id == "comissao") {
        var taxa = value.replace(/\D/g, "");
        var frete = formData.valor.replace(/\D/g, "");
        frete = frete.replace(/(\d)(\d{2})$/, "$1.$2");

        const comissao_vl = Number(frete) * (Number(taxa) / 100);
        setFormData({
          ...formData,
          [String(id)]: taxa,
          ["comissao_vl"]: comissao_vl,
        });
      } else {
        setFormData({ ...formData, [id]: value });
      }
    }
  }
  useEffect(() => {
    // console.log('formData.comissao',formData.comissao);
    // console.log('formData.valor',formData.valor);
    calculaComissao();
  }, [formData.valor]);
  async function calculaComissao() {
    // console.log('formData.comissao',formData.comissao);
    // console.log('formData.valor',formData.valor);

    var taxa = formData.comissao; //.replace(/\D/g, "");
    var frete = formData.valor.replace(/\D/g, "");
    // console.log('frete',frete);
    // console.log('formData.valor',formData.valor);
    frete = frete.replace(/(\d)(\d{2})$/, "$1.$2");
    // console.log('taxa',taxa);
    // console.log('frete',frete);
    // console.log('frete',Number(frete));

    const comissao_vl = Number(frete) * (Number(taxa) / 100);
    setFormData({ ...formData, comissao_vl: comissao_vl });
  }

  return (
    <div style={{ marginTop: "10px" }}>
      <div>
        <Link
          ref={linkRef}
          to={`/viagens`}
          style={{ display: "flex", fontSize: "17px" }}
        >
          <BsArrowBarLeft style={{ height: "23px", width: "36px" }} />
          Voltar
        </Link>
      </div>
      <div className={classes.root}>
        <Container className={classes.formdados}>
          <Form
            className={classes.dadosViavem}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <fieldset className={classes.titulo}>
              <h2 className={classes.tituloh2}>Nova Viagem</h2>
            </fieldset>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <TextField
                style={{ width: "15%", display: "none" }}
                disabled
                value={formData.id_viagem}
                onChange={handleInputChange}
                className={classes.field}
                id="id_viagem"
                name="id_viagem"
                label="ID"
              />
              <TextField
                style={{ width: "15%", marginRight: 13 }}
                disabled={habitidadoEdicao}
                type="number"
                value={formData.codigo}
                onChange={handleInputChange}
                className={classes.field}
                id="codigo"
                name="codigo"
                label="Código"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.descricao}
                onChange={handleInputChange}
                className={classes.field}
                id="descricao"
                name="descricao"
                label="Descrição"
              />
              <TextField
                required={showValorFrete}
                disabled={habitidadoEdicao}
                value={formData.mercadoria}
                onChange={handleInputChange}
                className={classes.field}
                id="mercadoria"
                name="mercadoria"
                label="Mercadoria"
              />
              <TextField
                required={showValorFrete}
                disabled={habitidadoEdicao}
                value={formData.origem}
                onChange={handleInputChange}
                className={classes.field}
                id="origem"
                name="origem"
                label="Origem"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.destino}
                onChange={handleInputChange}
                className={classes.field}
                id="destino"
                name="destino"
                label="Destino"
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  if (newValue?.nome) {
                    setFormData({
                      ...formData,
                      ["id_motorista"]: Number(newValue?.id_user),
                      ["nome_motorista"]: newValue?.nome,
                    });
                  }
                  setValue(newValue as AutocompleteOption);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                id="placa"
                options={placas}
                disabled={false}
                className={classes.field}
                renderInput={(params) => (
                  <TextField {...params} required={true} label="Placa" />
                )}
              />
              <Autocomplete
                value={value2}
                onChange={(event, newValue) => {
                  setValue2(newValue as AutocompleteOption);
                }}
                inputValue={inputValue2}
                onInputChange={(event, newInputValue) => {
                  setInputValue2(newInputValue);
                }}
                id="placa2"
                options={placas2}
                disabled={false}
                className={classes.field}
                renderInput={(params) => (
                  <TextField {...params} label="Placa 2" />
                )}
              />
              {/* <TextField disabled value={formData.placa2} onChange={handleInputChange} className={classes.field} id="placa2" name="placa2" label="Placa 2" /> */}
              <TextField
                required
                disabled
                value={formData.nome_motorista}
                onChange={handleInputChange}
                className={classes.field}
                id="nome_motorista"
                name="nome_motorista"
                label="Motorista"
              />
              <TextField
                required
                disabled={habitidadoEdicao}
                value={formData.km_inicio}
                onChange={handleInputChange}
                className={classes.field}
                id="km_inicio"
                name="km_inicio"
                label="Km Inical"
              />
              <TextField
                type="date"
                disabled={habitidadoEdicao}
                value={formData.data_saida}
                // value={formatDate(formData.data_saida)}
                onChange={handleInputChange}
                className={classes.field}
                id="data_saida"
                name="data_saida"
                label="Data de Saída"
              />
              {/* <TextField disabled={habitidadoEdicao} value={formatDate(formData.data_chegada)} onChange={handleInputChange} className={classes.field} id="data_chegada" name="data_chegada" label="Data de Chegada" /> */}
            </div>
            {showValorFrete && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex",
                  alignItems: "flex-center",
                }}
              >
                {/* <TextField required disabled={habitidadoEdicao} value={TipoValor.format(formData.valor)} onChange={handleInputChange} className={classes.field} id="valor" name="valor" label="Valor de Frete" /> */}
                <TextField
                  required={showValorFrete}
                  disabled={habitidadoEdicao}
                  value={formData.valor}
                  onChange={handleInputChange}
                  className={classes.field}
                  id="valor"
                  name="valor"
                  label="Valor de Frete"
                />
                <TextField
                  required={showValorFrete}
                  disabled={habitidadoEdicao}
                  value={formData.comissao}
                  onChange={handleInputChange}
                  className={classes.field}
                  id="comissao"
                  name="comissao"
                  label="Comissão em %"
                />
                <TextField
                  required={showValorFrete}
                  disabled
                  value={TipoValor.format(formData.comissao_vl)}
                  onChange={handleInputChange}
                  className={classes.field}
                  id="comissao_vl"
                  name="comissao_vl"
                  label="Comissão em R$"
                />
                <TextField
                  required={showValorFrete}
                  disabled={habitidadoEdicao}
                  value={formData.soma}
                  onChange={handleInputChange}
                  className={classes.field}
                  id="soma"
                  name="soma"
                  label="Adi. (+)"
                />
                {/* <TextField required disabled={habitidadoEdicao} value={TipoValor.format(formData.total)} onChange={handleInputChange} className={classes.field} id="total" name="total" label="Total" /> */}
              </div>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex",
                alignItems: "flex-center",
              }}
            >
              {!showValorFrete && (
                <TextField
                  required={!showValorFrete}
                  disabled={habitidadoEdicao}
                  value={formData.soma}
                  onChange={handleInputChange}
                  className={classes.field}
                  id="soma"
                  name="soma"
                  label="Adi. (+)"
                />
              )}
              <TextField
                value={formData.obs_viagem}
                onChange={handleInputChange}
                className={classes.field}
                id="obs_viagem"
                name="obs_viagem"
                label="Obs."
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Button
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                style={{ width: "35%" }}
              >
                Criar Viagem
              </Button>
            </div>
          </Form>
        </Container>
      </div>
      <ImageEvento
        show={showImage}
        source={sourceImage}
        onReturn={() => {
          setShowImage(false);
          setSourceImage("");
        }}
      />
    </div>
  );
};
export default ViagemAcerto;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "8px",
    },
    dadosViavem: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      //justifyContent: 'flex-end',
      alignItems: "center",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonExtra: {
      margin: theme.spacing(1),
      backgroundColor: "green",
    },
    button: {
      margin: theme.spacing(1),
    },
    titulo: {
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
      borderBottom: "2px solid rgb(0 0 0 / 20%)",
      width: "100%",
      marginBottom: "5px",
    },
    tituloh2: {
      textAlign: "center",
      color: "#444445",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
    },
    fieldID: {
      display: "none",
    },
    buttonHide: {
      display: "none",
    },
    formdados: {
      width: "100%",
      maxWidth: "10280px",
      backgroundColor: "#Fff",
      border: "1px solid #e5e5e6",
      borderRadius: "8px",
      paddingBottom: "8px",
      //display: 'flex',
      flexDirection: "row",
      //justifyContent: 'flex-end',
      alignItems: "center",
      //marginTop: theme.spacing(3),
      //marginBottom: theme.spacing(3),
      //padding: theme.spacing(2),
    },
  })
);
// codigo: 1
// comissao: 3610
// created_at: null
// data_chegada: "2022-02-22T03:00:00.000Z"
// data_concluiu: null
// data_finalizou: "2022-02-24T16:45:18.000Z"
// data_saida: "2022-02-09T03:00:00.000Z"
// desconta: "0"
// descricao: "Tetsr"
// destino: "SP "
// diesel_LT: null
// diesel_RS: null
// id_estabelecimento: 1
// id_motorista: null
// id_user_config: 5
// id_veiculo_1: 7
// id_veiculo_2: 1
// id_viagem: 5
// km_fim: "558"
// km_inicio: "542315"
// km_medio: "0"
// km_total: -541757
// mercadoria: "Cebola "
// obs: " "
// origem: "Petro "
// peso: "0.00"
// placa: "WKI-93D9"
// saldo: 19000
// soma: "0"
// st_ativo: "F"
// tipo_acerto: "B"
// total: 15390
// updated_at: null
// usu_concluiu: null
// usu_finalizou: 5
// valor: "19000.00"
