import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AccountTree from "@material-ui/icons/AccountTree";
import NoteAdd from "@material-ui/icons/NoteAdd";
import Visualisar from "@material-ui/icons/ViewList";
import PhotoIcon from "@material-ui/icons/Photo";
import CircularProgress from "@mui/material/CircularProgress";

import "bootstrap/dist/css/bootstrap.min.css";

import api, { URL } from "../../services/api";
import labels from "../../component/TableLabels";
import GridViagmDespesas from "../../component/GridViagemDespesas";
import ModalConfirm from "../../component/ModalConfirm";
import { Button as Button1 } from "react-bootstrap";
//import Form from 'react-bootstrap/Form';
import { Form } from "@unform/web";
import TextField from "@material-ui/core/TextField";

import Modal from "react-bootstrap/Modal";
import ImageEvento from "../../component/Img";

const baseURL = `${URL}/files/`;

interface Item {
  id_viagem: number;
  cd_viagem: number;
  comissao: number;
  comissao_viagem: number;
  desconta: number;
  descricao_viagem: string;
  destino_viagem: string;
  destino?: string;
  peso: number;
  id_estabelecimento: number;
  id_motorista: number;
  id_veiculo_1: number;
  id_veiculo_2: number;
  km_medio_viagem: number;
  mercadoria_viagem: string;
  nome_motorista: string;
  obs_viagem: string;
  origem_viagem: string;
  placa_veiculo: string;
  saldo: number;
  soma: number;
  st_ativo: string;
  total: number;
  valor: number;
}
interface HeaderProps {
  title?: string;
  match?: {
    params: {
      marca: string;
    };
  };
}
interface FimViagem {
  data_chegada: Date;
  km_final: number;
}
/**
"id_viagem": 3,
"descricao_viagem": "Carga Netto",
"valor": "18400.80",
"nome_motorista": "Samuel",
"placa_veiculo": "AAW-9D43",
"soma": "0",
"desconta": "2400.20",
 "saldo": 9188.5,
    "comissao": 275.655,
    "total": 8912.845
*/

const Viagem: React.FC<HeaderProps> = (props) => {
  const [isLoading, setLoading] = useState(true);
  let navigate = useNavigate();
  const classes = useStyles();
  const buttonEditRef = useRef<HTMLButtonElement>(null);
  const divGrid = useRef<HTMLDivElement>(null);
  const [showImage, setShowImage] = useState(false);
  const [sourceImage, setSourceImage] = useState("");
  const [ID, setID] = useState(Number);
  // const data_chegada = useState(Date);
  // const km_final     = useState(Number);
  const [visible, setVisible] = useState(true);

  const [itens, setItens] = useState([]);
  const marcado = useRef(0);
  var titleConfirm = "Viagem Finalizada com Sucesso!";
  var descricaoConfirm = "Fazer Acerto?";
  var titleConfirmEdit = "Editar Despesa";
  var descricaoConfirmEdit = "Editar Despesa?";
  const [descontos, setDescontos] = useState([]);
  const listaItens = useRef(Array<Item>());
  const [refresh, doRefresh] = useState(0);
  const [IDDespesa, setIDDespesa] = useState(Number);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmEdit, setShowConfirmEdit] = useState(false);
  const [formData, setFormData] = useState<FimViagem>({
    data_chegada: new Date(),
    km_final: 0,
  });

  const navigateAcerto = () => {
    let selecionado = itens[ID] as Item;
    navigate(`/viagemacerto/${selecionado.id_viagem}`);
  };

  const handleClose = async () => {
    setShow(false);
    let selecionado = itens[ID] as Item;
    await api
      .post(`finalizaViagem/${selecionado.id_viagem}/F`, formData)
      .then(function (response) {
        if (response.data.sucess) {
          if (localStorage.getItem("UserTipo") != "M") {
            setShowConfirm(true);
          } else {
            alert("Viagem Finalizada com Sucesso!");
            navigate("/");
          }
        } else {
          // console.log(response.data);
        }
      });
  };
  const handleCloseCancel = async () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    handleBuscaDados();
  }, []);
  useEffect(() => {
    doRefresh((prev) => prev + 1);
  }, [listaItens.current]);
  useEffect(() => {
    //if (marcado != -1) {
    // console.log('click');

    //}
    if (divGrid.current?.className == "makeStyles-grid70-21") {
      if ((listaItens.current.length = 0)) {
        // console.log('70-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    } else if (divGrid.current?.className == "makeStyles-grid100-22") {
      if (listaItens.current.length > 0) {
        // console.log('100-');
        divGrid.current?.classList.toggle(classes.grid70);
        divGrid.current?.classList.toggle(classes.grid100);
      }
    }
  }, [marcado]);

  const handleBuscaDados = async () => {
    // console.log('busca viagensfinalizadas/F');
    // passando C de Concluido
    await api
      .get(`viagensfinalizadas/A`)
      .then((response) => {
        // console.log('retorno',response.data)
        setItens(response.data);
      })
      .catch((error) => {
        // console.log('erro', error);
      })
      .finally(() => {
        // console.log('finally')
        setLoading(false);
      });
  };
  const handleCancelar = async () => {
    let selecionado = itens[ID] as Item;
    await api
      .put(`/viagem/cancelar/${selecionado.id_viagem}`)
      .then((response) => {
        if (response.data.sucess) {
          alert("Viagem Cancelada!");
        }
      })
      .catch((error) => {})
      .finally(() => {
        handleBuscaDados();
      });
  };
  const formataValor = (value: any, tableMeta: any, updateValue: any) => {
    // console.log(tableMeta);
    // console.log(updateValue);
    const nf = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return nf.format(value);
  };
  const columns = [
    {
      name: "id_viagem",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "placa",
      label: "Veículo",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nome",
      label: "Motorista",
      options: {
        filter: true,
        sort: false,
        print: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
        //display: false,
      },
    },
    {
      name: "peso",
      label: "Peso",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return parseInt(value) + " Ton.";
        },
      },
    },
    {
      name: "valor",
      label: "Frete",
      options: {
        filter: true,
        sort: true,
        //  customHeadLabelRender:(columnMeta : object) => {return columnMeta?.textLabels},
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "comissao_vl",
      label: "Comissão",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "soma",
      label: "Adi. (+)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "desconta",
      label: "Des. (-)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "saldo",
      label: "Saldo",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "total",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
  ];
  const columnsDespesas = [
    {
      name: "id_evento_viagem",
      label: "id_evento_viagem",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "foto",
      label: "foto",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "evento",
      label: "Evento",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "local",
      label: "Local",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "litros",
      label: "Litros",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "media",
      label: "Média",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "valor",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return formataValor(value, tableMeta, updateValue);
        },
      },
    },
    {
      name: "foto",
      label: "Foto",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setShowImage(true)}
            >
              {" "}
              <PhotoIcon />
            </div>
          );
        },
      },
    },
  ];
  var selectRow: number = -1;
  var SelectableRows: SelectableRows = "single";
  const CustomToolbarLoading = ({}) => {
    return (
      <>
        {isLoading && (
          <CircularProgress sx={{ display: "flex" }} color="inherit" />
        )}
      </>
    );
  };
  var options: MUIDataTableOptions = {
    customToolbar: CustomToolbarLoading,
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      setDescontos([]);
      let y1 = rowsSelected as number[];
      selectRow = -1;
      marcado.current = 0;
      setID(-1);
      setVisible(true);
      y1.map((numero: number) => {
        let item = itens[numero] as Item;
        selectRow = numero;
        setVisible(false);
        setID(numero);
      });
      doRefresh((prev) => prev + 1);
    },
    selectableRowsHeader: itens.length > 0,
    selectableRows: SelectableRows,
    textLabels: labels,
    selectableRowsOnClick: true,
    responsive: "standard",

    // responsiveScroll

    rowsPerPage: 15,
    rowsPerPageOptions: [15, 20, 50, 100],
    onRowsDelete(rowsDeleted, newTableData) {
      if (window.confirm("Concelar Viagem?") == true) {
        handleCancelar();
      }
      doRefresh((prev) => prev + 1);
    },
  };
  var SelectableRowsDespesas: SelectableRows = "none";
  var optionsDespesas: MUIDataTableOptions = {
    onRowClick: (rowData) => {
      const id = Number(rowData[0]);
      setIDDespesa(id);
      if (rowData[1] != "") {
        setSourceImage(baseURL + rowData[1]);
      } else {
        setSourceImage("");
      }
      // setShowImage(true);
      // console.log('click numero---', rowData);
    },
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (evt: any) => {
        // alert("Double click!");
        setShowConfirmEdit(true);
      },
    }),
    selectableRows: SelectableRowsDespesas,
    textLabels: labels,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
  };
  const handleClickNew = () => {
    navigate("/estabelecimentonew");
  };
  const handleClickEditDespesa = () => {
    if (IDDespesa >= 0) {
      let selecionado = itens[ID] as Item;
      navigate(`/viagem/${selecionado.id_viagem}/despesa/${IDDespesa}`);
    } else {
      alert("Nenhuma Despesa Selecionada!");
    }
  };
  async function handleClickPosicao() {
    setShow(!show);
  }
  const handleClickNewDespesa = () => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      // console.log(selecionado);
      navigate(`/viagem/${selecionado.id_viagem}/despesa`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };
  const handleClickNewViagem = () => {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      // console.log(selecionado);
      navigate(`/viagem`);
    } else {
      alert("Nenhum Item Selecionado!");
    }
  };

  const handleClickAprovacoes = () => {
    navigate(`/aprovacoes`);
  };
  const handleClickNewViagemResumo = () => {
    navigate(`/viagemresumo`);
  };
  const handleOnReturn = async () => {
    marcado.current = 0;
    setID(-1);
    listaItens.current = Array<Item>();
    //await alert('Viagem(s) Finalizada(s)!');
    doRefresh(0);
    handleBuscaDados();
  };
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  }
  async function handleBuscaDespesas() {
    if (ID >= 0) {
      let selecionado = itens[ID] as Item;
      // console.log(selecionado);
      // navigate(`/viagem/${selecionado.id_viagem}/despesa`);
      await api
        .get(`eventoviagem/${selecionado.id_viagem}/2`)
        .then((response) => {
          //descontos
          // console.log('descontos', response.data)
          setDescontos(response.data);
        });
    } else {
      alert("Nenhum Item Selecionado!");
    }
  }
  return (
    <div>
      <div className={classes.root}>
        <div className={classes.buttonsRight}>
          {localStorage.getItem("UserTipo") != "M" && (
            <>
              <Button
                ref={buttonEditRef}
                //disabled = {visible}
                size="small"
                variant="contained"
                color="primary"
                // className={classes.buttonExtra}
                onClick={(event) => handleClickNewViagem()}
                startIcon={<NoteAdd />}
              >
                Nova Viagem
              </Button>
              <Button
                ref={buttonEditRef}
                //disabled = {visible}
                size="small"
                variant="contained"
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={(event) => handleClickNewViagemResumo()}
                startIcon={<NoteAdd />}
              >
                Nova Viagem Resumida
              </Button>
              {/* <Button
                ref={buttonEditRef}
                // disabled={visible}
                size="small"
                variant="contained"
                color="default"
                className={".btn-danger"}
                style={{
                  marginLeft: "10px",
                  backgroundColor: "orange",
                  // color: "white",
                }}
                onClick={(event) => handleClickAprovacoes()}
                startIcon={<NoteAdd />}
              >
                Aprovações
              </Button> */}
            </>
          )}
        </div>
        <div className={classes.buttons}>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            // className={classes.buttonExtra}
            onClick={(event) => handleClickNewDespesa()}
            startIcon={<NoteAdd />}
          >
            Lançar Despesa
          </Button>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="primary"
            style={{ marginLeft: 10 }}
            onClick={(event) => handleBuscaDespesas()}
            startIcon={<Visualisar />}
          >
            Ver Despesas Lançadas
          </Button>
          <Button
            ref={buttonEditRef}
            disabled={visible}
            size="small"
            variant="contained"
            color="secondary"
            className={classes.buttonAcerto}
            onClick={(event) => handleClickPosicao()}
            startIcon={<AccountTree />}
          >
            Finalizar
          </Button>
        </div>
      </div>
      <div className={classes.grid}>
        <div
          className={
            listaItens.current.length > 0 ? classes.grid70 : classes.grid100
          }
          ref={divGrid}
        >
          <MUIDataTable
            title={`Viagens em Andamento`}
            data={itens}
            columns={columns}
            options={options}
          />
        </div>
        <GridViagmDespesas
          array={listaItens.current}
          refresh={refresh}
          onReturn={handleOnReturn}
        />
      </div>
      {descontos.length > 0 && (
        <div className={classes.tabelas}>
          <div className={classes.tabela} style={{ width: "100%" }}>
            <MUIDataTable
              title={`Despesas`}
              data={descontos}
              columns={columnsDespesas}
              options={optionsDespesas}
            />
          </div>
        </div>
      )}
      <Modal style={{ marginTop: 100 }} show={show} onHide={handleCloseCancel}>
        <Form //className={classes.dadosViavem} ref={formRef}
          onSubmit={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Finalizar Viagem</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1"> */}
            <div className="mb-3">
              {/* <div style={{marginTop: 10, marginLeft: 20}}> */}
              <span
                style={{ marginTop: 10, marginLeft: 20 }}
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-required Mui-required"
              >
                Data da Chegada
              </span>
              {/* </div> */}
              <TextField
                required
                type="date"
                value={formData.data_chegada}
                onChange={handleInputChange}
                // className="mb-3"//
                className={classes.field}
                id="data_chegada"
                name="data_chegada"
                // label="Data da Chegada"
              />
            </div>
            <div className="mb-3">
              {/* <h2>Km Final</h2> */}
              <TextField
                required
                type="number"
                value={formData.km_final}
                onChange={handleInputChange}
                className={classes.field}
                id="km_final"
                name="km_final"
                label="KM Final"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button1 variant="secondary" onClick={handleCloseCancel}>
              Fechar
            </Button1>
            <Button1 variant="primary" type="submit" onClick={handleClose}>
              Finalizar
            </Button1>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalConfirm
        title={titleConfirm}
        description={descricaoConfirm}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirm}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            navigateAcerto();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirm(false);
          }
        }}
      />
      <ModalConfirm
        title={titleConfirmEdit}
        description={descricaoConfirmEdit}
        noText="Não"
        yesText="Sim"
        confirm={true}
        show={showConfirmEdit}
        setShow={(show1: boolean) => {
          // alert(show1);
          if (show1) {
            // setShowConfirm(false);
            // navigateAcerto();
            handleClickEditDespesa();
            //se faz acerto envia para a tela de acerto
          } else {
            // se não fecha o confirm
            setShowConfirmEdit(false);
          }
        }}
      />
      <div>
        <ImageEvento
          show={showImage}
          source={sourceImage}
          onReturn={() => {
            setShowImage(false);
            setSourceImage("");
          }}
        />
      </div>
    </div>
  );
};
export default Viagem;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: 'flex-end',
      alignItems: "flex-start",
      justifyContent: "space-between",
      margin: theme.spacing(1),
    },
    tabelas: {
      marginTop: "10px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex",
      alignItems: "flex-center",
    },
    tabela: {
      width: "50%",
    },
    grid: {
      display: "flex",
      flexDirection: "row",
    },
    grid70: {
      width: "65%",
    },
    grid100: {
      width: "100%",
    },
    field: {
      width: "90%",
      fontSize: "22px",
      paddingTop: "7px",
      paddingBottom: "7px",
      marginLeft: "5px",
      marginRight: "5px",
      color: "black",
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    buttonAcerto: {
      marginLeft: theme.spacing(1),
      //  alignItems: 'flex-start',
      backgroundColor: "green",
    },
    buttonsRight: {
      flexDirection: "row",

      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    buttons: {
      flexDirection: "row",

      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    button: {
      margin: theme.spacing(1),
    },
    buttonHide: {
      display: "none",
    },
    resumo: {
      width: "100%",
      //display: 'flex',
      flexDirection: "column",
      backgroundColor: "#Fff",
      alignItems: "center",
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
      borderRadius: "5px",
      marginLeft: "5px",
      marginRight: "5px",
    },
    // tss-1akey0g-MUIDataTableHeadCell-data :{}
  })
);
